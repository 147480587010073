import React, { useState } from 'react'
import {
    Typography,
    Grid,
    Container,
    Box,
    Paper,
    Button,
    TextField,
    MenuItem
} from "@mui/material"
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    roundGrayBG: {
        backgroundColor: '#F3F3F3',
        borderRadius: '10px'
    }
}));

const BoatListing = ({ onChangeListingCredentials, listingCredentials }) => {
    const classes = useStyles();


    return (
        <Box className={classes.roundGrayBG} p={2} >
            <Grid container spacing={3} >
                <Grid item xs={12} >
                    <TextField
                        id="outlined-basic"
                        label="Boat Title"
                        variant="outlined"
                        required={true}
                        type="text"
                        fullWidth
                        placeholder="Boat Title"
                        onChange={(e) => onChangeListingCredentials("title", e.target.value)}
                        value={listingCredentials.title}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextField
                        id="outlined-basic"
                        label="Boat Description"
                        variant="outlined"
                        required={true}
                        type="text"
                        multiline={true}
                        rows={6}
                        fullWidth
                        placeholder="Boat Description"
                        onChange={(e) => onChangeListingCredentials("description", e.target.value)}
                        value={listingCredentials.description}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default BoatListing