import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import Google from "../../../Assets/Images/Google.png"
import Klaviyo from "../../../Assets/Images/Klaviyo.png"
import meta from "../../../Assets/Images/meta.png"
import shopify from "../../../Assets/Images/shopify.png"
import Webflow from "../../../Assets/Images/Webflow.png"
import bannerTwo from "../../../Assets/Images/homeBannerTwo.png"
import BannerWithText from '../../../Components/BannerWithText';
import sendIcon from "../../../Assets/Icons/sendIcon.png"
import blueSendIcon from '../../../Assets/Icons/blueSendIcon.png'
import ContainedBtn from '../../../Components/ContainedBtn';
import circleCheck from "../../../Assets/Icons/circle-check.png"



const useStyles = makeStyles((theme) => ({

    logoWrapper: {
        display: 'flex',
        // flexWrap: 'wrap',
        flexDirection: 'row',
        // justifyContent: 'center',
        // padding: '0px 10px',
        height: 'fit-content',
        marginBottom: '15px',
        // backgroundColor:'red'
    },
    widthCntrlr: {
        width: '100%',
        // minWidth: '800px',
        display: 'flex',
        justifyContent: 'start',
        // backgroundColor:'yellow',

        flexWrap: 'wrap',
        [theme.breakpoints.down("md")]: {
            width: '100%',
            minWidth: '0px',
        },
        [theme.breakpoints.down("m")]: {
            width: '100%',
            minWidth: '0px',
            flexWrap: 'wrap'
        },

    },
    logoImg: {
        maxWidth: '20%',
        width: '113px',
        height: 'auto',
        margin: 'auto',
        // border:'1px solid black',
        [theme.breakpoints.down("md")]: {
            maxWidth: '30%',
            margin: 'auto',
        },
        [theme.breakpoints.down("m")]: {
            maxWidth: '50%',
            margin: 'auto',
        },
    },
    bannerTwo: {
        marginTop: '60px !important',
        [theme.breakpoints.down("sevenHalfS")]: {
            marginTop: '40px !important',
        },
    },
    circleCheckIcon: {
        width: '24px',
        height: '24px',
        marginRight: '17px'
    }
}));




const HeroSectionText = styled(Typography)(({ theme }) => ({
    fontSize: '25px',
    lineHeight: '30px',
    // color: 'white',
    // maxWidth: '640px',
    // marginBottom: '30px',
    // marginTop: '20px',
    [theme.breakpoints.down("lg")]: {
        fontSize: '22px',
        lineHeight: '25px',
    },
    [theme.breakpoints.down("md")]: {
        fontSize: '20px',
        lineHeight: '25px',
    },
    [theme.breakpoints.down("nineS")]: {
        fontSize: '16px',
        lineHeight: '21px',
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: '16px',
        lineHeight: '25px',
    },
    [theme.breakpoints.down("ms")]: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    letterSpacing: '1px',
    marginTop: '-8px'
}));

const RightSide = (props) => {
    const classes = useStyles();

    return (
        <Grid container  >
            <Grid item xs={12} sx={{ margin: { md: '24px 0px', xs: '16px 0px', fontFamily: 'Gilroy Regular, sans-serif' } }} >
                <Box sx={{ display: 'flex', fontFamily: 'Gilroy Regular, sans-serif', mt: 5 }} >
                    <img src={circleCheck} alt="Icon" className={classes.circleCheckIcon} />
                    <HeroSectionText  >Efficient and effective to help you make sales on the fly
                    </HeroSectionText>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ margin: { md: '24px 0px', xs: '16px 0px', fontFamily: 'Gilroy Regular, sans-serif' } }} >
                <Box sx={{ display: 'flex' }} >
                    <img src={circleCheck} alt="Icon" className={classes.circleCheckIcon} />
                    <HeroSectionText>
                        We've got 24/7 customer service ready to assist you with anything.
                    </HeroSectionText>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ margin: { md: '24px 0px', xs: '16px 0px', fontFamily: 'Gilroy Regular, sans-serif' } }} >
                <Box sx={{ display: 'flex' }} >
                    <img src={circleCheck} alt="Icon" className={classes.circleCheckIcon} />
                    <HeroSectionText>
                        We have the experience working with the top companies in the industry to help create this platform.
                    </HeroSectionText>
                </Box>
            </Grid>
            <Grid item xs={12} my={2} >
                <ContainedBtn
                    text='Become a Member'
                    sx={{ '&:hover': { color: '#0B1A9B', outline: '1px solid #0B1A9B' }, textTransform: 'capitalize', }}
                    endIcon={<img src={sendIcon} style={{ width: '14px', height: '14px' }} alt="icon" />}
                />
            </Grid>
        </Grid>
    )
}

const BannerTwo = (props) => {
    const classes = useStyles();
    // const { classes } = props;
    const logoArray = [Google, meta, shopify, Webflow, Klaviyo]


    return (
        <div>
            <Container maxWidth='fl'   >
                <Grid container my={5} >
                    {/* <Grid item xs={12} >
                        <div className={classes.logoWrapper} >
                            <div className={classes.widthCntrlr} >
                                {logoArray.map((image, i) => {
                                    return (
                                        <img src={image} className={classes.logoImg} key={i} />
                                    )
                                })}
                            </div>
                        </div>
                    </Grid> */}
                    <Grid item xs={12} className={classes.bannerTwo} >
                        <BannerWithText image={bannerTwo} TextComp={<RightSide />} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default BannerTwo