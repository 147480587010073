import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Container,
  Switch,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

const BoatTypeHead = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontFamily: "Gilroy Bold, sans-serif",
  fontWeight: "bold",
  color: "#0B1A9B",
  // padding: "5px ",
}));

const DataBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
}));

const BroucherPricing = ({
  listingDetails,
  currentUser,
  isEditAllow,
  DataTextStyle,
  parentClasses,
  showPercentages,
}) => {
  const markUpPrices = useSelector((state) => state.listings.markUpPrices);

  const DataText = styled(Box)(({ theme }) => ({
    fontSize: "10px",
    fontFamily: "Gilroy Regular, sans-serif",
    color: "#fff",
    fontWeight: "bold",
    whiteSpace: "pre-line",
    letterSpacing: "1px",
    // background: "#0B1A9B",
    // marginTop: "5px",
    // [theme.breakpoints.up("xs")]: {
    //   fontSize: "12px",
    //   letterSpacing: "1px",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   fontSize: "12px",
    //   letterSpacing: "1px",
    // },
    // [theme.breakpoints.up("md")]: {
    //   fontSize: "12px",
    //   letterSpacing: "1px",
    // },
  }));

  let discountedPriceHoursRange = [2, 4, 6, 8];

  const [weekdaysMarkupPrice, setWeekdaysMarkupPrice] = useState({
    "1hrs": "",
    "2hrs": "",
    "3hrs": "",
    "4hrs": "",
    "5hrs": "",
    "6hrs": "",
    "8hrs": "",
  });
  const [weekendMarkupPrice, setWeekendMarkupPrice] = useState({
    "1hrs": "",
    "2hrs": "",
    "3hrs": "",
    "4hrs": "",
    "5hrs": "",
    "6hrs": "",
    "8hrs": "",
  });

  const [termCharterMarkupPrice, setTermCharterMarkupPrice] = useState(() => {
    if (listingDetails?.pricingType === "termCharter") {
      const keys = Object.keys(listingDetails?.termCharter?.pricing);
      return Object.fromEntries(keys.map((key) => [key, ""]));
    } else {
      return {};
    }
  });

  return (
    <div>
      {listingDetails?.pricingType == "dayCharter" ? (
        <>
          <Grid
            container
            sx={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "end",
              // flexWrap: "wrap",
              // border: "1px solid red",
            }}
          >
            {listingDetails.dayCharter.pricingSameEachDay === "yes" && (
            <Grid item xs={8} mt={2} sx={{ margin: "12px" }}>
            <DataText
              className={parentClasses?.dataTextStyle}
              sx={{ color: "black", textAlign: "end" }}
            >
                  Week Pricing (Mon - Sun){" "}
                </DataText>
              </Grid>
              
            )}

            {listingDetails?.dayCharter?.pricingSameEachDay === "yes" && (
              <div>
                {listingDetails.dayCharter.minimumHoursBeBooked < 4
                  ? markUpPrices[listingDetails?.id]?.weeklyPrice?.Monday?.[
                      `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                    ].trim().length > 0 && (
                      <Grid
                        sx={{
                          margin: "2px",
                          display: "flex",
                        }}
                        item
                      >
                        <DataBox
                          sx={{
                            // mb: 1,
                            p: 1.5,
                            backgroundColor: "#0B1A9B",
                            // border: "1px solid #ccc",
                            borderRadius: "8px",
                            width: "130px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // cursor: "pointer",
                            // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            // "&:hover": {
                            //   backgroundColor: "lightgreen",
                            // },
                          }}
                        >
                          {markUpPrices[
                            listingDetails?.id
                          ]?.weeklyPrice?.Monday?.[
                            `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                          ].trim().length > 0 && (
                            <DataText
                              className={parentClasses?.dataTextStyle}
                              color={"white"}
                            >
                              {`${listingDetails.dayCharter.minimumHoursBeBooked}hrs Price`}{" "}
                              :{" "}
                            </DataText>
                          )}
                          <DataText
                            className={parentClasses?.dataTextStyle}
                            style={{
                              color: "white",
                              fontFamily: "Gilroy Bold, sans-serif",
                              fontSize: "14px",
                            }}
                          >
                            {markUpPrices[
                              listingDetails?.id
                            ]?.weeklyPrice?.Monday?.[
                              `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                            ].trim().length > 0
                              ? `$${
                                  Number(
                                    listingDetails?.dayCharter?.weeklyPrice
                                      ?.Monday?.[
                                      `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                                    ]
                                  ) +
                                  Number(
                                    markUpPrices[listingDetails?.id]
                                      ?.weeklyPrice?.Monday?.[
                                      `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                                    ]
                                  )
                                }`
                              : `US$ ${
                                  listingDetails?.dayCharter?.weeklyPrice
                                    ?.Monday?.[
                                    `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                                  ]
                                }`}
                          </DataText>
                        </DataBox>
                      </Grid>
                    )
                  : null}
                {/* Different Pricing */}
                {listingDetails.dayCharter.pricingSameEachDay === "no" && (
                  <Grid item xs={8} mt={2} sx={{ margin: "12px" }}>
                  <DataText
                    className={parentClasses?.dataTextStyle}
                    sx={{ color: "black", textAlign: "end" }}
                  >
                      Weekdays Pricing (Mon - Thur){" "}
                    </DataText>
                  </Grid>
                )}
                {discountedPriceHoursRange.map((disPrice, ind) => {
                  let currentPrice =
                    listingDetails?.dayCharter?.weeklyPrice?.Monday?.[
                      `${disPrice}hrs`
                    ];

                  if (
                    listingDetails.dayCharter.minimumHoursBeBooked <= disPrice
                  )
                    return (
                      <Grid
                        item
                        sx={{
                          margin: "2px",
                          display: "flex",
                        }}
                        key={ind}
                      >
                        {Number(
                          markUpPrices[listingDetails?.id]?.weeklyPrice
                            ?.Monday?.[`${disPrice}hrs`]
                        ) > 0 && (
                          <DataBox
                            sx={{
                              // mb: 1,
                              p: 1.5,
                              backgroundColor: "#0B1A9B",
                              // border: "1px solid #ccc",
                              borderRadius: "8px",
                              width: "130px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // cursor: "pointer",
                              // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                              // "&:hover": {
                              //   backgroundColor: "lightgreen",
                              // },
                            }}
                          >
                            {Number(
                              markUpPrices[listingDetails?.id]?.weeklyPrice
                                ?.Monday?.[`${disPrice}hrs`]
                            ) > 0 && (
                              <DataText
                                className={parentClasses?.dataTextStyle}
                              >
                                {`${disPrice}hrs Price`}
                              </DataText>
                            )}
                            {Number(
                              markUpPrices[listingDetails?.id]?.weeklyPrice
                                ?.Monday?.[`${disPrice}hrs`]
                            ) > 0 && (
                              <DataText
                                color={"white"}
                                style={{
                                  color: "white",
                                  fontFamily: "Gilroy Bold, sans-serif",
                                  fontSize: "14px",
                                }}
                                className={parentClasses?.dataTextStyle}
                              >
                                {markUpPrices[
                                  listingDetails?.id
                                ]?.weeklyPrice?.Monday?.[
                                  `${disPrice}hrs`
                                ].trim().length > 0
                                  ? `$${
                                      Number(currentPrice) +
                                      Number(
                                        markUpPrices[listingDetails?.id]
                                          ?.weeklyPrice?.Monday?.[
                                          `${disPrice}hrs`
                                        ]
                                      )
                                    }`
                                  : `$${currentPrice}`}
                              </DataText>
                            )}
                          </DataBox>
                        )}
                      </Grid>
                    );
                })}
              </div>
            )}
            {listingDetails.dayCharter.pricingSameEachDay === "no" && (
              <Grid item xs={8} mt={2} sx={{ margin: "12px" }}>
                <DataText
                  className={parentClasses?.dataTextStyle}
                  sx={{ color: "black", textAlign: "end" }}
                >
                  Weekend Pricing (Fri - Sun){" "}
                </DataText>
              </Grid>
            )}
            {listingDetails.dayCharter.pricingSameEachDay === "no" && (
              <>
                {!discountedPriceHoursRange.includes(
                  listingDetails.dayCharter.minimumHoursBeBooked
                ) &&
                  Number(
                    markUpPrices[listingDetails?.id]?.weeklyPrice?.Monday?.[
                      `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                    ]
                  ) > 0 && (
                    <Grid
                      item
                      // xs={12}
                      // md={6}
                      sx={{
                        margin: "2px",
                        display: "flex",
                      }}
                    >
                      <DataBox
                        sx={{
                          // mb: 1,
                          p: 1.5,
                          backgroundColor: "#0B1A9B",
                          // border: "1px solid #ccc",
                          borderRadius: "8px",
                          width: "130px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // cursor: "pointer",
                          // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          // "&:hover": {
                          //   backgroundColor: "lightgreen",
                          // },
                        }}
                      >
                        <DataText className={parentClasses?.dataTextStyle}>
                          {`${listingDetails.dayCharter.minimumHoursBeBooked}hrs Price`}{" "}
                        </DataText>
                        <DataText
                          style={{
                            color: "white",
                            fontFamily: "Gilroy Bold, sans-serif",
                            fontSize: "14px",
                          }}
                          className={parentClasses?.dataTextStyle}
                        >
                          {markUpPrices[
                            listingDetails?.id
                          ]?.weeklyPrice?.Monday?.[
                            `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                          ].trim().length > 0
                            ? `$${
                                Number(
                                  listingDetails?.dayCharter?.weeklyPrice
                                    ?.Monday?.[
                                    `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                                  ]
                                ) +
                                Number(
                                  markUpPrices[listingDetails?.id]?.weeklyPrice
                                    ?.Monday?.[
                                    `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                                  ]
                                )
                              }`
                            : `$${
                                listingDetails?.dayCharter?.weeklyPrice
                                  ?.Monday?.[
                                  `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                                ]
                              }`}
                        </DataText>
                      </DataBox>
                    </Grid>
                  )}
                {discountedPriceHoursRange.map((disPrice, ind) => {
                  let currentPrice =
                    listingDetails?.dayCharter?.weeklyPrice?.Monday?.[
                      `${disPrice}hrs`
                    ];
                  if (
                    listingDetails.dayCharter.minimumHoursBeBooked <=
                      disPrice &&
                    Number(
                      markUpPrices[listingDetails?.id]?.weeklyPrice?.Monday?.[
                        `${disPrice}hrs`
                      ]
                    ) > 0
                  )
                    return (
                      <Grid
                        item
                        sx={{
                          margin: "2px",
                          display: "flex",
                        }}
                        key={ind}
                      >
                        <DataBox
                          sx={{
                            // mb: 1,
                            p: 1.5,
                            backgroundColor: "#0B1A9B",
                            // border: "1px solid #ccc",
                            borderRadius: "8px",
                            width: "130px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // cursor: "pointer",
                            // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            // "&:hover": {
                            //   backgroundColor: "lightgreen",
                            // },
                          }}
                        >
                          <DataText className={parentClasses?.dataTextStyle}>
                            {`${disPrice}hrs Price`} :{" "}
                          </DataText>
                          <DataText
                            className={parentClasses?.dataTextStyle}
                            style={{
                              color: "white",
                              fontFamily: "Gilroy Bold, sans-serif",
                              fontSize: "14px",
                            }}
                          >
                            {markUpPrices[
                              listingDetails?.id
                            ]?.weeklyPrice?.Monday?.[`${disPrice}hrs`].trim()
                              .length > 0
                              ? `$${
                                  Number(currentPrice) +
                                  Number(
                                    markUpPrices[listingDetails?.id]
                                      ?.weeklyPrice?.Monday?.[`${disPrice}hrs`]
                                  )
                                }`
                              : `$${currentPrice}`}
                          </DataText>
                        </DataBox>
                      </Grid>
                    );
                })}
              </>
            )}
          </Grid>
          {listingDetails.dayCharter.pricingSameEachDay === "no" && (
            <Grid item xs={8} mt={2} sx={{ margin: "12px" }}>
              <DataText
                className={parentClasses?.dataTextStyle}
                sx={{ color: "black", textAlign: "end" }}
              >
                Weekend Pricing (Fri - Sun){" "}
              </DataText>
            </Grid>
          )}
          <Grid
            container
            sx={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "end",
              // flexWrap: "wrap",
              // border: "1px solid red",
            }}
          >
            {listingDetails.dayCharter.pricingSameEachDay === "no" && (
              <>
                {!discountedPriceHoursRange.includes(
                  listingDetails.dayCharter.minimumHoursBeBooked
                ) &&
                  Number(
                    markUpPrices[listingDetails?.id]?.weeklyPrice?.Friday?.[
                      `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                    ]
                  ) > 0 && (
                    <Grid
                      item
                      sx={{
                        margin: "2px",
                        display: "flex",
                      }}
                    >
                      <DataBox
                        sx={{
                          // mb: 1,
                          p: 1.5,
                          backgroundColor: "#0B1A9B",
                          // border: "1px solid #ccc",
                          borderRadius: "8px",
                          width: "130px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // cursor: "pointer",
                          // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          // "&:hover": {
                          //   backgroundColor: "lightgreen",
                          // },
                        }}
                      >
                        <DataText className={parentClasses?.dataTextStyle}>
                          {`${listingDetails.dayCharter.minimumHoursBeBooked}hrs Price`}{" "}
                          :{" "}
                        </DataText>
                        <DataText
                          style={{
                            color: "white",
                            fontFamily: "Gilroy Bold, sans-serif",
                            fontSize: "14px",
                          }}
                          className={parentClasses?.dataTextStyle}
                        >
                          {markUpPrices[
                            listingDetails?.id
                          ]?.weeklyPrice?.Friday?.[
                            `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                          ].trim().length > 0
                            ? `$${
                                Number(
                                  listingDetails?.dayCharter?.weeklyPrice
                                    ?.Friday?.[
                                    `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                                  ]
                                ) +
                                Number(
                                  markUpPrices[listingDetails?.id]?.weeklyPrice
                                    ?.Friday?.[
                                    `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                                  ]
                                )
                              }`
                            : `$${
                                listingDetails?.dayCharter?.weeklyPrice
                                  ?.Friday?.[
                                  `${listingDetails.dayCharter.minimumHoursBeBooked}hrs`
                                ]
                              }`}
                        </DataText>
                      </DataBox>
                    </Grid>
                  )}
                {discountedPriceHoursRange.map((disPrice, ind) => {
                  let currentPrice =
                    listingDetails?.dayCharter?.weeklyPrice?.Friday?.[
                      `${disPrice}hrs`
                    ];
                  if (
                    listingDetails.dayCharter.minimumHoursBeBooked <=
                      disPrice &&
                    Number(
                      markUpPrices[listingDetails?.id]?.weeklyPrice?.Friday?.[
                        `${disPrice}hrs`
                      ]
                    ) > 0
                  )
                    return (
                      <Grid
                        item
                        sx={{
                          margin: "2px",
                          display: "flex",
                        }}
                      >
                        <DataBox
                          sx={{
                            // mb: 1,
                            p: 1.5,
                            backgroundColor: "#0B1A9B",
                            // border: "1px solid #ccc",
                            borderRadius: "8px",
                            width: "130px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // cursor: "pointer",
                            // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            // "&:hover": {
                            //   backgroundColor: "lightgreen",
                            // },
                          }}
                        >
                          <DataText className={parentClasses?.dataTextStyle}>
                            {`${disPrice}hrs Price`} :{" "}
                          </DataText>
                          <DataText
                            style={{
                              color: "white",
                              fontFamily: "Gilroy Bold, sans-serif",
                              fontSize: "14px",
                            }}
                            className={parentClasses?.dataTextStyle}
                          >
                            {markUpPrices[
                              listingDetails?.id
                            ]?.weeklyPrice?.Friday?.[`${disPrice}hrs`].trim()
                              .length > 0
                              ? `$${
                                  Number(currentPrice) +
                                  Number(
                                    markUpPrices[listingDetails?.id]
                                      ?.weeklyPrice?.Friday?.[`${disPrice}hrs`]
                                  )
                                }`
                              : `$${currentPrice}`}
                          </DataText>
                        </DataBox>
                      </Grid>
                    );
                })}
              </>
            )}
          </Grid>
        </>
      ) : (
        <Grid container>
          {Object.keys(listingDetails?.termCharter?.pricing)?.map(
            (dayPrice, ind) => {
              let currentPrice =
                listingDetails?.termCharter?.pricing?.[dayPrice];
              return (
                <Grid
                  item
                  // xs={12}
                  // md={6}
                  sx={{
                    margin: "2px",
                    display: "flex",
                  }}
                >
                  <DataBox
                    sx={{
                      // mb: 1,
                      p: 1.5,
                      backgroundColor: "#0B1A9B",
                      // border: "1px solid #ccc",
                      borderRadius: "8px",
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // cursor: "pointer",
                      // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      // "&:hover": {
                      //   backgroundColor: "lightgreen",
                      // },
                    }}
                  >
                    <DataText className={parentClasses?.dataTextStyle}>
                      {dayPrice} Day Price
                    </DataText>
                    <DataText
                      style={{
                        color: "white",
                        fontFamily: "Gilroy Bold, sans-serif",
                        fontSize: "14px",
                      }}
                      className={parentClasses?.dataTextStyle}
                    >
                      {termCharterMarkupPrice?.[dayPrice].trim().length > 0
                        ? `$${
                            Number(currentPrice) +
                            Number(termCharterMarkupPrice?.[dayPrice])
                          }`
                        : `$${currentPrice}`}
                    </DataText>
                    <DataText className={parentClasses?.dataTextStyle}>
                      {listingDetails?.termCharter?.commissionPercentage}%
                      Commision
                    </DataText>
                  </DataBox>
                </Grid>
              );
            }
          )}
        </Grid>
      )}
    </div>
  );
};

export default BroucherPricing;
