import React from "react";
import { Routes, Route } from "react-router-dom";
import PublicRoute from "./PublicRoutes";
import Home from "../Screens/Home/Home";
import AboutUs from "../Screens/AboutUs/AboutUs";
import MemberShip from "../Screens/MemberShip/MemberShip";
import Pricing from "../Screens/Pricing/Pricing";
import Login from "../Screens/Login/Login";
import Signup from "../Screens/Signup/Signup";
import Dashboard from "../Screens/Dashboard/Dashboard";
import Inventory from "../Screens/Inventory/Inventory";
import Reports from "../Screens/Reports/Reports";
import Mls from "../Screens/MLS/Mls";
import AddListing from "../Screens/AddListing/AddListing";
import MlsFilters from "../Screens/MLS/Components/MlsFilters";
import AccountSetting from "../Screens/AccountSetting/AccountSetting";
import ListingDetails from "../Screens/ListingDetails/ListingDetails";
import GeneratePdfs from "../Screens/GeneratePdfs/GeneratePdfs";
import EditListing from "../Screens/EditListing/EditListing";
import AddPDF from "../Screens/AddPDF/AddPDF";
import PDFViewer from "../Screens/GeneratePdfs/PdfViewer";

const routesArr = [
  {
    path: "/",
    Component: Home,
    hideDrawer: false,
    hideFooter: false,
    adminDrawer: false,
    privateRoute: false,
    acountAccess: ["viewAndUpload", "viewOnly"],
  },
  {
    path: "/about",
    Component: AboutUs,
    hideDrawer: false,
    hideFooter: false,
    adminDrawer: false,
    privateRoute: false,
    acountAccess: ["viewAndUpload", "viewOnly"],
  },
  {
    path: "/membership-benefit",
    Component: MemberShip,
    hideDrawer: false,
    hideFooter: false,
    adminDrawer: false,
    privateRoute: false,
    acountAccess: ["viewAndUpload", "viewOnly"],
  },
  {
    path: "/pricing",
    Component: Pricing,
    hideDrawer: false,
    hideFooter: false,
    adminDrawer: false,
    privateRoute: false,
    acountAccess: ["viewAndUpload", "viewOnly", "free"],
  },
  {
    path: "/login",
    Component: Login,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: false,
    privateRoute: false,
    acountAccess: ["viewAndUpload", "viewOnly"],
  },
  {
    path: "/signup",
    Component: Signup,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: false,
    privateRoute: false,
    acountAccess: ["viewAndUpload", "viewOnly"],
  },
  {
    path: "/dashboard",
    Component: Dashboard,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: true,
    privateRoute: true,
    acountAccess: ["viewAndUpload"],
  },
  {
    path: "/inventory",
    Component: Inventory,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: true,
    privateRoute: true,
    acountAccess: ["viewAndUpload"],
  },
  // {
  //     path: "/reports",
  //     Component: Reports,
  //     hideDrawer: true,
  //     hideFooter: true,
  //     adminDrawer: true,
  //     privateRoute: true,
  //     acountAccess: ['viewAndUpload']
  // },
  {
    path: "/addlisting",
    Component: AddListing,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: true,
    privateRoute: true,
    acountAccess: ["viewAndUpload"],
  },
  {
    path: "/editlisting/:id",
    Component: EditListing,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: true,
    privateRoute: true,
    acountAccess: ["viewAndUpload"],
  },
  {
    path: "/mls",
    Component: Mls,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: true,
    privateRoute: true,
    acountAccess: ["viewAndUpload", "viewOnly"],
  },
  {
    path: "/mls/Filters",
    Component: MlsFilters,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: true,
    privateRoute: true,
    acountAccess: ["viewAndUpload", "viewOnly"],
  },
  {
    path: "/account",
    Component: AccountSetting,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: true,
    privateRoute: true,
    acountAccess: ["viewAndUpload", "viewOnly", "free"],
  },
  {
    path: "/listing-details/:id/:brokerID",
    Component: ListingDetails,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: true,
    privateRoute: true,
    acountAccess: ["viewAndUpload", "viewOnly"],
  },
  {
    path: "/generate-pdfs/:ids/",
    Component: GeneratePdfs,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: true,
    privateRoute: true,
    acountAccess: ["viewAndUpload", "viewOnly"],
  },
  {
    path: "/AddPDF",
    Component: AddPDF,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: false,
    privateRoute: false,
    acountAccess: ["viewAndUpload"],
  },
  {
    path: "/pdfviewer/:ids/",
    Component: PDFViewer,
    hideDrawer: true,
    hideFooter: true,
    adminDrawer: false,
    privateRoute: false,
    acountAccess: ["viewAndUpload", "viewOnly", "free"],
  },
];

const routes = () => {
  return (
    <Routes>
      {routesArr.map(
        ({
          path,
          Component,
          hideDrawer,
          hideFooter,
          adminDrawer,
          privateRoute,
          acountAccess,
        }) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                <PublicRoute
                  component={Component}
                  hideDrawer={hideDrawer}
                  adminDrawer={adminDrawer}
                  hideFooter={hideFooter}
                  privateRoute={privateRoute}
                  acountAccess={acountAccess}
                />
              }
            />
          );
        }
      )}
    </Routes>
  );
};

export default routes;
