import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";


import { TeamData } from '../../../State/Home';
import SectionHead from '../../../Components/SectionHead';

const useStyles = makeStyles((theme) => ({
    teamImg: {
        // width: 'fit-content',
        // height: 'auto',
        width:'100%',
        height:'100%',
        marginBottom: '10px'
    }
}));


const Team = () => {
    const classes = useStyles();
    return (
        <>
            <Container maxWidth='fl'>
                <Grid container mt={10} mb={5} >
                    <Grid item xs={12} sx={{ margin: { lg: '24px 0px 30px 0px', md: '20px 0px 30px 0px', xs: '0px 0px 20px 0px' } }} >
                        <SectionHead title='Team' sx={{ textAlign: 'center' }} />
                    </Grid>
                    {
                        TeamData.map((team, ind) => {
                            return (
                                <Grid key={ind} item xs={12} sm={6} lg={4} sx={{ marginBottom: { xs: '50px', sm: '10px', md: '10px', lg: '0px' } }} >
                                    <Box  m={2}  >
                                        <img src={team.image} alt="Service" className={classes.teamImg} />
                                        <Typography variant='h4' sx={{ margin: '10px 0px', fontWeight: 'bold', textAlign: 'center' }} > {team.name} </Typography>
                                        <Typography style={{ textAlign: 'center' }} >{team.role}</Typography>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </>
    )
}

export default Team