import React, { useState } from 'react'
import {
    Typography,
    Grid,
    Container,
    Box,
    Paper,
    Button,
    TextField,
    MenuItem,
    IconButton,
    OutlinedInput,
    CircularProgress,
    ToggleButtonGroup,
    ToggleButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    InputAdornment,
    Snackbar
} from "@mui/material"
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import ContainedBtn from '../../../Components/ContainedBtn';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PlaceIcon from '@mui/icons-material/Place';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

// import {
//     geocodeByAddress,
//     geocodeByPlaceId,
//     getLatLng,
//   } from 'react-places-autocomplete';

import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';


const useStyles = makeStyles((theme) => ({
    roundGrayBG: {
        backgroundColor: '#F3F3F3',
        borderRadius: '10px'
    },
    checkBoxCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    outlinedBtn: {
        border: '1px solid #0B1A9B !important',
        color: '#0B1A9B !important',
        background: '#fff !important',
        fontSize: '17px',
        fontWeight: 'bold'
    },
    suggestionCntnr: {
        boxShadow: '0 2px 4px rgba(0,0,0,.2) !important',
        borderRadius: '0px 0px 16px 16px',
        outline: '0px 0px 16px 16px'
    },
    activeSuggestionRow: {
        cursor: 'pointer',
        padding: '4px 16px',
        display: 'flex',
        gap: '10px'
    },
    suggestionRow: {
        cursor: 'pointer',
        padding: '4px 16px',
        display: 'flex',
        gap: '10px'
    },
    addBtn: {
        height: '45px',
        width: '175px',
        marginBottom: '10px',
        marginTop: '8px',
        marginBottom: '8px',
        '&:hover': { color: '#0B1A9B', outline: '1px solid #0B1A9B !important' },
    },
    LocationsWrapper: {
        display: 'flex !important',
        flexWrap: 'wrap !important',
        flexDirection: 'column !important'
    }
}));

const DataText = styled(Box)(({ theme }) => ({
    fontSize: '20px',
    color: '#777777',
    fontFamily: 'Gilroy Regular, sans-serif !important',
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
    letterSpacing: '1px',
}));

const LocationsCntnr = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    margin: `${theme.spacing(1)} 0px`,
    maxWidth: 'fit-content',
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '50px',
    alignItems: 'center'
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BoatLocation = ({ onChangeListingCredentials, listingCredentials }) => {
    const classes = useStyles();

    const [multipleLocationObject, setmultipleLocationObject] = useState({
        latLng: '',
        address: '',
        formattedAddress: '',
        country: '',
        region: '',
        dropOffAddress: ''
    })
    const [isLocationWarningSnackBarOpen, setIsLocationWarningSnackBarOpen] = React.useState(false);


    const handleChange = (address1) => {
        let boatAddress = listingCredentials.boatAddress
        boatAddress.address = address1
        // console.log(boatAddress, "boatAddress")
        onChangeListingCredentials("boatAddress", boatAddress);
    };

    const handleSelect = (address1) => {
        let country = '';
        let region = '';
        geocodeByAddress(address1)
            .then((results) => {
                // console.log(results, "results");
                results[0]?.address_components.forEach((component) => {
                    if (component.types.includes('country')) {
                        country = component.long_name;
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                        region = component.long_name;
                    }
                });
                const formattedAddress = results[0]?.formatted_address;
                return getLatLng(results[0]).then((latLng) => {
                    // Create an object with all the updated values


                    const updatedState = {
                        address: address1,
                        formattedAddress: formattedAddress,
                        latLng: latLng,
                        country: country,
                        region: region,
                    };
                    // console.log(updatedState, "updatedState");
                    // Update the state with all the values at once
                    onChangeListingCredentials('boatAddress', updatedState);
                    // console.log("latLng", latLng);
                });
            })
            .catch((error) => {
                onChangeListingCredentials("address", address1);
                console.error("Error", error);
            });
    };

    const handlePickUpLocationChange = (address1) => {
        let boatPickUpAddress = listingCredentials.boatPickUpAddress
        boatPickUpAddress.address = address1
        onChangeListingCredentials("boatPickUpAddress", boatPickUpAddress);
    }

    const handlePickUpLocationSelect = (address1) => {
        let country = '';
        let region = '';
        geocodeByAddress(address1)
            .then((results) => {
                // console.log(results, "results");
                results[0]?.address_components.forEach((component) => {
                    if (component.types.includes('country')) {
                        country = component.long_name;
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                        region = component.long_name;
                    }
                });
                const formattedAddress = results[0]?.formatted_address;
                return getLatLng(results[0]).then((latLng) => {
                    // Create an object with all the updated values


                    const updatedState = {
                        address: address1,
                        formattedAddress: formattedAddress,
                        latLng: latLng,
                        country: country,
                        region: region,
                    };
                    // console.log(updatedState, "updatedState");
                    // Update the state with all the values at once
                    onChangeListingCredentials('boatPickUpAddress', updatedState);
                    // console.log("latLng", latLng);
                });
            })
            .catch((error) => {
                // onChangeListingCredentials("address", address1);
                console.error("Error", error);
            });
    };


    const handleMultipleLocationChange = (address1) => {
        // let boatDropOffAddress = listingCredentials.boatDropOffAddress
        // boatDropOffAddress.address = address1
        // onChangeListingCredentials("boatDropOffAddress", boatDropOffAddress);
        // console.log(address1,"handleMultipleLocationChange")
        setmultipleLocationObject({
            ...multipleLocationObject,
            address: address1
        })
    };

    const handleMultipleLocationSelect = (address1) => {
        let country = '';
        let region = '';
        geocodeByAddress(address1)
            .then((results) => {
                results[0]?.address_components.forEach((component) => {
                    if (component.types.includes('country')) {
                        country = component.long_name;
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                        region = component.long_name;
                    }
                });
                const formattedAddress = results[0]?.formatted_address;
                return getLatLng(results[0]).then((latLng) => {


                    const updatedState = {
                        address: address1,
                        formattedAddress: formattedAddress,
                        latLng: latLng,
                        country: country,
                        region: region,
                    };
                    // Update the state with all the values at once
                    setmultipleLocationObject({ ...updatedState });
                });
            })
            .catch((error) => {
                // onChangeListingCredentials("address", address1);
                console.error("Error", error);
            });
    };

    const addMultipleBoatLocation = () => {
        onChangeListingCredentials('multipleLocations', [...listingCredentials.multipleLocations, { ...multipleLocationObject }]);
        setmultipleLocationObject({
            latLng: '',
            address: '',
            formattedAddress: '',
            country: '',
            region: '',
            dropOffAddress: ''
        });
    }


    const removeLocationFromMultipleLocations = (index) => {
        let location = [...listingCredentials.multipleLocations]
        location = location.filter((val, ind) => ind !== index)
        onChangeListingCredentials('multipleLocations', [...location]);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsLocationWarningSnackBarOpen(false);
    };


    return (
        <>
            <Box className={classes.roundGrayBG} p={2} >
                <Grid container spacing={2} >
                    {/* <Grid item xs={12} >
                    <Typography>Smart Address finder</Typography>
                </Grid> */}
                    <Grid item xs={12}   >
                        <Typography>Vessels City of Operation</Typography>
                        <PlacesAutocomplete
                            value={listingCredentials.boatAddress.address}
                            onChange={handleChange}
                            onSelect={handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="location-search-input-dropdown1">
                                    <OutlinedInput
                                        fullWidth={true}
                                        required={true}
                                        {...getInputProps({
                                            placeholder: "Major City",
                                            className: "location-search-input1",
                                        })}
                                        style={{
                                            color: '#6c757d',
                                        }}
                                        type="text"

                                    />
                                    <div
                                        className={classes.suggestionCntnr}
                                        style={{
                                            backgroundColor: suggestions.length > 0 ? "#ffff " : "",
                                            border: suggestions.length > 0 ? "#6c707d80" : "",
                                            boxShadow: suggestions.length > 0 ? "1px 1px 5px grey" : "",
                                        }}
                                    >
                                        {suggestions.map((suggestion) => {
                                            const className = suggestion.active
                                                ? classes.suggestionRow
                                                : classes.activeSuggestionRow;
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                : { backgroundColor: "#ffffff", cursor: "pointer" };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <PlaceIcon className="place-mui-icon1" />
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </Grid>


                    <Grid item xs={12}   >
                        <Typography>Add specific pick up address</Typography>
                        <PlacesAutocomplete
                            value={listingCredentials.boatPickUpAddress?.address}
                            onChange={handlePickUpLocationChange}
                            onSelect={handlePickUpLocationSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="location-search-input-dropdown1">
                                    <OutlinedInput
                                        fullWidth={true}
                                        {...getInputProps({
                                            placeholder: "Where are you going?",
                                            className: "location-search-input1",
                                        })}
                                        style={{
                                            color: '#6c757d',
                                        }}
                                        type="text"
                                    />
                                    <div
                                        className={classes.suggestionCntnr}
                                        style={{
                                            backgroundColor: suggestions.length > 0 ? "#ffff " : "",
                                            border: suggestions.length > 0 ? "#6c707d80" : "",
                                            boxShadow: suggestions.length > 0 ? "1px 1px 5px grey" : "",
                                        }}
                                    >
                                        {suggestions.map((suggestion) => {
                                            const className = suggestion.active
                                                ? classes.suggestionRow
                                                : classes.activeSuggestionRow;
                                            const style = suggestion.active
                                                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                : { backgroundColor: "#ffffff", cursor: "pointer" };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <PlaceIcon className="place-mui-icon1" />
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </Grid>


                    {/* <Grid item xs={12} md={4} >
                    <TextField
                        id="outlined-basic"
                        label="Enter Slip Number"
                        variant="outlined"
                        // required={true}
                        type="text"
                        fullWidth
                        placeholder="Enter Slip Number"
                        name="slipNumber"
                        onChange={(e) => {
                            onChangeListingCredentials("slipNumber", e.target.value)
                        }}
                        value={listingCredentials.slipNumber}
                        sx={{ mt: 3 }}
                    />
                </Grid> */}

                    <Grid item xs={12} mt={3} >
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    backgroundColor: '#F3F3F3',
                                    borderTop: '1px solid #AFAFAF',
                                    borderLeft: '1px solid #AFAFAF',
                                    borderRight: '1px solid #AFAFAF'
                                }}
                            >
                                <Typography>Does your vessel operatorate in multiple cities?</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    backgroundColor: '#F3F3F3',
                                    // borderTop: '1px solid #AFAFAF',
                                    borderLeft: '1px solid #AFAFAF',
                                    borderRight: '1px solid #AFAFAF'
                                }}
                            >
                                <PlacesAutocomplete
                                    value={multipleLocationObject.address}
                                    onChange={handleMultipleLocationChange}
                                    onSelect={handleMultipleLocationSelect}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div className="location-search-input-dropdown1">
                                            <OutlinedInput
                                                fullWidth={true}
                                                required={true}
                                                {...getInputProps({
                                                    placeholder: "Major City",
                                                    className: "location-search-input1",
                                                })}
                                                style={{
                                                    color: '#6c757d',
                                                }}
                                                type="text"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                        >
                                                            <ContainedBtn
                                                                text='Add City'
                                                                className={classes.addBtn}
                                                                disabled={multipleLocationObject.address.length == 0}
                                                                endIcon={<AddIcon />}
                                                                onClick={addMultipleBoatLocation}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <div
                                                className={classes.suggestionCntnr}
                                                style={{
                                                    backgroundColor: suggestions.length > 0 ? "#ffff " : "",
                                                    border: suggestions.length > 0 ? "#6c707d80" : "",
                                                    boxShadow: suggestions.length > 0 ? "1px 1px 5px grey" : "",
                                                }}
                                            >
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? classes.suggestionRow
                                                        : classes.activeSuggestionRow;
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <PlaceIcon className="place-mui-icon1" />
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} className={classes.LocationsWrapper} >

                        {
                            listingCredentials?.multipleLocations?.map((location, ind) => {
                                return (
                                    <LocationsCntnr key={ind}  >
                                        <DataText>{location?.formattedAddress || location?.address}</DataText>
                                        <Box  >
                                            <IconButton sx={{ backgroundColor: '#F5F5F5' }}
                                                onClick={() => removeLocationFromMultipleLocations(ind)}  >
                                                <CloseIcon sx={{ color: 'red' }} />
                                            </IconButton>
                                        </Box>
                                    </LocationsCntnr>
                                )
                            })
                        }
                    </Grid>



                </Grid>
            </Box>
            <Snackbar open={isLocationWarningSnackBarOpen} autoHideDuration={1000} onClose={handleClose} >
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                    Please select City First from Google SMART address finder
                </Alert>
            </Snackbar>
        </>
    )
}

export default BoatLocation