import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Container,
  Switch,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

const DataBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  // flexWrap: 'wrap',
  gap: "10px",
}));

const DataText = styled(Box)(({ theme }) => ({
  fontSize: "10px",
  fontFamily: "Gilroy Regular, sans-serif !important",
  color: "#777777",
  fontWeight: "bold",
  whiteSpace: "pre-line",
  letterSpacing: "1px",
  marginTop: "10px",
  // [theme.breakpoints.up("xs")]: {
  //     fontSize: '13px',
  //     letterSpacing: '1px',
  // },
  // [theme.breakpoints.up("sm")]: {
  //     fontSize: '14px',
  //     letterSpacing: '1px',
  // },
  // [theme.breakpoints.up("md")]: {
  //     fontSize: '15px',
  //     letterSpacing: '1px',
  // },
}));

const MultipleLocation = ({
  listingDetails,
  currentUser,
  isEditAllow,
  parentClasses,
}) => {
  return (
    <>
      <Grid container>
        {listingDetails?.multipleLocations?.map((address, ind) => {
          ind = ind + 1;
          return (
            <Grid item xs={12} key={ind}>
              <DataBox my={0.5}>
                <DataText width={"70px"}>Address {ind + 1} : </DataText>
                <DataText>
                  {address?.formattedAddress || address?.address}
                </DataText>
              </DataBox>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default MultipleLocation;
