import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Container,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Button,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { uploadFiles } from "../../Utils/FirebaseUtils";

import ContainedBtn from "../../Components/ContainedBtn";

import { useNavigate } from "react-router-dom";

import {
  createUserListing,
  getAddListingOptionsExtras,
  getAddListingOptionsTendersAndToys,
} from "../../Redux/Listing/ListingActions";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useSelector, useDispatch } from "react-redux";
import BoatDetails from "./Components/BoatDetails";
import BoatListing from "./Components/BoatListing";
import BoatPhotos from "./Components/BoatPhotos";
import BoatSpecificDetails from "./Components/BoatSpecificDetails";
import BoatExtras from "./Components/BoatExtras";
import TenderAndToys from "./Components/TenderAndToys";
import BoatAvailability from "./Components/BoatAvailability";
import Pricing from "./Pricing/Pricing";
import CaptianInformation from "./Components/CaptianInformation";
import BoatLocation from "./Components/BoatLocation";
import BoatExtrasAndTenderToys from "./Components/BoatExtrasAndTenderToys";
import BoatAvailabilityAndPricing from "./Components/BoatAvailabilityAndPricing";

const useStyles = makeStyles((theme) => ({
  textCntnr: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  displaybtwn: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnWrapper: {
    // border: '1px solid black',
  },
  bottomBtn: {
    background: "#0B1A9B !important",
    color: "white important",
  },
  backBottomBtn: {
    border: "1px solid #0B1A9B !important",
    color: "#0B1A9B !important",
    background: "#fff !important",
    fontSize: "17px",
    fontWeight: "bold",
  },
}));

const BottomBtnWrapper = styled(Box)(({ theme }) => ({
  background: "#fff",
  backgroundColor: "#fff",
  [theme.breakpoints.down("sevenHalfS")]: {
    width: "auto",
  },
  [theme.breakpoints.up("sevenHalfS")]: {
    width: "78%",
    position: "fixed",
    bottom: "0px",
  },
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  boxShadow: "rgba(0, 0, 0, 0.04) 0px -4px 10px",
  zIndex: "99",
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddListing = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const uid = localStorage.getItem("uid");

  let discountedPriceHoursRange = [2, 4, 6, 8];

  const [activeStep, setActiveStep] = React.useState(0);
  const [addListingLoading, setaddListingLoading] = useState(false);
  const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = React.useState(false);

  const currentUser = useSelector((state) => state.user.user);

  const [step, setSteps] = useState(0);
  const [listingCredentials, setListingtCredentials] = useState({
    // Boat Details Step 1
    boatName: "",
    majorCity: "",
    make: "",
    model: "",
    boatLength: "",
    beamLength: "",
    draft: "",
    hin: "",
    year: "",

    // boat Address
    boatAddress: {
      latLng: "",
      address: "",
      formattedAddress: "",
      country: "",
      region: "",
      dropOffAddress: "",
    },
    multipleLocations: [],
    boatPickUpAddress: {
      latLng: "",
      address: "",
      formattedAddress: "",
      country: "",
      region: "",
      boatPickUpAddress: "",
    },
    slipNumber: "",

    // boat Details
    noOfEngines: 1,
    engineMake: "",
    engineModel: "",
    engineHoursePower: "",
    engineType: "",
    fuelType: "",
    noOfPassengers: 1,
    noOfBathrooms: 0,
    sleepingAccmmodations: {},

    // boat Extras
    extras: [],

    // Tender And Toys
    tenderAndToys: [],
    jetskis: {},
    inflatableRIB: {},
    tenders: {},
    sailBoat: {},
    hydroFoil: {},
    seaBob: {},
    paddleBoards: {},
    // tenders: [],
    // jetskis: [],

    // Boat Listing
    title: "",
    description: "",

    // Availability
    availability: {
      Monday: true,
      Tuesday: true,
      Wednesday: true,
      Thursday: true,
      Friday: true,
      Saturday: true,
      Sunday: true,
    },

    // Pricing
    pricingType: "",
    dayCharter: {
      pricingSameEachDay: "",
      minimumHoursBeBooked: 1,
      price: "",
      weeklyPrice: {
        Monday: {
          "1hrs": "",
          "2hrs": "",
          "3hrs": "",
          "4hrs": "",
          "5hrs": "",
          "6hrs": "",
          "8hrs": "",
        },
        Tuesday: {
          "1hrs": "",
          "2hrs": "",
          "3hrs": "",
          "4hrs": "",
          "5hrs": "",
          "6hrs": "",
          "8hrs": "",
        },
        Wednesday: {
          "1hrs": "",
          "2hrs": "",
          "3hrs": "",
          "4hrs": "",
          "5hrs": "",
          "6hrs": "",
          "8hrs": "",
        },
        Thursday: {
          "1hrs": "",
          "2hrs": "",
          "3hrs": "",
          "4hrs": "",
          "5hrs": "",
          "6hrs": "",
          "8hrs": "",
        },
        Friday: {
          "1hrs": "",
          "2hrs": "",
          "3hrs": "",
          "4hrs": "",
          "5hrs": "",
          "6hrs": "",
          "8hrs": "",
        },
        Saturday: {
          "1hrs": "",
          "2hrs": "",
          "3hrs": "",
          "4hrs": "",
          "5hrs": "",
          "6hrs": "",
          "8hrs": "",
        },
        Sunday: {
          "1hrs": "",
          "2hrs": "",
          "3hrs": "",
          "4hrs": "",
          "5hrs": "",
          "6hrs": "",
          "8hrs": "",
        },
      },
      charterType: "",
    },
    termCharter: {
      minimumDaysBeBooked: 1,
      pricing: {
        1: "",
      },
      commissionPercentage: 5,
    },
    // Captains Information
    captianInformation: [
      // { firstName: '', lastName: '', phNo: '', emailAddress: '' }
    ],
    // Boat Images
    boatImages: [],
  });

  const onChangeListingCredentials = (key, value) => {
    setListingtCredentials({
      ...listingCredentials,
      [key]: value,
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const CreateAdd = () => {
    return (
      <>
        <Typography variant="h5">Click here to Publish</Typography>
        <ContainedBtn
          text={addListingLoading ? <CircularProgress /> : "ADD Listing"}
          className={classes.bottomBtn}
          sx={{ mt: 2 }}
          onClick={createListing}
        />
      </>
    );
  };

  const ListingStepper = [
    {
      label: "Tell us a little about your boat",
      StepComponent: (
        <BoatDetails
          onChangeListingCredentials={onChangeListingCredentials}
          listingCredentials={listingCredentials}
        />
      ),
    },
    {
      label: "Where is your boat",
      StepComponent: (
        <BoatLocation
          onChangeListingCredentials={onChangeListingCredentials}
          listingCredentials={listingCredentials}
        />
      ),
    },
    {
      label: "Boat Specific Details",
      StepComponent: (
        <BoatSpecificDetails
          onChangeListingCredentials={onChangeListingCredentials}
          listingCredentials={listingCredentials}
        />
      ),
    },
    {
      label: "Tender and Toys included with a Boats",
      StepComponent: (
        <BoatExtrasAndTenderToys
          onChangeListingCredentials={onChangeListingCredentials}
          listingCredentials={listingCredentials}
        />
      ),
    },
    // {
    //     label: 'Extras included with a Boats',
    //     StepComponent: <BoatExtras
    //         onChangeListingCredentials={onChangeListingCredentials}
    //         listingCredentials={listingCredentials}
    //     />,
    // },
    // {
    //     label: 'Tender and Toys included with a Boats',
    //     StepComponent: <TenderAndToys
    //         onChangeListingCredentials={onChangeListingCredentials}
    //         listingCredentials={listingCredentials}
    //     />,
    // },
    {
      label: "Your boat listing",
      StepComponent: (
        <BoatListing
          onChangeListingCredentials={onChangeListingCredentials}
          listingCredentials={listingCredentials}
        />
      ),
    },
    {
      label: "Your boat Availability",
      StepComponent: (
        <BoatAvailabilityAndPricing
          onChangeListingCredentials={onChangeListingCredentials}
          listingCredentials={listingCredentials}
        />
      ),
    },
    // {
    //     label: 'Now, add your Prices',
    //     StepComponent: <Pricing
    //         onChangeListingCredentials={onChangeListingCredentials}
    //         listingCredentials={listingCredentials}
    //     />,
    // },
    {
      label: "Boat photos",
      StepComponent: (
        <BoatPhotos
          onChangeListingCredentials={onChangeListingCredentials}
          listingCredentials={listingCredentials}
        />
      ),
    },
    {
      label: "Captains information (Optional):",
      StepComponent: (
        <CaptianInformation
          onChangeListingCredentials={onChangeListingCredentials}
          listingCredentials={listingCredentials}
        />
      ),
    },
    {
      label: "Create Ad",
      StepComponent: <CreateAdd />,
    },
  ];

  const createListing = () => {
    if (
      listingCredentials?.boatName.trim().length == 0 ||
      String(listingCredentials?.year).trim().length == 0 ||
      listingCredentials?.make?.trim().length == 0 ||
      listingCredentials?.model.trim().length == 0 ||
      listingCredentials?.boatLength <= 0 ||
      listingCredentials?.beamLength <= 0 ||
      listingCredentials?.hin.trim().length == 0
    ) {
      setSteps(0);
      setIsErrorSnackBarOpen(true);
    } else if (listingCredentials?.pricingType.trim().length === 0) {
      setSteps(7);
      setIsErrorSnackBarOpen(true);
    } else if (
      Object.values(listingCredentials?.boatAddress).length == 0
      // || listingCredentials?.slipNumber.trim().length == 0
    ) {
      setSteps(1);
      setIsErrorSnackBarOpen(true);
    } else if (
      listingCredentials?.title.trim().length == 0 ||
      listingCredentials?.description.trim().length == 0
    ) {
      setSteps(5);
      setIsErrorSnackBarOpen(true);
    } else if (listingCredentials?.boatImages.length < 4) {
      setSteps(8);
      setIsErrorSnackBarOpen(true);
    } else {
      setaddListingLoading(true);
      // console.log(listingCredentials, "listingCredentials")
      uploadFiles(listingCredentials?.boatImages).then((res) => {
        if (res) {
          if (res.success) {
            let { imagesUrls } = res;
            let {
              boatName,
              year,
              make,
              model,
              boatLength,
              beamLength,
              hin,
              boatAddress,
              multipleLocations,
              boatPickUpAddress,
              noOfEngines,
              engineMake,
              engineModel,
              engineHoursePower,
              engineType,
              fuelType,
              noOfPassengers,
              noOfBathrooms,
              sleepingAccmmodations,
              extras,
              tenderAndToys,
              jetskis,
              inflatableRIB,
              tenders,
              sailBoat,
              hydroFoil,
              seaBob,
              paddleBoards,
              title,
              description,
              availability,
              pricingType,
              captianInformation,
            } = listingCredentials;

            let data = {
              boatName,
              year,
              make,
              model,
              boatLength,
              beamLength,
              hin,
              boatAddress,
              multipleLocations,
              boatPickUpAddress,
              noOfEngines,
              engineMake,
              engineModel,
              engineHoursePower,
              engineType,
              fuelType,
              noOfPassengers,
              noOfBathrooms,
              sleepingAccmmodations,
              extras,
              tenderAndToys,
              jetskis,
              inflatableRIB,
              tenders,
              sailBoat,
              hydroFoil,
              seaBob,
              paddleBoards,
              title,
              description,
              availability,
              pricingType,
              captianInformation,
              imagesUrls,
              uid: uid,
              companyName: currentUser?.companyName || "",
              phoneNo: currentUser?.phoneNo || "",
              brokerName:
                currentUser?.firstName + currentUser?.lastName ||
                currentUser?.displayName,
              active: true,
              views: 0,
            };

            if (pricingType === "dayCharter") {
              data = { ...data, dayCharter: listingCredentials?.dayCharter };
            } else {
              data = { ...data, termCharter: listingCredentials?.termCharter };
            }

            dispatch(createUserListing(data)).then((res) => {
              if (res) {
                // console.log(res, "createListing")
                setListingtCredentials({
                  // Boat Details Step 1
                  boatName: "",
                  majorCity: "",
                  make: "",
                  model: "",
                  boatLength: "",
                  beamLength: "",
                  draft: "",
                  hin: "",
                  year: "",

                  // boat Address
                  boatAddress: {
                    latLng: "",
                    address: "",
                    formattedAddress: "",
                    country: "",
                    region: "",
                    dropOffAddress: "",
                  },
                  multipleLocations: [],
                  boatPickUpAddress: {
                    latLng: "",
                    address: "",
                    formattedAddress: "",
                    country: "",
                    region: "",
                    boatPickUpAddress: "",
                  },
                  slipNumber: "",

                  // boat Details
                  noOfEngines: 1,
                  engineMake: "",
                  engineModel: "",
                  engineHoursePower: "",
                  engineType: "",
                  fuelType: "",
                  noOfPassengers: 1,
                  noOfBathrooms: 0,
                  sleepingAccmmodations: {},

                  // boat Extras
                  extras: [],

                  // Tender And Toys
                  tenderAndToys: [],
                  jetskis: {},
                  inflatableRIB: {},
                  tenders: {},
                  sailBoat: {},
                  hydroFoil: {},
                  seaBob: {},
                  paddleBoards: {},
                  // tenders: [],
                  // jetskis: [],

                  // Boat Listing
                  title: "",
                  description: "",

                  // Availability
                  availability: {
                    Monday: true,
                    Tuesday: true,
                    Wednesday: true,
                    Thursday: true,
                    Friday: true,
                    Saturday: true,
                    Sunday: true,
                  },

                  // Pricing
                  pricingType: "",
                  dayCharter: {
                    pricingSameEachDay: "",
                    minimumHoursBeBooked: 1,
                    price: "",
                    weeklyPrice: {
                      Monday: {
                        "1hrs": "",
                        "2hrs": "",
                        "3hrs": "",
                        "4hrs": "",
                        "5hrs": "",
                        "6hrs": "",
                        "8hrs": "",
                      },
                      Tuesday: {
                        "1hrs": "",
                        "2hrs": "",
                        "3hrs": "",
                        "4hrs": "",
                        "5hrs": "",
                        "6hrs": "",
                        "8hrs": "",
                      },
                      Wednesday: {
                        "1hrs": "",
                        "2hrs": "",
                        "3hrs": "",
                        "4hrs": "",
                        "5hrs": "",
                        "6hrs": "",
                        "8hrs": "",
                      },
                      Thursday: {
                        "1hrs": "",
                        "2hrs": "",
                        "3hrs": "",
                        "4hrs": "",
                        "5hrs": "",
                        "6hrs": "",
                        "8hrs": "",
                      },
                      Friday: {
                        "1hrs": "",
                        "2hrs": "",
                        "3hrs": "",
                        "4hrs": "",
                        "5hrs": "",
                        "6hrs": "",
                        "8hrs": "",
                      },
                      Saturday: {
                        "1hrs": "",
                        "2hrs": "",
                        "3hrs": "",
                        "4hrs": "",
                        "5hrs": "",
                        "6hrs": "",
                        "8hrs": "",
                      },
                      Sunday: {
                        "1hrs": "",
                        "2hrs": "",
                        "3hrs": "",
                        "4hrs": "",
                        "5hrs": "",
                        "6hrs": "",
                        "8hrs": "",
                      },
                    },
                    charterType: "",
                  },
                  termCharter: {
                    minimumDaysBeBooked: 1,
                    pricing: {
                      1: "",
                    },
                    commissionPercentage: 5,
                  },
                  // Captains Information
                  captianInformation: [
                    // { firstName: '', lastName: '', phNo: '', emailAddress: '' }
                  ],
                  // Boat Images
                  boatImages: [],
                });
                setActiveStep(0);
                navigate("/mls");

                setActiveStep(0);
                navigate("/mls");
              }
              setaddListingLoading(false);
            });
          }
        }
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsErrorSnackBarOpen(false);
  };

  const handleBack = () => {
    if (step > 0) {
      setSteps((step) => step - 1);
    }
  };

  const handleNext = () => {
    if (step < ListingStepper.length - 1) {
      if (step == 0) {
        if (
          listingCredentials?.boatName.trim().length == 0 ||
          // listingCredentials?.majorCity.trim().length == 0 ||
          listingCredentials?.make?.trim().length == 0 ||
          listingCredentials?.model?.trim().length == 0 ||
          listingCredentials?.boatLength <= 0 ||
          listingCredentials?.beamLength <= 0 ||
          listingCredentials?.hin?.trim().length == 0 ||
          String(listingCredentials?.year).trim().length == 0
        ) {
          setIsErrorSnackBarOpen(true);
        } else {
          setSteps((step) => step + 1);
        }
      } else if (step == 1) {
        if (
          listingCredentials?.boatAddress.address.trim().length == 0
          // || listingCredentials?.slipNumber.trim().length == 0
        ) {
          setIsErrorSnackBarOpen(true);
        } else {
          setSteps((step) => step + 1);
        }
      } else if (step == 2) {
        if (
          listingCredentials?.fuelType.trim().length == 0 ||
          listingCredentials?.engineHoursePower.trim().length == 0 ||
          listingCredentials?.engineModel.trim().length == 0 ||
          listingCredentials?.engineMake.trim().length == 0 ||
          listingCredentials?.engineType.trim().length == 0 ||
          String(listingCredentials?.noOfEngines).trim().length == 0 ||
          listingCredentials?.noOfEngines < 1
        ) {
          setIsErrorSnackBarOpen(true);
        } else {
          setSteps((step) => step + 1);
        }
      } else if (step == 3) {
        // Boat Extras / Tender and Toys
        let emptyTender = false;
        let emptyJetskis = false;
        if (
          !listingCredentials?.tenderAndToys.includes("Tender") &&
          listingCredentials?.tenders.length > 0
        ) {
          emptyTender = true;
        }
        if (
          !listingCredentials?.tenderAndToys.includes("Jetski") &&
          listingCredentials?.jetskis.length > 0
        ) {
          emptyJetskis = true;
        }

        if (emptyTender && emptyJetskis) {
          setListingtCredentials({
            ...listingCredentials,
            tenders: [],
            jetskis: [],
          });
        } else if (emptyTender) {
          setListingtCredentials({
            ...listingCredentials,
            tenders: [],
          });
        } else if (emptyJetskis) {
          setListingtCredentials({
            ...listingCredentials,
            jetskis: [],
          });
        }

        setSteps((step) => step + 1);
      } else if (step == 4) {
        // Boat Listing
        if (
          listingCredentials?.title.trim().length == 0 ||
          listingCredentials?.description.trim().length == 0
        ) {
          setIsErrorSnackBarOpen(true);
        } else {
          setSteps((step) => step + 1);
        }
      } else if (step == 5) {
        let availabilityDone = false;
        if (!Object.values(listingCredentials?.availability).includes(true)) {
          setIsErrorSnackBarOpen(true);
        } else {
          // setSteps((step) => step + 1)
          availabilityDone = true;
        }
        // }
        // else if (step == 6) {
        let pricingOkay = false;

        if (listingCredentials?.pricingType.trim().length > 0) {
          if (listingCredentials?.pricingType == "dayCharter") {
            if (listingCredentials?.dayCharter?.pricingSameEachDay.length > 0) {
              if (listingCredentials?.dayCharter?.pricingSameEachDay == "yes") {
                if (listingCredentials?.dayCharter?.price > 0) {
                  pricingOkay = true;
                }

                // working
                let FirstWeekDayPrice = {
                  ...listingCredentials?.dayCharter.weeklyPrice.Monday,
                };
                for (
                  let index = 0;
                  index < discountedPriceHoursRange.length;
                  index++
                ) {
                  const disPrice = discountedPriceHoursRange[index];
                  if (
                    listingCredentials?.dayCharter.minimumHoursBeBooked <=
                    disPrice
                  ) {
                    let price = FirstWeekDayPrice[`${disPrice}hrs`];
                    if (price.trim().length == 0) {
                      pricingOkay = false;
                    }
                  }
                }
              } else {
                if (listingCredentials?.dayCharter?.price > 0) {
                  pricingOkay = true;
                }

                // working
                let FirstWeekDayPrice = {
                  ...listingCredentials?.dayCharter.weeklyPrice.Monday,
                };
                for (
                  let index = 0;
                  index < discountedPriceHoursRange.length;
                  index++
                ) {
                  const disPrice = discountedPriceHoursRange[index];
                  if (
                    listingCredentials?.dayCharter.minimumHoursBeBooked <=
                    disPrice
                  ) {
                    let price = FirstWeekDayPrice[`${disPrice}hrs`];
                    if (price.trim().length == 0) {
                      pricingOkay = false;
                    }
                  }
                }

                // weekend
                let FirstWeekEndPrice = {
                  ...listingCredentials?.dayCharter.weeklyPrice.Friday,
                };
                for (
                  let index = 0;
                  index < discountedPriceHoursRange.length;
                  index++
                ) {
                  const disPrice = discountedPriceHoursRange[index];
                  if (
                    listingCredentials?.dayCharter.minimumHoursBeBooked <=
                    disPrice
                  ) {
                    let price = FirstWeekEndPrice[`${disPrice}hrs`];
                    if (price.trim().length == 0) {
                      pricingOkay = false;
                    }
                  }
                }
              }
            }
          } else {
            // Temr Charter
            pricingOkay = true;
            let day = Object.keys(listingCredentials?.termCharter.pricing);
            let dayPricing = Object.values(
              listingCredentials?.termCharter.pricing
            );

            for (let index = 0; index < dayPricing.length; index++) {
              const element = dayPricing[index];
              if (element < 1) {
                pricingOkay = false;
              }
            }
          }
        }

        if (pricingOkay && availabilityDone) {
          setSteps((step) => step + 1);
        } else {
          setIsErrorSnackBarOpen(true);
        }
      } else if (step == 6) {
        if (listingCredentials?.boatImages.length < 4) {
          setIsErrorSnackBarOpen(true);
        } else {
          setSteps((step) => step + 1);
        }
      } else {
        setSteps((step) => step + 1);
      }
    }
  };

  useEffect(() => {
    dispatch(getAddListingOptionsExtras());
    dispatch(getAddListingOptionsTendersAndToys());
  }, []);

  return (
    <>
      <Container maxWidth="fl">
        <Grid container mt={10} mb={4}>
          <Grid item xs={12} pb={5}>
            {
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "xx-large",
                    xs: "30px",
                    sm: "40px ",
                    sevenHalfS: "25px",
                    nineS: "30px",
                    md: "35px",
                    lg: "40px",
                  },
                  marginBottom: { xs: "24px", sevenHalfS: "15px", md: "24px" },
                }}
              >
                {ListingStepper[step].label}
              </Typography>
            }
            {ListingStepper[step].StepComponent}
          </Grid>
          <Grid item xs={12}>
            <BottomBtnWrapper>
              <ContainedBtn
                text="Back"
                className={classes.backBottomBtn}
                startIcon={<ArrowBackIosIcon />}
                sx={{ mr: 2, mb: 2 }}
                onClick={handleBack}
              />
              {step === ListingStepper.length - 1 ? (
                <ContainedBtn
                  text={
                    addListingLoading ? <CircularProgress /> : "ADD Listing"
                  }
                  className={classes.bottomBtn}
                  sx={{ mr: 5, mb: 2 }}
                  onClick={createListing}
                />
              ) : (
                <ContainedBtn
                  text="Next"
                  className={classes.bottomBtn}
                  endIcon={<ArrowForwardIosIcon />}
                  sx={{ mb: 2 }}
                  onClick={handleNext}
                />
              )}
            </BottomBtnWrapper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={isErrorSnackBarOpen}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Empty Input Fields OR Incorrect Data
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddListing;
