import {
    SET_ALL_PLANS,
    SET_LOADING,
    SET_ERROR
} from "./PlanTypes";
import Swal from "sweetalert2";
import { db } from "../../FireBase/firebase";
import { collection, doc, setDoc, getDocs } from "firebase/firestore";


export const setLoading = (loading) => {
    return {
        type: SET_LOADING,
        payload: loading,
    };
};


export const setError = (error) => {
    return {
        type: SET_ERROR,
        payload: error,
    };
};


export const setAllPlans = (plans) => {
    return {
        type: SET_ALL_PLANS,
        payload: plans
    };
};


export const getAllPlans = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        let plans = []

        const querySnapshot = await getDocs(collection(db, "plans"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            plans.push(doc.data())
        });

        dispatch(setAllPlans(plans))
        dispatch(setLoading(false));


        // const Toast = Swal.mixin({
        //     toast: true,
        //     position: 'top',
        //     background: '#F62424',
        //     iconColor: 'white',
        //     color: 'white',
        //     customClass: {
        //         popup: 'colored-toast'
        //     },
        //     showConfirmButton: false,
        //     timer: 3000,
        //     timerProgressBar: true
        // })
        // await Toast.fire({
        //     icon: 'error',
        //     title: `${res?.data?.message}`
        // })
        // return res


    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err));
        console.log(err, "getAllPlans")
        return null
    }
}