import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import Drawer from "../Components/CustomDrawer";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { getAllListing } from "../Redux/Listing/ListingActions";

const PublicRoute = ({
  component: Component,
  hideDrawer,
  hideFooter,
  adminDrawer,
  privateRoute,
  acountAccess,
}) => {
  const userAuth = useSelector((state) => state.user.userAuth);

  const dispatch = useDispatch();

  const allListings = useSelector((state) => state.listings.allListings);

  let uid = localStorage.getItem("uid");
  let accountType = localStorage.getItem("accountType");

  if (privateRoute) {
    if (uid || userAuth) {
      if (acountAccess.includes(accountType)) {
        <>
          {!hideDrawer && <NavBar />}
          {/* <Component /> */}
          {adminDrawer && <Drawer Component={Component} />}
          {!adminDrawer && <Component />}
          {!hideFooter && <Footer />}
        </>;
      } else {
        return <Navigate to="/account" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  }

  return (
    <>
      {!hideDrawer && <NavBar />}
      {/* <Component /> */}
      {adminDrawer && <Drawer Component={Component} />}
      {!adminDrawer && <Component />}
      {!hideFooter && <Footer />}
    </>
  );
};

export default PublicRoute;
