import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";


import SectionHead from '../../../Components/SectionHead';
import { ServicesData } from '../../../State/Home';


const useStyles = makeStyles((theme) => ({
    serviceImg: {
        width: '100%',
        height: 'auto',
        marginBottom: '10px'
    }
}));

const Services = () => {
    const classes = useStyles();
    return (
        <>
            <Container maxWidth='fl'>
                <Grid container mt={10} >
                    <Grid item xs={12} sx={{ margin: { lg: '24px 0px 30px 0px', md: '20px 0px 30px 0px', xs: '0px 0px 20px 0px' } }} >
                        <SectionHead title='Our Services' sx={{ textAlign: 'center' }} />
                    </Grid>
                    {
                        ServicesData.map((service, ind) => {
                            return (
                                <Grid key={ind} item xs={12} sm={6} lg={3} sx={{ marginBottom: { xs: '50px', sm: '10px', md: '10px', lg: '0px' } }} >
                                    <Box m={2} >
                                        <img src={service.image} alt="Service" className={classes.serviceImg} />
                                        <Typography variant='h5' sx={{ marginBottom: '10px', fontWeight: 'bold' }} > {service.title} </Typography>
                                        <Typography sx={{fontFamily: 'Gilroy Regular, sans-serif !important'}}>{service.text}</Typography>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </>
    )
}

export default Services