import React, { useState, useEffect } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ListingCard from "./ListingCard";

import { combinedFilter } from "./utils";

const MlsListings = ({ setPrintListingIDs, printListingIDs, mlsListings }) => {
  const listings = useSelector((state) => state.listings.allListings);
  const listingLoading = useSelector((state) => state.listings.loading);
  const isMlsFiltersEnables = useSelector(
    (state) => state.listings.isMlsFiltersEnables
  );

  const {
    priceRange,
    passengerRange,
    boatLengthRange,
    boatMake,
    country,
    boatYear,
    boatPricingType,
  } = useSelector((state) => state.listings.mlsFilters);

  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[dayOfWeek];

  return (
    <>
      {isMlsFiltersEnables && mlsListings.length == 0 ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              No Match Results Found{" "}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          {mlsListings?.map((listing, ind) => {
            return (
              <Grid item xs={12} key={ind}>
                <ListingCard
                  title={listing?.title}
                  image={listing?.imagesUrls[0]}
                  imagesUrls={listing?.imagesUrls}
                  formattedAddress={listing?.boatAddress?.formattedAddress}
                  pricingType={listing?.pricingType}
                  availability={listing?.availability}
                  make={listing?.make}
                  boatLength={listing?.boatLength}
                  id={listing?.id}
                  noOfPassengers={listing?.noOfPassengers}
                  year={listing?.year}
                  model={listing?.model}
                  pricing={listing[listing?.pricingType]}
                  ListingUser={listing?.uid}
                  isAlreadyExists={printListingIDs.includes(listing?.id)}
                  currentDay={currentDay}
                  setPrintListingIDs={setPrintListingIDs}
                  printListingIDs={printListingIDs}
                  brokerName={listing?.brokerName}
                  companyName={listing?.companyName}
                  phoneNo={listing?.phoneNo}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default MlsListings;
