import {
    SET_USER,
    SET_LISTING_BROKER,
    SET_USER_LOADING,
    SET_ERROR,
    SET_LOGOUT,
    SET_USER_AUTH,
    SET_USER_TEAM,
    SET_USER_TEAMS_UPDATING
} from "./UserTypes";


const initialState = {
    user: {},
    userAuth: false,
    loading: false,
    teamUpdating: false,
    updating: false,
    logged: false,
    error: null,
    passwordOtp: "",
    users: [],
    userTeam: [],
    listingBroker: {}
};

const userReducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_USER:
            return {
                ...state,
                user: payload,
                updating: false,
                logged: true,
            };
        case SET_LISTING_BROKER:
            return {
                ...state,
                listingBroker: payload
            }
        case SET_USER_TEAMS_UPDATING:
            return {
                ...state,
                teamUpdating: payload
            };
        case SET_USER_TEAM:
            return {
                ...state,
                userTeam: payload
            };
        case SET_USER_AUTH:
            return {
                ...state,
                userAuth: payload
            };
        case SET_LOGOUT:
            return {
                ...state,
                user: {},
                updating: false,
                logged: false,
                loading: false,
            };
        case SET_ERROR:
            return {
                ...state,
                error: payload,
            };
        case SET_USER_LOADING:
            return {
                ...state,
                loading: payload,
                error: null,
            };
        default:
            return state;
    }
};

export default userReducer;