import React, { useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Box,
  Paper,
  Button,
  TextField,
  MenuItem,
  IconButton,
  OutlinedInput,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import ContainedBtn from "../../../Components/ContainedBtn";

const useStyles = makeStyles((theme) => ({
  roundGrayBG: {
    backgroundColor: "#F3F3F3",
    borderRadius: "10px",
  },
  checkBoxCntnr: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  outlinedBtn: {
    border: "1px solid #0B1A9B !important",
    color: "#0B1A9B !important",
    background: "#fff !important",
    fontSize: "17px",
    fontWeight: "bold",
  },
}));

const CaptianInformation = ({
  onChangeListingCredentials,
  listingCredentials,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.roundGrayBG} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ContainedBtn
            text="Add Captian"
            sx={{
              "&:hover": { color: "#0B1A9B", outline: "1px solid #0B1A9B" },
              height: "50px",
              width: "200px",
              marginBottom: "10px",
            }}
            onClick={() => {
              const captianInformation = [
                ...listingCredentials.captianInformation,
                { firstName: "", lastName: "", phNo: "", emailAddress: "" },
              ];
              onChangeListingCredentials(
                "captianInformation",
                captianInformation
              );
            }}
          />
        </Grid>
      </Grid>
      {listingCredentials.captianInformation.map((val, ind) => {
        return (
          <Grid container spacing={2} key={ind} my={2}>
            <Grid item xs={12}>
              <Typography>{ind + 1} Captain </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Enter Captains First Name"
                variant="outlined"
                // required={true}
                type="text"
                fullWidth
                placeholder="Enter Captains First Name"
                name="firstName"
                onChange={(e) => {
                  const updatedCaptainInformation = [
                    ...listingCredentials.captianInformation,
                  ];
                  updatedCaptainInformation[ind].firstName = e.target.value;
                  onChangeListingCredentials(
                    "captianInformation",
                    updatedCaptainInformation
                  );
                }}
                value={listingCredentials.captianInformation[ind]?.firstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Enter Captains Last Name"
                variant="outlined"
                // required={true}
                type="text"
                fullWidth
                placeholder="Enter Captains Last Name"
                name="LastName"
                onChange={(e) => {
                  const updatedCaptainInformation = [
                    ...listingCredentials.captianInformation,
                  ];
                  updatedCaptainInformation[ind].lastName = e.target.value;
                  onChangeListingCredentials(
                    "captianInformation",
                    updatedCaptainInformation
                  );
                }}
                value={listingCredentials.captianInformation[ind]?.lastName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Enter Captians Phone number"
                variant="outlined"
                // required={true}
                type="text"
                fullWidth
                placeholder="Enter Captians Phone number"
                name="Phone number"
                onChange={(e) => {
                  const updatedCaptainInformation = [
                    ...listingCredentials.captianInformation,
                  ];
                  updatedCaptainInformation[ind].phNo = e.target.value;
                  onChangeListingCredentials(
                    "captianInformation",
                    updatedCaptainInformation
                  );
                }}
                value={listingCredentials.captianInformation[ind]?.phNo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Enter Captians Email Address"
                variant="outlined"
                // required={true}
                type="text"
                fullWidth
                placeholder="Enter Captians Email Address"
                name="Email Address"
                onChange={(e) => {
                  const updatedCaptainInformation = [
                    ...listingCredentials.captianInformation,
                  ];
                  updatedCaptainInformation[ind].emailAddress = e.target.value;
                  onChangeListingCredentials(
                    "captianInformation",
                    updatedCaptainInformation
                  );
                }}
                value={listingCredentials.captianInformation[ind]?.emailAddress}
              />
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default CaptianInformation;
