import React from 'react'
import {
    Typography,
    Grid,
    Container,
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import ContainedBtn from '../../Components/ContainedBtn';
import sendIcon from "../../Assets/Icons/sendIcon.png"
import LatestUpdate from './Components/LatestUpdate';
import AllListings from './Components/AllListings';
import ListingGraphs from './Components/ListingGraphs';
import ListingTable from './Components/ListingTable';


const useStyles = makeStyles((theme) => ({
    textCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    }
}));

const Reports = () => {
    const classes = useStyles();

    return (
        <>
            <Container maxWidth='fl' >
                <Grid container mt={10}  >
                    <Grid item xs={12} className={classes.textCntnr} >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: 'xx-large', xs: '30px', sm: '40px ', sevenHalfS: '25px', nineS: '30px', md: '35px', lg: '40px' },
                                marginBottom: { xs: '24px', sevenHalfS: '15px', md: '24px' }
                            }}
                        > Reports </Typography>
                        <ContainedBtn
                            text='Export'
                            sx={{ '&:hover': { color: '#0B1A9B', outline: '1px solid #0B1A9B' }, height: '50px', width: '185px', marginBottom: '10px' }}
                            endIcon={<img src={sendIcon} style={{ width: '14px', height: '14px', }} alt="icon" />}
                        />
                    </Grid>
                    <Grid item xs={12} mb={10} >
                        <LatestUpdate />
                    </Grid>
                    <Grid item xs={12} mb={5} >
                        <AllListings />
                    </Grid>
                    <Grid item xs={12} mb={5} >
                        <ListingGraphs />
                    </Grid>
                    <Grid item xs={12} mb={5} >
                        <ListingTable/>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Reports