import React, { useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Box,
  Paper,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles((theme) => ({
  roundGrayBG: {
    backgroundColor: "#F3F3F3",
    borderRadius: "10px",
  },
  imgCntnr: {
    width: "250px",
    height: "200px",
    margin: "10px",
    border: "3px solid #0B1A9B",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    // padding: "5px",
  },
  img: {
    width: "250px",
    height: "200px",
    // border: '3px solid #0B1A9B',
    borderRadius: "5px",
  },
}));

const DeleteBtn = styled("button")(({ theme }) => ({
  width: "25px",
  height: "25px",
  backgroundColor: "#fff",
  border: "1px solid #0B1A9B",
  borderRadius: "25px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "13px",
  right: "10px",
  cursor: "pointer",
}));

const EditBoatPhotos = ({ onChangeListingCredentials, listingCredentials }) => {
  const classes = useStyles();
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      setSelectedImages([...selectedImages, ...files]);
      onChangeListingCredentials("boatImages", [...selectedImages, ...files]);
    } else {
      setSelectedImages([]);
      onChangeListingCredentials("boatImages", []);
    }
  };

  const deleteImagesUrls = (path) => {
    let arr = listingCredentials?.imagesUrls.filter((val) => val !== path);
    onChangeListingCredentials("imagesUrls", [...arr]);
  };

  const deleteBoatImages = (path) => {
    let arr = listingCredentials?.boatImages.filter((val) => val !== path);
    onChangeListingCredentials("boatImages", [...arr]);
  };

  return (
    <Box className={classes.roundGrayBG} p={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Minimum 4 Pictures</Typography>
          <Box mt={1}>
            <Typography variant="h6">
              Tips to get great photos on your boat
            </Typography>
            <ol type="i">
              <li>Use your camera or smartphone.</li>
              <li>Avoid using flash. Boats look best in natural light.</li>
              <li>Tidy up before taking interior shots.</li>
              <li>
                Take photos in landscape mode and avoid extreme close-ups.
              </li>
            </ol>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <input
            type="file"
            accept="image/*" // Accept only image files
            placeholder="Select Boat Images"
            multiple={true}
            onChange={handleImageChange}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
          {listingCredentials.boatImages.map((image, ind) => {
            return (
              <Box key={ind} className={classes.imgCntnr}>
                <DeleteBtn onClick={() => deleteBoatImages(image)}>
                  <ClearIcon sx={{ color: "#0B1A9B", fontSize: "20px" }} />
                </DeleteBtn>
                <img
                  src={URL.createObjectURL(image)}
                  alt="image"
                  className={classes.img}
                />
              </Box>
            );
          })}
          {listingCredentials?.imagesUrls.map((image, ind) => {
            return (
              <Box
                key={ind}
                sx={{ position: "relative" }}
                className={classes.imgCntnr}
              >
                <DeleteBtn onClick={() => deleteImagesUrls(image)}>
                  <ClearIcon sx={{ color: "#0B1A9B", fontSize: "20px" }} />
                </DeleteBtn>
                <img src={image} alt="image" className={classes.img} />
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditBoatPhotos;
