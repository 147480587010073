import React from 'react'
import Testimonal from '../Home/Components/Testimonal'
import Virtual from '../Home/Components/Virtual'
import BannerOne from './Component/BannerOne'
import BannerTwo from './Component/BannerTwo'


const AboutUs = () => {
  return (
    <>
    <BannerOne/>
    <BannerTwo/>
    <Testimonal/>
    <Virtual/>
    </>
  )
}

export default AboutUs