import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Container,
  Switch,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

const DataBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
}));

const DataText = styled(Box)(({ theme }) => ({
  fontSize: "10px",
  fontFamily: "Gilroy Regular, sans-serif !important",
  color: "#777777",
  fontWeight: "bold",
  //   whiteSpace: "pre-line",
  letterSpacing: "1px",
  marginTop: "10px",
  // [theme.breakpoints.up("xs")]: {
  //     fontSize: '13px',
  //     letterSpacing: '1px',
  // },
  // [theme.breakpoints.up("sm")]: {
  //     fontSize: '14px',
  //     letterSpacing: '1px',
  // },
  // [theme.breakpoints.up("md")]: {
  //     fontSize: '15px',
  //     letterSpacing: '1px',
  // },
}));

const BoatAvalibility = ({
  listingDetails,
  currentUser,
  isEditAllow,
  parentClasses,
}) => {
  let boatAvailability = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <>
      <Grid container>
        {boatAvailability.map((day, ind) => {
          return (
            <Grid item xs={12} key={ind}>
              <DataBox>
                <DataText className={parentClasses?.availabilityTextStyle}>
                  {day}
                </DataText>
                <DataText className={parentClasses?.dataTextStyle}>
                  {listingDetails?.availability?.[day]
                    ? ": Available"
                    : ": Not Available"}
                </DataText>
              </DataBox>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default BoatAvalibility;
