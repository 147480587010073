import React, { useState, useEffect, useRef } from "react";
import { Grid, Container, Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getAllListing } from "../../Redux/Listing/ListingActions";
import MlsListings from "./Components/MlsListings";
import MlsFilters from "./Components/MlsFilters";
import ContainedBtn from "../../Components/ContainedBtn";
import ListingSearch from "./Components/ListingSearch";

import { combinedFilter, combinedSearch } from "./Components/utils";

const useStyles = makeStyles((theme) => ({
  textCntnr: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
}));

const MainCntnr = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "90vh",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
}));

const Mls = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const listings = useSelector((state) => state.listings.allListings);
  const listingLoading = useSelector((state) => state.listings.loading);

  const [printListingIDs, setPrintListingIDs] = useState([]);

  useEffect(() => {
    if (listings.length == 0) {
      dispatch(getAllListing());
    }
  }, []);

  const isMlsFiltersEnables = useSelector(
    (state) => state.listings.isMlsFiltersEnables
  );
  const {
    priceRange,
    passengerRange,
    boatLengthRange,
    boatMake,
    country,
    boatYear,
    boatPricingType,
    dayCharterMinimumHour,
  } = useSelector((state) => state.listings.mlsFilters);
  const { latLng, formattedAddress, searchCountry } = useSelector(
    (state) => state.listings.boatSearch
  );

  let mlsListings = isMlsFiltersEnables
    ? combinedFilter(
        listings,
        priceRange,
        passengerRange,
        boatLengthRange,
        boatMake,
        country,
        boatYear,
        boatPricingType,
        latLng,
        formattedAddress,
        searchCountry,
        dayCharterMinimumHour
      )
    : combinedSearch(listings, latLng, formattedAddress, searchCountry);
    

  return (
    <Box sx={{ backgroundColor: "#F3F3F3" }}>
      {listingLoading ? (
        <MainCntnr>
          <CircularProgress sx={{ color: "#0B1A9B" }} />
        </MainCntnr>
      ) : (
        <Container maxWidth="fl">
          <Grid container pt={4} spacing={3}>
            <Grid item xs={0} md={3}>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <MlsFilters />
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <ContainedBtn
                  text="Filter"
                  startIcon={<FilterAltIcon />}
                  sx={{
                    background: "#0B1A9B !important",
                    color: "white !important",
                  }}
                  onClick={() => navigate("/mls/Filters")}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={9} pb={2}>
              <ListingSearch
                mlsListings={mlsListings}
                setPrintListingIDs={(state) => setPrintListingIDs(state)}
                printListingIDs={printListingIDs}
              />
              <Box mt={4}>
                <MlsListings
                  setPrintListingIDs={(state) => setPrintListingIDs(state)}
                  printListingIDs={printListingIDs}
                  mlsListings={mlsListings}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export default Mls;
