import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";


import { IndustriesData } from '../../../State/Home';
import SectionHead from '../../../Components/SectionHead';


const useStyles = makeStyles((theme) => ({
    industryImg: {
        width: 'auto',
        height: '60px',
        marginBottom: '30px'
    }
}));




const Industries = () => {
    const classes = useStyles();
    return (
        <>
            <Container maxWidth='fl'>
                <Grid container mt={10} mb={15}  >
                    <Grid item xs={12} sx={{ margin: { lg: '24px 0px 80px 0px', md: '20px 0px 50px 0px', xs: '0px 0px 30px 0px' } }} >
                        <SectionHead title='Our  Industries' sx={{ textAlign: 'center',  }} />
                    </Grid>
                    {
                        IndustriesData.map((industry, ind) => {
                            return (
                                <Grid key={ind} item xs={12} sm={6} lg={4} sx={{ marginBottom: { xs: '50px', sm: '10px', md: '10px', lg: '0px' } }} >
                                    <Box m={2} >
                                        <img src={industry.image} alt="industry" className={classes.industryImg} />
                                        <Typography variant='h4' sx={{ marginBottom: '20px', fontWeight: 'bold',fontSize: { xs: 'xx-large', sm: '34px ',fontFamily: 'Obadiah Pro, sans-serif' } }} > {industry.title} </Typography>
                                        <Typography sx={{ fontSize: '24px', fontFamily: 'Gilroy Regular, sans-serif !important' }} >{industry.text}</Typography>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </>
    )
}

export default Industries