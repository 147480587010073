import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Container,
  CircularProgress,
  Box,
  TextField,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import { MuiTelInput } from "mui-tel-input";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import defaultUser from "../../Assets/Icons/defaultUser.svg";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getCurrentUser,
  setUserLoading,
  UpdateUser,
} from "../../Redux/User/UserActions";
import ContainedBtn from "../../Components/ContainedBtn";

import { uploadFiles } from "../../Utils/FirebaseUtils";

const MainCntnr = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "90vh",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
}));
const DefaultImage = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
  borderRadius: "5px",
  marginRight: "10px",
  objectFit: "contain"
}));

const useStyles = makeStyles((theme) => ({
  textCntnr: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  alingCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    display: "inline-block",
    backgroundColor: "#0074d9",
    color: "#fff",
    padding: "10px 15px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    "& input": {
      display: "none",
    },
  },
  alignEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AccountSetting = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user.user);
  const userLoading = useSelector((state) => state.user.loading);
  const accountType = localStorage.getItem("accountType");

  const [userCredential, setUserCredential] = useState({ ...currentUser });

  const [localProilePicture, setlocalProilePicture] = useState(null);
  const [localCompanyPicture, setlocalCompanyPicture] = useState(null);
  // const [files, setFiles] = useState([])

  const [editProfileLoading, seteditProfileLoading] = useState(false);

  const [showError, setshowError] = useState(false);
  const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = useState(false);

  useEffect(() => {
    dispatch(setUserLoading(true));
    dispatch(getCurrentUser()).then((res) => {
      // dispatch(setUserLoading(false))
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      // console.log(currentUser, "currentUser")
      setUserCredential({ ...currentUser });
    }
  }, [currentUser]);

  const onChangeUserCredentials = (key, value) => {
    setUserCredential({
      ...userCredential,
      [key]: value,
    });
  };

  const handleProfielImageChange = (e) => {
    const file = e.target.files[0];
    setlocalProilePicture(file);
  };
  const handleCompanyImageChange = (e) => {
    const file = e.target.files[0];
    setlocalCompanyPicture(file);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsErrorSnackBarOpen(false);
  };

  const updateProfile = async () => {
    seteditProfileLoading(true);
    setshowError(false);

    const imagesFiles = [];
    let data = {};

    if (localProilePicture) {
      imagesFiles.push(localProilePicture);
    }
    if (localCompanyPicture) {
      imagesFiles.push(localCompanyPicture);
    }

    if (imagesFiles.length > 0) {
      try {
        const uploadResult = await uploadFiles(imagesFiles);

        if (uploadResult && uploadResult.success) {
          const imagesUrls = uploadResult.imagesUrls;
          // console.log(imagesUrls, "")
          let profilePictureURL = "";
          let companyPictureURL = "";

          if (imagesUrls.length == 1 && localProilePicture) {
            profilePictureURL = imagesUrls[0];
            data.profilePicture = profilePictureURL;
          }
          if (imagesUrls.length == 1 && localCompanyPicture) {
            companyPictureURL = imagesUrls[0];
            data.companyPicture = companyPictureURL;
          }

          if (imagesUrls.length == 2) {
            profilePictureURL = imagesUrls[0];
            companyPictureURL = imagesUrls[1];
            data.profilePicture = profilePictureURL;
            data.companyPicture = companyPictureURL;
          }
        }
      } catch (error) {
        // Handle any errors that occurred during image upload.
        console.error(error);
      }
    }

    // Ensure that userCredential?.profileDescription and userCredential?.companyDescription are defined.
    let phoneNo = userCredential?.phoneNo;
    let officePhoneNo = userCredential?.officePhoneNo;
    let profileDescription = userCredential?.profileDescription || "";
    let companyDescription = userCredential?.companyDescription || "";
    let userAddress = userCredential?.userAddress || "";
    let companyAddress = userCredential?.companyAddress || "";
    let userWeb = userCredential?.userWeb || "";
    let companyWeb = userCredential?.companyWeb || "";
    let salesMessage = userCredential?.salesMessage || "";
    let companyName = userCredential?.companyName || "";
    let firstName = userCredential?.firstName || "";
    let lastName = userCredential?.lastName || "";

    let profilePicture = userCredential?.profilePicture || data.profilePicture;
    let companyPicture = userCredential?.companyPicture || data.companyPicture;

    data = {
      ...data,
      phoneNo: phoneNo,
      officePhoneNo: officePhoneNo,
      firstName: firstName,
      lastName: lastName,
      companyName: companyName,
      profileDescription: profileDescription,
      companyDescription: companyDescription,
      userAddress: userAddress,
      companyAddress: companyAddress,
      userWeb: userWeb,
      companyWeb: companyWeb,
      salesMessage: salesMessage,
    };

    if (
      // String(phoneNo)?.trim()?.length == 0 ||
      // String(officePhoneNo)?.trim()?.length == 0 ||
      firstName?.trim()?.length == 0 ||
      lastName?.trim()?.length == 0 ||
      profileDescription?.trim()?.length == 0 ||
      companyDescription?.trim()?.length == 0 ||
      companyName?.trim()?.length == 0 ||
      companyWeb?.trim()?.length == 0 ||
      salesMessage?.trim()?.length == 0 ||
      profilePicture?.length == 0 ||
      companyPicture?.length == 0
    ) {
      setshowError(true);
      setIsErrorSnackBarOpen(true);
      seteditProfileLoading(false);
    } else {
      try {
        const updateUserResult = await dispatch(UpdateUser(data));

        if (updateUserResult) {
          setlocalProilePicture(null);
          setlocalCompanyPicture(null);
          seteditProfileLoading(false);
          setshowError(false);
        }
        seteditProfileLoading(false);
      } catch (error) {
        // Handle any errors that occurred during user update.
        console.error(error);
        seteditProfileLoading(false);
      }
    }
  };

  return (
    <>
      {userLoading ? (
        <MainCntnr>
          <CircularProgress sx={{ color: "#0B1A9B" }} />
        </MainCntnr>
      ) : (
        <Container maxWidth="fl">
          <Grid container mt={5}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "xx-large",
                    xs: "30px",
                    sm: "40px ",
                    sevenHalfS: "25px",
                    nineS: "30px",
                    md: "35px",
                    lg: "40px",
                  },
                  marginBottom: { xs: "24px", sevenHalfS: "15px", md: "24px" },
                }}
              >
                Welcome {currentUser?.displayName || currentUser?.firstName}{" "}
                {currentUser?.lastName}!
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                error={
                  showError &&
                  (userCredential?.firstName?.trim().length == 0 ||
                    !userCredential?.firstName)
                }
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="First Name"
                required={true}
                value={userCredential?.firstName}
                onChange={(e) =>
                  onChangeUserCredentials("firstName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={
                  showError &&
                  (userCredential?.lastName?.trim().length == 0 ||
                    !userCredential?.lastName)
                }
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Last Name"
                required={true}
                value={userCredential?.lastName}
                onChange={(e) =>
                  onChangeUserCredentials("lastName", e.target.value)
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label={currentUser?.email}
                variant="outlined"
                type="text"
                fullWidth
                placeholder={currentUser?.email}
                value={currentUser?.email}
                disabled
                // onChange={(e) => onChangeListingCredentials("boatAddress", e.target.value)}
                // value={listingCredentials.boatAddress}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={
                  showError &&
                  (userCredential?.companyName?.trim().length == 0 ||
                    !userCredential?.companyName)
                }
                id="outlined-basic"
                label="Company Name"
                variant="outlined"
                type="text"
                required={true}
                fullWidth
                placeholder="Company Name"
                onChange={(e) =>
                  onChangeUserCredentials("companyName", e.target.value)
                }
                value={userCredential?.companyName}
              />
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{ backgroundColor: "#F3F3F3", borderRadius: "10px" }}
                p={1}
              >
                <Box className={classes.alingCenter} mb={1}>
                  <DefaultImage
                    src={
                      localProilePicture
                        ? URL.createObjectURL(localProilePicture)
                        : userCredential?.profilePicture
                        ? userCredential?.profilePicture
                        : defaultUser
                    }
                  />

                  <label className={classes.label}>
                    {userCredential?.profilePicture
                      ? "Update Profile Picture"
                      : "Select Profile Picture"}
                    <input
                      type="file"
                      accept="image/*" // Accept only image files
                      placeholder="Select Profile Picture"
                      multiple={false}
                      onChange={handleProfielImageChange}
                    />
                  </label>
                </Box>
                <TextField
                  error={
                    showError &&
                    (userCredential?.profileDescription?.trim().length == 0 ||
                      !userCredential?.profileDescription)
                  }
                  id="outlined-basic"
                  label="Profile Description"
                  variant="outlined"
                  required={true}
                  type="text"
                  multiline={true}
                  rows={6}
                  fullWidth
                  placeholder="Profile Description"
                  onChange={(e) =>
                    onChangeUserCredentials(
                      "profileDescription",
                      e.target.value
                    )
                  }
                  value={userCredential?.profileDescription}
                  sx={{ backgroundColor: "#fff" }}
                />
                <TextField
                  error={
                    showError &&
                    (userCredential?.userAddress?.trim().length == 0 ||
                      !userCredential?.userAddress)
                  }
                  id="outlined-basic"
                  label="Your Complete Address"
                  variant="outlined"
                  required={false}
                  type="text"
                  multiline={true}
                  rows={3}
                  fullWidth
                  placeholder="Your Complete Address"
                  onChange={(e) =>
                    onChangeUserCredentials("userAddress", e.target.value)
                  }
                  value={userCredential?.userAddress}
                  sx={{ backgroundColor: "#fff", mt: 2 }}
                />
                <TextField
                  error={
                    showError &&
                    (userCredential?.userWeb?.trim().length == 0 ||
                      !userCredential?.userWeb)
                  }
                  id="outlined-basic"
                  label="Your Website"
                  variant="outlined"
                  required={false}
                  type="text"
                  rows={1}
                  fullWidth
                  placeholder="Your Website"
                  onChange={(e) =>
                    onChangeUserCredentials("userWeb", e.target.value)
                  }
                  value={userCredential?.userWeb}
                  sx={{ backgroundColor: "#fff", mt: 2 }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{ backgroundColor: "#F3F3F3", borderRadius: "10px" }}
                p={1}
              >
                <Box className={classes.alingCenter} mb={1}>
                  <DefaultImage
                    src={
                      localCompanyPicture
                        ? URL.createObjectURL(localCompanyPicture)
                        : userCredential?.companyPicture
                        ? userCredential?.companyPicture
                        : defaultUser
                    }
                  />
                  <label className={classes.label}>
                    {userCredential?.companyPicture
                      ? "Update Company Picture"
                      : "Select Company Picture"}
                    <input
                      type="file"
                      accept="image/*" // Accept only image files
                      placeholder="Select Company Picture"
                      multiple={false}
                      onChange={handleCompanyImageChange}
                    />
                  </label>
                </Box>
                <TextField
                  error={
                    showError &&
                    (userCredential?.companyDescription?.trim().length == 0 ||
                      !userCredential?.companyDescription)
                  }
                  id="outlined-basic"
                  label="Company Description"
                  variant="outlined"
                  required={true}
                  type="text"
                  multiline={true}
                  rows={6}
                  fullWidth
                  placeholder="Company Description"
                  onChange={(e) =>
                    onChangeUserCredentials(
                      "companyDescription",
                      e.target.value
                    )
                  }
                  value={userCredential?.companyDescription}
                  sx={{ backgroundColor: "#fff" }}
                />
                <TextField
                  error={
                    showError &&
                    (userCredential?.companyAddress?.trim().length == 0 ||
                      !userCredential?.companyAddress)
                  }
                  id="outlined-basic"
                  label="Company Address"
                  variant="outlined"
                  required={false}
                  type="text"
                  multiline={true}
                  rows={3}
                  fullWidth
                  placeholder="Company Address"
                  onChange={(e) =>
                    onChangeUserCredentials("companyAddress", e.target.value)
                  }
                  value={userCredential?.companyAddress}
                  sx={{ backgroundColor: "#fff", mt: 2 }}
                />
                <TextField
                  error={
                    showError &&
                    (userCredential?.companyWeb?.trim().length == 0 ||
                      !userCredential?.companyWeb)
                  }
                  id="outlined-basic"
                  label="Company Website"
                  variant="outlined"
                  required={true}
                  type="text"
                  rows={1}
                  fullWidth
                  placeholder="Company Website"
                  onChange={(e) =>
                    onChangeUserCredentials("companyWeb", e.target.value)
                  }
                  value={userCredential?.companyWeb}
                  sx={{ backgroundColor: "#fff", mt: 2 }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={
                  showError &&
                  (userCredential?.salesMessage?.trim().length == 0 ||
                    !userCredential?.salesMessage)
                }
                id="outlined-basic"
                label=" Sales Message"
                variant="outlined"
                required={true}
                type="text"
                multiline={true}
                rows={3}
                fullWidth
                placeholder=" Sales Message"
                onChange={(e) =>
                  onChangeUserCredentials("salesMessage", e.target.value)
                }
                value={userCredential?.salesMessage}
                sx={{ backgroundColor: "#fff" }}
              />
            </Grid>

            {/* Contact Info  */}

            <Grid item xs={12} md={6}>
              <TextField
                // error={showError && String(userCredential?.phoneNo)?.trim().length < 11}
                id="outlined-basic"
                label="Phone Number"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Phone Number"
                value={userCredential?.phoneNo}
                // onChange={(e) => {
                //     const numericValue = e.target.value
                //     if (numericValue.length <= 11) {
                //         if (/^\d*$/.test(numericValue)) {
                //             onChangeUserCredentials("phoneNo", numericValue)
                //         }
                //     }
                // }}
                onChange={(e) => {
                  const numericValue = e.target.value;
                  if (numericValue && /^[\d\s+]*$/.test(numericValue)) {
                    // const trimmedValue = numericValue.replace(/\s/g, '');
                    onChangeUserCredentials("phoneNo", numericValue);
                  }
                  if (numericValue.trim().length == 0) {
                    onChangeUserCredentials("phoneNo", numericValue);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                // error={showError && String(userCredential?.officePhoneNo)?.trim().length < 11}
                id="outlined-basic"
                label="Office Phone Number"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Office Phone Number"
                value={userCredential?.officePhoneNo}
                onChange={(e) => {
                  const numericValue = e.target.value;
                  if (numericValue && /^[\d\s+]*$/.test(numericValue)) {
                    // const trimmedValue = numericValue.replace(/\s/g, '');
                    onChangeUserCredentials("officePhoneNo", numericValue);
                  }
                  if (numericValue.trim().length == 0) {
                    onChangeUserCredentials("officePhoneNo", numericValue);
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} mt={2} className={classes.alignEnd}>
              <ContainedBtn
                text={
                  editProfileLoading ? (
                    <CircularProgress sx={{ color: "#fff" }} />
                  ) : (
                    "Save Profile"
                  )
                }
                sx={{
                  "&:hover": { color: "#0B1A9B", outline: "1px solid #0B1A9B" },
                  height: "50px",
                  width: "185px",
                  marginBottom: "10px",
                }}
                // endIcon={<img src={sendIcon} style={{ width: '14px', height: '14px', }} alt="icon" />}
                onClick={updateProfile}
              />
            </Grid>
          </Grid>
        </Container>
      )}
      <Snackbar
        open={isErrorSnackBarOpen}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Empty Input Fields OR Incorrect Data
        </Alert>
      </Snackbar>
    </>
  );
};

export default AccountSetting;
