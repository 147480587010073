import React, { useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Zoom,
  Snackbar,
  Alert,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { useNavigate, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  logInUser,
  resendVerificationEmail,
  resetPassword,
} from "../../Redux/User/UserActions";

import blueLogo from "../../Assets/Images/blueLogo.png";
import { HideIcon, UnHideIcon } from "../../Assets/IconsPath/IconsPath";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FullScreenDialog from "../../Components/Dialog";
import ContainedBtn from "../../Components/ContainedBtn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const useStyles = makeStyles((theme) => ({
  loginCntnr: {
    minHeight: "100vh",
    display: "flex !important",
    flexDirection: "column !important ",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5F5F5",
  },
  logo: {
    width: "180px",
    maxHeight: "90px",
    marginBottom: "80px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  alignCenter: {
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
  },
  widthSetter: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  inputLabel: {
    color: "black",
    textTransform: "uppercase",
    fontWeight: "bold",
    alignSelf: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
      marginRight: "5%",
    },
  },
  inputField: {
    // backgroundColor: "#FFFFFF",
    backgroundColor: "#F5F5F5",
    border: "none !important",
    outline: "none !important",
    borderRadius: "5px",
    height: "51px !important",
    width: "430px !important ",
    [theme.breakpoints.down("sm")]: {
      width: "400px !important ",
    },
    [theme.breakpoints.down("ms")]: {
      width: "90vw !important ",
      marginLeft: "5%",
      marginRight: "5%",
    },
  },
  signupBtn: {
    backgroundColor: "#0B1A9B !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: "#0B1A9B",
      color: "white",
    },
    height: "51px !important",
    width: "430px !important ",
    [theme.breakpoints.down("sm")]: {
      width: "400px !important ",
    },
    [theme.breakpoints.down("ms")]: {
      width: "90vw !important ",
      marginLeft: "5%",
      marginRight: "5%",
    },
  },
  forgotPasswordText: {
    fontSize: "17px !important",
    // fontWeight: 'bold !important',
    color: "#0B1A9B !important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  inLineForgotpasswordCntnr: {
    display: "flex",
    flexWrap: "wrap",
  },
  mainHeadDialog: {
    color: "#000",
    fontSize: "30px !important",
    fontWeight: "700 !important",
  },

  inpuField: {
    background:
      "linear-gradient(136deg, rgba(35, 52, 156, 0.50) 0%, rgba(35, 52, 156, 0.50) 100%)",
    border: "none !important",
    marginTop: "5px",
    borderRadius: "10px !important",
    color: "#FFFF !important",
    fontSize: "18px !important",
    outline: "none !important",
    width: "100% !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    [theme.breakpoints.down("nineS")]: {
      width: "100% !important",
    },
  },
  inpuFieldText: {
    fontSize: "20px !important",
    color: "#000",
  },
  cancelBtn: {
    background: "transparent",
    padding: "14px 24px 14px 24px",
    border: "1px solid",
    color: "#000",
    fontSize: "18px",
    borderRadius: "7px",
    cursor: "pointer",
    width: "49%",
  },
  saveBtn: {
    // background: '#4CCFF8 !important',
    width: "49%",
    fontSize: "18px !important",
  },
  btnCntnr: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
  },
}));

const DialogCntnr = styled(Box)(({ theme }) => ({
  padding: "48px 32px",
  offset: "0px 4px rgba(0, 0, 0, 0.25)",
  backdropFilter: "blur(10px) ",
  scrollbarWidth: "none",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  borderRadius: "30px",
}));

const Login = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const userLoading = useSelector((state) => state.user.loading);
  const userAuth = useSelector((state) => state.user.userAuth);
  const user = useSelector((state) => state.user.user);
  const uid = localStorage.getItem("uid");

  const [showPassword, setShowPassword] = useState(false);
  const [addForgetPasswordDialogOpen, setAddForgetPasswordDialogOpen] =
    useState(false);
  const [disableEmailResetnBtn, setDisableEmailResetnBtn] = useState(false);
  const [verifyEmailDialogOpen, setVerifyEmailDialogOpen] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [sendVerificationEmail, setsendVerificationEmail] = useState({
    email: "",
    password: "",
  });

  const valStyle = {
    color: "red",
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      password: Yup.string()
        .min(6)
        .max(20)
        .required("Please Enter Your password"),
    }),
    onSubmit: (values) => {
      dispatch(logInUser(values, navigate));
      // setUserSignupLoading(true)
      // dispatch(registerUser({ ...values, accountType: "viewAndUpload" }, navigate))
      // setUserSignupLoading(false)
    },
  });

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  if (userAuth || uid) {
    return <Navigate to="/mls" />;
  }

  const handleDialog = () => {
    setAddForgetPasswordDialogOpen(false);
    setIsEmailSent(false);
  };
  const handleEmailVerifyDialog = () => {
    setVerifyEmailDialogOpen(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsEmailSent(false);
  };
  const sendEmailVerificationHandler = async (e) => {
    e.preventDefault();
    dispatch(resendVerificationEmail(sendVerificationEmail, navigate));
    setVerifyEmailDialogOpen(false);
  };

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    dispatch(resetPassword(sendVerificationEmail, navigate));
  };
  return (
    <>
      <Grid container className={classes.loginCntnr}>
        <Grid item xs={12}>
          <Box className={classes.alignCenter} sx={{ paddingY: 2 }}>
            <Link to="/">
              <img className={classes.logo} src={blueLogo} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
              // color: '#0B1A9B'
              marginBottom: { xs: "5px", md: "10px" },
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: ["Gilroy Regular"].join(","),
            }}
          >
            Login
          </Typography>
          <Typography
            variant="h5"
            sx={{
              marginBottom: { xs: "10px", md: "20px" },
              fontSize: 20,
              textAlign: "center",
              fontFamily: ["Gilroy Regular"].join(","),
            }}
          >
            Please provide your email address and password.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.alignCenter}>
          <form className={classes.widthSetter} onSubmit={formik.handleSubmit}>
            <Box my={1} className={classes.widthSetter}>
              <TextField
                id="standard-basic"
                label="Email"
                variant="standard"
                required
                type="email"
                fullWidth
                className={classes.inputField}
                placeholder="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email && formik.touched.email ? (
                <p style={valStyle}>{formik.errors.email}</p>
              ) : null}
            </Box>
            <Box my={1} className={classes.widthSetter}>
              <TextField
                id="standard-basic"
                label="Password"
                variant="standard"
                required
                type={showPassword ? "text" : "password"}
                fullWidth
                className={classes.inputField}
                placeholder="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handlePasswordToggle}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {formik.errors.password && formik.touched.password ? (
                <p style={valStyle}>{formik.errors.password}</p>
              ) : null}
            </Box>
            <Grid item xs={12}>
              <Box className={classes.inLineForgotpasswordCntnr}>
                <Typography
                  onClick={() => setAddForgetPasswordDialogOpen(true)}
                  className={classes.forgotPasswordText}
                >
                  Forgot your password
                </Typography>
              </Box>
            </Grid>
            <Box my={3} className={classes.widthSetter}>
              <Button type="submit" className={classes.signupBtn}>
                {userLoading ? (
                  <CircularProgress sx={{ color: "#FFF" }} />
                ) : (
                  "LOGIN"
                )}
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ color: "#000000", margin: "10px 0px" }}>
            Don’t have an account?
            <Link
              style={{
                color: "#0B1A9B",
                textDecoration: "none",
                marginLeft: "5px",
              }}
              to="/signup"
            >
              SIGNUP
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.inLine}>
            <Typography
              className={classes.forgotPasswordText}
              sx={{ textAlign: "center" }}
              onClick={() => setVerifyEmailDialogOpen(true)}
            >
              Verify your email address
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Start Forget Password Dialog  */}
      <FullScreenDialog
        maxWidth="sm"
        fullWidth={true}
        open={addForgetPasswordDialogOpen}
        hideDialogHandler={handleDialog}
        style={{
          background: "rgba(3, 0, 125, 0.3)",
          "& .MuiDialog-paper": {
            // background: 'rgba(255, 255, 255, 0.1)',
            offset: "0px 4px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "30px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <DialogCntnr>
          <form onSubmit={(e) => handleForgetPassword(e)}>
            <Box mb={1}>
              <Typography className={classes.mainHeadDialog}>
                Forgot your password?
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography className={classes.inpuFieldText}>
                Please enter your account's email. We will email you a new
                password!
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography className={classes.inpuFieldText}>Email</Typography>
              <OutlinedInput
                className={classes.inpuField}
                placeholder="Enter your Email Address"
                type="email"
                value={sendVerificationEmail.email}
                onChange={(e) =>
                  setsendVerificationEmail({
                    ...sendVerificationEmail,
                    email: e.target.value,
                  })
                }
              />
            </Box>
            <Box mt={3} className={classes.btnCntnr}>
              <button
                className={classes.cancelBtn}
                type="reset"
                onClick={() => {
                  setAddForgetPasswordDialogOpen(false);
                }}
              >
                Cancel
              </button>
              <ContainedBtn
                text={
                  userLoading ? (
                    <CircularProgress sx={{ color: "#000" }} />
                  ) : (
                    "Enter"
                  )
                }
                disabled={disableEmailResetnBtn}
                className={classes.saveBtn}
                onClick={(e) => handleForgetPassword(e)}
                type="submit"
              />
            </Box>
          </form>
        </DialogCntnr>
      </FullScreenDialog>
      {/* End Forget Password Dialog  */}

      {/* Start verify Email Dialog */}
      <FullScreenDialog
        maxWidth="sm"
        fullWidth={true}
        open={verifyEmailDialogOpen}
        hideDialogHandler={handleEmailVerifyDialog}
        style={{
          background: "rgba(3, 0, 125, 0.3)",
          "& .MuiDialog-paper": {
            // background: 'rgba(255, 255, 255, 0.1)',
            offset: "0px 4px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "30px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <DialogCntnr>
          <form onSubmit={sendEmailVerificationHandler} autoComplete="email">
            <Box mb={1}>
              <Typography className={classes.mainHeadDialog}>
                {" "}
                Verify your email address
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography sx={{ fontSize: 18, color: "black" }}>
                Please enter your account's email and password. We will email
                you verification Link!
              </Typography>
            </Box>
            <Box mt={2}>
              {/* <Typography className={classes.inpuFieldText}>Email</Typography> */}
              <TextField
                // className={classes.inpuField}
                id="standard-basic"
                label="Email"
                variant="standard"
                required={true}
                type="text"
                fullWidth
                placeholder="Enter your email address"
                value={sendVerificationEmail.email}
                onChange={(e) =>
                  setsendVerificationEmail({
                    ...sendVerificationEmail,
                    email: e.target.value,
                  })
                }
                name="email"
                sx={{ fontFamily: "Gilroy Regular, sans-serif" }}
              />
            </Box>
            <Box mt={2}>
              <TextField
                id="standard-basic"
                label="Password"
                variant="standard"
                required
                type={showPassword ? "text" : "password"}
                fullWidth
                placeholder="Password"
                name="password"
                value={sendVerificationEmail.password}
                onChange={(e) =>
                  setsendVerificationEmail({
                    ...sendVerificationEmail,
                    password: e.target.value,
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handlePasswordToggle}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mt={3} className={classes.btnCntnr}>
              <button
                className={classes.cancelBtn}
                type="reset"
                onClick={handleEmailVerifyDialog}
              >
                Cancel
              </button>
              <ContainedBtn
                text="Verify"
                disabled={disableEmailResetnBtn}
                className={classes.saveBtn}
                onClick={sendEmailVerificationHandler}
                type="submit"
              />
            </Box>
          </form>
        </DialogCntnr>
      </FullScreenDialog>
      {/* End verify Email Dialog */}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isEmailSent}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert
          icon={<CheckCircleIcon fontSize="large" color="white" />}
          severity="success"
          variant="filled"
          sx={{
            width: "auto",
            fontSize: "18px",
            background: "#08A73D !important",
            color: "white",
            fontFamily: "Plus Jakarta Sans !important",
          }}
        >
          Please check your Email,
          <br /> New password has been sent to your Email
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
