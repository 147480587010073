import React, { useState } from 'react'
import {
    Typography,
    Grid,
    Box,
    IconButton,
    OutlinedInput,
    CircularProgress
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import { useSelector, useDispatch } from 'react-redux'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ContainedBtn from '../../../Components/ContainedBtn';

import { addListingOptionsExtras } from '../../../Redux/Listing/ListingActions';

import AddIcon from '@mui/icons-material/Add';
import FullScreenDialog from '../../../Components/Dialog';

const useStyles = makeStyles((theme) => ({
    roundGrayBG: {
        backgroundColor: '#F3F3F3',
        borderRadius: '10px'
    },
    checkBoxCntnr: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    icon: {
        color: '#0B1A9B',
        cursor: 'pointer'
    },
    displayBtwn: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    outlinedBtn: {
        border: '1px solid #0B1A9B !important',
        color: '#0B1A9B !important',
        background: '#fff !important',
        fontSize: '17px',
        fontWeight: 'bold'
    },
    extraInput: {
        border: '1px solid white',
        outline: 'none',
        color: '#fff !important',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
        },
    }
}));

const ExtraCheckBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
    borderRadius: '5px',
    border: '1px solid #0B1A9B',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    minWidth: '200px'
}));

const BoatExtras = ({ onChangeListingCredentials, listingCredentials }) => {
    const classes = useStyles();
    const dispatch = useDispatch();


    const listingExtras = useSelector((state) => state.listings.listingExtras[0])

    const [addMoreExtrasDialogOpen, setAddMoreExtrasDialogOpen] = useState(false)
    const [addMoreExtrasLoading, setAddMoreExtrasLoading] = useState(false)

    const [extrasName, setExtrasName] = useState('')



    const addExtras = (extra) => {
        let extras = listingCredentials.extras;
        if (listingCredentials.extras.includes(extra)) {
            extras = extras.filter((val) => val !== extra)
        }
        else {
            extras.push(extra)
        }
        onChangeListingCredentials('extras', extras)
    }

    const addMoreExtras = () => {

        if (!listingExtras.includes(extrasName)) {

            if (extrasName.trim().length > 0) {
                listingExtras.push(extrasName)
                hideDialog()

                // setAddMoreExtrasLoading(true)
                // dispatch(addListingOptionsExtras(extrasName)).then((res) => {
                //     if (res) {
                //         setAddMoreExtrasLoading(false)
                //         setExtrasName('')
                // hideDialog()
                //     }
                //     setAddMoreExtrasLoading(false)
                // })
            }
        }
    }

    const hideDialog = () => {
        setAddMoreExtrasDialogOpen(false)
        setExtrasName('')
    }

    return (
        <>
            <Box className={classes.roundGrayBG} p={2} >
                <Grid container spacing={3} >
                    <Grid item xs={12} >
                        <ContainedBtn
                            text='Add more Extras'
                            sx={{ '&:hover': { color: '#0B1A9B', outline: '1px solid #0B1A9B' }, height: '50px', width: '210px', marginBottom: '10px' }}
                            endIcon={<AddIcon sx={{ '&:hover': { color: '#0B1A9B' } }} />}
                            onClick={() => setAddMoreExtrasDialogOpen(true)}
                        />
                    </Grid>
                    <Grid item xs={12}  >
                        <Box className={classes.checkBoxCntnr} >
                            {
                                listingExtras?.map((extra, ind) => {
                                    return (
                                        <ExtraCheckBox key={ind} px={2} py={1} m={1} >
                                            <IconButton onClick={() => addExtras(extra)} sx={{ color: '#0B1A9B' }} >
                                                {
                                                    listingCredentials.extras.includes(extra) ? <CheckBoxIcon className={classes.icon} /> : <CheckBoxOutlineBlankIcon className={classes.icon} />
                                                }
                                            </IconButton>
                                            <Typography variant='h6' sx={{ color: '#0B1A9B', width: 'max-content' }} >{extra}</Typography>
                                        </ExtraCheckBox>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <FullScreenDialog
                maxWidth='xs'
                fullWidth={true}
                open={addMoreExtrasDialogOpen}
                hideDialogHandler={hideDialog}
                style={{
                    background: 'rgba(255, 255, 255, 0.10)',
                    "& .MuiDialog-paper": {
                        // background: 'rgba(255, 255, 255, 0.10)',
                        // offset: '0px 4px rgba(0, 0, 0, 0.25)',
                        // backdropFilter: 'blur(10px)',
                        // border: '1px solid rgba(255, 255, 255, 0.20)',
                        // borderRadius: '20px',
                        // boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)'

                          // background: 'rgba(255, 255, 255, 0.10)',
                        // offset: '0px 4px rgba(0, 0, 0, 0.25)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.20)',
                        borderRadius: '20px',
                        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)'
                    },
                }}
            >
                <Box p={2} sx={{ border: '2px solid #0B1A9B', borderRadius: '20px' }} >
                    <Typography variant='h6' sx={{ color: '#000' }} >Enter the name of EXTRA you want to Add</Typography>
                    <OutlinedInput
                        id="outlined-basic"
                        label="Extras"
                        variant="outlined"
                        required={true}
                        type="text"
                        fullWidth
                        placeholder="Extras"
                        name="boatName"
                        sx={{ my: 4 }}
                        className={classes.extraInput}
                        onChange={(e) => setExtrasName(e.target.value)}
                        value={extrasName}
                    />
                    <Box className={classes.displayBtwn}  >
                        <ContainedBtn text='Cancel'
                            className={classes.outlinedBtn}
                            disabled={addMoreExtrasLoading}
                            onClick={hideDialog} />

                        <ContainedBtn
                            text={addMoreExtrasLoading ? <CircularProgress /> : 'Add'}
                            disabled={addMoreExtrasLoading}
                            onClick={addMoreExtras}
                        />
                    </Box>
                </Box>
            </FullScreenDialog>
        </>
    )
}

export default BoatExtras