import React from 'react'
import { styled } from '@mui/material/styles';
import {
    Typography,
    Container,
    Hidden
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import homeCover from "../../../Assets/Images/homeCover.png"
import MainHeading from '../../../Components/MainHeading';
import ContainedBtn from '../../../Components/ContainedBtn';
import SendIcon from '@mui/icons-material/Send';
import sendIcon from "../../../Assets/Icons/sendIcon.png"
import '../../../App.css';

const useStyles = makeStyles((theme) => ({
    homeCover: {
        width: '100%',
        height: '120vh',
        backgroundImage: `-webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),url(${homeCover})`,
        backgroundSize: 'auto, cover,cover',
        backgroundPosition: '0px 0px, 50% 0%,50% 0%',
        backgroundRepeat: 'no-repeat,no-repeat,repeat-y',
        [theme.breakpoints.down("md")]: {
            backgroundSize: 'auto, cover,cover',
            backgroundPosition: '0px 0px, 82% 0%,50% 0%',
            backgroundRepeat: 'repeat,no-repeat,repeat-y'
        },
        display: 'flex',
        alignItems: 'center'
    },
}));



const HeroSectionText = styled(Typography)(({ theme }) => ({
    fontSize: '30px',
    lineHeight: '40px',
    color: 'white',
    maxWidth: '700px',
    marginBottom: '30px',
    marginTop: '20px',
    [theme.breakpoints.down("sm")]: {
        fontSize: '20px',
        lineHeight: '30px',
    },
    [theme.breakpoints.down("ms")]: {
        fontSize: '16px',
        lineHeight: '25px',
    },

}));


const BannerOne = () => {
    const classes = useStyles();

    return (
        <div className={classes.homeCover} >

            <Container maxWidth='fl'  >
                <MainHeading sx={{
                    color: 'white',
                    textTransform: 'capitalize',
                    fontWeight: '400',
                    fontFamily: 'Obadiah Pro, sans-serif'
                }} text={<> <>Grow your business </>
                    <Hidden> <br /> </Hidden>
                    with Rent By Broker</>} />
                <HeroSectionText sx={{ fontFamily: 'Gilroy Regular, sans-serif' }}>
                    We are a broker to broker marketplace helping
                    you manage Your Fleet, Broker Rentals,
                    and Browse The Marketplace
                </HeroSectionText>
                <ContainedBtn
                    sx={{ fontFamily: 'Gilroy Regular, sans-serif', textTransform: 'capitalize' }}
                    text='Become a Member'
                    endIcon={<img  src={sendIcon} style={{ width: '14px', height: '14px' }} />}
                />
            </Container>
        </div>
    )
}

export default BannerOne