export const priceFilter = (data, startLimit, endLimit, initial) => {

    if (initial) {
        return data
    }

    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const currentDay = daysOfWeek[dayOfWeek];

    return data.filter((item) => {
        let price = 1
        if (item?.pricingType === "termCharter") {
            price = item?.termCharter.pricing[1]
        }
        else {
            // if (item?.dayCharter?.pricingSameEachDay === "yes") {
            //     price = item?.dayCharter?.price
            // }
            // else {
            //     if (item?.availability[currentDay]) {
            //         price = item?.dayCharter?.weeklyPrice[currentDay]
            //     }
            //     else {
            //         price = -1
            //     }
            // }
            price = item?.dayCharter?.price
        }
        return price >= startLimit && price <= endLimit
    })
}

export const noOFPassengersFilter = (data, startLimit, endLimit) => {
    return data.filter((item) => item?.noOfPassengers >= startLimit && item?.noOfPassengers <= endLimit)
}

export const boatLengthFilter = (data, startLimit, endLimit) => {
    return data.filter((item) => item?.boatLength >= startLimit && item?.boatLength <= endLimit)
}

export const boatYearFilter = (data, startLimit, endLimit) => {
    // return data.filter((item) => {
    //     const year = item?.year;
    //     return (!startLimit.length || year >= startLimit) && (!endLimit.length || year <= endLimit);
    // });
    if (startLimit.length == 0) {
        return data.filter((item) => item?.year <= endLimit)
    }
    else if (endLimit.length == 0) {
        return data.filter((item) => item?.year >= startLimit)
    }
    else {
        return data.filter((item) => item?.year >= startLimit && item?.year <= endLimit)
    }
}

export const boatMakeFilter = (data, boatMake) => {
    if (boatMake.length === 0) {
        return data
    }
    return data.filter((item) => item?.make == boatMake)
}

export const boatPricingTypeFilter = (data, boatPricingType, dayCharterMinimumHour) => {
    if (boatPricingType.length === 0) {
        return data
    }

    if (boatPricingType == "dayCharter" && dayCharterMinimumHour?.trim()?.length > 0) {
        return data.filter((item) => item?.pricingType == boatPricingType && item?.dayCharter?.minimumHoursBeBooked <= dayCharterMinimumHour)
    }
    else {
        return data.filter((item) => item?.pricingType == boatPricingType)
    }
}

export const boatCountryFilter = (data, boatCountry) => {
    if (boatCountry.length === 0) {
        return data
    }
    const lowercaseCountry = boatCountry.toLowerCase();

    return data.filter((item) => {
        // Convert the item's country to lowercase for case-insensitive comparison
        const itemCountry = item?.boatAddress?.country?.toLowerCase();

        return itemCountry == lowercaseCountry;
    });
}

export const listingSearch = (data, latLng, formattedAddress, searchCountry) => {

    if (formattedAddress.length === 0 || Object.keys(latLng).length === 0) {

        return data
    }
    const threshold = 0.8;
    // str1 == formattedAddress
    const formattedAddressLen = formattedAddress.length;

    function levenshteinDistance(str1, str2) {
        // const formattedAddressLen = str1.length;
        const len2 = str2.length;

        const dp = Array.from({ length: formattedAddressLen + 1 }, () => Array(len2 + 1).fill(0));

        for (let i = 0; i <= formattedAddressLen; i++) {
            for (let j = 0; j <= len2; j++) {
                if (i === 0) {
                    dp[i][j] = j;
                } else if (j === 0) {
                    dp[i][j] = i;
                } else {
                    const cost = str1[i - 1] !== str2[j - 1] ? 1 : 0;
                    dp[i][j] = Math.min(
                        dp[i - 1][j - 1] + cost,
                        dp[i][j - 1] + 1,
                        dp[i - 1][j] + 1
                    );
                }
            }
        }

        const maxLen = Math.max(formattedAddressLen, len2);
        const similarityRatio = 1 - dp[formattedAddressLen][len2] / maxLen;

        return similarityRatio;
    }

    const lowercaseCountry = searchCountry.toLowerCase();

    return data.filter((item) => {
        let boatAddress = item?.boatAddress;
        let itemCountry = item?.boatAddress?.country?.toLowerCase();

        function areStringsSimilar(str1, str2, threshold) {
            const similarityRatio = levenshteinDistance(str1, str2);
            return similarityRatio >= threshold;
        }

        if (boatAddress?.latLng?.lng == latLng.lng && boatAddress?.latLng?.lat == latLng.lat) {
            return item
        }

        let isSimilar = areStringsSimilar(formattedAddress, boatAddress?.formattedAddress, threshold);
        if (isSimilar) {
            return item
        }

        if (lowercaseCountry == itemCountry) {
            return item
        }

    })
}

export const combinedFilter = (data, priceRange, passengerRange, boatLengthRange, boatMake, boatCountry, boatYear, boatPricingType,
    latLng, formattedAddress, searchCountry, dayCharterMinimumHour) => {

    const filters = [
        (data) => priceFilter(data, priceRange[0], priceRange[1], (priceRange[0] == 1 && priceRange[1] == 1000)),
        (data) => noOFPassengersFilter(data, passengerRange[0], passengerRange[1]),
        (data) => boatLengthFilter(data, boatLengthRange[0], boatLengthRange[1]),
        (data) => boatMakeFilter(data, boatMake),
        (data) => boatPricingTypeFilter(data, boatPricingType, dayCharterMinimumHour),
        (data) => boatCountryFilter(data, boatCountry),
        (data) => boatYearFilter(data, boatYear[0], boatYear[1]),
        (data) => listingSearch(data, latLng, formattedAddress, searchCountry),
    ]

    // Start with the original data.
    let filteredData = data;


    // Apply each filter in the provided array.
    filters.forEach((filter) => {
        filteredData = filter(filteredData);
    });

    // Return the final filtered result.
    return filteredData;
}

export const combinedSearch = (data, latLng, formattedAddress, searchCountry) => {

    const filters = [
        (data) => listingSearch(data, latLng, formattedAddress, searchCountry),
    ]

    // Start with the original data.
    let filteredData = data;


    // Apply each filter in the provided array.
    filters.forEach((filter) => {
        filteredData = filter(filteredData);
    });

    // Return the final filtered result.
    return filteredData;
}
