import React from 'react'
import {
    Typography,
    Container,
    Hidden,
    Grid
} from "@mui/material";
import { makeStyles, } from "@mui/styles";

import MainHeading from '../../../Components/MainHeading';
import BannerWithText from '../../../Components/BannerWithText';

import yart from "../../../Assets/Images/yart.png"

const TextComp = () => {
    return (
        <Grid container  >
            <Grid item xs={12}  >
                <Typography
                    // mb={3}
                    sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: 'xx-large', xs: '30px', sm: '40px ', sevenHalfS: '25px', nineS: '30px', md: '35px', lg: '40px' },
                        marginBottom: { xs: '24px', md: '24px' },
                        fontFamily: 'Obadiah Pro, sans-serif'
                    }}
                > What is Lorem Ipsum? </Typography>
                <Typography
                    // mb={6}
                    sx={{
                        letterSpacing: '1px',
                        marginBottom: { xs: '24px', lg: '48px', md: '40px' },
                        fontFamily: 'Gilroy Regular, sans-serif !important'
                    }} >
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here.
                </Typography>
                <Typography
                    sx={{
                        letterSpacing: '1px',
                        display: { xs: 'block', sevenHalfS: 'none', md: 'block' },
                        fontFamily: 'Gilroy Regular, sans-serif !important'
                    }} >
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                    as opposed to using ndesktop publishing packages and web page editors now use Lorem Ipsum as their default model text,
                    and a search for 'lorem ipsum' will uncover many web sites still in their infancy.
                </Typography>
            </Grid>
        </Grid>
    )
}

const BannerTwo = () => {
    return (
        <Container maxWidth='fl' >
            <Grid container my={15} >
                <Grid item xs={12} >
                    <BannerWithText image={yart} TextComp={<TextComp />} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default BannerTwo