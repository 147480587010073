import { Box, Button, CircularProgress, Grid, Link, TextField } from '@mui/material';
import { makeStyles, styled } from "@mui/styles";
import React, { useState } from 'react';

import blueLogo from "../../Assets/Images/blueLogo.png";
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    loginCntnr: {
        minHeight: "100vh",
        display: "flex !important",
        flexDirection: "column !important ",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F5F5F5",
    },
    logo: {
        width: "180px",
        maxHeight: "90px",
        marginBottom: "80px",
        // [theme.breakpoints.down("sm")]: {
        //     width: "100%",
        // },
    },
    alignCenter: {
        display: "flex",
        flexDirection: "column !important",
        justifyContent: "center",
        alignItems: "center",
    },
    widthSetter: {
        width: "auto",
        // [theme.breakpoints.down("sm")]: {
        //     width: "100%",
        // },
    },
    inputLabel: {
        color: "black",
        textTransform: "uppercase",
        fontWeight: "bold",
        alignSelf: "flex-start",
        // [theme.breakpoints.down("sm")]: {
        //     marginLeft: "5%",
        //     marginRight: "5%",
        // },
    },
    inputField: {
        // backgroundColor: "#FFFFFF",
        backgroundColor: "#F5F5F5",
        border: "none !important",
        outline: "none !important",
        borderRadius: "5px",
        height: "51px !important",
        width: "430px !important ",
        // [theme.breakpoints.down("sm")]: {
        //     width: "400px !important ",
        // },
        // [theme.breakpoints.down("ms")]: {
        //     width: "90vw !important ",
        //     marginLeft: "5%",
        //     marginRight: "5%",
        // },
    },
    signupBtn: {
        backgroundColor: "#0B1A9B !important",
        color: "white !important",
        "&:hover": {
            backgroundColor: "#0B1A9B",
            color: "white",
        },
        height: "51px !important",
        width: "430px !important ",
        // [theme.breakpoints.down("sm")]: {
        //     width: "400px !important ",
        // },
        // [theme.breakpoints.down("ms")]: {
        //     width: "90vw !important ",
        //     marginLeft: "5%",
        //     marginRight: "5%",
        // },
    },
}))

const PasswordPrompt = ({ onPasswordSubmit }) => {
    const classes = useStyles();
    const [password, setPassword] = useState('');
    const userLoading = useSelector((state) => state.user.loading);

    const handleSubmit = (e) => {
        e.preventDefault();
        onPasswordSubmit(password);
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container className={classes.loginCntnr}>
                    <Grid item xs={12}>
                        <Box className={classes.alignCenter} sx={{ paddingY: 0 }}>
                            <Link to="/">
                                <img className={classes.logo} src={blueLogo} />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.alignCenter}>
                        <form className={classes.widthSetter}>
                            <Box my={0} className={classes.widthSetter}>
                                <TextField
                                    className={classes.inputField}
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter password"
                                    required
                                />
                            </Box>
                        </form>
                    </Grid>
                    <Box my={3} className={classes.widthSetter}>
                        <Button type="submit" className={classes.signupBtn}>
                            {userLoading ? (
                                <CircularProgress sx={{ color: "#FFF" }} />
                            ) : (
                                "Submit"
                            )}
                        </Button>
                    </Box>
                </Grid>
            </form>
        </>
    );
};

export default PasswordPrompt;
