import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Box,
  Container,
  Switch,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  getAllListing,
  getAllPDFListingMarkUpPrices,
  setAllMarkUpPrices,
} from "../../Redux/Listing/ListingActions";
import { setUserLoading, getCurrentUser } from "../../Redux/User/UserActions";

import { PDFExport, savePDF, PDFExportProps } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import SingleListingDetails from "./Components/SingleListingDetails";
import CustomizePDf from "./Components/CustomizePDf";
import Swal from "sweetalert2";
import clipboard from "clipboard-copy";
import ComparisonLayout from "./Components/ComparisonLayout";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { storage } from "../../FireBase/firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadString,
} from "firebase/storage";
import FullScreenDialog from "../../Components/Dialog";

const useStyles = makeStyles((theme) => ({}));

const MainCntnr = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "90vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const EllipsisText = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "95%", // Optionally set a maximum width
  color: "#17233C",
  fontSize: "15px",
  fontWeight: "bold",
  cursor: "pointer",
});

const GeneratePdfs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  let { ids } = params;

  let slectedListingIDs = ids.split("-");
  const container = React.useRef(null);
  const pdfExportComponent = useRef(null);
  const exportPDFWithMethod = () => {
    let element = container?.current || document?.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
  };
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const currentUser = useSelector((state) => state.user.user);
  const markUpPrices = useSelector((state) => state.listings.markUpPrices);
  const slectedListings = useSelector((state) =>
    state.listings.allListings.filter((item) =>
      slectedListingIDs.includes(item?.id)
    )
  );

  const [markUpPrice, setmarkUpPrice] = useState(
    [...Array(slectedListingIDs.length).keys()].map(() => "")
  );

  const [pdfURL, setpdfURL] = useState(null);
  const [URLDialogOpen, setURLDialogOpen] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [isEditAllow, setIsEditAllow] = useState(true);

  const [isDescriptionDisplay, setisDescriptionDisplay] = useState(true);
  const [isDataSheetDisplay, setisDataSheetDisplay] = useState(true);
  const [isExtrastDisplay, setisExtrastDisplay] = useState(true);
  const [isTenderToysDisplay, setisTenderToysDisplay] = useState(true);
  const [isHeaderInfoDisplay, setisHeaderInfoDisplay] = useState(true);
  const [isAvaliabilityDisplay, setisAvaliabilityDisplay] = useState(true);
  const [isPhotoAlbumDisplay, setisPhotoAlbumDisplay] = useState(true);
  const [isCaptianInfoDisplay, setisCaptianInfoDisplay] = useState(true);
  const [isMarkUpPrice, setIsMarkUpPrice] = useState(true);
  const [viewOption, setViewOption] = useState(
    slectedListings?.length > 1 ? "Comparison" : "Full View"
  );
  useEffect(() => {
    // dispatch(getAllListing())

    dispatch(getAllPDFListingMarkUpPrices(slectedListingIDs));

    dispatch(setUserLoading(true));
    dispatch(getCurrentUser());

    return () => {
      dispatch(setAllMarkUpPrices({}));
    };
  }, []);

  const handleExportWithComponent = (event) => {
    setIsEditAllow(false);
    setPrintLoading(true);

    setTimeout(() => {
      const content = document.getElementById("PDFExporter");
      drawDOM(content, {
        paperSize: "A4",
      })
        .then((group) => exportPDF(group))
        .then(async (dataUri) => {
          if (dataUri) {
            const pdfData = dataUri.split(";base64,")[1];

            const timestamp = Date.now();
            const fileName = `pdf_${timestamp}.pdf`;

            // Create a storage reference for the PDF file
            const docFileRef = ref(storage, `pdfs/${fileName}`);

            try {
              // Upload the PDF data to Firebase Storage
              await uploadString(docFileRef, pdfData, "base64", {
                contentType: "application/pdf",
              });

              // Get the download URL for the uploaded PDF
              const downloadURL = await getDownloadURL(docFileRef);

              setpdfURL(downloadURL);
              setURLDialogOpen(true);
              // console.log(downloadURL, "downloadURL")
              // You can use the downloadURL as needed

              setPrintLoading(false);
              // setIsEditAllow(true)
            } catch (error) {
              console.error("Error uploading PDF:", error);
              setPrintLoading(false);
              setIsEditAllow(true);
            }
          } else {
            setPrintLoading(false);
            setIsEditAllow(true);
          }
        });
    }, 3000);
  };

  const copyCodeToClipBoard = () => {
    clipboard(pdfURL)
      .then(() => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          background: "#08A73D",
          iconColor: "white",
          color: "white",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "success",
          title: `PDF Link  Copied`,
        });
        hideDialog();
        // You can also provide user feedback here
      })
      .catch((err) => {
        // console.error('Failed to copy text:', err);
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          background: "#F62424",
          iconColor: "white",
          color: "white",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "error",
          title: `${err}`,
        });
        // Handle any errors that may occur during copying
      });
  };

  const hideDialog = () => {
    setURLDialogOpen(false);
    setpdfURL(null);
  };

  const TopLogo = styled("img")(({ theme }) => ({
    width: "100px",
    maxHeight: "200px",
  }));

  const PDFHeader = ({ currentUser }) => {
    return (
      <Box sx={{ display: "flex", gap: "10px", mx: 2 }}>
        <Box>
          {currentUser?.profilePicture && (
            <img
              src={currentUser?.profilePicture}
              alt="Profile"
              style={{ width: "50px", height: "50px" }}
            />
          )}
        </Box>
        <Box className="topInfo" pt={1}>
          <Typography className="bold" style={{ fontSize: "18px" }}>
            {currentUser?.firstName + " " + currentUser?.lastName}
          </Typography>
          <Typography className="smallFont">{currentUser?.email}</Typography>
          <Typography className="smallFont">{currentUser?.phoneNo}</Typography>
        </Box>
      </Box>
    );
  };
  const pageTemplate = (props) => {
    return (
      <div>
        {viewOption === "Comparison" && <PDFHeader currentUser={currentUser} />}
        <div style={{ height: "100px" }}></div>{" "}
        {/* Adjust this spacing as needed */}
        <div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
          <Typography
            variant="caption"
            style={{ textAlign: "center", width: "100%" }}
          >
            Page {props.pageNum} of {props.totalPages}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      {slectedListings?.length !== slectedListingIDs?.length ||
      Object.keys(markUpPrices).length == 0 ? (
        <MainCntnr>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="164"
            height="81"
            viewBox="0 0 164 81"
            fill="none"
          >
            <path
              d="M90.8593 7.20694H99.8538C100.919 7.18555 101.972 7.42594 102.923 7.90701C103.792 8.34873 104.519 9.02731 105.02 9.86452C105.531 10.7372 105.792 11.7344 105.772 12.7458C105.772 14.3671 105.488 15.6457 104.92 16.5817C104.352 17.5177 103.308 18.1606 101.787 18.5103L106.348 26.3326H102.31L97.9242 18.8111H94.3657V26.3326H90.8593V7.20694ZM99.0442 15.7293C101.133 15.7293 102.177 14.8434 102.177 13.0717C102.177 12.1036 101.947 11.4183 101.489 11.0159C101.03 10.6134 100.259 10.4135 99.1753 10.4161H94.3561V15.7293H99.0442Z"
              fill="#0B1A9B"
            />
            <path
              d="M109.889 25.7945C108.84 25.1611 107.992 24.2412 107.447 23.1427C106.845 21.9473 106.542 20.6239 106.562 19.2856C106.543 17.9631 106.844 16.6555 107.439 15.4747C107.988 14.3777 108.829 13.4539 109.87 12.8056C110.907 12.1617 112.106 11.8272 113.327 11.8413C114.614 11.8071 115.88 12.1752 116.949 12.8943C117.99 13.6378 118.785 14.6772 119.229 15.8778C119.743 17.1635 119.964 18.6678 119.892 20.3906H109.978C110.144 21.4604 110.529 22.2916 111.135 22.8843C111.442 23.1826 111.808 23.4154 112.208 23.5685C112.608 23.7217 113.035 23.792 113.463 23.7753C114.967 23.7753 115.936 23.1324 116.37 21.8467C116.437 21.6387 116.558 21.4524 116.721 21.3067C116.876 21.1778 117.071 21.1093 117.272 21.1139H119.778C119.561 22.8689 118.893 24.2433 117.774 25.2371C116.654 26.231 115.218 26.7286 113.463 26.7299C112.209 26.7537 110.972 26.43 109.889 25.7945ZM111.239 15.4535C110.68 15.9549 110.294 16.6736 110.082 17.6096H116.444C116.293 16.6569 115.955 15.9344 115.43 15.4419C114.904 14.9495 114.206 14.7026 113.336 14.7014C112.568 14.6763 111.819 14.9453 111.243 15.4535H111.239Z"
              fill="#0B1A9B"
            />
            <path
              d="M121.178 12.1691H123.433C123.676 12.1668 123.911 12.2517 124.097 12.4083C124.286 12.5605 124.407 12.7806 124.436 13.0216C125.404 12.2502 126.59 11.8644 127.994 11.8644C129.548 11.8644 130.769 12.2907 131.657 13.1431C132.545 13.9955 132.988 15.1655 132.985 16.6531V26.3808H129.695V16.8324C129.695 16.1806 129.498 15.6836 129.105 15.3416C128.719 14.9984 128.141 14.8267 127.389 14.8267C126.998 14.8111 126.607 14.8765 126.243 15.0189C125.878 15.1612 125.546 15.3775 125.269 15.6541C124.997 15.9363 124.785 16.2705 124.645 16.6367C124.505 17.0028 124.441 17.3935 124.455 17.7851V26.3847H121.178V12.1691Z"
              fill="#0B1A9B"
            />
            <path
              d="M135.209 12.169V8.08429H138.487V12.169H140.993V15.0773H138.487V22.8745C138.466 23.0393 138.511 23.2057 138.612 23.3374C138.779 23.4335 138.972 23.4734 139.163 23.4511C139.272 23.455 139.382 23.4466 139.489 23.4261C139.58 23.4067 139.672 23.3983 139.765 23.401C140.077 23.3871 140.382 23.495 140.617 23.7019C140.727 23.8083 140.813 23.9373 140.87 24.08C140.926 24.2228 140.951 24.376 140.942 24.5292V26.1589C140.207 26.355 139.449 26.4562 138.687 26.4597C137.584 26.4597 136.729 26.1878 136.119 25.6439C135.51 25.1001 135.205 24.3286 135.204 23.3296V15.0773H134.352C134.079 15.0773 133.818 14.969 133.625 14.7762C133.432 14.5834 133.324 14.322 133.324 14.0494V12.169H135.209Z"
              fill="#0B1A9B"
            />
            <path
              d="M90.8593 30.7355H99.077C101.133 30.7355 102.72 31.3205 103.838 32.4905C104.276 32.9316 104.613 33.4618 104.827 34.045C105.056 34.6621 105.171 35.3155 105.167 35.9735C105.167 37.6283 104.515 38.8735 103.212 39.7092C104.029 40.1116 104.715 40.7375 105.192 41.5143C105.666 42.3092 105.909 43.221 105.893 44.1468C105.898 44.8661 105.767 45.5799 105.508 46.2509C105.261 46.9006 104.891 47.4965 104.419 48.0059C103.829 48.6389 103.1 49.1245 102.289 49.4234C101.391 49.7571 100.439 49.9225 99.4818 49.9113H90.8593V30.7355ZM98.8013 38.4074C99.7202 38.4074 100.427 38.2068 100.922 37.8057C101.417 37.4045 101.663 36.8362 101.66 36.1008C101.677 35.8027 101.626 35.5047 101.511 35.2292C101.396 34.9536 101.22 34.7076 100.997 34.5098C100.552 34.1318 99.9207 33.9427 99.102 33.9427H94.3657V38.4055L98.8013 38.4074ZM99.3796 46.6289C100.464 46.6289 101.249 46.3865 101.735 45.9018C101.945 45.6877 102.109 45.4328 102.217 45.1528C102.325 44.8729 102.374 44.5738 102.362 44.2741C102.362 43.4037 102.091 42.7351 101.548 42.2684C101.006 41.8017 100.235 41.5677 99.235 41.5664H94.3657V46.6289H99.3796Z"
              fill="#0B1A9B"
            />
            <path
              d="M109.502 35.6995L112.708 44.1737L113.033 45.0261L113.334 44.1737L116.442 35.6995H119.95L114.061 50.565C113.547 51.9008 112.968 52.8413 112.326 53.3865C111.683 53.9316 110.859 54.2003 109.853 54.1926C109.209 54.2103 108.566 54.1256 107.948 53.9419C107.782 53.8755 107.641 53.7571 107.547 53.6044C107.449 53.4507 107.397 53.272 107.397 53.0895V50.8658C107.629 50.898 107.992 50.9442 108.486 51.0047C108.979 51.0625 109.317 51.1088 109.502 51.1416C109.784 51.1574 110.063 51.0735 110.29 50.9044C110.549 50.6392 110.742 50.3168 110.853 49.9632L111.254 48.9102L105.943 35.6995H109.502Z"
              fill="#0B1A9B"
            />
            <path
              d="M90.8593 54.2659H99.077C101.133 54.2659 102.72 54.8509 103.838 56.0209C104.276 56.4616 104.614 56.9919 104.827 57.5754C105.056 58.1924 105.171 58.8458 105.167 59.5039C105.167 61.1574 104.515 62.4019 103.212 63.2376C104.028 63.6412 104.714 64.2669 105.192 65.0428C105.666 65.8379 105.909 66.7496 105.893 67.6753C105.898 68.3953 105.767 69.1097 105.508 69.7813C105.261 70.431 104.891 71.0269 104.419 71.5363C103.829 72.1687 103.1 72.6536 102.289 72.9519C101.391 73.2863 100.439 73.4523 99.4818 73.4417H90.8593V54.2659ZM98.8013 61.9359C99.7202 61.9359 100.427 61.7353 100.922 61.3341C101.417 60.933 101.663 60.3654 101.66 59.6312C101.66 58.9446 101.439 58.4136 100.997 58.0382C100.555 57.6628 99.9232 57.4738 99.102 57.4712H94.3657V61.932L98.8013 61.9359ZM99.3796 70.1593C100.464 70.1593 101.249 69.9169 101.735 69.4322C101.945 69.2178 102.109 68.9626 102.217 68.6823C102.325 68.402 102.374 68.1026 102.362 67.8026C102.362 66.9347 102.091 66.2661 101.548 65.7969C101.006 65.3276 100.235 65.0936 99.235 65.0949H94.3657V70.1593H99.3796Z"
              fill="#0B1A9B"
            />
            <path
              d="M106.695 59.2301H108.951C109.223 59.2301 109.485 59.3384 109.677 59.5312C109.87 59.724 109.978 59.9855 109.978 60.2581V60.3641C110.437 59.9671 110.958 59.6491 111.52 59.423C112.072 59.2007 112.661 59.0861 113.255 59.0855C113.496 59.0823 113.737 59.1115 113.97 59.1723C114.224 59.2485 114.471 59.3453 114.709 59.4616V62.312C114.162 62.2179 113.609 62.1676 113.055 62.1616C111.898 62.1616 111.097 62.4502 110.651 63.0275C110.205 63.6048 109.979 64.4694 109.974 65.6214V73.4437H106.697L106.695 59.2301Z"
              fill="#0B1A9B"
            />
            <path
              d="M117.808 72.8285C116.762 72.1846 115.913 71.266 115.353 70.1728C114.767 68.9835 114.462 67.6753 114.462 66.3494C114.462 65.0235 114.767 63.7154 115.353 62.5261C115.913 61.4323 116.762 60.5132 117.808 59.8685C118.883 59.239 120.106 58.9063 121.352 58.9042C122.597 58.9022 123.821 59.231 124.898 59.8569C125.946 60.4952 126.793 61.4154 127.343 62.5126C127.92 63.7088 128.22 65.0201 128.22 66.3485C128.22 67.6769 127.92 68.9881 127.343 70.1844C126.793 71.2821 125.946 72.203 124.898 72.842C123.829 73.4837 122.601 73.8137 121.353 73.7947C120.104 73.8143 118.875 73.4793 117.808 72.8285ZM123.935 69.6463C124.568 68.8518 124.886 67.7525 124.887 66.3485C124.888 64.9445 124.571 63.8413 123.935 63.0391C123.617 62.6621 123.221 62.359 122.774 62.1511C122.327 61.9432 121.84 61.8355 121.348 61.8355C120.855 61.8355 120.368 61.9432 119.921 62.1511C119.474 62.359 119.078 62.6621 118.761 63.0391C118.118 63.8413 117.797 64.9445 117.797 66.3485C117.797 67.7525 118.118 68.8453 118.761 69.6271C119.078 70.0031 119.474 70.3056 119.92 70.5135C120.366 70.7214 120.852 70.8297 121.345 70.831C121.837 70.8323 122.323 70.7265 122.77 70.5209C123.218 70.3154 123.615 70.015 123.935 69.6406V69.6463Z"
              fill="#0B1A9B"
            />
            <path
              d="M133.004 68.4043V72.4157C133.004 72.6883 132.896 72.9498 132.703 73.1426C132.511 73.3353 132.249 73.4436 131.977 73.4436H129.722V54.2659H132.999V63.9088L135.956 59.5984C136.037 59.4826 136.145 59.388 136.27 59.3226C136.388 59.2575 136.521 59.223 136.655 59.2224H140.615L136.112 65.3784L141.024 73.4514H137.139L133.531 67.3069L133.004 68.4043Z"
              fill="#0B1A9B"
            />
            <path
              d="M144.092 72.8536C143.042 72.2229 142.194 71.3056 141.646 70.2095C141.047 69.0134 140.746 67.69 140.769 66.3523C140.75 65.0298 141.051 63.7223 141.646 62.5415C142.195 61.445 143.035 60.5213 144.075 59.8724C145.113 59.2295 146.312 58.8951 147.533 58.9081C148.821 58.8739 150.087 59.242 151.155 59.9611C152.197 60.7032 152.991 61.7422 153.434 62.9426C153.948 64.2283 154.17 65.7326 154.099 67.4555H144.175C144.342 68.5252 144.728 69.3571 145.332 69.9511C145.64 70.2486 146.006 70.4808 146.406 70.6335C146.807 70.7863 147.234 70.8566 147.662 70.8401C149.166 70.8401 150.135 70.1973 150.569 68.9116C150.636 68.7038 150.757 68.5179 150.92 68.3735C151.075 68.2447 151.271 68.1762 151.472 68.1806H153.978C153.759 69.9356 153.091 71.3101 151.973 72.3039C150.855 73.2978 149.418 73.7954 147.662 73.7966C146.408 73.8177 145.173 73.4913 144.092 72.8536ZM145.442 62.5126C144.88 63.014 144.495 63.7327 144.285 64.6687H150.646C150.496 63.716 150.158 62.9934 149.633 62.501C149.107 62.0086 148.409 61.7617 147.539 61.7604C146.771 61.7354 146.022 62.0044 145.446 62.5126H145.442Z"
              fill="#0B1A9B"
            />
            <path
              d="M155.381 59.2301H157.646C157.918 59.2301 158.18 59.3384 158.372 59.5312C158.565 59.724 158.673 59.9855 158.673 60.2581V60.3641C159.135 59.9658 159.66 59.6476 160.227 59.423C160.778 59.2002 161.367 59.0857 161.962 59.0855C162.203 59.0823 162.444 59.1115 162.677 59.1723C162.931 59.2485 163.178 59.3453 163.415 59.4616V62.312C162.87 62.218 162.317 62.1677 161.763 62.1616C160.607 62.1616 159.805 62.4502 159.358 63.0275C158.91 63.6048 158.685 64.4694 158.681 65.6214V73.4437H155.404L155.381 59.2301Z"
              fill="#0B1A9B"
            />
            <path
              d="M44.8398 38.5712C45.2574 38.5518 45.6698 38.4713 46.0639 38.3321C46.8127 38.039 47.435 37.4925 47.8224 36.7877C48.2099 36.0829 48.338 35.2645 48.1844 34.4749C47.8856 32.8626 46.4494 31.7093 44.7145 31.7036C42.5324 31.7036 41.8442 31.7036 39.6659 31.7036H37.3199H36.3252C35.8645 31.7036 35.7469 31.7691 35.9397 32.2956C36.8149 34.7333 36.7262 37.0958 35.1841 39.279C34.1547 40.737 32.7185 41.682 31.1224 42.3936C30.6983 42.5865 30.708 42.7022 30.9566 43.0455C32.36 44.9959 33.7473 46.9579 35.1185 48.9315C35.2108 49.0799 35.3428 49.1996 35.4994 49.2769C35.6561 49.3543 35.8313 49.3863 36.0053 49.3693H36.331C38.1816 49.3693 38.544 49.3693 40.3965 49.3693H44.8803C45.6279 49.287 46.325 48.9517 46.8561 48.4189C47.3872 47.8862 47.7205 47.1879 47.8008 46.4398C47.9607 44.9143 47.5656 43.0763 44.9439 42.8063C44.3406 42.7427 43.9647 42.3782 43.9435 41.7553C43.9203 41.0205 43.9203 40.2838 43.9435 39.547C43.9396 38.9183 44.2384 38.6194 44.8398 38.5712Z"
              fill="#0B1A9B"
            />
            <path
              d="M60.2824 38.5712C60.6993 38.5519 61.1111 38.4713 61.5045 38.3321C62.2533 38.039 62.8756 37.4925 63.263 36.7877C63.6505 36.0829 63.7786 35.2645 63.625 34.4749C63.3262 32.8626 61.8901 31.7093 60.1552 31.7036H51.3321C51.5277 32.0982 51.6883 32.5093 51.8121 32.9321C52.5119 35.7323 52.2883 38.3051 49.9423 40.3339C49.6975 40.5441 49.8266 40.6328 49.977 40.7833C50.4859 41.2905 51.4439 43.0243 51.7986 45.0531C52.0518 46.5118 51.8414 48.0132 51.1972 49.3461C55.1933 49.3461 58.6882 49.3461 59.9393 49.3635H59.9682C60.7777 49.3581 61.5569 49.0547 62.1571 48.5111C62.7573 47.9676 63.1364 47.222 63.2221 46.4166C63.384 44.8911 62.9869 43.0532 60.3672 42.7832C59.7619 42.7196 59.386 42.3551 59.3667 41.7321C59.3417 40.9973 59.3417 40.2606 59.3667 39.5239C59.3821 38.9183 59.6809 38.6194 60.2824 38.5712Z"
              fill="#0B1A9B"
            />
            <path
              d="M40.4814 0C18.1202 0 0 18.1286 0 40.5C0 62.8714 18.1202 81 40.4814 81C62.8425 81 80.9627 62.8714 80.9627 40.5C80.9627 18.1286 62.8425 0 40.4814 0ZM65.4179 40.7796C65.9268 41.2869 66.8868 43.0206 67.2396 45.0495C67.457 46.2929 67.3393 47.5717 66.8985 48.7544C66.4577 49.9372 65.7097 50.9809 64.7316 51.7783C63.5615 52.731 61.8729 53.5468 59.5173 53.5564C59.5173 53.5564 34.1586 53.5796 33.0752 53.5931C32.9298 53.6035 32.7842 53.5744 32.6539 53.5089C32.5236 53.4433 32.4134 53.3438 32.335 53.2209C30.0121 50.0676 27.6758 46.9279 25.3548 43.7709C24.3389 42.3919 24.8324 40.77 26.4382 40.1336C27.5061 39.7054 28.5818 39.2985 29.6497 38.8646C31.454 38.1375 32.4256 36.4461 32.0767 34.641C31.7278 32.8359 30.2126 31.6286 28.2984 31.6286C24.7168 31.6131 21.1428 31.6286 17.5612 31.6131C17.0754 31.6131 17.0253 31.7867 17.0311 32.1917C17.0401 38.6331 17.0401 45.0771 17.0311 51.5237C17.0311 52.5574 16.5087 53.2999 15.6586 53.5082C14.4364 53.8129 13.3608 52.947 13.3608 51.6279C13.3608 48.384 13.3608 45.1459 13.3608 41.9021V30.3306C13.3608 28.5236 14.3998 27.4899 16.2138 27.4899C23.0281 27.4899 60.5505 27.405 60.5505 27.405C65.6646 27.6171 67.149 32.5292 67.2473 32.9265C67.9489 35.7268 67.7253 38.2995 65.3774 40.3284C65.1403 40.5405 65.2695 40.6292 65.4179 40.7796Z"
              fill="#0B1A9B"
            />
          </svg>
          <CircularProgress />
        </MainCntnr>
      ) : (
        <Container maxWidth="fl">
          <Grid container p={2}>
            <Grid item xs={12} md={3}>
              <CustomizePDf
                isDescriptionDisplay={isDescriptionDisplay}
                isDataSheetDisplay={isDataSheetDisplay}
                isExtrastDisplay={isExtrastDisplay}
                isTenderToysDisplay={isTenderToysDisplay}
                isHeaderInfoDisplay={isHeaderInfoDisplay}
                isAvaliabilityDisplay={isAvaliabilityDisplay}
                isPhotoAlbumDisplay={isPhotoAlbumDisplay}
                isCaptianInfoDisplay={isCaptianInfoDisplay}
                setisDescriptionDisplay={(state) =>
                  setisDescriptionDisplay(state)
                }
                setisDataSheetDisplay={(state) => setisDataSheetDisplay(state)}
                setisExtrastDisplay={(state) => setisExtrastDisplay(state)}
                setisTenderToysDisplay={(state) =>
                  setisTenderToysDisplay(state)
                }
                setisHeaderInfoDisplay={(state) =>
                  setisHeaderInfoDisplay(state)
                }
                setisAvaliabilityDisplay={(state) =>
                  setisAvaliabilityDisplay(state)
                }
                setisPhotoAlbumDisplay={(state) =>
                  setisPhotoAlbumDisplay(state)
                }
                setisCaptianInfoDisplay={(state) =>
                  setisCaptianInfoDisplay(state)
                }
                setIsMarkUpPrice={(state) => setIsMarkUpPrice(state)}
                viewOption={viewOption}
                setViewOption={setViewOption}
                setIsEditAllow={(state) => setIsEditAllow(state)}
                isEditAllow={isEditAllow}
                printLoading={printLoading}
                printPDF={handleExportWithComponent}
                markUpPrice={markUpPrice}
                setmarkUpPrice={setmarkUpPrice}
                slectedListings={slectedListings}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <PDFExport ref={pdfExportComponent} paperSize="auto" margin={40}>
                <Document>
                  <Page size="A4">
                    <Grid container id="PDFExporter" p={2} ref={container}>
                      {viewOption === "Comparison" && (
                        <Box
                          sx={{
                            display: "flex",
                            mx: 2,
                            alignSelf: "center",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            width: "100%",
                          }}
                        >
                          {/* <Box>
                        {currentUser?.profilePicture && (
                          <TopLogo src={currentUser?.profilePicture} />
                        )}
                      </Box> */}
                          <Box
                            className={classes.topInfo}
                            alignSelf={"flex-end"}
                          >
                            <Typography
                              className={[classes.fontBold, classes.fontFamily]}
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                textAlign: "end",
                              }}
                            >
                              {currentUser?.firstName +
                                " " +
                                currentUser?.lastName}{" "}
                            </Typography>
                            <Typography
                              className={classes.smallFont}
                              sx={{
                                fontSize: "12px",
                                textAlign: "end",
                              }}
                            >
                              {currentUser?.email}
                            </Typography>
                            <Typography
                              className={classes.smallFont}
                              sx={{
                                fontSize: "12px",
                                textAlign: "end",
                              }}
                            >
                              {currentUser?.phoneNo}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {slectedListings.map((listing, ind) => {
                        return (
                          <>
                            {viewOption === "Full View" ? (
                              <SingleListingDetails
                                listingDetails={listing}
                                markUpPrice={markUpPrice[ind]}
                                key={ind}
                                ind={ind}
                                totalLength={slectedListings?.length}
                                isDescriptionDisplay={isDescriptionDisplay}
                                isDataSheetDisplay={isDataSheetDisplay}
                                isExtrastDisplay={isExtrastDisplay}
                                isTenderToysDisplay={isTenderToysDisplay}
                                isHeaderInfoDisplay={isHeaderInfoDisplay}
                                isAvaliabilityDisplay={isAvaliabilityDisplay}
                                isPhotoAlbumDisplay={isPhotoAlbumDisplay}
                                isCaptianInfoDisplay={isCaptianInfoDisplay}
                                isEditAllow={isEditAllow}
                                setIsEditAllow={(state) =>
                                  setIsEditAllow(state)
                                }
                                isMarkUpPrice={isMarkUpPrice}
                              />
                            ) : (
                              <ComparisonLayout
                                listingDetails={listing}
                                markUpPrice={markUpPrice[ind]}
                                key={ind}
                                ind={ind}
                                isDescriptionDisplay={isDescriptionDisplay}
                                isDataSheetDisplay={isDataSheetDisplay}
                                isExtrastDisplay={isExtrastDisplay}
                                isTenderToysDisplay={isTenderToysDisplay}
                                isHeaderInfoDisplay={isHeaderInfoDisplay}
                                isAvaliabilityDisplay={isAvaliabilityDisplay}
                                isPhotoAlbumDisplay={isPhotoAlbumDisplay}
                                isCaptianInfoDisplay={isCaptianInfoDisplay}
                                isEditAllow={isEditAllow}
                                setIsEditAllow={(state) =>
                                  setIsEditAllow(state)
                                }
                              />
                            )}
                          </>
                        );
                      })}
                    </Grid>
                  </Page>
                </Document>
              </PDFExport>
            </Grid>
          </Grid>
        </Container>
      )}
      <FullScreenDialog
        maxWidth="xs"
        fullWidth={true}
        open={URLDialogOpen}
        hideDialogHandler={hideDialog}
        style={{
          background: "rgba(255, 255, 255, 0.10)",
          "& .MuiDialog-paper": {
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "20px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box
          p={2}
          sx={{
            backgroundColor: "#fff",
            border: "2px solid #0B1A9B",
            borderRadius: "20px",
          }}
        >
          <Typography variant="h6">Copy Your Online Brochure Link</Typography>
          <Box sx={{ display: "flex", gap: "10px" }} p={1}>
            <EllipsisText
              onClick={() => {
                window.open(pdfURL, "_blank");
                hideDialog();
              }}
            >
              {pdfURL}
            </EllipsisText>
            <ContentCopyIcon
              sx={{ color: "#0B1A9B", cursor: "pointer" }}
              onClick={copyCodeToClipBoard}
            />
          </Box>
        </Box>
      </FullScreenDialog>
    </>
  );
};

export default GeneratePdfs;
