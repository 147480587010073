import React from 'react'
import {
    Box,
    Typography,
    Snackbar,
} from "@mui/material"
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';


import BoatExtras from './BoatExtras'
import TenderAndToys from './TenderAndToys'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BoatExtrasAndTenderToys = ({ onChangeListingCredentials, listingCredentials }) => {

    const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsErrorSnackBarOpen(false);
    };


    return (
        <>
            <Box  >
                <Box>
                    <TenderAndToys
                        onChangeListingCredentials={onChangeListingCredentials}
                        listingCredentials={listingCredentials}
                        setIsErrorSnackBarOpen={(state) => setIsErrorSnackBarOpen(state)}
                        isErrorSnackBarOpen={isErrorSnackBarOpen}
                        handleClose={handleClose}
                    />
                </Box>
                <Box mt={5} >
                    {/* <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: 'xx-large', xs: '30px', sm: '40px ', sevenHalfS: '25px', nineS: '30px', md: '35px', lg: '40px' },
                            marginBottom: { xs: '24px', sevenHalfS: '15px', md: '30px' }
                        }}
                    >Tender and Toys included with a Boats</Typography> */}
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: 'xx-large', xs: '30px', sm: '40px ', sevenHalfS: '25px', nineS: '30px', md: '35px', lg: '40px' },
                            marginBottom: { xs: '24px', sevenHalfS: '15px', md: '30px' }
                        }}
                    >Extras included with a Boats</Typography>

                    <BoatExtras
                        onChangeListingCredentials={onChangeListingCredentials}
                        listingCredentials={listingCredentials}
                        setIsErrorSnackBarOpen={(state) => setIsErrorSnackBarOpen(state)}
                        isErrorSnackBarOpen={isErrorSnackBarOpen}
                        handleClose={handleClose}
                    />
                </Box>
            </Box>
            <Snackbar open={isErrorSnackBarOpen} autoHideDuration={1000} onClose={handleClose} >
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                    Empty Input Fields OR Incorrect Data
                </Alert>
            </Snackbar>
        </>
    )
}

export default BoatExtrasAndTenderToys