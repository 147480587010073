import React, { useState } from "react";
import {
  Typography,
  Grid,
  Box,
  IconButton,
  Paper,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { resizeFile } from "react-image-file-resizer";

const useStyles = makeStyles((theme) => ({
  roundGrayBG: {
    backgroundColor: "#F3F3F3",
    borderRadius: "10px",
  },
  imgCntnr: {
    width: "250px",
    height: "200px",
    margin: "10px",
    border: "3px solid #0B1A9B",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    // padding: "5px",
    position: "relative",
  },
  img: {
    width: "250px",
    height: "200px",
    // borderRadius: "5px",
  },
  deleteIcon: {
    position: "absolute",
    border: "1px solid black",
  },
}));
const DeleteBtn = styled("button")(({ theme }) => ({
  width: "25px",
  height: "25px",
  backgroundColor: "#fff",
  border: "1px solid #0B1A9B",
  borderRadius: "25px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "13px",
  right: "10px",
  cursor: "pointer",
}));
const BoatPhotos = ({ onChangeListingCredentials, listingCredentials }) => {
  const classes = useStyles();
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = async (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const newImages = Array.from(files);
      setSelectedImages([...selectedImages, ...newImages]);
      onChangeListingCredentials("boatImages", [
        ...selectedImages,
        ...newImages,
      ]);
    } else {
      setSelectedImages([]);
      onChangeListingCredentials("boatImages", []);
    }

  };
  // const handleImageChange = async (e) => {
  //   const files = e.target.files;

  //   if (files.length > 0) {
  //     const landscapeImages = [];

  //     for (let i = 0; i < files.length; i++) {
  //       const file = files[i];

  //       // Check if the file is an image
  //       if (!file.type.startsWith("image/")) {
  //         console.warn(`${file.name} is not an image file.`);
  //         continue;
  //       }

  //       // Read the image file and get its dimensions
  //       const img = document.createElement("img");
  //       img.src = URL.createObjectURL(file);

  //       await new Promise((resolve) => {
  //         img.onload = () => {
  //           URL.revokeObjectURL(img.src);
  //           resolve();
  //         };
  //       });

  //       // Check if the image is landscape (width > height)
  //     }

  //     // Update selectedImages state and call onChangeListingCredentials
  //     setSelectedImages((prevSelectedImages) => [
  //       ...prevSelectedImages,
  //       ...landscapeImages,
  //     ]);
  //     onChangeListingCredentials("boatImages", [
  //       ...selectedImages,
  //       ...landscapeImages,
  //     ]);
  //   } else {
  //     setSelectedImages([]);
  //     onChangeListingCredentials("boatImages", []);
  //   }
  // };

  const handleImageRemove = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
    onChangeListingCredentials("boatImages", updatedImages);
  };

  return (
    <Box className={classes.roundGrayBG} p={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Minimum 4 Pictures</Typography>
          <Box mt={1}>
            <Typography variant="h6">
              Tips to get great photos on your boat
            </Typography>
            <ol type="i">
              <li>Use your camera or smartphone.</li>
              <li>Avoid using flash. Boats look best in natural light.</li>
              <li>Tidy up before taking interior shots.</li>
              <li>
                Take photos in landscape mode and avoid extreme close-ups.
              </li>
            </ol>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <input
              type="file"
              accept="image/*"
              placeholder="Select Boat Images"
              multiple
              name="myImage"
              onChange={handleImageChange}
              id="image-upload"
              style={{ display: "none" }} // Hide the actual input element
            />
            <label htmlFor="image-upload">
              <Button variant="contained" component="span" color="primary">
                Upload Image
              </Button>
            </label>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
          {selectedImages.map((image, ind) => (
            <Box key={ind} className={classes.imgCntnr}>
              <img
                src={URL.createObjectURL(image)}
                alt={`boat-${ind}`}
                className={classes.img}
              />
              <DeleteBtn onClick={() => handleImageRemove(ind)}>
                <ClearIcon sx={{ color: "#0B1A9B", fontSize: "20px" }} />
              </DeleteBtn>
              {/* <IconButton
                className={classes.deleteIcon}
                sx={{
                  border: "1px solid black",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                onClick={() => handleImageRemove(ind)}
              >
                <DeleteIcon />
              </IconButton> */}
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BoatPhotos;
