import {
    Typography,
    Grid,
    Box,
    TextField,
    MenuItem,
    InputAdornment
} from "@mui/material"
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    roundGrayBG: {
        backgroundColor: '#F3F3F3',
        borderRadius: '10px'
    },
    checkBoxCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    outlinedBtn: {
        border: '1px solid #0B1A9B !important',
        color: '#0B1A9B !important',
        background: '#fff !important',
        fontSize: '17px',
        fontWeight: 'bold'
    },
}));



const DayCharter = ({ onChangeListingCredentials, listingCredentials }) => {
    const classes = useStyles();

    // let discountedPriceHoursRange = [1, 2, 3, 4, 5, 6, 8]
    let discountedPriceHoursRange = [2, 4, 6, 8]
    let charterType = ['Bareboat', 'Uninspected passenger vessel', 'Inspected passenger vessel']

    return (
        <Box className={classes.roundGrayBG} p={2} >
            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Typography variant="h5" sx={{ fontFamily: 'Gilroy Regular, sans-serif ' }} >Day Charter Pricing</Typography>
                </Grid>

                {listingCredentials.dayCharter.charterType == "Bareboat" && <Grid item xs={12} my={1} >
                    <Typography>
                        THIS IS WHOLESALE PRICING. BROKERS WILL MARK UP THEIR COMMISSIONS ON TOP OF YOUR RATES.
                    </Typography>
                    <Typography>
                        THIS PRICING SHOULD NOT INCLUDE CAPTAIN PAY OR FUEL AND STORES ACCORDING TO A BAREBOAT AGREEMENT.
                    </Typography>
                </Grid>}
                <Grid item xs={12} md={6} >

                    <TextField
                        id="outlined-select-currency"
                        select
                        required={true}
                        label="What Charter type do you operate under?"
                        fullWidth
                        onChange={(e) => {
                            onChangeListingCredentials("dayCharter", {
                                ...listingCredentials.dayCharter,
                                charterType: e.target.value,
                            })
                        }}
                        value={listingCredentials.dayCharter.charterType}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: 200 }, // Set the maximum height here
                                },
                            },
                        }}
                        sx={{
                            textTransform: 'capitalize'
                        }}
                    >
                        {charterType.map((val) => (
                            <MenuItem key={val} value={val} sx={{ textTransform: 'capitalize' }} >
                                {val}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={0} md={1} />

                <Grid item xs={12} md={6} >
                    <TextField
                        id="outlined-select-currency"
                        select
                        required={true}
                        label="Is your pricing the same each day"
                        fullWidth
                        onChange={(e) => {

                            onChangeListingCredentials("dayCharter", {
                                ...listingCredentials.dayCharter,
                                pricingSameEachDay: e.target.value,
                                weeklyPrice: {
                                    'Monday': { '2hrs': '', '4hrs': '', '6hrs': '', '8hrs': '' },
                                    'Tuesday': { '2hrs': '', '4hrs': '', '6hrs': '', '8hrs': '' },
                                    'Wednesday': { '2hrs': '', '4hrs': '', '6hrs': '', '8hrs': '' },
                                    'Thursday': { '2hrs': '', '4hrs': '', '6hrs': '', '8hrs': '' },
                                    'Friday': { '2hrs': '', '4hrs': '', '6hrs': '', '8hrs': '' },
                                    'Saturday': { '2hrs': '', '4hrs': '', '6hrs': '', '8hrs': '' },
                                    'Sunday': { '2hrs': '', '4hrs': '', '6hrs': '', '8hrs': '' }
                                }
                            })
                        }}
                        value={listingCredentials.dayCharter.pricingSameEachDay}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: 200 }, // Set the maximum height here
                                },
                            },
                        }}
                        sx={{
                            textTransform: 'capitalize'
                        }}
                    >
                        {['yes', 'no'].map((val) => (
                            <MenuItem key={val} value={val} sx={{ textTransform: 'capitalize' }} >
                                {val}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>


                <Grid item xs={12} md={6} >
                    <TextField
                        id="outlined-select-currency"
                        select
                        required={true}
                        label="Minimum amount of hours needed to book"
                        fullWidth
                        onChange={(e) => {

                            let value = e.target.value;

                            let FirstWeekDayPrice = {
                                ...listingCredentials.dayCharter.weeklyPrice.Monday,
                            }
                            let FirstWeekEndPrice = {
                                ...listingCredentials.dayCharter.weeklyPrice.Friday,
                            }

                            for (let index = 0; index < discountedPriceHoursRange.length; index++) {
                                const disPrice = discountedPriceHoursRange[index];

                                if (value > disPrice) {
                                    FirstWeekDayPrice[`${disPrice}hrs`] = "";
                                    FirstWeekEndPrice[`${disPrice}hrs`] = ""
                                }

                            }

                            let weeklyPrice = {
                                'Monday': FirstWeekDayPrice,
                                'Tuesday': FirstWeekDayPrice,
                                'Wednesday': FirstWeekDayPrice,
                                'Thursday': FirstWeekDayPrice,
                                'Friday': FirstWeekEndPrice,
                                'Saturday': FirstWeekEndPrice,
                                'Sunday': FirstWeekEndPrice
                            }


                            onChangeListingCredentials("dayCharter", {
                                ...listingCredentials.dayCharter,
                                weeklyPrice: weeklyPrice,
                                minimumHoursBeBooked: e.target.value
                            })
                        }}
                        value={listingCredentials.dayCharter.minimumHoursBeBooked}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: 200 }, // Set the maximum height here
                                },
                            },
                        }}
                        sx={{
                            textTransform: 'capitalize'
                        }}
                    >
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((val) => (
                            <MenuItem key={val} value={val} sx={{ textTransform: 'capitalize' }} >
                                {val}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12} md={6} >
                    <TextField
                        id="outlined-basic"
                        label="This is for Display only purpose"
                        variant="outlined"
                        required={true}
                        type="number"
                        fullWidth
                        placeholder="Enter Hourly Price"
                        name="price"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                        }}
                        sx={{
                            textTransform: 'capitalize'
                        }}
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/^0+/, '');
                            if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                onChangeListingCredentials("dayCharter", { ...listingCredentials.dayCharter, price: e.target.value })
                            }
                        }}
                        value={listingCredentials.dayCharter.price}
                    />
                </Grid>

            </Grid>

            {listingCredentials.dayCharter.pricingSameEachDay === "yes" && <Grid container spacing={2} mt={1} >
                {
                    !discountedPriceHoursRange.includes(listingCredentials.dayCharter.minimumHoursBeBooked) &&
                    <Grid item xs={12} md={6} >
                        <TextField
                            id="outlined-basic"
                            label={`${listingCredentials.dayCharter.minimumHoursBeBooked}hrs Price`}
                            variant="outlined"
                            required={true}
                            type="number"
                            fullWidth
                            placeholder="Enter Hourly Price"
                            name="price"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                            }}
                            onChange={(e) => {
                                const inputValue = e.target.value.replace(/^0+/, '');
                                if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                    let OneDayPrice = {
                                        ...listingCredentials.dayCharter.weeklyPrice.Monday,
                                        [`${listingCredentials.dayCharter.minimumHoursBeBooked}hrs`]: inputValue
                                    }
                                    let weeklyPrice = listingCredentials.dayCharter.weeklyPrice
                                    weeklyPrice.Monday = OneDayPrice;
                                    weeklyPrice.Tuesday = OneDayPrice;
                                    weeklyPrice.Wednesday = OneDayPrice;
                                    weeklyPrice.Thursday = OneDayPrice;
                                    weeklyPrice.Friday = OneDayPrice;
                                    weeklyPrice.Saturday = OneDayPrice;
                                    weeklyPrice.Sunday = OneDayPrice;


                                    onChangeListingCredentials("dayCharter", { ...listingCredentials.dayCharter, weeklyPrice: weeklyPrice })
                                }
                            }}
                            value={listingCredentials?.dayCharter?.weeklyPrice?.Monday?.[`${listingCredentials.dayCharter.minimumHoursBeBooked}hrs`] || ""}
                        />
                    </Grid>
                }

                {
                    discountedPriceHoursRange.map((disPrice, ind) => {
                        if (listingCredentials.dayCharter.minimumHoursBeBooked <= disPrice) {

                            return (
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        id="outlined-basic"
                                        label={`${disPrice}hrs Price`}
                                        variant="outlined"
                                        required={true}
                                        type="number"
                                        fullWidth
                                        placeholder="Enter Hourly Price"
                                        name="price"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                                        }}
                                        onChange={(e) => {
                                            const inputValue = e.target.value.replace(/^0+/, '');
                                            if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                                let OneDayPrice = {
                                                    ...listingCredentials.dayCharter.weeklyPrice.Monday,
                                                    [`${disPrice}hrs`]: inputValue
                                                }
                                                let weeklyPrice = listingCredentials.dayCharter.weeklyPrice
                                                weeklyPrice.Monday = OneDayPrice;
                                                weeklyPrice.Tuesday = OneDayPrice;
                                                weeklyPrice.Wednesday = OneDayPrice;
                                                weeklyPrice.Thursday = OneDayPrice;
                                                weeklyPrice.Friday = OneDayPrice;
                                                weeklyPrice.Saturday = OneDayPrice;
                                                weeklyPrice.Sunday = OneDayPrice;


                                                onChangeListingCredentials("dayCharter", { ...listingCredentials.dayCharter, weeklyPrice: weeklyPrice })
                                            }
                                        }}
                                        value={listingCredentials?.dayCharter?.weeklyPrice?.Monday?.[`${disPrice}hrs`] || ""}
                                    />
                                </Grid>
                            )
                        }
                    })
                }

            </Grid>}


            {/* {listingCredentials.dayCharter.pricingSameEachDay === "no" && <Grid container spacing={2} mt={1} >

                {
                    Object.keys(listingCredentials.availability).map((val, ind) => {
                        if (listingCredentials.availability[val]) {
                            return (
                                <Grid item xs={12} md={6} key={ind} >
                                    <Typography>{val} Hourly Price</Typography>
                                    <TextField
                                        id="outlined-basic"
                                        label={`Enter ${val} Hourly Price`}
                                        variant="outlined"
                                        required={true}
                                        type="number"
                                        fullWidth
                                        placeholder={`Enter ${val} Hourly Price`}
                                        name="price"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                                        }}
                                        onChange={(e) => {
                                            const inputValue = e.target.value.replace(/^0+/, '');
                                            if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                                let weeklyPrice = listingCredentials.dayCharter.weeklyPrice || {}
                                                weeklyPrice = { ...weeklyPrice, [val]: e.target.value }
                                                onChangeListingCredentials("dayCharter", { ...listingCredentials.dayCharter, weeklyPrice: { ...weeklyPrice } })
                                            }
                                        }}
                                        value={listingCredentials.dayCharter.weeklyPrice[val]}
                                    />
                                </Grid>
                            )
                        }
                    })
                }
            </Grid>} */}
            {
                listingCredentials.dayCharter.pricingSameEachDay === "no" && <Grid container spacing={2} mt={1} >

                    <Grid item xs={12} mt={1} />

                    {
                        !discountedPriceHoursRange.includes(listingCredentials.dayCharter.minimumHoursBeBooked) &&
                        <Grid item xs={12} md={6} >
                            <TextField
                                id="outlined-basic"
                                label={`Weekdays (Mon - Thur) ${listingCredentials.dayCharter.minimumHoursBeBooked}hrs Price`}
                                variant="outlined"
                                required={true}
                                type="number"
                                fullWidth
                                placeholder="Enter Hourly Price"
                                name="price"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                                }}
                                onChange={(e) => {
                                    const inputValue = e.target.value.replace(/^0+/, '');
                                    if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                        let OneDayPrice = {
                                            ...listingCredentials.dayCharter.weeklyPrice.Monday,
                                            [`${listingCredentials.dayCharter.minimumHoursBeBooked}hrs`]: inputValue
                                        }
                                        let weeklyPrice = listingCredentials.dayCharter.weeklyPrice
                                        weeklyPrice.Monday = OneDayPrice;
                                        weeklyPrice.Tuesday = OneDayPrice;
                                        weeklyPrice.Wednesday = OneDayPrice;
                                        weeklyPrice.Thursday = OneDayPrice;
                                        // weeklyPrice.Friday = OneDayPrice;
                                        // weeklyPrice.Saturday = OneDayPrice;
                                        // weeklyPrice.Sunday = OneDayPrice;


                                        onChangeListingCredentials("dayCharter", { ...listingCredentials.dayCharter, weeklyPrice: weeklyPrice })
                                    }
                                }}
                                value={listingCredentials.dayCharter.weeklyPrice.Monday[`${listingCredentials.dayCharter.minimumHoursBeBooked}hrs`]}
                            />
                        </Grid>
                    }

                    {
                        discountedPriceHoursRange.map((disPrice, ind) => {
                            if (listingCredentials.dayCharter.minimumHoursBeBooked <= disPrice) {

                                return (
                                    <Grid item xs={12} md={6} >
                                        <TextField
                                            id="outlined-basic"
                                            label={`Weekdays (Mon - Thur) ${disPrice}hrs Price`}
                                            variant="outlined"
                                            required={true}
                                            type="number"
                                            fullWidth
                                            placeholder="Enter Hourly Price"
                                            name="price"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                                            }}
                                            onChange={(e) => {
                                                const inputValue = e.target.value.replace(/^0+/, '');
                                                if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                                    let OneDayPrice = {
                                                        ...listingCredentials.dayCharter.weeklyPrice.Monday,
                                                        [`${disPrice}hrs`]: inputValue
                                                    }
                                                    let weeklyPrice = listingCredentials.dayCharter.weeklyPrice
                                                    weeklyPrice.Monday = OneDayPrice;
                                                    weeklyPrice.Tuesday = OneDayPrice;
                                                    weeklyPrice.Wednesday = OneDayPrice;
                                                    weeklyPrice.Thursday = OneDayPrice;
                                                    // weeklyPrice.Friday = OneDayPrice;
                                                    // weeklyPrice.Saturday = OneDayPrice;
                                                    // weeklyPrice.Sunday = OneDayPrice;


                                                    onChangeListingCredentials("dayCharter", { ...listingCredentials.dayCharter, weeklyPrice: weeklyPrice })
                                                }
                                            }}
                                            value={listingCredentials.dayCharter.weeklyPrice.Monday[`${disPrice}hrs`]}
                                        />
                                    </Grid>
                                )
                            }
                        })
                    }
                    <Grid item xs={12} mt={1} />

                    {
                        !discountedPriceHoursRange.includes(listingCredentials.dayCharter.minimumHoursBeBooked) &&
                        <Grid item xs={12} md={6} >
                            <TextField
                                id="outlined-basic"
                                label={`Weekdays (Mon - Thur) ${listingCredentials.dayCharter.minimumHoursBeBooked}hrs Price`}
                                variant="outlined"
                                required={true}
                                type="number"
                                fullWidth
                                placeholder="Enter Hourly Price"
                                name="price"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                                }}
                                onChange={(e) => {
                                    const inputValue = e.target.value.replace(/^0+/, '');
                                    if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                        let OneDayPrice = {
                                            ...listingCredentials.dayCharter.weeklyPrice.Friday,
                                            [`${listingCredentials.dayCharter.minimumHoursBeBooked}hrs`]: inputValue
                                        }
                                        let weeklyPrice = listingCredentials.dayCharter.weeklyPrice
                                        // weeklyPrice.Monday = OneDayPrice;
                                        // weeklyPrice.Tuesday = OneDayPrice;
                                        // weeklyPrice.Wednesday = OneDayPrice;
                                        // weeklyPrice.Thursday = OneDayPrice;
                                        weeklyPrice.Friday = OneDayPrice;
                                        weeklyPrice.Saturday = OneDayPrice;
                                        weeklyPrice.Sunday = OneDayPrice;


                                        onChangeListingCredentials("dayCharter", { ...listingCredentials.dayCharter, weeklyPrice: weeklyPrice })
                                    }
                                }}
                                value={listingCredentials?.dayCharter?.weeklyPrice?.Friday?.[`${listingCredentials.dayCharter.minimumHoursBeBooked}hrs`]}
                            />
                        </Grid>
                    }

                    {
                        discountedPriceHoursRange.map((disPrice, ind) => {
                            if (listingCredentials.dayCharter.minimumHoursBeBooked <= disPrice) {

                                return (
                                    <Grid item xs={12} md={6} >
                                        <TextField
                                            id="outlined-basic"
                                            label={`Weekend days (Fir - Sun) ${disPrice}hrs Price`}
                                            variant="outlined"
                                            required={true}
                                            type="number"
                                            fullWidth
                                            placeholder="Enter Hourly Price"
                                            name="price"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                                            }}
                                            onChange={(e) => {
                                                const inputValue = e.target.value.replace(/^0+/, '');
                                                if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                                    let OneDayPrice = {
                                                        ...listingCredentials.dayCharter.weeklyPrice.Friday,
                                                        [`${disPrice}hrs`]: inputValue
                                                    }
                                                    let weeklyPrice = listingCredentials.dayCharter.weeklyPrice
                                                    // weeklyPrice.Monday = OneDayPrice;
                                                    // weeklyPrice.Tuesday = OneDayPrice;
                                                    // weeklyPrice.Wednesday = OneDayPrice;
                                                    // weeklyPrice.Thursday = OneDayPrice;
                                                    weeklyPrice.Friday = OneDayPrice;
                                                    weeklyPrice.Saturday = OneDayPrice;
                                                    weeklyPrice.Sunday = OneDayPrice;


                                                    onChangeListingCredentials("dayCharter", { ...listingCredentials.dayCharter, weeklyPrice: weeklyPrice })
                                                }
                                            }}
                                            value={listingCredentials?.dayCharter?.weeklyPrice?.Friday?.[`${disPrice}hrs`]}
                                        />
                                    </Grid>
                                )
                            }
                        })
                    }
                </Grid>
            }


        </Box>
    )
}

export default DayCharter