import React, { useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Box,
  Button,
  colors,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import ContainedBtn from "../../../Components/ContainedBtn";

import blueCheckCircle from "../../../Assets/Icons/blueCheckCircle.png";
import whiteCheckCircle from "../../../Assets/Icons/whiteCheckCircle.png";
import sendIcon from "../../../Assets/Icons/sendIcon.png";
import blueSendIcon from "../../../Assets/Icons/blueSendIcon.png";
import { useElements, useStripe } from "@stripe/react-stripe-js";

import SendIcon from "@mui/icons-material/Send";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUser } from "../../../Redux/User/UserActions";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../FireBase/firebase";

const useStyles = makeStyles((theme) => ({
  textCntnr: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  pricingSelect: {
    backgroundColor: "#0B1A9B",
    borderRadius: "22px",
    padding: "10px 20px",
    height: "25px",
    marginLeft: "0px",
    color: "white",
    "&:hover": {
      backgroundColor: "#0B1A9B",
    },
    cursor: "pointer",
  },
  pricingNotSelect: {
    borderRadius: "22px",
    padding: "10px 20px",
    height: "25px",
    marginLeft: "0px",
    "&:hover": {
      backgroundColor: "#0B1A9B",
      color: "white",
    },
    cursor: "pointer",
  },
  packagePricing: {
    color: "#0B1A9B",
    "&:hover": {
      color: "white",
    },
  },
}));

const HeroSectionText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "20px",
  [theme.breakpoints.down("nineS")]: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  letterSpacing: "1px",
  marginTop: "-8px",
}));

const PricingContainer = (isBgBlue, feature) => {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.user.user);
  const [userCredential, setUserCredential] = useState({ ...currentUser });
  let proFeatures = [
    "Viewing access to our MLS",
    "MarkUp Feature to add commissions",
    "Customizable PDF brochures with your commission included",
    "Access to all Broker contact information",
    "Exportable PDF Brochures with your information",
    "Export URL Link with your company information",
  ];
  let businessFeatures = [
    "Listing abilities to add your inventory to the MLS",
    "List up to 5 assets",
    "Viewing access to our MLS",
    "MarkUp Feature to add commissions",
    "Customizable PDF brochures with your commission included",
    "Access to all Broker contact information",
    "Exportable PDF Brochures with your information",
    "Export URL Link with your company information",
  ];
  let entripriseFeatures = [
    "5 log in",
    "Up to 10 listings",
    "Access to the marketplace",
    "Access to the markup tool",
    "Reporting",
    "Dashboard",
  ];
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const uid = localStorage.getItem("uid");
  //   const currentUserRef = doc(db, "users", uid);
  //   await updateDoc(currentUserRef, {
  //     accountType: "viewAndUpload",
  //   });
  //   // await dispatch(
  //   //   UpdateUser({
  //   //     ...currentUser,
  //   //     accountType: "viewAndUpload",
  //   //   })
  //   // );
  // };

  const handleSubmit = async (event) => {
    let data = {
      accountType: "viewAndUpload",
    };

    try {
      const updateUserResult = await dispatch(UpdateUser(data));

      if (updateUserResult) {
        console.log("User updated successfully");
      }
    } catch (error) {
      // Handle any errors that occurred during user update.
      console.error(error);
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sevenHalfS={6}
        nineS={4}
        sx={{
          ":hover": {
            bgcolor: "#0B1A9B",
            borderRadius: "20px",
            color: "white",
          },
        }}
      >
        <Box p={5}>
          <Typography
            sx={{ marginBottom: "10px", ":hover": { color: "white" } }}
          >
            <spam
              style={{
                fontSize: "36px",
                marginRight: "5px",
                fontFamily: "Obadiah Pro, sans-serif !important",
                fontWeight: 800,
              }}
            >
              $125
            </spam>
            /per month
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "40px" }}>
            Pro
          </Typography>
          {proFeatures.map((feature, i) => {
            return (
              <Box sx={{ display: "flex" }} my={1} key={i}>
                <CheckCircleOutlineIcon
                  sx={{ marginRight: "5px", height: "25px", widht: "25px" }}
                />
                <HeroSectionText
                  sx={{
                    marginTop: "2px",
                    fontFamily: "Gilroy Regular, sans-serif !important",
                  }}
                >
                  {feature}
                </HeroSectionText>
              </Box>
            );
          })}
          <ContainedBtn
            text="Get Started"
            onClick={async (e) => {
              currentUser?.accountType === "free"
                ? handleSubmit(e)
                : navigate("/login");
            }}
            href={
              currentUser?.accountType === "free"
                ? "https://buy.stripe.com/test_00g4gwfiE6Me9vafYY"
                : ""
            }
            sx={{
              "&:hover": {
                color: "#0B1A9B",
                bgcolor: "white",
                border: "1px solid #0B1A9B",
              },
              marginTop: "40%",
              justifyContent: "center",
              display: "flex",
            }}
            endIcon={<SendIcon />}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sevenHalfS={6}
        nineS={4}
        sx={{
          ":hover": {
            bgcolor: "#0B1A9B",
            borderRadius: "20px",
            color: "white !important",
          },
        }}
      >
        <Box p={5}>
          <Typography
            sx={{ marginBottom: "10px", ":hover": { color: "white" } }}
          >
            <spam
              style={{
                fontSize: "36px",
                marginRight: "5px",
                fontFamily: "Obadiah Pro, sans-serif !important",
                fontWeight: 800,
              }}
            >
              $150
            </spam>
            /per month
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "40px" }}>
            Business
          </Typography>
          {businessFeatures.map((feature, i) => {
            return (
              <Box sx={{ display: "flex" }} my={1} key={i}>
                <CheckCircleOutlineIcon
                  sx={{ marginRight: "5px", height: "25px", widht: "25px" }}
                />
                <HeroSectionText
                  sx={{
                    marginTop: "2px",
                    fontFamily: "Gilroy Regular, sans-serif !important",
                  }}
                >
                  {feature}
                </HeroSectionText>
              </Box>
            );
          })}
          <ContainedBtn
            text="Get Started"
            onClick={async (e) => {
              currentUser?.accountType === "free"
                ? handleSubmit(e)
                : navigate("/login");
            }}
            href={
              currentUser?.accountType === "free"
                ? "https://buy.stripe.com/test_00g6oEfiE4E67n29AB"
                : ""
            }
            sx={{
              "&:hover": {
                color: "#0B1A9B",
                bgcolor: "white",
                border: "1px solid #0B1A9B",
              },
              marginTop: "10%",
              justifyContent: "center",
              display: "flex",
            }}
            endIcon={<SendIcon />}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sevenHalfS={6}
        nineS={4}
        sx={{
          ":hover": {
            bgcolor: "#0B1A9B",
            borderRadius: "20px",
            color: "white",
          },
        }}
      >
        <Box p={5}>
          <Typography
            sx={{ marginBottom: "10px", ":hover": { color: "white" } }}
          >
            <spam
              style={{
                fontSize: "36px",
                marginRight: "5px",
                fontFamily: "Obadiah Pro, sans-serif !important",
                fontWeight: 800,
              }}
            >
              $300
            </spam>
            /per month
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "40px" }}>
            Enterprise
          </Typography>
          {businessFeatures.map((feature, i) => {
            return (
              <Box sx={{ display: "flex" }} my={1} key={i}>
                <CheckCircleOutlineIcon
                  sx={{ marginRight: "5px", height: "25px", widht: "25px" }}
                />
                <HeroSectionText
                  sx={{
                    marginTop: "2px",
                    fontFamily: "Gilroy Regular, sans-serif !important",
                  }}
                >
                  {feature}
                </HeroSectionText>
              </Box>
            );
          })}
          <ContainedBtn
            text="Get Started"
            Enterprise300
            onClick={async (e) => {
              currentUser?.accountType === "free"
                ? handleSubmit(e)
                : navigate("/login");
            }}
            href={
              currentUser?.accountType === "free"
                ? "https://buy.stripe.com/test_00g6oEfiE4E67n29AB"
                : ""
            }
            sx={{
              "&:hover": {
                color: "#0B1A9B",
                bgcolor: "white",
                border: "1px solid #0B1A9B",
              },
              marginTop: "10%",
              justifyContent: "center",
              display: "flex",
            }}
            endIcon={<SendIcon />}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const PricingContainer02 = (isBgBlue, feature) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let proFeatures = [
    "Viewing access to our MLS",
    "MarkUp Feature to add commissions",
    "Customizable PDF brochures with your commission included",
    "Access to all Broker contact information",
    "Exportable PDF Brochures with your information",
    "Export URL Link with your company information",
  ];
  let businessFeatures = [
    "Listing abilities to add your inventory to the MLS",
    "List up to 5 assets",
    "Viewing access to our MLS",
    "MarkUp Feature to add commissions",
    "Customizable PDF brochures with your commission included",
    "Access to all Broker contact information",
    "Exportable PDF Brochures with your information",
    "Export URL Link with your company information",
  ];
  let entripriseFeatures = [
    "5 log in",
    "Up to 10 listings",
    "Access to the marketplace",
    "Access to the markup tool",
    "Reporting",
    "Dashboard",
  ];

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sevenHalfS={6}
        nineS={4}
        sx={{
          ":hover": {
            bgcolor: "#0B1A9B",
            borderRadius: "20px",
            color: "white",
          },
        }}
      >
        <Box p={5}>
          <Typography
            sx={{ marginBottom: "10px", ":hover": { color: "white" } }}
          >
            <span style={{ fontSize: "20px" }}>
              <sup>
                <del>$1,500</del>
              </sup>
            </span>{" "}
            <spam
              style={{
                fontSize: "36px",
                marginRight: "5px",
                fontFamily: "Obadiah Pro, sans-serif !important",
                fontWeight: 800,
              }}
            >
              {" "}
              $1,250
            </spam>
            /per month
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "40px" }}>
            Pro
          </Typography>
          {proFeatures.map((feature, i) => {
            return (
              <Box sx={{ display: "flex" }} my={1} key={i}>
                <CheckCircleOutlineIcon
                  sx={{ marginRight: "5px", height: "25px", widht: "25px" }}
                />
                <HeroSectionText
                  sx={{
                    marginTop: "2px",
                    fontFamily: "Gilroy Regular, sans-serif !important",
                  }}
                >
                  {feature}
                </HeroSectionText>
              </Box>
            );
          })}
          <ContainedBtn
            text="Get Started"
            onClick={() => {
              navigate("/login");
            }}
            sx={{
              "&:hover": {
                color: "#0B1A9B",
                bgcolor: "white",
                border: "1px solid #0B1A9B",
              },
              marginTop: "40%",
              justifyContent: "center",
              display: "flex",
            }}
            endIcon={<SendIcon />}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sevenHalfS={6}
        nineS={4}
        sx={{
          ":hover": {
            bgcolor: "#0B1A9B",
            borderRadius: "20px",
            color: "white !important",
          },
        }}
      >
        <Box p={5}>
          <Typography
            sx={{ marginBottom: "10px", ":hover": { color: "white" } }}
          >
            <span style={{ fontSize: "20px" }}>
              <sup>
                <del>$1,800</del>
              </sup>
            </span>
            <spam
              style={{
                fontSize: "36px",
                marginRight: "5px",
                fontFamily: "Obadiah Pro, sans-serif !important",
                fontWeight: 800,
              }}
            >
              $1,500
            </spam>
            /per month
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "40px" }}>
            Business
          </Typography>
          {businessFeatures.map((feature, i) => {
            return (
              <Box sx={{ display: "flex" }} my={1} key={i}>
                <CheckCircleOutlineIcon
                  sx={{ marginRight: "5px", height: "25px", widht: "25px" }}
                />
                <HeroSectionText
                  sx={{
                    marginTop: "2px",
                    fontFamily: "Gilroy Regular, sans-serif !important",
                  }}
                >
                  {feature}
                </HeroSectionText>
              </Box>
            );
          })}
          <ContainedBtn
            text="Get Started"
            onClick={() => {
              navigate("/login");
            }}
            sx={{
              "&:hover": {
                color: "#0B1A9B",
                bgcolor: "white",
                border: "1px solid #0B1A9B",
              },
              marginTop: "10%",
              justifyContent: "center",
              display: "flex",
            }}
            endIcon={<SendIcon />}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sevenHalfS={6}
        nineS={4}
        sx={{
          ":hover": {
            bgcolor: "#0B1A9B",
            borderRadius: "20px",
            color: "white",
          },
        }}
      >
        <Box p={5}>
          <Typography
            sx={{ marginBottom: "10px", ":hover": { color: "white" } }}
          >
            <spam
              style={{
                fontSize: "36px",
                marginRight: "5px",
                fontFamily: "Obadiah Pro, sans-serif !important",
                fontWeight: 800,
              }}
            >
              $3000
            </spam>
            /per month
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "40px" }}>
            Enterprise
          </Typography>
          {businessFeatures.map((feature, i) => {
            return (
              <Box sx={{ display: "flex" }} my={1} key={i}>
                <CheckCircleOutlineIcon
                  sx={{ marginRight: "5px", height: "25px", widht: "25px" }}
                />
                <HeroSectionText
                  sx={{
                    marginTop: "2px",
                    fontFamily: "Gilroy Regular, sans-serif !important",
                  }}
                >
                  {feature}
                </HeroSectionText>
              </Box>
            );
          })}
          <ContainedBtn
            text="Get Started"
            Enterprise300
            onClick={() => {
              navigate("/login");
            }}
            sx={{
              "&:hover": {
                color: "#0B1A9B",
                bgcolor: "white",
                border: "1px solid #0B1A9B",
              },
              marginTop: "10%",
              justifyContent: "center",
              display: "flex",
            }}
            endIcon={<SendIcon />}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const PricingPlans = () => {
  const classes = useStyles();
  const [pricingPlanning, setPricingPlanning] = useState("Monthly");
  return (
    <>
      <Container maxWidth="fl">
        <Grid container mt={10}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: {
                  xs: "xx-large",
                  xs: "30px",
                  sm: "40px ",
                  sevenHalfS: "25px",
                  nineS: "30px",
                  md: "35px",
                  lg: "40px",
                },
                marginBottom: { xs: "24px", sevenHalfS: "15px", md: "24px" },
                fontFamily: "Obadiah Pro, sans-serif",
              }}
            >
              {" "}
              Plans & Pricing{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.textCntnr}>
            <Typography
              sx={{
                maxWidth: "590px",
                letterSpacing: "1px",
                marginBottom: {
                  xs: "24px",
                  sevenHalfS: "15px",
                  lg: "48px",
                  md: "40px",
                },
                fontFamily: "Gilroy Regular, sans-serif !important",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using.
            </Typography>
            <Box display={"flex"} gap={1}>
              <Typography
                onClick={() => {
                  setPricingPlanning("Monthly");
                }}
                className={
                  pricingPlanning === "Monthly"
                    ? classes.pricingSelect
                    : classes.pricingNotSelect
                }
              >
                MONTHLY
              </Typography>
              <Typography
                onClick={() => {
                  setPricingPlanning("Yearly");
                }}
                className={
                  pricingPlanning === "Yearly"
                    ? classes.pricingSelect
                    : classes.pricingNotSelect
                }
              >
                YEARLY
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ backgroundColor: "#EAEAEA", borderRadius: "20px" }}
          mb={5}
        >
          <Grid
            item
            xs={12}
            sx={{
              margin: { xs: "20px 20px", sm: "40px 60px", md: "40px 100px" },
            }}
          >
            {pricingPlanning === "Monthly" && <PricingContainer />}
            {pricingPlanning === "Yearly" && <PricingContainer02 />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PricingPlans;
