import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Box,
  Container,
  Switch,
  TextField,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import { getAllListing } from "../../Redux/Listing/ListingActions";
import {
  getCurrentUser,
  setUserLoading,
  getListingBroker,
  setListingBroker,
} from "../../Redux/User/UserActions";

import Swal from "sweetalert2";
import clipboard from "clipboard-copy";

import ContainedBtn from "../../Components/ContainedBtn";

import PrintIcon from "@mui/icons-material/Print";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Toggle } from "../../Components/Toggle";

import { storage } from "../../FireBase/firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadString,
} from "firebase/storage";

import ReactToPrint from "react-to-print";

import { PDFExport, savePDF, PDFExportProps } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import {} from "@progress/kendo-file-saver";

import FullScreenDialog from "../../Components/Dialog";

import { savePdfToFirebaseStorage } from "../../Utils/FirebaseUtils.js";
import { blueLogo } from "../../Assets/IconsPath/IconsPath.js";
import BroucherPricing from "../GeneratePdfs/Components/BroucherPricing.js";
import BoatAvalibility from "../GeneratePdfs/Components/BoatAvalibility.js";
import MultipleLocation from "../GeneratePdfs/Components/MultipleLocation.js";
import TermCharterHandiPricing from "../GeneratePdfs/Components/TermCharterHandiPricing.js";

const EllipsisText = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "95%", // Optionally set a maximum width
  color: "#17233C",
  fontSize: "15px",
  fontWeight: "bold",
  cursor: "pointer",
});

const useStyles = makeStyles((theme) => ({
  coverPhoto: {
    width: "100%",
    height: "80vh",
    borderRadius: "20px",
    objectFit: "cover",
    [theme.breakpoints.up("xs")]: {
      height: "40vh",
      borderRadius: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "60vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "80vh",
    },
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  galleryCntnr: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: "10px",
  },
  galleryImg: {
    width: "32.7%",
    borderRadius: "10px",
    height: "30vh",
    objectFit: "stretch",
    [theme.breakpoints.up("xs")]: {
      width: "32.7%",
      height: "10vh",
      borderRadius: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "32.7%",
      height: "20vh",
      borderRadius: "7px",
    },
    [theme.breakpoints.up("md")]: {
      height: "30vh",
      borderRadius: "10px",
    },
  },
  titleHead: {
    fontSize: "20px !important",
    fontWeight: "bold !important",
  },
  alignCentr: {
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
  },
  alignEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  justifyBtwn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fontFamily: {
    fontFamily: "Gilroy Regular, sans-serif !important",
  },
  fontBold: {
    fontWeight: "bold !important",
  },
  smallFont: {
    fontFamily: "Gilroy Regular, sans-serif !important",
    fontSize: "12px !important",
  },
  topInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  dataTextStyle: {
    fontSize: "20px !important",
    color: "#777777",
    // color:'blue !important',
    fontFamily: "Gilroy Regular, sans-serif !important",
    fontWeight: "bold",
    whiteSpace: "pre-line",
    letterSpacing: "1px",
    marginTop: "10px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "15px !important",
      letterSpacing: "1px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px !important",
      letterSpacing: "1px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px !important",
      letterSpacing: "1px",
    },
  },
  availabilityTextStyle: {
    fontSize: "20px !important",
    color: "#777777",
    width: "140px",
    fontFamily: "Gilroy Regular, sans-serif !important",
    fontWeight: "bold",
    whiteSpace: "pre-line",
    letterSpacing: "1px",
    marginTop: "10px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "15px !important",
      letterSpacing: "1px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px !important",
      letterSpacing: "1px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px !important",
      letterSpacing: "1px",
    },
  },
}));

const MainCntnr = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "90vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const DataBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
}));

const TitleHead = styled(Box)(({ theme }) => ({
  fontSize: "25px",
  fontWeight: 700,
  whiteSpace: "pre-line",
  letterSpacing: "2px",
  color: "#0B1A9B",
  [theme.breakpoints.up("xs")]: {
    fontSize: "25px",
    letterSpacing: "1px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "25px",
    letterSpacing: "2px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "25px",
    letterSpacing: "2px",
  },
  fontFamily: "Gilroy Bold,sans-serif!important",
}));

const DescriptionHead = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  color: "#777777",
  fontFamily: "Gilroy Regular, sans-serif !important",
  whiteSpace: "pre-line",
  letterSpacing: "1px",
  marginTop: "12px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px",
    letterSpacing: "1px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    letterSpacing: "1px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "12px",
    letterSpacing: "1px",
  },
}));

const DataText = styled(Box)(({ theme }) => ({
  fontSize: "11px",
  color: "#777777",
  fontFamily: "Gilroy Regular, sans-serif !important",
  fontWeight: "bold",
  whiteSpace: "pre-line",
  letterSpacing: "1px",
  // marginTop: "8px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "11px",
    letterSpacing: "1px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "11px",
    letterSpacing: "1px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "11px",
    letterSpacing: "1px",
  },
}));

const DefaultImage = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
  borderRadius: "50px",
  marginRight: "10px",
  border: "1px solid #0B1A9B",
}));

const TopLogo = styled("img")(({ theme }) => ({
  width: "auto",
  maxHeight: "60px",
}));

const SectionHead = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  lineHeight: 1.5,
  letterSpacing: "0.00938em",
  color: "#081474",
  fontWeight: "bold",
  fontFamily: "Gilroy Bold, sans-serif !important",
  // backgroundColor: '#F3F3F3',
  // padding: '5px 15px',
}));

const ContactHead = styled(Typography)(({ theme }) => ({
  fontSize: "2.3rem",
  fontFamily: "Gilroy Regular, sans-serif !important",
  fontWeight: "bold",
  color: "#0B1A9B",
  backgroundColor: "#F3F3F3",
  padding: "5px 15px",
  borderRight: "8px solid #0B1A9B",
  display: "inline-block",
  WebkitTransform: "rotate(360deg) scale(1.003) skew(-14deg) translate(0px)",
}));

const ListingDetails = () => {
  let { id, brokerID } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const pdfExportComponent = useRef(null);

  const currentUser = useSelector((state) => state.user.user);
  const currentListing = useSelector((state) =>
    state.listings.allListings.find((item) => item?.id == id)
  );
  const listingBroker = useSelector((state) => state.user.listingBroker);

  const [isDescriptionDisplay, setIsDescriptionDisplay] = useState(true);
  const [isDataSheetDisplay, setIsDataSheetDisplay] = useState(true);
  const [isCharterDisplay, setIsCharterDisplay] = useState(true);

  const [printLoading, setPrintLoading] = useState(false);

  const [markUpPrice, setmarkUpPrice] = useState("");

  const [pdfURL, setpdfURL] = useState(null);
  const [URLDialogOpen, setURLDialogOpen] = useState(false);

  const [currentPrice, setCurrentPrice] = useState("");

  useEffect(() => {
    // dispatch(getAllListing())

    dispatch(setUserLoading(true));
    dispatch(getCurrentUser());

    dispatch(getListingBroker(brokerID));

    return () => {
      dispatch(setListingBroker({}));
    };
  }, []);

  const handleExportWithComponent = (event) => {
    const content = document.getElementById("PDFExporter");

    setPrintLoading(true);

    drawDOM(content, {
      paperSize: "A4",
    })
      .then((group) => exportPDF(group))
      .then(async (dataUri) => {
        if (dataUri) {
          const pdfData = dataUri.split(";base64,")[1];

          const timestamp = Date.now();
          const fileName = `pdf_${timestamp}.pdf`;

          // Create a storage reference for the PDF file
          const docFileRef = ref(storage, `pdfs/${fileName}`);

          try {
            // Upload the PDF data to Firebase Storage
            await uploadString(docFileRef, pdfData, "base64", {
              contentType: "application/pdf",
            });

            // Get the download URL for the uploaded PDF
            const downloadURL = await getDownloadURL(docFileRef);

            setpdfURL(downloadURL);
            setURLDialogOpen(true);
            // console.log(downloadURL, "downloadURL")
            // You can use the downloadURL as needed

            setPrintLoading(false);
          } catch (error) {
            console.error("Error uploading PDF:", error);
            setPrintLoading(false);
          }
        } else {
          setPrintLoading(false);
        }
      });
  };

  const hideDialog = () => {
    setURLDialogOpen(false);
    setpdfURL(null);
  };

  const copyCodeToClipBoard = () => {
    clipboard(pdfURL)
      .then(() => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          background: "#08A73D",
          iconColor: "white",
          color: "white",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "success",
          title: `PDF Link  Copied`,
        });
        hideDialog();
        // You can also provide user feedback here
      })
      .catch((err) => {
        // console.error('Failed to copy text:', err);
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          background: "#F62424",
          iconColor: "white",
          color: "white",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "error",
          title: `${err}`,
        });
        // Handle any errors that may occur during copying
      });
  };

  const findPrice = (pricingType, pricing, availability) => {
    if (pricingType === "termCharter") {
      return Number(pricing?.pricing[1]);
    } else {
      return Number(pricing?.price);
    }
  };

  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[dayOfWeek];

  let pricingTypeStatus = {
    dayCharter: "Day Charter",
    termCharter: "Term Charter",
  };

  useEffect(() => {
    if (currentListing) {
      let address = findPrice(
        currentListing?.pricingType,
        currentListing[currentListing?.pricingType],
        currentListing?.availability
      );
      setCurrentPrice(address);
    }
  }, [currentListing]);

  let sleepingAccmmodationskey = Object.keys(
    currentListing?.sleepingAccmmodations || {}
  );
  let sleepingFormatedKey = {
    sleepsTotal: "Sleep Total",
    kingsBeds: "Kings Beds",
    queenBeds: "Queens Beds",
    noOfCabins: "No Of Cabins",
    twinBeds: "Twins Bed",
  };

  return (
    <>
      {!currentListing ? (
        <MainCntnr>
          {blueLogo}
          <CircularProgress />
        </MainCntnr>
      ) : (
        <Box>
          <Container maxWidth={"fl"}>
            <Grid container p={2}>
              <Grid item xs={12} className={classes.alignEnd} mt={1}>
                <ContainedBtn
                  text={
                    printLoading ? <CircularProgress /> : "MarkUp and Export"
                  }
                  sx={{
                    "&:hover": {
                      color: "#0B1A9B",
                      outline: "1px solid #0B1A9B",
                    },
                    height: "50px",
                    width: "240px",
                    marginBottom: "10px",
                  }}
                  endIcon={<PrintIcon />}
                  onClick={() => navigate(`/generate-pdfs/${id}`)}
                />
              </Grid>
              <Grid item xs={12} className={classes.justifyBtwn}>
                <Box>
                  {listingBroker?.companyPicture && (
                    <TopLogo src={listingBroker?.companyPicture} />
                  )}
                  <Typography
                    className={[classes.fontBold, classes.fontFamily]}
                    sx={{ fontSize: "12px" }}
                  >
                    {listingBroker?.companyName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box className={classes.topInfo}>
                    <Typography
                      className={[classes.fontBold, classes.fontFamily]}
                      sx={{ fontSize: "12px" }}
                    >
                      {listingBroker?.firstName + " " + listingBroker?.lastName}
                    </Typography>
                    <Typography className={classes.smallFont}>
                      {listingBroker?.email}
                    </Typography>
                    <Typography className={classes.smallFont}>
                      {listingBroker?.phoneNo}
                    </Typography>
                  </Box>
                  <Box>
                    {listingBroker?.profilePicture && (
                      <TopLogo src={listingBroker?.profilePicture} />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
          {/* Print Component Start */}

          {/* Print Brouceher Context Start  */}

          <PDFExport ref={pdfExportComponent} paperSize="A4">
            <Container maxWidth="fl" id="PDFExporter">
              <Grid container pb={5}>
                <Grid item xs={12}>
                  <img
                    src={currentListing?.imagesUrls[0]}
                    className={classes.coverPhoto}
                  />
                </Grid>
                <Grid item xs={12} className={classes.galleryCntnr} my={2}>
                  {currentListing?.imagesUrls
                    ?.slice(1, 4)
                    ?.map((yatchImg, ind) => {
                      return (
                        <img
                          className={classes.galleryImg}
                          src={yatchImg}
                          key={ind}
                        />
                      );
                    })}
                </Grid>

                <Grid item xs={12} mt={3}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: "#0B1A9B",
                        fontWeight: "bold",
                        textAlign: "end",
                        fontSize: "30px",
                      }}
                      className={classes.fontFamily}
                    >
                      {currentListing?.pricingType === "dayCharter" ? (
                        <>
                          {typeof currentPrice === "number"
                            ? `US$ ${currentPrice + Number(markUpPrice)}`
                            : `US$ ${currentPrice}`}
                        </>
                      ) : (
                        <>{`US$ ${currentPrice}`}</>
                      )}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        color: "#0B1A9B",
                        fontWeight: "bold",
                        textAlign: "end",
                        fontSize: "30px",
                      }}
                      className={classes.fontFamily}
                    >
                      {currentListing?.pricingType === "dayCharter"
                        ? "+/HOUR"
                        : "+/DAY"}
                    </Typography>
                    {/* <BroucherPricing
                      listingDetails={currentListing}
                      currentUser={currentUser}
                      isEditAllow={false}
                      parentClasses={classes}
                    /> */}
                  </Box>

                  {currentListing?.pricingType === "termCharter" && (
                    <DataText textAlign="right">
                      {currentListing?.termCharter?.commissionPercentage}%
                      Commision{" "}
                    </DataText>
                  )}
                </Grid>
                <Grid item xs={12} md={9}>
                  <TitleHead>
                    {" "}
                    {currentListing?.dayCharter?.pricingSameEachDay === "yes"
                      ? "Day Charter"
                      : "Term Charter"}
                    {/* {currentListing?.boatName} */}
                  </TitleHead>
                  <Box sx={{ marginTop: "10px" }}>
                    <DataText>
                      {currentListing?.year} {currentListing?.make}{" "}
                      {currentListing?.boatLength}'
                    </DataText>
                  </Box>
                  <Box mt={1}>
                    <DataText>
                      {currentListing?.boatAddress?.formattedAddress}{" "}
                    </DataText>
                  </Box>
                </Grid>
                {currentListing?.pricingType === "termCharter" && (
                  <Grid item xs={12} md={3}>
                    <TermCharterHandiPricing
                      listingDetails={currentListing}
                      parentClasses={classes}
                    />
                  </Grid>
                )}

                {/* Description */}
                {isDescriptionDisplay && (
                  <Grid
                    item
                    xs={12}
                    mt={3}
                    border={"0.7px solid #081474"}
                    borderRadius={"5px"}
                    p={1}
                  >
                    <SectionHead>Description</SectionHead>
                    <DescriptionHead>
                      {currentListing?.description}
                    </DescriptionHead>
                  </Grid>
                )}
              </Grid>
              {/* Data Sheet */}
              <Grid container pb={5} spacing={1}>
                <Grid item xs={12} mt={0} mb={1}>
                  <SectionHead>Specifications</SectionHead>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DataBox>
                    <DataText sx={{ width: "50%" }}>Boat Type </DataText>
                    <DataText>
                      : {pricingTypeStatus[currentListing?.pricingType]}{" "}
                    </DataText>
                  </DataBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DataBox>
                    <DataText sx={{ width: "50%" }}>
                      Beam Length (feet){" "}
                    </DataText>
                    <DataText>: {currentListing?.beamLength}'' </DataText>
                  </DataBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DataBox>
                    <DataText sx={{ width: "50%" }}>
                      Boat Length (feet){" "}
                    </DataText>
                    <DataText>: {currentListing?.boatLength}'' </DataText>
                  </DataBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DataBox>
                    <DataText sx={{ width: "50%" }}>No Of Passengers </DataText>
                    <DataText>: {currentListing?.noOfPassengers} </DataText>
                  </DataBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DataBox>
                    <DataText sx={{ width: "50%" }}>No Of Bathrooms </DataText>
                    <DataText>: {currentListing?.noOfBathrooms} </DataText>
                  </DataBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DataBox>
                    <DataText sx={{ width: "50%" }}>Make </DataText>
                    <DataText>: {currentListing?.make} </DataText>
                  </DataBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DataBox>
                    <DataText sx={{ width: "50%" }}>Model </DataText>
                    <DataText>: {currentListing?.model} </DataText>
                  </DataBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DataBox>
                    <DataText sx={{ width: "50%" }}>Country </DataText>
                    <DataText>
                      : {currentListing?.boatAddress?.country}{" "}
                    </DataText>
                  </DataBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DataBox>
                    <DataText sx={{ width: "50%" }}>Fuel Type </DataText>
                    <DataText>: {currentListing?.fuelType} </DataText>
                  </DataBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DataBox>
                    <DataText sx={{ width: "50%" }}>Year </DataText>
                    <DataText>: {currentListing?.year} </DataText>
                  </DataBox>
                </Grid>
                {currentListing?.state && (
                  <Grid item xs={12} md={6}>
                    <DataBox>
                      <DataText sx={{ width: "50%" }}>State </DataText>
                      <DataText>: {currentListing?.state} </DataText>
                    </DataBox>
                  </Grid>
                )}
                {sleepingAccmmodationskey.map((key, ind) => {
                  let value = currentListing?.sleepingAccmmodations?.[key];
                  if (value.trim().length > 0 || value > 0) {
                    return (
                      <Grid item xs={12} md={6} key={ind}>
                        <DataBox>
                          <DataText sx={{ width: "50%" }}>
                            {sleepingFormatedKey?.[key]}{" "}
                          </DataText>
                          <DataText>
                            : {currentListing?.sleepingAccmmodations?.[key]}{" "}
                          </DataText>
                        </DataBox>
                      </Grid>
                    );
                  }
                })}

                {/* Extras */}
                {currentListing?.extras?.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        mt={2}
                        mb={1}
                        pt={2}
                        borderTop={"1px solid #081474"}
                      >
                        <SectionHead>Extras</SectionHead>
                      </Grid>
                      {currentListing?.extras.map((extra, ind) => {
                        return (
                          <Grid item xs={12} md={6} key={ind}>
                            <Box my={0.5}>
                              <DataText>{extra} </DataText>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}

                {/* Tender and Tpys */}
                {currentListing?.tenderAndToys?.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        mt={2}
                        mb={1}
                        pt={2}
                        borderTop={"1px solid #081474"}
                      >
                        <SectionHead>Toys and Tenders</SectionHead>
                      </Grid>
                      {!currentListing?.tenderAndToys?.length % 2 == 0 && (
                        <Grid item xs={12}>
                          {" "}
                        </Grid>
                      )}

                      {/* Jetskis */}
                      {Object.keys(currentListing?.jetskis)?.length > 0 && (
                        <Grid item xs={12} md={6}>
                          <Box my={1}>
                            <DataText sx={{ color: "#000" }}> Jetski </DataText>
                            <ul style={{ margin: "0px" }}>
                              <li>
                                <DataBox sx={{ py: 0.5 }}>
                                  <DataText sx={{ width: "20%" }}>
                                    Make
                                  </DataText>
                                  <DataText>
                                    : {currentListing?.jetskis?.make}
                                  </DataText>
                                </DataBox>
                              </li>
                              <li>
                                <DataBox sx={{ py: 0.5 }}>
                                  <DataText sx={{ width: "20%" }}>
                                    Model
                                  </DataText>
                                  <DataText>
                                    : {currentListing?.jetskis?.model}
                                  </DataText>
                                </DataBox>
                              </li>
                              <li>
                                <DataBox sx={{ py: 0.5 }}>
                                  <DataText sx={{ width: "20%" }}>
                                    Year
                                  </DataText>
                                  <DataText>
                                    : {currentListing?.jetskis?.year}
                                  </DataText>
                                </DataBox>
                              </li>
                              <li>
                                <DataBox sx={{ py: 0.5 }}>
                                  <DataText sx={{ width: "20%" }}>
                                    Quantity
                                  </DataText>
                                  <DataText>
                                    : {currentListing?.jetskis?.quantity}
                                  </DataText>
                                </DataBox>
                              </li>
                            </ul>
                          </Box>
                        </Grid>
                      )}

                      {/* Tenders */}
                      {Object.keys(currentListing?.tenders)?.length > 0 && (
                        <Grid item xs={12} md={6}>
                          <Box my={1}>
                            <ul style={{ margin: "0px" }}>
                              <li>
                                <DataBox sx={{ py: 0.5 }}>
                                  <DataText sx={{ width: "20%" }}>
                                    Make
                                  </DataText>
                                  <DataText>
                                    : {currentListing?.tenders?.make}
                                  </DataText>
                                </DataBox>
                              </li>
                              <li>
                                <DataBox sx={{ py: 0.5 }}>
                                  <DataText sx={{ width: "20%" }}>
                                    Model
                                  </DataText>
                                  <DataText>
                                    : {currentListing?.tenders?.model}
                                  </DataText>
                                </DataBox>
                              </li>
                              <li>
                                <DataBox sx={{ py: 0.5 }}>
                                  <DataText sx={{ width: "20%" }}>
                                    Year
                                  </DataText>
                                  <DataText>
                                    : {currentListing?.tenders?.year}
                                  </DataText>
                                </DataBox>
                              </li>
                              <li>
                                <DataBox sx={{ py: 0.5 }}>
                                  <DataText sx={{ width: "20%" }}>
                                    Quantity
                                  </DataText>
                                  <DataText>
                                    : {currentListing?.tenders?.quantity}
                                  </DataText>
                                </DataBox>
                              </li>
                              <li>
                                <DataBox sx={{ py: 0.5 }}>
                                  <DataText sx={{ width: "20%" }}>
                                    Length :{" "}
                                  </DataText>
                                  <DataText>
                                    {" "}
                                    {currentListing?.tenders?.length}
                                  </DataText>
                                </DataBox>
                              </li>
                              <li>
                                <DataBox sx={{ py: 0.5 }}>
                                  <DataText sx={{ width: "20%" }}>
                                    Fuel :{" "}
                                  </DataText>
                                  <DataText>
                                    {" "}
                                    {currentListing?.tenders?.fuelType}
                                  </DataText>
                                </DataBox>
                              </li>
                            </ul>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                {/* Pricing */}
                {!currentUser && (
                  <Grid item xs={12} pt={2} borderTop={"1px solid #081474"}>
                    <SectionHead>Pricing</SectionHead>
                    <BroucherPricing
                      isEditAllow={false}
                      currentUser={currentUser}
                      listingDetails={currentListing}
                      parentClasses={classes}
                      showPercentages={true}
                    />
                  </Grid>
                )}

                {currentListing?.multipleLocations.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    mt={2}
                    mb={1}
                    pt={2}
                    borderTop={"1px solid #081474"}
                  >
                    <SectionHead>Pick Up Locations</SectionHead>

                    <DataBox mt={2}>
                      <DataText width={"70px"}>Address 1 :</DataText>
                      <DataText>
                        {currentListing?.boatAddress?.formattedAddress}{" "}
                      </DataText>
                    </DataBox>
                    <MultipleLocation
                      listingDetails={currentListing}
                      currentUser={currentUser}
                      isEditAllow={false}
                      parentClasses={classes}
                    />
                  </Grid>
                )}

                {currentListing?.captianInformation?.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    mt={2}
                    pt={2}
                    borderTop={"1px solid #081474"}
                  >
                    <SectionHead>Captians Information</SectionHead>
                    <Grid container spacing={2}>
                      {currentListing?.captianInformation.map(
                        (captian, ind) => {
                          let { emailAddress, firstName, lastName, phNo } =
                            captian;
                          return (
                            <Grid item xs={12} md={6} key={ind} mt={2} mb={1}>
                              <Typography
                                sx={{
                                  color: "#0B1A9B",
                                  border: "5px solid #0B1A9B",
                                  borderRadius: "50%",
                                  width: "min-content",
                                  padding: "3px 10px",
                                }}
                                variant="h5"
                              >
                                {ind + 1}
                              </Typography>
                              {emailAddress && (
                                <DataText pt={2}>
                                  Email : {emailAddress}{" "}
                                </DataText>
                              )}
                              {firstName && (
                                <DataText>
                                  Name : {firstName + " " + lastName}{" "}
                                </DataText>
                              )}
                              {phNo && <DataText>Phone No : {phNo} </DataText>}
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {/*  CONTACT INGORMATION */}
              {isCharterDisplay && (
                <Grid container spacing={2} mt={0} mb={5}>
                  {listingBroker?.salesMessage && (
                    <Grid item xs={12} className={classes.alignCentr}>
                      <ContactHead>CONTACT INFORMATION</ContactHead>
                      <Box my={3}>
                        <DataText
                          sx={{ textAlign: "center", maxWidth: "600px" }}
                        >
                          {listingBroker?.salesMessage}
                        </DataText>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} className={classes.alignCentr}>
                    {listingBroker?.profilePicture && (
                      <DefaultImage src={listingBroker?.profilePicture} />
                    )}
                    {listingBroker?.userAddress && (
                      <DescriptionHead sx={{ width: "100%" }}>
                        {listingBroker?.userAddress}
                      </DescriptionHead>
                    )}
                    {listingBroker?.officePhoneNo && (
                      <DescriptionHead sx={{ width: "100%" }}>
                        Office: {listingBroker?.officePhoneNo}
                      </DescriptionHead>
                    )}
                    {listingBroker?.phoneNo && (
                      <DescriptionHead sx={{ width: "100%" }}>
                        Phone No : {listingBroker?.phoneNo}
                      </DescriptionHead>
                    )}
                    {listingBroker?.userWeb && (
                      <DescriptionHead sx={{ width: "100%" }}>
                        Website : {listingBroker?.userWeb}
                      </DescriptionHead>
                    )}
                    {listingBroker?.email && (
                      <DescriptionHead sx={{ width: "100%" }}>
                        Email : {listingBroker?.email}
                      </DescriptionHead>
                    )}
                    {listingBroker?.profileDescription && (
                      <DescriptionHead>
                        {listingBroker?.profileDescription}
                      </DescriptionHead>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} className={classes.alignCentr}>
                    {listingBroker?.companyPicture && (
                      <DefaultImage src={listingBroker?.companyPicture} />
                    )}
                    {listingBroker?.companyAddress && (
                      <DescriptionHead sx={{ width: "100%" }}>
                        {listingBroker?.companyAddress}
                      </DescriptionHead>
                    )}
                    {listingBroker?.companyWeb && (
                      <DescriptionHead sx={{ width: "100%" }}>
                        Website : {listingBroker?.companyWeb}
                      </DescriptionHead>
                    )}
                    {listingBroker?.companyDescription && (
                      <DescriptionHead>
                        {listingBroker?.companyDescription}
                      </DescriptionHead>
                    )}
                  </Grid>
                </Grid>
              )}
            </Container>
          </PDFExport>
        </Box>
      )}

      <FullScreenDialog
        maxWidth="xs"
        fullWidth={true}
        open={URLDialogOpen}
        hideDialogHandler={hideDialog}
        style={{
          background: "rgba(255, 255, 255, 0.10)",
          "& .MuiDialog-paper": {
            background: "rgba(255, 255, 255, 0.10)",
            offset: "0px 4px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "20px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box
          p={2}
          sx={{
            backgroundColor: "#fff",
            border: "5px solid #0B1A9B",
            borderRadius: "20px",
          }}
        >
          <Typography variant="h6">Copy Your Online Brochure Link</Typography>
          <Box sx={{ display: "flex", gap: "10px" }} p={1}>
            <EllipsisText
              onClick={() => {
                window.open(pdfURL, "_blank");
                hideDialog();
              }}
            >
              {pdfURL}
            </EllipsisText>
            <ContentCopyIcon
              sx={{ color: "#0B1A9B", cursor: "pointer" }}
              onClick={copyCodeToClipBoard}
            />
          </Box>
        </Box>
      </FullScreenDialog>
    </>
  );
};

export default ListingDetails;
