import React, { useState, useEffect } from 'react'
import {
    Typography,
    Grid,
    Box,
    Slider,
    TextField,
    MenuItem
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import { Country, State, City } from "country-state-city";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {
    setPriceRange,
    setPassengerRange,
    setBoatLengthRange,
    setmlsFilterEnables,
    setBoatMake,
    setCountry,
    setBoatYear,
    setBoatPricingType,
    setDayCharterMinimumHours,
    setCountryState,
    setCountryCode,
    setCountryStateCode,
    setCountryStateCity
} from '../../../Redux/Listing/ListingActions';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import ContainedBtn from "../../../Components/ContainedBtn"
import FullScreenDialog from '../../../Components/Dialog';

const useStyles = makeStyles((theme) => ({
    textCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    headOne: {
        fontWeight: 'bold'
    },
    sliderHead: {
        color: '#000 !important',
        textTransform: 'capitalize',
        fontSize: '15px !important',
        fontWeight: 'bold !important'
    },
    displaybtwn: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    btnWrapper: {
        // border: '1px solid black',
        background: '#fff',
        backgroundColor: '#fff',
        position: 'sticky',
        bottom: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
        boxShadow: 'rgba(0, 0, 0, 0.04) 0px -4px 10px',
        zIndex: '99'
    },
    bottomBtn: {
        background: '#0B1A9B !important',
        color: 'white important',
        borderRadius: '5px !important',
        '&:hover': {
            background: 'transparent !important',
            color: '#0B1A9B !important',
            border: '1px solid #0B1A9B !important'
        }
    }
}));

const Wrapper = styled(Box)(({ theme }) => ({
    borderRadius: '15px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 15px',
    height: 'auto',
    backgroundColor: '#fff',
    padding: '12px 20px',
    // width: '100%'
}));

const SliderWrapper = styled(Box)(({ theme }) => ({
    borderBottom: '1px solid #C0C0C0',
    maxWidth: '100%',
}));

const MlsFilters = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [DayCharterFilterDialog, setDayCharterFilterDialog] = useState(false)

    let {
        priceRange,
        passengerRange,
        boatLengthRange,
        boatMake,
        country,
        boatYear,
        boatPricingType,
        dayCharterMinimumHour,
        countyrState,
        stateCode,
        countryCode,
        city
    } = useSelector((state) => state.listings.mlsFilters)
    const allCountries = Country.getAllCountries();
    const [allStateOfSelectedCountry, setAllStateOfSelectedCountry] = useState(State.getStatesOfCountry('US'));
    const [allCitiesOfSelectedStateCountry, setAllCitiesOfSelectedStateCountry] = useState([
        { name: 'Miami', countryCode: 'US', stateCode: 'FL', latitude: '26.55063000', longitude: '-80.14866000' },
        { name: 'Aventura', countryCode: 'US', stateCode: 'FL', latitude: '26.55063000', longitude: '-80.14866000' },
        { name: 'Hollywood', countryCode: 'US', stateCode: 'FL', latitude: '26.55063000', longitude: '-80.14866000' },
        { name: 'Fort Lauderdale', countryCode: 'US', stateCode: 'FL', latitude: '26.55063000', longitude: '-80.14866000' },
        { name: 'Pompano Beach', countryCode: 'US', stateCode: 'FL', latitude: '26.55063000', longitude: '-80.14866000' },
        { name: 'Boca Raton', countryCode: 'US', stateCode: 'FL', latitude: '26.55063000', longitude: '-80.14866000' },
        { name: 'West Palm Beach', countryCode: 'US', stateCode: 'FL', latitude: '26.55063000', longitude: '-80.14866000' },
        ...City.getCitiesOfState('US', 'FL')]);

    // let boatMakeArr = ['bayliner', 'beneteau', 'sea ray', 'yamaha', 'boston whaler', 'catalina', 'formula', 'azimut', 'wellcraft',]
    const boatMakeArr = useSelector((state) => state.listings.boatMakes[0])
    let ListingTypesArr = [
        {
            name: 'Day Charter',
            value: 'dayCharter'
        },
        {
            name: 'Term Charter',
            value: 'termCharter'
        }
    ]

    const minpriceRange = 50;
    const minpassengerRange = 1;
    const minboatLengthRange = 5;
    let startYear = 1950;
    let endYear = new Date().getFullYear();

    let dayCharterMinimumHourDurations = ['1', '2', '4', '6', '8']


    const priceHandleChange = (event, newValue, activeThumb) => {
        dispatch(setmlsFilterEnables(true))
        if (!Array.isArray(newValue)) {
            return;
        }
        if (newValue[1] - newValue[0] < minpriceRange) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 10000 - minpriceRange);
                dispatch(setPriceRange([clamped, clamped + minpriceRange]));
            } else {
                const clamped = Math.max(newValue[1], minpriceRange);
                dispatch(setPriceRange([clamped - minpriceRange, clamped]));
            }
        } else {
            dispatch(setPriceRange(newValue));
        }
    };
    const passengerHandleChange = (event, newValue, activeThumb) => {
        dispatch(setmlsFilterEnables(true))
        if (!Array.isArray(newValue)) {
            return;
        }
        if (newValue[1] - newValue[0] < minpassengerRange) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - minpassengerRange);
                dispatch(setPassengerRange([clamped, clamped + minpassengerRange]));
            } else {
                const clamped = Math.max(newValue[1], minpassengerRange);
                dispatch(setPassengerRange([clamped - minpassengerRange, clamped]));
            }
        } else {
            dispatch(setPassengerRange(newValue));
        }
    };
    const boatLengthHandleChange = (event, newValue, activeThumb) => {
        dispatch(setmlsFilterEnables(true))
        if (!Array.isArray(newValue)) {
            return;
        }
        if (newValue[1] - newValue[0] < minboatLengthRange) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 175 - minboatLengthRange);
                dispatch(setBoatLengthRange([clamped, clamped + minboatLengthRange]));
            } else {
                const clamped = Math.max(newValue[1], minboatLengthRange);
                dispatch(setBoatLengthRange([clamped - minboatLengthRange, clamped]));
            }
        } else {
            dispatch(setBoatLengthRange(newValue));
        }
    };


    const calculateYear = (startYear, endYear) => {
        let arr = []

        for (let index = startYear; index <= endYear; index++) {
            arr.push(index)
        }
        return arr
    }

    const clearAllFilters = () => {
        dispatch(setmlsFilterEnables(false))

        dispatch(setPriceRange([200, 10000]));
        dispatch(setPassengerRange([1, 100]));
        dispatch(setBoatLengthRange([15, 175]));
        dispatch(setBoatYear([1950, new Date().getFullYear()]))

        dispatch(setBoatMake(""))
        dispatch(setCountry("United States"))
        dispatch(setBoatPricingType(''))

        dispatch(setDayCharterMinimumHours(''))
    }

    const hideDayCharterFilterDialog = () => {
        setDayCharterFilterDialog(false)
    }

    const onChangeCountryCode = (code) => {

        let selectCountry = Country.getCountryByCode(code)
        dispatch(setCountryCode(code))
        dispatch(setCountry(selectCountry?.name))

        dispatch(setmlsFilterEnables(true))

        setAllStateOfSelectedCountry(State.getStatesOfCountry(code))

        dispatch(setCountryState(''))
        dispatch(setCountryStateCode(''))
    }

    const onChangeCountryStateCode = (code) => {

        setAllCitiesOfSelectedStateCountry(City.getCitiesOfState(countryCode, code))
        dispatch(setCountryStateCode(code))

        dispatch(setCountryStateCity(''))
    }



    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    <Wrapper>
                        <Typography variant='h4' mt={2} className={classes.headOne} >MLS Filters</Typography>

                        <SliderWrapper mt={3}  >
                            <Box className={classes.displaybtwn} >
                                <Typography className={classes.sliderHead} >Price Range</Typography>
                                <Typography className={classes.sliderHead} >${priceRange[0]} - ${priceRange[1]} {priceRange[1] == 1000 ? '+' : ''}</Typography>
                            </Box>
                            <Slider
                                getAriaLabel={() => 'Per Hour Price range'}
                                value={priceRange}
                                onChange={priceHandleChange}
                                valueLabelDisplay="off"
                                disableSwap
                                min={200}
                                max={10000}
                                sx={{ maxWidth: { xs: '95%', md: '100%' } }}
                            />
                        </SliderWrapper>
                        <SliderWrapper mt={2}  >
                            <Box className={classes.displaybtwn} >
                                <Typography className={classes.sliderHead} >No Of Passengers</Typography>
                                <Typography className={classes.sliderHead} >{passengerRange[0]} - {passengerRange[1]}</Typography>
                            </Box>
                            <Slider
                                getAriaLabel={() => 'No Of Passenger'}
                                value={passengerRange}
                                onChange={passengerHandleChange}
                                valueLabelDisplay="auto"
                                disableSwap
                                min={1}
                                max={100}
                                sx={{ maxWidth: { xs: '95%', md: '100%' } }}
                            />
                        </SliderWrapper>
                        <SliderWrapper mt={2}  >
                            <Box className={classes.displaybtwn} >
                                <Typography className={classes.sliderHead} >Boat Length in Feet</Typography>
                                <Typography className={classes.sliderHead} >{boatLengthRange[0]}' - {boatLengthRange[1]}'</Typography>
                            </Box>
                            <Slider
                                getAriaLabel={() => 'Boat Length'}
                                value={boatLengthRange}
                                onChange={boatLengthHandleChange}
                                valueLabelDisplay="auto"
                                disableSwap
                                min={15}
                                max={175}
                                sx={{ maxWidth: { xs: '95%', md: '100%' } }}
                            />
                        </SliderWrapper>


                        <SliderWrapper mt={4} pb={2} >
                            <Box className={classes.displaybtwn} mb={1} >
                                <Typography className={classes.sliderHead} >Listing Type</Typography>
                            </Box>
                            <TextField
                                id="Make"
                                select
                                required={true}
                                label="Select Listing Type"
                                fullWidth
                                onChange={(e) => {
                                    let type = e.target.value
                                    dispatch(setmlsFilterEnables(true))
                                    dispatch(setBoatPricingType(type))
                                    if (type == "dayCharter") {
                                        setDayCharterFilterDialog(true)
                                    }
                                }}
                                value={boatPricingType}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            style: { maxHeight: 200 }, // Set the maximum height here
                                        },
                                    },
                                }}
                            >
                                {ListingTypesArr.map((val) => (
                                    <MenuItem key={val.name} value={val.value} sx={{ textTransform: 'capitalize' }} >
                                        {val.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {dayCharterMinimumHour && <Typography className={classes.sliderHead} >{dayCharterMinimumHour} Minimum hour durations</Typography>}
                        </SliderWrapper>
                        <SliderWrapper mt={2} pb={2} >
                            <Box className={classes.displaybtwn} mb={1} >
                                <Typography className={classes.sliderHead} >Country</Typography>
                            </Box>
                            <TextField
                                // id="outlined-select-currency"
                                select
                                label="Country"
                                fullWidth
                                value={countryCode}
                                onChange={(e) => onChangeCountryCode(e.target.value)}
                                name="country"
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            style: { maxHeight: 200 }, // Set the maximum height here
                                        },
                                    },
                                }}
                            >
                                {allCountries.map((val, i) => (
                                    <MenuItem key={i} value={val.isoCode}>
                                        {val.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </SliderWrapper>
                        <SliderWrapper mt={2} pb={2} >
                            <Box className={classes.displaybtwn} mb={1} >
                                <Typography className={classes.sliderHead} >States</Typography>
                            </Box>
                            <TextField
                                select
                                label="State"
                                fullWidth
                                value={stateCode}
                                onChange={(e) => onChangeCountryStateCode(e.target.value)}
                                name="State"
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            style: { maxHeight: 200 }, // Set the maximum height here
                                        },
                                    },
                                }}
                            >
                                {allStateOfSelectedCountry?.map((val, i) => (
                                    <MenuItem key={i} value={val.isoCode}>
                                        {val.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </SliderWrapper>
                        <SliderWrapper mt={2} pb={2} >
                            <Box className={classes.displaybtwn} mb={1} >
                                <Typography className={classes.sliderHead} >City</Typography>
                            </Box>
                            <TextField
                                select
                                label="City"
                                fullWidth
                                value={city}
                                onChange={(e) => dispatch(setCountryStateCity(e.target.value))}
                                name="City"
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            style: { maxHeight: 200 }, // Set the maximum height here
                                        },
                                    },
                                }}
                            >
                                {allCitiesOfSelectedStateCountry?.map((val, i) => (
                                    <MenuItem key={i} value={val.name}>
                                        {val.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </SliderWrapper>
                        <SliderWrapper mt={2} pb={2} >
                            <Box className={classes.displaybtwn} mb={1} >
                                <Typography className={classes.sliderHead} >Year</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '10px' }} >
                                <TextField
                                    id="outlined-basic"
                                    label="Min Year"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    placeholder="Min Year"
                                    value={boatYear[0]}
                                    onChange={(e) => {
                                        const numericValue = e.target.value
                                        if (/^\d*$/.test(numericValue)) {
                                            dispatch(setmlsFilterEnables(true))
                                            dispatch(setBoatYear([numericValue, boatYear[1]]))
                                        }
                                    }}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Max year"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    placeholder="Max year"
                                    value={boatYear[1]}
                                    onChange={(e) => {
                                        const numericValue = e.target.value
                                        if (/^\d*$/.test(numericValue)) {
                                            dispatch(setmlsFilterEnables(true))
                                            dispatch(setBoatYear([boatYear[0], numericValue]))
                                        }
                                    }}
                                />
                            </Box>
                        </SliderWrapper>
                        <SliderWrapper mt={2} pb={2} >
                            <Box className={classes.displaybtwn} mb={1} >
                                <Typography className={classes.sliderHead} >Make</Typography>
                            </Box>
                            <TextField
                                id="Make"
                                select
                                required={true}
                                label="Select Make"
                                fullWidth
                                onChange={(e) => {
                                    dispatch(setmlsFilterEnables(true))
                                    dispatch(setBoatMake(e.target.value))
                                }}
                                value={boatMake}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            style: { maxHeight: 200 }, // Set the maximum height here
                                        },
                                    },
                                }}
                            >
                                {boatMakeArr?.map((make) => (
                                    <MenuItem key={make} value={make} sx={{ textTransform: 'capitalize' }} >
                                        {make}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </SliderWrapper>

                        <Box className={classes.btnWrapper} pt={2} pb={1} >
                            <ContainedBtn text='Back' className={classes.bottomBtn}
                                onClick={() => navigate('/mls')}
                                startIcon={<ArrowBackIosIcon />}
                                sx={{ display: { xs: 'flex', md: 'none', }, mr: 2 }}
                            />
                            <ContainedBtn text='Clear' className={classes.bottomBtn} onClick={clearAllFilters} />
                        </Box>
                    </Wrapper>
                </Grid>
            </Grid>
            <FullScreenDialog
                maxWidth='xs'
                fullWidth={true}
                open={DayCharterFilterDialog}
                hideDialogHandler={hideDayCharterFilterDialog}
                style={{
                    background: 'rgba(255, 255, 255, 0.10)',
                    "& .MuiDialog-paper": {
                        // background: 'rgba(255, 255, 255, 0.10)',
                        // offset: '0px 4px rgba(0, 0, 0, 0.25)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.20)',
                        borderRadius: '20px',
                        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)'
                    },
                }}>
                <Box p={2} sx={{ border: '2px solid #0B1A9B', borderRadius: '20px' }} >
                    <Grid container spacing={2} >
                        <Grid item xs={12} >
                            <Typography variant='h6' sx={{ color: '#000', fontFamily: ["Gilroy Regular, sans-serif"].join(",") }}>Day charter minimum hour durations</Typography>
                            <SliderWrapper sx={{ border: 'none' }} mt={2} pb={2} >
                                {/* <Box className={classes.displaybtwn} mb={1} >
                                    <Typography className={classes.sliderHead} >Country</Typography>
                                </Box> */}
                                <TextField
                                    // id="outlined-select-currency"
                                    select
                                    label="Minimum hour durations"
                                    fullWidth
                                    value={dayCharterMinimumHour}
                                    onChange={(e) => {
                                        dispatch(setmlsFilterEnables(true))
                                        dispatch(setDayCharterMinimumHours(e.target.value))
                                    }}
                                    // name="country"
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: { maxHeight: 200 }, // Set the maximum height here
                                            },
                                        },
                                    }}
                                >
                                    {dayCharterMinimumHourDurations.map((val, i) => (
                                        <MenuItem key={i} value={val}>
                                            {val}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </SliderWrapper>
                        </Grid>
                        <Grid item xs={12} >
                            <Box className={classes.displayBtwn}  >
                                <ContainedBtn text='Close'
                                    className={classes.outlinedBtn}
                                    onClick={hideDayCharterFilterDialog} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </FullScreenDialog>
        </>
    )
}

export default MlsFilters