import {
    SET_ALL_LISTINGS,
    SET_LOADING,
    SET_ERROR,
    SET_ALL_MY_LISTINGS,
    SET_LISTINGS_EXTRAS,
    SET_LISTINGS_TENDERS_TOYS,
    SET_LISTINGS_BOAT_MAKES,
    SET_MARKUP_PRICES
} from "./ListingTypes";


// for mls Filter
import {
    SET_PRICE_RANGE,
    SET_PASSENGERS_RANGE,
    SET_BOAT_LENGTH_RANGE,
    SET_BOAT_MAKE,
    SET_COUNTRY,
    SET_COUNTRY_CODE,
    SET_BOAT_YEAR,
    SET_BOAT_PRICING_TYPE,
    SET_FILTERS_ENABLES,
    SET_DAY_CHARTER_MINIMUM_HOUR,
    SET_STATE,
    SET_STATE_CODE,
    SET_CITY
} from "./ListingTypes";

// for search filter
import {
    SET_SEARCH_COUNTRY,
    SET_SEARCH_FORMATTEDADDRESS,
    SET_SEARCH_LATLNG,
    SET_BOAT_SEARCH
} from "./ListingTypes";

const initialState = {
    loading: false,
    updating: false,
    error: null,
    allListings: [],
    allMyListings: [],
    listingExtras: [],
    listingTendersAndToys: [],
    boatMakes: [],
    markUpPrices: {},
    mlsFilters: {
        priceRange: [200, 10000],
        passengerRange: [1, 100],
        boatLengthRange: [15, 175],
        boatYear: [1950, new Date().getFullYear()],
        boatMake: '',
        country: 'United States',
        countryCode: 'US',
        countyrState: 'Florida',
        stateCode: 'FL',
        city: '',
        cityCode: '',
        boatPricingType: '',
        dayCharterMinimumHour: '',

    },
    boatSearch: {
        latLng: {
            lng: '',
            lat: ''
        },
        formattedAddress: '',
        searchCountry: ''
    },
    isMlsFiltersEnables: false
};


const listingReducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_ALL_LISTINGS:
            return {
                ...state,
                allListings: payload,
                updating: false,
                loading: false,
            };
        case SET_MARKUP_PRICES:
            return {
                ...state,
                markUpPrices: payload
            };
        case SET_LISTINGS_BOAT_MAKES:
            return {
                ...state,
                boatMakes: payload,
                loading: false
            };
        case SET_LISTINGS_EXTRAS:
            return {
                ...state,
                listingExtras: payload,
                loading: false,
            };
        case SET_LISTINGS_TENDERS_TOYS:
            return {
                ...state,
                listingTendersAndToys: payload,
                loading: false,
            };
        case SET_ALL_MY_LISTINGS:
            return {
                ...state,
                allMyListings: payload,
                updating: false,
                loading: false,
            };
        case SET_ERROR:
            return {
                ...state,
                error: payload,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: payload,
                error: null,
            };
        case SET_FILTERS_ENABLES:
            return {
                ...state,
                isMlsFiltersEnables: payload,
                error: null,
            };
        case SET_PRICE_RANGE:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    priceRange: payload
                }
            }
        case SET_PASSENGERS_RANGE:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    passengerRange: payload
                }
            }
        case SET_BOAT_LENGTH_RANGE:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    boatLengthRange: payload
                }
            }
        case SET_BOAT_MAKE:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    boatMake: payload
                }
            }
        case SET_COUNTRY:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    country: payload
                }
            }
        case SET_COUNTRY_CODE:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    countryCode: payload
                }
            }
        case SET_STATE:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    countyrState: payload
                }
            }
        case SET_STATE_CODE:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    stateCode: payload
                }
            }
        case SET_CITY:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    city: payload
                }
            }
        case SET_BOAT_YEAR:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    boatYear: payload
                }
            }
        case SET_BOAT_PRICING_TYPE:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    boatPricingType: payload
                }
            }
        case SET_DAY_CHARTER_MINIMUM_HOUR:
            return {
                ...state,
                mlsFilters: {
                    ...state.mlsFilters,
                    dayCharterMinimumHour: payload
                }
            }
        case SET_SEARCH_COUNTRY:
            return {
                ...state,
                boatSearch: {
                    ...state.boatSearch,
                    searchCountry: payload
                }
            }
        case SET_SEARCH_FORMATTEDADDRESS:
            return {
                ...state,
                boatSearch: {
                    ...state.boatSearch,
                    formattedAddress: payload
                }
            }
        case SET_SEARCH_LATLNG:
            return {
                ...state,
                boatSearch: {
                    ...state.boatSearch,
                    latLng: payload
                }
            }
        case SET_BOAT_SEARCH:
            return {
                ...state,
                boatSearch: payload
            }
        default:
            return state;
    }
};

export default listingReducer;