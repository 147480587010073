export const SET_ALL_LISTINGS = "SET_ALL_LISTINGS";
export const SET_ALL_MY_LISTINGS = "SET_ALL_MY_LISTINGS";
export const SET_LISTINGS_EXTRAS = "SET_LISTINGS_EXTRAS";
export const SET_LISTINGS_TENDERS_TOYS = "SET_LISTINGS_TENDERS_TOYS";
export const SET_LISTINGS_BOAT_MAKES = "SET_LISTINGS_BOAT_MAKES";
export const SET_MARKUP_PRICES = "SET_MARKUP_PRICES";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";

// Mls Filters
export const SET_FILTERS_ENABLES = "SET_FILTERS_ENABLES";
export const SET_PRICE_RANGE = "SET_PRICE_RANGE";
export const SET_PASSENGERS_RANGE = "SET_PASSENGERS_RANGE";
export const SET_BOAT_LENGTH_RANGE = "SET_BOAT_LENGTH_RANGE";
export const SET_BOAT_MAKE = "SET_BOAT_MAKE";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";
export const SET_BOAT_YEAR = "SET_BOAT_YEAR";
export const SET_BOAT_PRICING_TYPE = "SET_BOAT_PRICING_TYPE";
export const SET_DAY_CHARTER_MINIMUM_HOUR = "SET_DAY_CHARTER_MINIMUM_HOUR";
export const SET_STATE = "SET_STATE";
export const SET_STATE_CODE = "SET_STATE_CODE";
export const SET_CITY = "SET_CITY";


// Boat Search Factors
export const SET_BOAT_SEARCH = "SET_BOAT_SEARCH";
export const SET_SEARCH_FILTERS_ENABLES = "SET_SEARCH_FILTERS_ENABLES";
export const SET_SEARCH_COUNTRY = "SET_SEARCH_COUNTRY";
export const SET_SEARCH_FORMATTEDADDRESS = "SET_SEARCH_FORMATTEDADDRESS";
export const SET_SEARCH_LATLNG = "SET_SEARCH_LATLNG";