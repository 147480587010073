import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Container,
  Switch,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import BroucherPricing from "./BroucherPricing";
import BoatAvalibility from "./BoatAvalibility";
import MultipleLocation from "./MultipleLocation";

import CircleIcon from "@mui/icons-material/Circle";
import { capitalizeFirstLetter } from "../../../App";

const BoatTypeHead = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontFamily: "Gilroy Bold, sans-serif",
  fontWeight: "bold",
  color: "#0B1A9B",
  // padding: "5px ",
}));
const DataText = styled(Box)(({ theme }) => ({
  fontSize: "10px",
  fontFamily: "Gilroy Regular, sans-serif",
  color: "#49556D",
  // fontWeight: "700",
  whiteSpace: "pre-line",
  textAlign: "left",
  // letterSpacing: "1px",
  // border: '1px solid #0B1A9B',
  width: "50%",
  // marginTop: "10px",
  // [theme.breakpoints.up("xs")]: {
  //   fontSize: "13px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   fontSize: "12px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   fontSize: "13px",
  //   letterSpacing: "1px",
  // },
}));

const DataText2 = styled(Box)(({ theme }) => ({
  fontSize: "10px",
  fontFamily: "Gilroy Bold, sans-serif !important",
  color: "#252D3C",
  // border: '1px solid #0B1A9B',
  // whiteSpace: "pre-line",
  // letterSpacing: "1px",
  // marginTop: "10px",
  // [theme.breakpoints.up("xs")]: {
  //   fontSize: "13px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   fontSize: "12px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   fontSize: "13px",
  //   letterSpacing: "1px",
  // },
}));

const TitleHead = styled(Box)(({ theme }) => ({
  fontSize: "1rem",
  fontFamily: "Gilroy Regular, sans-serif",
  fontWeight: "semi-bold",
  whiteSpace: "pre-line",
  letterSpacing: "2px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "1rem",
    letterSpacing: "1px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
    letterSpacing: "2px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
    letterSpacing: "2px",
  },
}));

const DescriptionHead = styled(Box)(({ theme }) => ({
  fontSize: "10px",
  color: "#2C3444",
  fontFamily: "Gilroy Regular, sans-serif",
  marginTop: "5px",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 5,

  // [theme.breakpoints.up("xs")]: {
  //   // fontSize: "12px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   // fontSize: "13px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   // fontSize: "15px",
  //   letterSpacing: "1px",
  // },
}));

const DataBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginBottom: "10px",
  // flexWrap: 'wrap',
  gap: "10px",
}));
const BulletBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "baseline",
  gap: "10px",
  marginLeft: "8px",
}));

const SectionHead = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontFamily: "Gilroy Bold, sans-serif",
  fontWeight: "bold",
  color: "#0B1A9B",
  // backgroundColor: "#F3F3F3",
  // padding: "5px 15px",
  // borderBottom: "5px solid #0B1A9B",
  // borderTopRightRadius: "8px",
  // borderTopLeftRadius: "8px",
}));

const TopLogo = styled("img")(({ theme }) => ({
  width: "auto",
  maxHeight: "60px",
}));

const useStyles = makeStyles((theme) => ({
  coverPhoto: {
    maxWidth: "35%",
    width: "auto",
    maxHeight: "50vh",
    resizeBy: "contain",
    borderRadius: "10px",
    // borderRadius: "5px",
    [theme.breakpoints.up("xs")]: {
      maxHeight: "40vh",
      // borderRadius: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: "60vh",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "80vh",
    },
  },
  galleryCntnr: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: "10px",
  },
  galleryImg: {
    maxWidth: "32%",
    width: "auto",
    borderRadius: "10px",
    maxHeight: "30vh",
    height: "auto",
    [theme.breakpoints.up("xs")]: {
      maxWidth: "30%",
      maxHeight: "10vh",
      borderRadius: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "32%",
      maxHeight: "20vh",
      borderRadius: "7px",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "30vh",
      borderRadius: "10px",
    },
  },
  titleHead: {
    fontSize: "20px !important",
    fontWeight: "bold !important",
  },
  alignCentr: {
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
  },
  alignEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  fontFamily: {
    fontFamily: "Gilroy Regular, sans-serif !important",
  },
  fontBold: {
    fontWeight: "bold !important",
  },
  smallFont: {
    fontFamily: "Gilroy Regular, sans-serif !important",
    fontSize: "12px !important",
  },
  topInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  justifyBtwn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dataTextStyle: {
    fontSize: "1rem",
    // fontSize: "12px",
    fontFamily: "Gilroy Regular, sans-serif",
    color: "#777777",
    // fontWeight: "700",
    whiteSpace: "pre-line",
    letterSpacing: "1px",
    textAlign: "left",
  },
  // topInfo: {
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "end",
  // },
  displayEnd: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  availabilityTextStyle: {
    width: "110px !important",
  },
  toysAndTendersTextStyle: {
    width: "80px !important",
  },
  addressTextStyle: {
    width: "90px !important",
  },
}));

const ComparisonLayout = ({
  listingDetails,
  markUpPrice,
  isDescriptionDisplay,
  isDataSheetDisplay,
  isExtrastDisplay,
  isTenderToysDisplay,
  isHeaderInfoDisplay,
  isAvaliabilityDisplay,
  isPhotoAlbumDisplay,
  isCaptianInfoDisplay,
  isEditAllow,
  ind,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentPrice, setCurrentPrice] = useState("");

  const [displayMarkupPrice, setDisplayMarkupPrice] = useState("");

  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[dayOfWeek];

  const findPrice = (pricingType, pricing, availability) => {
    if (pricingType === "termCharter") {
      return Number(pricing?.pricing[1]);
    } else {
      return Number(pricing?.price);
    }
  };

  useEffect(() => {
    if (listingDetails) {
      let price = findPrice(
        listingDetails?.pricingType,
        listingDetails[listingDetails?.pricingType],
        listingDetails?.availability
      );
      setCurrentPrice(price);
    }
  }, [listingDetails]);

  let pricingTypeStatus = {
    dayCharter: "Day Charter",
    termCharter: "Term Charter",
  };

  const currentUser = useSelector((state) => state.user.user);

  let sleepingAccmmodationskey = Object.keys(
    listingDetails?.sleepingAccmmodations || {}
  );
  let sleepingFormatedKey = {
    sleepsTotal: "Sleep Total",
    kingsBeds: "Kings Beds",
    queenBeds: "Queens Beds",
    noOfCabins: "No Of Cabins",
    twinBeds: "Twins Bed",
  };

  return (
    <>
      <Grid
        container
        mb={2}
        // spacing={1}
        sx={{
          // border: "2px solid #0B1A9B",
          outline: "none",
          backgroundColor: "#F9F9F9",
          borderRadius: "5px",
          boxShadow: "0px 0px 5px 0px #d3d3d3",
        }}
      >
        {/* Intro */}

        <Grid item xs={12} className={classes.justifyBtwn} px={2}>
          <Box>
            {/* <TitleHead>{listingDetails?.boatName}</TitleHead> */}
            <BoatTypeHead>
              {listingDetails?.dayCharter?.pricingSameEachDay === "yes"
                ? "Day Charter"
                : "Term Charter"}
            </BoatTypeHead>
            <DataText sx={{ width: "200px", color: "#49556D" }}>
              {listingDetails?.boatAddress?.formattedAddress}{" "}
            </DataText>
          </Box>
          <Box
            sx={
              {
                // marginBottom: "10px",
              }
            }
          >
            <BroucherPricing
              listingDetails={listingDetails}
              currentUser={currentUser}
              isEditAllow={isEditAllow}
              parentClasses={classes}
            />
          </Box>
        </Grid>
        {/* Image with Detail */}
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"top"}
          alignItems={"top"}
          gap={2}
          borderTop={"0.5px solid #000"}
          borderBottom={"0.5px solid #000"}
          paddingBottom={2}
          paddingTop={2}
          margin={2}
          marginTop={1}
          // borderBottom={"1px solid #0B1A9B"}
        >
          <img
            src={listingDetails?.imagesUrls[0]}
            className={classes.coverPhoto}
            // onResize={"contain"}
          />
          <Box
            pt={0}
            pr={2}
            pb={0}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <SectionHead>Description</SectionHead>
            <DescriptionHead boxShadow={"inherit"}>
              {listingDetails?.description}
            </DescriptionHead>
          </Box>
        </Grid>
        {/* Listing */}
        <Grid
          container
          borderTop={"0.5px solid #000"}
          marginBottom={2}
          marginRight={2}
          marginLeft={2}
          marginTop={-2}
        >
          <Grid item xs={12} mt={1} marginBottom={1}>
            <SectionHead>Boat Details</SectionHead>
          </Grid>

          <Grid item xs={4}>
            <DataBox>
              <DataText>Boat Type</DataText>
              <DataText2>
                {":"}
                {" " + pricingTypeStatus[listingDetails?.pricingType]}{" "}
              </DataText2>
            </DataBox>
          </Grid>

          {/* <Grid item xs={4}>
            <DataBox>
              <DataText>HIN</DataText>
              <DataText2>
                {":"}
                {" " + listingDetails?.hin}{" "}
              </DataText2>
            </DataBox>
          </Grid> */}

          <Grid item xs={4}>
            <DataBox>
              <DataText>Beam Length (feet)</DataText>
              <DataText2>
                {":"}
                {" " + listingDetails?.beamLength}''{" "}
              </DataText2>
            </DataBox>
          </Grid>

          <Grid item xs={4}>
            <DataBox>
              <DataText>Boat Length (feet)</DataText>
              <DataText2>
                {":"}
                {" " + listingDetails?.boatLength}{" "}
              </DataText2>
            </DataBox>
          </Grid>

          <Grid item xs={4}>
            <DataBox>
              <DataText>No Of Passengers</DataText>
              <DataText2>
                {":"}
                {" " + listingDetails?.noOfPassengers}{" "}
              </DataText2>
            </DataBox>
          </Grid>

          <Grid item xs={4}>
            <DataBox>
              <DataText>No Of Bathrooms</DataText>
              <DataText2>
                {":"}
                {" " + listingDetails?.noOfBathrooms}{" "}
              </DataText2>
            </DataBox>
          </Grid>

          <Grid item xs={4}>
            <DataBox>
              <DataText>Make</DataText>
              <DataText2>
                {":"}
                {" " + capitalizeFirstLetter(listingDetails?.make)}{" "}
              </DataText2>
            </DataBox>
          </Grid>

          <Grid item xs={4}>
            <DataBox>
              <DataText>Model</DataText>
              <DataText2>
                {":"}
                {" " + listingDetails?.model}{" "}
              </DataText2>
            </DataBox>
          </Grid>

          <Grid item xs={4}>
            <DataBox>
              <DataText>Country</DataText>
              <DataText2>
                {":"}
                {" " +
                  capitalizeFirstLetter(
                    listingDetails?.boatAddress?.country
                  )}{" "}
              </DataText2>
            </DataBox>
          </Grid>

          <Grid item xs={4}>
            <DataBox>
              <DataText>Fuel Type</DataText>
              <DataText2>
                {":"}
                {" " + capitalizeFirstLetter(listingDetails?.fuelType)}{" "}
              </DataText2>
            </DataBox>
          </Grid>

          <Grid item xs={4}>
            <DataBox>
              <DataText>Year</DataText>
              <DataText2>
                {":"}
                {" " + listingDetails?.year}{" "}
              </DataText2>
            </DataBox>
          </Grid>

          {sleepingAccmmodationskey.map((key, ind) => {
            let value = listingDetails?.sleepingAccmmodations?.[key];
            if (value.trim().length > 0 || value > 0) {
              return (
                <Grid item xs={4} key={ind}>
                  <DataBox>
                    <DataText>{sleepingFormatedKey?.[key]}</DataText>
                    <DataText2>
                      {":"}
                      {" " + listingDetails?.sleepingAccmmodations?.[key]}{" "}
                    </DataText2>
                  </DataBox>
                </Grid>
              );
            }
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default ComparisonLayout;
