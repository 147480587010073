import React from 'react'
import {
    Box, Button, Switch, Typography,
    // FormControlLabel,
    FormControlLabel,
    FormControl,
    FormLabel,
    // Switch,
    FormGroup,
} from '@mui/material'
import { styled } from '@mui/system';


const MainCntnr = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    // justifyContent: 'space-between',
    flexWrap: 'wrap'
}));

export const Toggle = ({ text, checked, onChange, props }) => {
    return (
        <MainCntnr>
            <Typography sx={{ fontFamily: 'Gilroy Regular, sans-serif ', fontSize: '1rem', fontWeight:'bold' ,color: 'black', textTransform: 'capitalize', }} > {text} </Typography>
            <FormControl >
                <FormGroup>
                    {/* <FormLabel component="legend" sx={{ fontSize: '1rem', color: '#000' }} > {text}</FormLabel> */}
                    <FormControlLabel
                        sx={{
                            display: 'flex',
                            // backgroundColor:'red'
                            // backgroundImage: 'linear-gradient(rgba(76, 207, 248, 1), rgba(74, 75, 227, 1),rgba(35, 52, 156, 1))',
                            borderRadius: '10px',
                            height: '27px',
                            width: '45px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        control={
                            <Switch checked={checked} onChange={onChange} />
                        }
                    />
                </FormGroup>
            </FormControl>
        </MainCntnr>
    )
}
