import React from 'react'
import BannerOne from "./Components/BannerOne"
import PricingPlans from './Components/PricingPlans'
import Virtual from '../Home/Components/Virtual'

const Pricing = () => {
  return (
    <>
      <BannerOne />
      <PricingPlans />
      <Virtual />
    </>
  )
}

export default Pricing