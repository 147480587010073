import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Container,
  Switch,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import BroucherPricing from "./BroucherPricing";
import BoatAvalibility from "./BoatAvalibility";
import MultipleLocation from "./MultipleLocation";
import CheckIcon from "../../../Assets/Icons/CheckIcon.svg";

import CircleIcon from "@mui/icons-material/Circle";
import { capitalizeFirstLetter } from "../../../App";

const DataText = styled(Box)(({ theme }) => ({
  fontSize: "10px",
  fontFamily: "Gilroy Regular, sans-serif !important",
  color: "#49556D",
  fontWeight: "bold",
  whiteSpace: "pre-line",
  letterSpacing: "1px",
  marginTop: "10px",
  // [theme.breakpoints.up("xs")]: {
  //   fontSize: "13px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   fontSize: "14px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   fontSize: "15px",
  //   letterSpacing: "1px",
  // },
}));

const TitleHead = styled(Box)(({ theme }) => ({
  fontSize: "25px",
  fontFamily: "Gilroy Bold, sans-serif !important",
  fontWeight: "bold",
  whiteSpace: "pre-line",
  letterSpacing: "2px",
  color: "#081474",
  // [theme.breakpoints.up("xs")]: {
  //   fontSize: "25px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   fontSize: "30px",
  //   letterSpacing: "2px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   fontSize: "35px",
  //   letterSpacing: "2px",
  // },
}));

const DescriptionHead = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  color: "#777777",
  fontFamily: "Gilroy Regular, sans-serif !important",
  whiteSpace: "pre-line",
  letterSpacing: "1px",
  marginTop: "10px",
  // [theme.breakpoints.up("xs")]: {
  //   fontSize: "12px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   fontSize: "13px",
  //   letterSpacing: "1px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   fontSize: "15px",
  //   letterSpacing: "1px",
  // },
}));

const DataBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  // flexWrap: 'wrap',
  gap: "10px",
}));
const BulletBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "baseline",
  gap: "10px",
  marginLeft: "8px",
}));

const SectionHead = styled(Typography)(({ theme }) => ({
  // fontSize: "1.5rem",
  // fontFamily: "Gilroy Regular, sans-serif !important",
  // fontWeight: "bold",
  // color: "#0B1A9B",
  // backgroundColor: "#F3F3F3",
  // padding: "5px 15px",
  // borderBottom: "5px solid #0B1A9B",
  // borderTopRightRadius: "8px",
  // borderTopLeftRadius: "8px",
  color: "#081474",
  fontWeight: "bold",
  fontFamily: "Gilroy Bold, sans-serif !important",
}));

const TopLogo = styled("img")(({ theme }) => ({
  width: "auto",
  maxHeight: "60px",
}));

const useStyles = makeStyles((theme) => ({
  coverPhoto: {
    // maxWidth: "100%",
    width: "100%",
    maxHeight: "80vh",
    borderRadius: "5px",
    // border: "1px solid #000",
    boxShadow: "0px 0px 5px 0px #000",
    [theme.breakpoints.up("xs")]: {
      maxHeight: "40vh",
      borderRadius: "5px",
      objectFit: "contain",
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: "60vh",
      objectFit: "contain",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "40vh",
      objectFit: "fill",
    },
  },
  galleryCntnr: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "5px",
  },
  galleryImg: {
    // maxWidth: "32%",
    borderRadius: "10px",
    // maxHeight: "20%",
    width: "32%",
    height: "10vh",
    objectFit: 'cover',
    [theme.breakpoints.up("xs")]: {
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
  },
  titleHead: {
    fontSize: "20px !important",
    fontWeight: "bold !important",
  },
  alignCentr: {
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
  },
  alignEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  fontFamily: {
    fontFamily: "Gilroy Regular, sans-serif !important",
  },
  fontBold: {
    fontWeight: "bold !important",
  },
  smallFont: {
    fontFamily: "Gilroy Regular, sans-serif !important",
    fontSize: "12px !important",
  },
  topInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  justifyBtwn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  displayEnd: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  availabilityTextStyle: {
    width: "70px !important",
  },
  toysAndTendersTextStyle: {
    width: "80px !important",
  },
  addressTextStyle: {
    width: "90px !important",
  },
}));

const SingleListingDetails = ({
  listingDetails,
  markUpPrice,
  isDescriptionDisplay,
  isDataSheetDisplay,
  isExtrastDisplay,
  isTenderToysDisplay,
  isHeaderInfoDisplay,
  isAvaliabilityDisplay,
  isPhotoAlbumDisplay,
  isCaptianInfoDisplay,
  isEditAllow,
  ind,
  totalLength,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentPrice, setCurrentPrice] = useState("");

  const [displayMarkupPrice, setDisplayMarkupPrice] = useState("");

  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[dayOfWeek];

  const markUpPrices = useSelector((state) => state.listings.markUpPrices);

  const findPrice = (pricingType, pricing, availability) => {
    if (pricingType === "termCharter") {
      return Number(pricing?.pricing[1]);
    } else {
      return Number(pricing?.price);
    }
  };

  useEffect(() => {
    if (listingDetails) {
      let price = findPrice(
        listingDetails?.pricingType,
        listingDetails[listingDetails?.pricingType],
        listingDetails?.availability
      );
      setCurrentPrice(price);
    }
  }, [listingDetails]);

  let pricingTypeStatus = {
    dayCharter: "Day Charter",
    termCharter: "Term Charter",
  };

  const currentUser = useSelector((state) => state.user.user);

  let sleepingAccmmodationskey = Object.keys(
    listingDetails?.sleepingAccmmodations || {}
  );
  let sleepingFormatedKey = {
    sleepsTotal: "Sleep Total",
    kingsBeds: "Kings Beds",
    queenBeds: "Queens Beds",
    noOfCabins: "No Of Cabins",
    twinBeds: "Twins Bed",
  };

  let discountedPriceHoursRange = [2, 4, 6, 8];
  return (
    <Grid
      container
      // mb={10}
      spacing={1}
      sx={{ border: "none", outline: "none" }}
    >
      {totalLength > 1 && (
        <Grid item xs={12} sx={{}}>
          <Typography
            sx={{
              color: "#0B1A9B",
              // border: "5px solid #0B1A9B",
              // borderRadius: "50%",
              // width: "min-content",
              // padding: "3px 18px",
            }}
            variant="h2"
          >
            {ind + 1}
          </Typography>
        </Grid>
      )}

      {/* Header Top Info */}
      {isHeaderInfoDisplay && (
        <Grid item xs={12} className={classes.justifyBtwn}>
          <Box>
            {/* {currentUser?.companyPicture && (
              <TopLogo src={currentUser?.companyPicture} />
            )} */}
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box className={classes.topInfo}>
              <Typography
                className={[classes.fontBold, classes.fontFamily]}
                sx={{ fontSize: "12px" }}
              >
                {currentUser?.firstName + " " + currentUser?.lastName}{" "}
              </Typography>
              <Typography className={classes.smallFont}>
                {currentUser?.email}
              </Typography>
              <Typography className={classes.smallFont}>
                {currentUser?.phoneNo}
              </Typography>
            </Box>
            {/* <Box>
              {currentUser?.profilePicture && (
                <TopLogo src={currentUser?.profilePicture} />
              )}
            </Box> */}
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src={listingDetails?.imagesUrls[0]}
          className={classes.coverPhoto}
        />
      </Grid>
      {isPhotoAlbumDisplay && (
        <Grid item xs={12} my={1}>
          <Box className={classes.galleryCntnr} sx={{ mt: 1 }}>
            {listingDetails?.imagesUrls?.slice(-3).map((yatchImg, ind) => {
              return (
                <img
                  className={classes.galleryImg}
                  src={yatchImg}
                  key={ind}
                  alt="Yacht Image"
                />
              );
            })}
          </Box>
        </Grid>
      )}
      <Grid
        item
        // xs={10}
        // mt={1}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {/* //Temporary Commented */}
        {/* {listingDetails?.pricingType === "termCharter" && (
          <DataText textAlign="right">
            {listingDetails?.termCharter?.commissionPercentage}% Commision{" "}
          </DataText>
        )} */}
        {/* {(isEditAllow && listingDetails?.pricingType === "dayCharter") && <Box key={ind} className={classes.displayEnd} sx={{ display: isEditAllow ? 'flex' : 'none' }} >
                    <Typography sx={{ maxWidth: '250px' }} >Enter Markup Price </Typography>
                    <TextField
                        id="outlined-basic"
                        label="Enter Markup Price"
                        variant="outlined"
                        type="number"
                        // fullWidth
                        sx={{ maxWidth: '250px' }}
                        placeholder="Enter Markup Price"
                        value={displayMarkupPrice}
                        onChange={(e) => {
                            const numericValue = e.target.value
                            if (/^\d*$/.test(numericValue)) {
                                setDisplayMarkupPrice(numericValue)
                            }
                        }}
                    />
                </Box>} */}
        {/* <TitleHead sx={{ mt: 1 }} >{listingDetails?.title}</TitleHead> */}
        {/* <TitleHead>{listingDetails?.boatName}</TitleHead> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <TitleHead>
            {listingDetails?.dayCharter?.pricingSameEachDay === "yes"
              ? "Day Charter"
              : "Term Charter"}
          </TitleHead>
          {/* <DataText> </DataText>
          <DataText> </DataText> */}
          <DataText sx={{ color: "#49556D" }}>
            {listingDetails?.boatLength}' ft {listingDetails?.year}{" "}
            {listingDetails?.make},{" "}
            {listingDetails?.boatAddress?.formattedAddress}
          </DataText>
        </Box>
        <Box
          sx={{
            // width: "50%",
          }}
        >
          <BroucherPricing 
            listingDetails={listingDetails}
            currentUser={currentUser}
            isEditAllow={isEditAllow}
            parentClasses={classes}
          />
        </Box>
      </Grid>

      {/* Description */}
      {isDescriptionDisplay && (
        <Grid
          item
          xs={12}
          mt={2}
          mb={1}
          // p={2}
          paddingBottom={2}
          paddingTop={4}
          sx={{
            border: "0.7px solid #081474",
            borderRadius: "5px",
            background: "#f6f6f6",
          }}
        >
          <SectionHead>Description</SectionHead>
          <DescriptionHead>
            {String(listingDetails?.description)}
          </DescriptionHead>
        </Grid>
      )}

      {/* Data Sheet */}
      {isDataSheetDisplay && (
        <Grid
        container
        pb={5}
        sx={{ borderBottom: "0.5px solid black", paddingLeft: "10px" }}
      >
        <Grid item xs={12} mt={2}>
          <SectionHead>Boat Details</SectionHead>
        </Grid>
      
        <Grid item xs={12} sm={6} md={4}>
          <DataBox>
            <DataText sx={{ width: "53%" }}>Boat Type </DataText>
            <DataText sx={{ color: "#252D3C" }}>
              : {pricingTypeStatus[listingDetails?.pricingType]}{" "}
            </DataText>
          </DataBox>
        </Grid>
      
        <Grid item xs={12} sm={6} md={4}>
          <DataBox>
            <DataText sx={{ width: "53%" }}>Beam Length (feet) </DataText>
            <DataText sx={{ color: "#252D3C" }}>
              : {listingDetails?.beamLength}”{" "}
            </DataText>
          </DataBox>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <DataBox>
            <DataText sx={{ width: "53%" }}>Boat Length (feet)</DataText>
            <DataText sx={{ color: "#252D3C" }}>
              : {listingDetails?.boatLength}”{" "}
            </DataText>
          </DataBox>
        </Grid>
      
        <Grid item xs={12} sm={6} md={4}>
          <DataBox>
            <DataText sx={{ width: "53%" }}>No Of Passengers</DataText>
            <DataText sx={{ color: "#252D3C" }}>
              : {listingDetails?.noOfPassengers}{" "}
            </DataText>
          </DataBox>
        </Grid>
      
        <Grid item xs={12} sm={6} md={4}>
          <DataBox>
            <DataText sx={{ width: "53%" }}>No Of Bathrooms</DataText>
            <DataText sx={{ color: "#252D3C" }}>
              : {listingDetails?.noOfBathrooms}{" "}
            </DataText>
          </DataBox>
        </Grid>
      
        <Grid item xs={12} sm={6} md={4}>
          <DataBox>
            <DataText sx={{ width: "53%" }}>Make </DataText>
            <DataText sx={{ color: "#252D3C" }}>
              : {capitalizeFirstLetter(listingDetails?.make)}{" "}
            </DataText>
          </DataBox>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <DataBox>
            <DataText sx={{ width: "53%" }}>Model </DataText>
            <DataText sx={{ color: "#252D3C" }}>
              : {listingDetails?.model}{" "}
            </DataText>
          </DataBox>
        </Grid>
      
        <Grid item xs={12} sm={6} md={4}>
          <DataBox>
            <DataText sx={{ width: "53%" }}>Country </DataText>
            <DataText sx={{ color: "#252D3C" }}>
              : {capitalizeFirstLetter(listingDetails?.boatAddress?.country)}{" "}
            </DataText>
          </DataBox>
        </Grid>
      
        <Grid item xs={12} sm={6} md={4}>
          <DataBox>
            <DataText sx={{ width: "53%" }}>Fuel Type</DataText>
            <DataText sx={{ color: "#252D3C" }}>
              : {capitalizeFirstLetter(listingDetails?.fuelType)}{" "}
            </DataText>
          </DataBox>
        </Grid>
      
        <Grid item xs={12} sm={6} md={4}>
          <DataBox>
            <DataText sx={{ width: "53%" }}>Year</DataText>
            <DataText sx={{ color: "#252D3C" }}>
              : {listingDetails?.year}{" "}
            </DataText>
          </DataBox>
        </Grid>
      
        {sleepingAccmmodationskey.map((key, ind) => {
          let value = listingDetails?.sleepingAccmmodations?.[key];
          if (value.trim().length > 0 || value > 0) {
            return (
              <Grid item xs={12} sm={6} md={4} key={ind}>
                <DataBox>
                  <DataText sx={{ width: "53%" }}>
                    {sleepingFormatedKey?.[key]}
                  </DataText>
                  <DataText sx={{ color: "#252D3C" }}>
                    : {listingDetails?.sleepingAccmmodations?.[key]}{" "}
                  </DataText>
                </DataBox>
              </Grid>
            );
          }
        })}
      </Grid>
      
      )}

      {/* Extars */}
      {isExtrastDisplay && listingDetails?.extras?.length > 0 && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} mt={2}>
              <SectionHead>Features & Information</SectionHead>
            </Grid>
            {listingDetails?.extras.map((extra, ind) => {
              return (
                <Grid item xs={12} md={8} key={ind}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      src={CheckIcon}
                      alt="Check Icon"
                      style={{
                        width: 15,
                        height: 15,
                        resizeMode: "contain",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    />
                    <DataText sx={{ margin: 1 }}>{extra} </DataText>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}

      {/* Tender and Tpys */}
      {isTenderToysDisplay && listingDetails?.tenderAndToys?.length > 0 && (
        <Grid item xs={12}>
          <Grid container>
          <Grid item xs={12} mt={2}>
              <SectionHead>Toys & Tenders</SectionHead>
            </Grid>
            {!listingDetails?.tenderAndToys?.length % 2 == 0 && (
              <Grid item xs={12}>
                {" "}
              </Grid>
            )}
            {/* Jetskis */}

            {Object.keys(listingDetails?.jetskis)?.length > 0 && (
              <Grid item xs={12} md={4}>
                <Box my={1}>
                  <DataText sx={{ color: "#000" }}> Jetski </DataText>

                  <BulletBox>
                    <CircleIcon sx={{ color: "#000", fontSize: "7px" }} />
                    <DataBox>
                      <DataText className={classes?.toysAndTendersTextStyle}>
                        Make :{" "}
                      </DataText>
                      <DataText className={classes?.dataTextStyle}>
                        {" "}
                        {listingDetails?.jetskis?.make}
                      </DataText>
                    </DataBox>
                  </BulletBox>
                  <BulletBox>
                    <CircleIcon sx={{ color: "#000", fontSize: "7px" }} />
                    <DataBox>
                      <DataText className={classes?.toysAndTendersTextStyle}>
                        Model :{" "}
                      </DataText>
                      <DataText className={classes?.dataTextStyle}>
                        {" "}
                        {listingDetails?.jetskis?.model}
                      </DataText>
                    </DataBox>
                  </BulletBox>
                  <BulletBox>
                    <CircleIcon sx={{ color: "#000", fontSize: "7px" }} />
                    <DataBox>
                      <DataText className={classes?.toysAndTendersTextStyle}>
                        Year :{" "}
                      </DataText>
                      <DataText className={classes?.dataTextStyle}>
                        {" "}
                        {listingDetails?.jetskis?.year}
                      </DataText>
                    </DataBox>
                  </BulletBox>
                  <BulletBox>
                    <CircleIcon sx={{ color: "#000", fontSize: "7px" }} />
                    <DataBox>
                      <DataText className={classes?.toysAndTendersTextStyle}>
                        Quantity :{" "}
                      </DataText>
                      <DataText className={classes?.dataTextStyle}>
                        {" "}
                        {listingDetails?.jetskis?.quantity}
                      </DataText>
                    </DataBox>
                  </BulletBox>
                </Box>
              </Grid>
            )}

            {/* Tenders */}
            {Object.keys(listingDetails?.tenders)?.length > 0 && (
              <Grid item xs={12} md={4}>
                <Box my={1} key={ind}>
                  <DataText sx={{ color: "#000" }}> Tender {ind + 1} </DataText>

                  <BulletBox>
                    <CircleIcon sx={{ color: "#000", fontSize: "7px" }} />
                    <DataBox>
                      <DataText className={classes?.toysAndTendersTextStyle}>
                        Make :{" "}
                      </DataText>
                      <DataText className={classes?.dataTextStyle}>
                        {" "}
                        {listingDetails?.tenders?.make}
                      </DataText>
                    </DataBox>
                  </BulletBox>
                  <BulletBox>
                    <CircleIcon sx={{ color: "#000", fontSize: "7px" }} />
                    <DataBox>
                      <DataText className={classes?.toysAndTendersTextStyle}>
                        Model :{" "}
                      </DataText>
                      <DataText className={classes?.dataTextStyle}>
                        {" "}
                        {listingDetails?.tenders?.model}
                      </DataText>
                    </DataBox>
                  </BulletBox>
                  <BulletBox>
                    <CircleIcon sx={{ color: "#000", fontSize: "7px" }} />
                    <DataBox>
                      <DataText className={classes?.toysAndTendersTextStyle}>
                        Year :{" "}
                      </DataText>
                      <DataText className={classes?.dataTextStyle}>
                        {" "}
                        {listingDetails?.tenders?.year}
                      </DataText>
                    </DataBox>
                  </BulletBox>
                  <BulletBox>
                    <CircleIcon sx={{ color: "#000", fontSize: "7px" }} />
                    <DataBox>
                      <DataText className={classes?.toysAndTendersTextStyle}>
                        Quantity :{" "}
                      </DataText>
                      <DataText className={classes?.dataTextStyle}>
                        {" "}
                        {listingDetails?.tenders?.quantity}
                      </DataText>
                    </DataBox>
                  </BulletBox>
                  <BulletBox>
                    <CircleIcon sx={{ color: "#000", fontSize: "7px" }} />
                    <DataBox>
                      <DataText className={classes?.toysAndTendersTextStyle}>
                        Length :{" "}
                      </DataText>
                      <DataText className={classes?.dataTextStyle}>
                        {" "}
                        {listingDetails?.tenders?.length}
                      </DataText>
                    </DataBox>
                  </BulletBox>
                  <BulletBox>
                    <CircleIcon sx={{ color: "#000", fontSize: "7px" }} />
                    <DataBox>
                      <DataText className={classes?.toysAndTendersTextStyle}>
                        Fuel :{" "}
                      </DataText>
                      <DataText className={classes?.dataTextStyle}>
                        {" "}
                        {listingDetails?.tenders?.fuelType}
                      </DataText>
                    </DataBox>
                  </BulletBox>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      {/* Pricing */}
      {/* <Grid item xs={12} my={3} >
                <SectionHead>Pricing</SectionHead>
                <BroucherPricing
                    listingDetails={listingDetails}
                    currentUser={currentUser}
                    isEditAllow={isEditAllow}
                />
            </Grid> */}

      {/* Avaliability */}
      {/* {isAvaliabilityDisplay && (
        <Grid item xs={12} md={6}>
          <Grid item xs={12} mt={2} mb={3}>
            <SectionHead>Availability</SectionHead>
            <BoatAvalibility
              listingDetails={listingDetails}
              currentUser={currentUser}
              isEditAllow={isEditAllow}
              parentClasses={classes}
            />
          </Grid>
        </Grid>
      )} */}

      {listingDetails?.multipleLocations.length > 0 && (
        <Grid item xs={12}>
          <Grid item xs={12} mt={2} mb={3}>
            <SectionHead>Pick Up Locations</SectionHead>
            <DataBox>
              <DataText width={"70px"}>Address {1} : </DataText>
              <DataText>
                {listingDetails?.boatAddress?.formattedAddress}{" "}
              </DataText>
            </DataBox>

            <MultipleLocation
              listingDetails={listingDetails}
              currentUser={currentUser}
              isEditAllow={isEditAllow}
              parentClasses={classes}
            />
          </Grid>
        </Grid>
      )}

      {isCaptianInfoDisplay &&
        listingDetails?.captianInformation?.length > 0 && (
          <Grid item xs={12} sx={{ borderBottom: "0.5px solid black" }}>
            <SectionHead>Captain Information</SectionHead>
            <Grid container spacing={2}>
              {listingDetails?.captianInformation.map((captian, ind) => {
                let { emailAddress, firstName, lastName, phNo } = captian;
                return (
                  <Grid item xs={12} md={6} key={ind} mt={1} mb={3}>
                    <Typography
                      sx={{
                        color: "#000",
                        // border: "5px solid #0B1A9B",
                        borderRadius: "50%",
                        fontWeight: "bold",
                        // width: "min-content",
                        // padding: "3px 10px",?
                      }}
                      // variant="h6"
                    >
                      Captain {ind + 1}
                    </Typography>
                    {emailAddress && (
                      <DataText>Email : {emailAddress} </DataText>
                    )}
                    {firstName && (
                      <DataText>Name : {firstName + " " + lastName} </DataText>
                    )}
                    {phNo && <DataText>Phone No : {phNo} </DataText>}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}

      {isPhotoAlbumDisplay && (
        <Grid item xs={12} my={2}>
          <SectionHead>Photos</SectionHead>
          <Box className={classes.galleryCntnr} sx={{ mt: 6 }}>
            {listingDetails?.imagesUrls?.map((yatchImg, ind) => {
              return (
                <img
                  className={classes.galleryImg}
                  src={yatchImg}
                  key={ind}
                  alt="Yatch Image"
                />
              );
            })}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default SingleListingDetails;
