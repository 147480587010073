import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/system';


const Btn = styled(Button)(({ theme }) => ({
    background: '#0B1A9B',
    color: 'white',
    padding: '10px 25px',
    textTransform: 'capitalize',
    fontFamily: 'Gilroy Regular, sans-serif !important' ,
    // borderRadius: '10px',
    // fontWeight: 'bold',
    fontSize: '17px',
    '&:hover': {
        transition: '0.2 s',
        background: 'transparent',
        outline: '2px solid white',
    }
}));


const ContainedBtn = ({ text, sx, ...props }) => {
    return (
        <Btn variant="contained" sx={sx} {...props} > {text} </Btn>
    )
}

export default ContainedBtn