import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box,
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import threeUser from "../../../Assets/Icons/threeUser.png"
import show from "../../../Assets/Icons/Show.png"
import Chart from "../../../Assets/Icons/Chart.png"


const useStyles = makeStyles((theme) => ({
    grayBg: {
        backgroundColor: '#F3F3F3',
        borderRadius: '20px'
    },
    textCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    }

}));


const StatsCardStyle = styled(Box)(({ theme, isBlue }) => ({
    backgroundColor: `${isBlue ? '#0B1A9B' : '#191717'}`,
    borderRadius: '20px',
    // maxWidth: '371px',
    margin: 'auto'
}));

const StatsCard = ({ isBlue, head, number, iconImg }) => {
    const classes = useStyles();

    return (
        <StatsCardStyle isBlue={isBlue} p={5} >
            <Box className={classes.textCntnr}  >
                <Typography variant='h5' sx={{ marginBottom: { xs: '10px', md: '20px' }, color: 'white' }} >
                    {head}
                </Typography>
                <img src={iconImg} alt="Icon" style={{ width: '28px', height: '28px' }} />
            </Box>
            <Typography
                sx={{
                    color: 'white',
                    fontSize: { xs: '2rem', ms: '3.75rem' }
                }}
            > {number} </Typography>
        </StatsCardStyle>
    )
}

const StatsView = () => {
    return (
        <Grid container spacing={4} mt={1} >
            <Grid item xs={12} nineS={6}   >
                <StatsCard isBlue={true} iconImg={threeUser} number='92,680' head='Impressions' />
            </Grid>
            <Grid item xs={12} nineS={6}   >
                <StatsCard isBlue={false} iconImg={show} number='580.5K' head='Views' />
            </Grid>
            <Grid item xs={12} nineS={6}   >
                <StatsCard isBlue={false} iconImg={Chart} number='15' head='Listing Click through Rate' />
            </Grid>
            <Grid item xs={12} nineS={6}   >
                <StatsCard isBlue={false} iconImg={Chart} number='4' head='Total Connections' />
            </Grid>
            <Grid item xs={12} nineS={6}   >
                <StatsCard isBlue={false} iconImg={Chart} number='2' head='Total  Active  Listings' />
            </Grid>
            <Grid item xs={12} nineS={6}   >
                <StatsCard isBlue={false} iconImg={Chart} number='4' head='Condition New Listings' />
            </Grid>
            <Grid item xs={12} nineS={6}   >
                <StatsCard isBlue={false} iconImg={Chart} number='4' head='Condition Used Listings' />
            </Grid>
        </Grid>
    )
}

const AllListings = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.grayBg} sx={{ padding: { xs: '20px', ms: '40px' } }}  >
            <Grid item xs={12} >
                <Typography variant='h5' sx={{ marginBottom: { xs: '10px' }, fontWeight: 'bold', }} >
                    Custom All Listings
                </Typography>
                <Typography sx={{ color: '#585F80' }} >January 18, 2022  to February 15, 2022</Typography>
            </Grid>
            <Grid item xs={12} >
                <StatsView />
            </Grid>
        </Grid>
    )
}

export default AllListings