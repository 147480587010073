import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';


import BannerWithText from '../../../Components/BannerWithText';
import sendIcon from "../../../Assets/Icons/sendIcon.png"
import ContainedBtn from '../../../Components/ContainedBtn';
import circleCheck from "../../../Assets/Icons/circle-check.png"
import AboutUsImg from "../../../Assets/Images/AboutUs.png"
import SectionHead from '../../../Components/SectionHead';
import AboutUS02 from '../../../Assets/Images/AboutUS02.png'




const useStyles = makeStyles((theme) => ({
    bannerTwo: {
        marginTop: '60px !important',
        [theme.breakpoints.down("sevenHalfS")]: {
            marginTop: '40px !important',
        },
    },
    circleCheckIcon: {
        width: '24px',
        height: '24px',
        marginRight: '17px'
    }
}));


const HeroSectionText = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    lineHeight: '20px',
    [theme.breakpoints.down("nineS")]: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    letterSpacing: '1px',
    marginTop: '-8px',
    fontFamily: 'Gilroy Regular, sans-serif !important'
}));

const RightSide = (props) => {
    const classes = useStyles();

    return (
        <Grid container  >
            <Grid item xs={12} sx={{ margin: { lg: '24px 0px 5px 0px', md: '20px 0px 5px 0px', xs: '0px 0px' } }} >
                <SectionHead title='About Us' sx={{ textAlign: { sevenHalfS: 'left', xs: 'center' } }} />
            </Grid>
            <Grid item xs={12} sx={{ margin: { lg: '24px 0px', md: '20px 0px', xs: '16px 0px',}, display: { xs: 'block', sevenHalfS: 'none', nineS: 'block' } }} >
                <HeroSectionText   >
                    Rent by Broker is a broker to broker luxury rental marketplace with tools to help drive your sales,
                    provide tools for your business to succeed, and gives us (the brokers) a lot more freedom and convenience.
                </HeroSectionText>
            </Grid>
            <Grid item xs={12} sx={{ margin: { lg: '24px 0px', md: '20px 0px', xs: '16px 0px',} }} >
                <Box sx={{ display: 'flex' }} >
                    <img src={circleCheck} alt="Icon" className={classes.circleCheckIcon} />
                    <HeroSectionText>
                        65 happy clients and growing rapidly with the expansion into several new luxury rental marketplaces
                    </HeroSectionText>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ margin: { lg: '24px 0px', md: '20px 0px', xs: '16px 0px',} }} >
                <Box sx={{ display: 'flex' }} >
                    <img src={circleCheck} alt="Icon" className={classes.circleCheckIcon} />
                    <HeroSectionText>
                        18 years of collective experience between our team and our brokers
                    </HeroSectionText>
                </Box>
            </Grid>
            <Grid item xs={12} my={2} >
                <ContainedBtn
                    text='Become a Member'
                    sx={{ '&:hover': { color: '#0B1A9B', outline: '1px solid #0B1A9B' } }}
                    endIcon={<img src={sendIcon} style={{ width: '14px', height: '14px', }} alt="icon" />}
                />
            </Grid>
        </Grid>
    )
}


const AboutUs = () => {
    const classes = useStyles();

    return (
        <>
            <Container maxWidth='fl'>
                <Grid container >
                    <Grid item xs={12} className={classes.bannerTwo} >
                        <BannerWithText image={AboutUS02} TextComp={<RightSide />} reverse={true} />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default AboutUs