import {
    Typography,
    Grid,
    Box,
    TextField,
    MenuItem,
    InputAdornment
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    roundGrayBG: {
        backgroundColor: '#F3F3F3',
        borderRadius: '10px'
    },
    checkBoxCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    outlinedBtn: {
        border: '1px solid #0B1A9B !important',
        color: '#0B1A9B !important',
        background: '#fff !important',
        fontSize: '17px',
        fontWeight: 'bold'
    },
}));


const TermCharter = ({ onChangeListingCredentials, listingCredentials }) => {
    const classes = useStyles();

    let commissionPercentages = [
        {
            name: '5%',
            value: 5
        },
        {
            name: '10%',
            value: 10
        },
        {
            name: '15%',
            value: 15
        },
        {
            name: '20%',
            value: 20
        }
    ]


    return (
        <Box className={classes.roundGrayBG} p={2} >
            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Typography variant="h5" sx={{ fontFamily: 'Gilroy Regular, sans-serif ', }} >Term Charter Pricing</Typography>
                </Grid>

                <Grid item xs={12} md={6} >
                    <TextField
                        id="outlined-select-currency"
                        select
                        required={true}
                        label="Minimum Days needed to book"
                        fullWidth
                        onChange={(e) => {
                            let limit = e.target.value;
                            // onChangeListingCredentials("termCharter", { ...listingCredentials.termCharter, minimumDaysBeBooked: limit })

                            let pricingData = {}
                            for (let index = 1; index <= limit; index++) {
                                pricingData[index] = ''
                            }
                            pricingData = { ...pricingData }
                            onChangeListingCredentials("termCharter", {
                                ...listingCredentials.termCharter,
                                minimumDaysBeBooked: limit,
                                pricing: pricingData
                            })
                        }}
                        value={listingCredentials.termCharter.minimumDaysBeBooked}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: 200 }, // Set the maximum height here
                                },
                            },
                        }}
                    >
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((val) => (
                            <MenuItem key={val} value={val} sx={{ textTransform: 'capitalize' }} >
                                {val}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} >
                    <TextField
                        id="outlined-select-currency"
                        select
                        required={true}
                        label="Commission Percentage To Broker"
                        fullWidth
                        onChange={(e) => {
                            let percentage = e.target.value;
                            // onChangeListingCredentials("termCharter", { ...listingCredentials.termCharter, minimumDaysBeBooked: limit })

                            // let pricingData = {}
                            // for (let index = 1; index <= limit; index++) {
                            //     pricingData[index] = ''
                            // }
                            // pricingData = { ...pricingData }
                            onChangeListingCredentials("termCharter", {
                                ...listingCredentials.termCharter,
                                commissionPercentage: percentage
                            })
                        }}
                        value={listingCredentials.termCharter.commissionPercentage}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: 200 }, // Set the maximum height here
                                },
                            },
                        }}
                    >
                        {commissionPercentages.map((val) => (
                            <MenuItem key={val} value={val.value} sx={{ textTransform: 'capitalize' }} >
                                {val.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} >
                {
                    Object.keys(listingCredentials.termCharter.pricing).map((val, ind) => {
                        return (
                            <Grid item xs={12} md={6} key={ind} >
                                {/* <Typography>{val} {val == 1 ? 'Day' : 'Days'} Pricing</Typography> */}
                                <TextField
                                    id="outlined-basic"
                                    label={val + ` ${val == 1 ? 'Day' : 'Days'} ` + 'Pricing'}
                                    variant="outlined"
                                    required={true}
                                    type="number"
                                    fullWidth
                                    placeholder={val + ` ${val == 1 ? 'Days' : 'Days'} ` + 'Pricing'}
                                    name="price"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        const inputValue = e.target.value.replace(/^0+/, '');
                                        if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                            let pricing = listingCredentials.termCharter.pricing || {}
                                            pricing = { ...pricing, [val]: e.target.value }
                                            onChangeListingCredentials("termCharter", { ...listingCredentials.termCharter, pricing: pricing })
                                        }
                                    }}
                                    value={listingCredentials.termCharter?.pricing[val]}
                                />
                            </Grid>
                        )
                    })
                }

            </Grid>
        </Box>
    )
}

export default TermCharter