import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import bannerTwo from "../../../Assets/Images/homeBannerTwo.png"
import BannerWithText from '../../../Components/BannerWithText';
import sendIcon from "../../../Assets/Icons/sendIcon.png"
import ContainedBtn from '../../../Components/ContainedBtn';
import circleCheck from "../../../Assets/Icons/circle-check.png"



const useStyles = makeStyles((theme) => ({

    logoWrapper: {
        display: 'flex',
        // flexWrap: 'wrap',
        flexDirection: 'row',
        // justifyContent: 'center',
        // padding: '0px 10px',
        height: 'fit-content',
        marginBottom: '15px',
        // backgroundColor:'red'
    },
    widthCntrlr: {
        width: '100%',
        // minWidth: '800px',
        display: 'flex',
        justifyContent: 'start',
        // backgroundColor:'yellow',

        flexWrap: 'wrap',
        [theme.breakpoints.down("md")]: {
            width: '100%',
            minWidth: '0px',
        },
        [theme.breakpoints.down("m")]: {
            width: '100%',
            minWidth: '0px',
            flexWrap: 'wrap'
        },

    },
    logoImg: {
        maxWidth: '20%',
        width: '113px',
        height: 'auto',
        margin: 'auto',
        // border:'1px solid black',
        [theme.breakpoints.down("md")]: {
            maxWidth: '30%',
            margin: 'auto',
        },
        [theme.breakpoints.down("m")]: {
            maxWidth: '50%',
            margin: 'auto',
        },
    },
    bannerTwo: {
        marginTop: '60px !important',
        [theme.breakpoints.down("sevenHalfS")]: {
            marginTop: '40px !important',
        },
    },
    circleCheckIcon: {
        width: '24px',
        height: '24px',
        marginRight: '17px'
    }
}));




const HeroSectionText = styled(Typography)(({ theme }) => ({
    fontSize: '25px',
    lineHeight: '30px',
    // color: 'white',
    // maxWidth: '640px',
    // marginBottom: '30px',
    // marginTop: '20px',
    [theme.breakpoints.down("lg")]: {
        fontSize: '22px',
        lineHeight: '25px',
    },
    [theme.breakpoints.down("md")]: {
        fontSize: '20px',
        lineHeight: '25px',
    },
    [theme.breakpoints.down("nineS")]: {
        fontSize: '16px',
        lineHeight: '21px',
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: '16px',
        lineHeight: '25px',
    },
    [theme.breakpoints.down("ms")]: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    letterSpacing: '1px',
    marginTop: '-8px'
}));

const RightSide = () => {
    return (
        <Grid container  >
            <Grid item xs={12} mt={5} >
                <Typography
                    // mb={3}
                    sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: 'xx-large', xs: '30px', sm: '40px ', sevenHalfS: '25px', nineS: '30px', md: '35px', lg: '40px' },
                        marginBottom: { xs: '24px', sevenHalfS: '15px', md: '24px' },
                        fontFamily: 'Obadiah Pro, sans-serif'
                    }}
                > What is Lorem Ipsum? </Typography>
                <Typography
                    // mb={6}
                    sx={{
                        letterSpacing: '1px',
                        marginBottom: { xs: '24px', sevenHalfS: '15px', lg: '48px', md: '40px' },
                        fontFamily: 'Gilroy Regular, sans-serif !important'
                    }} >
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                    as opposed to using 'Content here,  making it look like readable English.
                    and a search for 'lorem ipsum' will uncover many web sites still in their infancy.
                </Typography>

            </Grid>
            <Grid item xs={12}  >
                <ContainedBtn
                    text='Become a Member'
                    sx={{ '&:hover': { color: '#0B1A9B', outline: '1px solid #0B1A9B' } }}
                    endIcon={<img src={sendIcon} style={{ width: '14px', height: '14px', }} alt="icon" />}
                />
            </Grid>
        </Grid>
    )
}

const BannerTwo = (props) => {
    const classes = useStyles();
    // const { classes } = props;


    return (
        <div>
            <Container maxWidth='fl'   >
                <Grid container my={5} >
                    <Grid item xs={12} className={classes.bannerTwo} >
                        <BannerWithText image={bannerTwo} TextComp={<RightSide />} />
                    </Grid>
                </Grid>
                <Grid container my={5} >
                    <Grid item xs={12} className={classes.bannerTwo} >
                        <BannerWithText image={bannerTwo} TextComp={<RightSide />} reverse={true} />
                    </Grid>
                </Grid>
                <Grid container my={5} >
                    <Grid item xs={12} className={classes.bannerTwo} >
                        <BannerWithText image={bannerTwo} TextComp={<RightSide />} />
                    </Grid>
                </Grid>
                <Grid container my={5} >
                    <Grid item xs={12} className={classes.bannerTwo} >
                        <BannerWithText image={bannerTwo} TextComp={<RightSide />} reverse={true} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default BannerTwo