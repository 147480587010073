import React from 'react'
import {
} from "@mui/material";
// import { makeStyles,  } from "@mui/styles";
import BannerOne from './Components/BannerOne';
import BannerTwo from './Components/BannerTwo';
import AboutUs from './Components/AboutUs';
import Services from './Components/Services';
import Industries from './Components/Industries';
import Features from './Components/Features';
import Team from './Components/Team';
import Testimonal from './Components/Testimonal';
import Virtual from './Components/Virtual';
import Footer from '../../Components/Footer';


// const useStyles = makeStyles((theme) => ({

// }));





const Home = () => {
    // const classes = useStyles();

    return (
        <>
            <BannerOne />
            <BannerTwo/>
            <AboutUs/>
            <Services/>
            <Industries/>
            <Features/>
            <Team/>
            <Testimonal/>
            <Virtual/>
        </>
    )
}

export default Home