import React from 'react'
import {
    Grid,
} from "@mui/material"
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    bannerImage: {
        width: '100%',
        height: 'auto'
    },
    bannerTopMargin: {
        marginTop: '0px !important',
        [theme.breakpoints.down("sevenHalfS")]: {
            marginTop: '20px !important',
        },
    },
    none:{

    }
}));

const BannerWithText = ({ image, TextComp, reverse }) => {
    const classes = useStyles();


    return (
        <Grid container  >
            <Grid item xs={12} sevenHalfS={6} md={6} order={reverse ? 3 : 1} className={reverse ? classes.bannerTopMargin : classes.none} >
                <img src={image} className={classes.bannerImage} alt="Banner Image" />
            </Grid>
            <Grid item xs={0} sevenHalfS={1} md={1} order={2}  ></Grid>
            <Grid item xs={12} sevenHalfS={5} md={5} className={reverse ? classes.none : classes.bannerTopMargin} order={reverse ? 1 : 3}  >
                {TextComp}
            </Grid>
        </Grid>
    )
}

export default BannerWithText