import {
  SET_USER,
  SET_USER_LOADING,
  SET_ERROR,
  SET_LOGOUT,
  SET_USER_AUTH,
  SET_USER_TEAM,
  SET_USER_TEAMS_UPDATING,
  SET_LISTING_BROKER,
} from "./UserTypes";
import Swal from "sweetalert2";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  getDoc,
  getDocFromCache,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../../FireBase/firebase";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  signOut,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";

export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const setListingBroker = (broker) => {
  return {
    type: SET_LISTING_BROKER,
    payload: broker,
  };
};

export const setUserLoading = (loading) => {
  return {
    type: SET_USER_LOADING,
    payload: loading,
  };
};

export const setError = (error) => {
  return {
    type: SET_ERROR,
    payload: error,
  };
};

export const setLogout = () => {
  return {
    type: SET_LOGOUT,
  };
};

export const setUserAuth = (isAuth) => {
  return {
    type: SET_USER_AUTH,
    payload: isAuth,
  };
};

export const setUserTeam = (team) => {
  return {
    type: SET_USER_TEAM,
    payload: team,
  };
};

export const setTeamUpdating = (updating) => {
  return {
    type: SET_USER_TEAMS_UPDATING,
    payload: updating,
  };
};

export const registerUser = (docData, navigate) => async (dispatch) => {
  try {
    dispatch(setUserLoading(true));

    let user = null;
    let { email, password, firstName, lastName, phoneNo, accountType } =
      docData;

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        user = userCredential.user;
        dispatch(setUser(user));
        dispatch(setUserAuth(true));
        // localStorage.setItem("uid", user.uid); // For Few Time
        // localStorage.setItem("accountType", accountType); // For Few Time
        logOutUser(navigate)(dispatch);
        // Send email verification
        await sendEmailVerification(user);
        // console.log(user, "user")
        const docRef = await setDoc(doc(db, "users", user.uid), {
          firstName,
          lastName,
          email,
          phoneNo,
          uid: user.uid,
          accountType,
        });
        await updateProfile(user, {
          displayName: firstName, // Replace 'displayName' with the actual display name you want to set
          // displayName: `${firstName}|${accountType}`,
        });
        // console.log("Document written with ID: ", docRef);

        dispatch(setUserLoading(false));
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          background: "#08A73D",
          iconColor: "white",
          color: "white",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        await Toast.fire({
          icon: "success",
          // title: `${email} Registered`,
          title: "Email Verification Sent! Please verify your email address.",
        });
        // navigate("/login");

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // console.log(errorMessage, "errorMessage")
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          background: "#F62424",
          iconColor: "white",
          color: "white",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "error",
          title: `${errorCode}`,
        });
        dispatch(setUserLoading(false));
        // return null
        // ..
      });
    // dispatch(setUserLoading(false));
  } catch (err) {
    dispatch(setUserLoading(false));
    dispatch(setError(err));
    // console.log(err, "registerUser")
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      background: "#F62424",
      iconColor: "white",
      color: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: "error",
      title: `${err}`,
    });
    return null;
  }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    // dispatch(setUserLoading(true));
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        // console.log(user, "getCurrentUser")
        let currentUser = { ...user };
        const uid = user.uid;

        const docRef = doc(db, "users", uid);
        const userSnap = await getDoc(docRef);
        // const userSnap = await getDocFromCache(docRef);
        // console.log(currentUser, "currentUser")
        if (userSnap.exists()) {
          // console.log(userSnap.data(), "userSnap")
          currentUser = {
            ...currentUser,
            ...userSnap.data(),
          };
          localStorage.setItem("accountType", userSnap.data()?.accountType);
          // Document exists, you can safely access its data
        } else {
          // Document doesn't exist locally; consider fetching it from the server
        }

        localStorage.setItem("uid", uid);
        dispatch(setUser(currentUser));
        dispatch(setUserAuth(true));
        dispatch(setUserLoading(false));
        return currentUser;

        // ...
      } else {
        // User is signed out
        // ...
        localStorage.clear();
        dispatch(setUserAuth(false));
        return false;
        // console.log('signOut')
      }
    });
  } catch (err) {
    dispatch(setUserLoading(false));
    dispatch(setError(err));
    // console.log(err, "registerUser")
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      background: "#F62424",
      iconColor: "white",
      color: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: "error",
      title: `${err}`,
    });
    return null;
  }
};

export const logOutUser = (navigate) => async (dispatch) => {
  try {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
        dispatch(setUserAuth(false));
        dispatch(setUser({}));
        localStorage.clear();
      })
      .catch((error) => {
        // An error happened.
      });
  } catch (err) {
    dispatch(setUserLoading(false));
    dispatch(setError(err));
    // console.log(err, "registerUser")
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      background: "#F62424",
      iconColor: "white",
      color: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: "error",
      title: `${err}`,
    });
  }
};
//Resend Verification Email
export const resendVerificationEmail =
  (userCredential, navigate) => async (dispatch) => {
    try {
      dispatch(setUserLoading(true));
      let { email, password } = userCredential;

      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          // ...
          if (user.emailVerified === false) {
            // If email is not verified, sign out the user and show an error message
            await auth.signOut();
            // Send email verification
            await sendEmailVerification(user);
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              background: "#08A73D",
              iconColor: "white",
              color: "white",
              customClass: {
                popup: "colored-toast",
              },
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            await Toast.fire({
              icon: "success",
              // title: `${email} Registered`,
              title:
                "Email Verification Sent! Please verify your email address.",
            });
          }

          // Check if the user's email is verified

          // Proceed with logging in if email is verified
        })
        .catch((error) => {
          const errorCode =
            error.code === "auth/invalid-login-credentials"
              ? "Invalid login credentials. Please check your information and try again."
              : error.code;
          // const errorMessage = error.message;
          // console.log(error?.code, "errorMessage");
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            background: "#F62424",
            iconColor: "white",
            color: "white",
            customClass: {
              popup: "colored-toast",
            },
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          Toast.fire({
            icon: "error",
            title: `${errorCode}`,
          });
        });
    } catch (err) {
      dispatch(setUserLoading(false));
      dispatch(setError(err));
      // console.log(err, "registerUser")
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        background: "#F62424",
        iconColor: "white",
        color: "white",
        customClass: {
          popup: "colored-toast",
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      Toast.fire({
        icon: "error",
        title: `${err}`,
      });
    }
  };

export const resetPassword = (userCredential) => async (dispatch) => {
  try {
    let { email } = userCredential;

    console.log(email, "email");
    dispatch(setUserLoading(true));
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        dispatch(setUserLoading(false));
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          background: "#08A73D",
          iconColor: "white",

          color: "white",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "success",
          title: `Password reset email sent to ${email}`,
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // console.log(errorCode, errorMessage, "errorMessage")
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          background: "#F62424",

          iconColor: "white",
          color: "white",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "error",
          title: `${errorCode}`,
        });
        dispatch(setUserLoading(false));
      });
  } catch (err) {
    dispatch(setUserLoading(false));
    dispatch(setError(err));
  }
};

export const logInUser = (userCredential, navigate) => async (dispatch) => {
  try {
    dispatch(setUserLoading(true));

    let { email, password } = userCredential;

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        console.log(user?.emailVerified, "user");
        // Check if the user's email is verified
        if (user.emailVerified === false) {
          // If email is not verified, sign out the user and show an error message
          await auth.signOut();
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            background: "#F62424",
            iconColor: "white",
            color: "white",
            customClass: {
              popup: "colored-toast",
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          Toast.fire({
            icon: "error",
            title: `Please verify your email address before logging in.`,
          });
        }
        // Proceed with logging in if email is verified
        dispatch(setUser(user));
        dispatch(getCurrentUser()).then((res) => {
          if (res) {
            localStorage.setItem("uid", user.uid);
            navigate("/mls");
            dispatch(setUserLoading(false));
          }
        });
      })
      .catch((error) => {
        const errorCode =
          error.code === "auth/invalid-login-credentials"
            ? "Invalid login credentials. Please check your information and try again."
            : error.code;
        // const errorMessage = error.message;
        // console.log(error?.code, "errorMessage");
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          background: "#F62424",
          iconColor: "white",
          color: "white",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "error",
          title: `${errorCode}`,
        });
        dispatch(setUserLoading(false));
      });
  } catch (err) {
    dispatch(setUserLoading(false));
    dispatch(setError(err));
    // console.log(err, "registerUser")
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      background: "#F62424",
      iconColor: "white",
      color: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: "error",
      title: `${err}`,
    });
  }
};

export const UpdateUser = (userCredential) => async (dispatch) => {
  try {
    console.log(userCredential, "userCredential");
    // dispatch(setUserLoading(true));
    const uid = localStorage.getItem("uid");

    const currentUserRef = doc(db, "users", uid);

    await updateDoc(currentUserRef, {
      ...userCredential,
    });

    dispatch(getCurrentUser());

    dispatch(setUserLoading(false));
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      background: "#08A73D",
      iconColor: "white",
      color: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });
    await Toast.fire({
      icon: "success",
      title: `Prrofile Updated`,
    });
  } catch (err) {
    dispatch(setUserLoading(false));
    dispatch(setError(err));
    console.log(err, "UpdateUser");
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      background: "#F62424",
      iconColor: "white",
      color: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: "error",
      title: `${err}`,
    });
  }
};

export const getUserTeam = () => async (dispatch) => {
  try {
    dispatch(setTeamUpdating(true));
    let team = [];

    const uid = localStorage.getItem("uid");

    const unsub = onSnapshot(doc(db, "teams", uid), (doc) => {
      if (doc.data()) {
        team = [];
        team = doc.data()?.team;
        team = team?.filter((val) => !val?.deleted);
        // console.log("Current data: ", team);
      }

      dispatch(setUserTeam(team));
    });

    dispatch(setTeamUpdating(false));

    // return true
  } catch (err) {
    dispatch(setTeamUpdating(false));
    dispatch(setError(err));
    console.log(err, "getUserTeam");
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      background: "#F62424",
      iconColor: "white",
      color: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: "error",
      title: `${err}`,
    });
    return null;
  }
};

export const addTeamMember = (data) => async (dispatch) => {
  try {
    const uid = localStorage.getItem("uid");

    const teamRef = doc(db, "teams", uid);
    const teamSnap = await getDoc(teamRef);

    if (teamSnap.exists()) {
      let team = teamSnap.data()?.team;
      let id = team?.length + 1;

      setDoc(
        teamRef,
        { team: [...team, { ...data, id: id }] },
        { merge: true }
      );
    } else {
      setDoc(teamRef, { team: [{ ...data, id: 1 }] }, { merge: true });
    }

    // console.log("Document written with ID: ", teamRef.id);

    return true;
  } catch (err) {
    dispatch(setError(err));
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      background: "#F62424",
      iconColor: "white",
      color: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: "error",
      title: `${err}`,
    });
    return null;
  }
};

export const deleteTeamMember = (id) => async (dispatch) => {
  try {
    const uid = localStorage.getItem("uid");

    const teamDocRef = doc(db, "teams", uid);

    const teamSnap = await getDoc(teamDocRef);
    if (teamSnap.exists()) {
      let team = teamSnap.data()?.team;

      updateDoc(teamDocRef, {
        team: team.map((item) =>
          item.id === id ? { ...item, deleted: true } : item
        ),
      });
    }

    return true;
  } catch (err) {
    dispatch(setError(err));
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      background: "#F62424",
      iconColor: "white",
      color: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: "error",
      title: `${err}`,
    });
    return null;
  }
};

export const getListingBroker = (brokerID) => async (dispatch) => {
  try {
    const docRef = doc(db, "users", brokerID);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const user = docSnapshot.data();
      dispatch(setListingBroker(user));
    }

    return true;
  } catch (err) {
    dispatch(setError(err));
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      background: "#F62424",
      iconColor: "white",
      color: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    Toast.fire({
      icon: "error",
      title: `${err}`,
    });
    return null;
  }
};
