import React from 'react';
import Routes from '../Routes/Routes';


const Layout = () => {


    return (
        <React.Fragment>
            <Routes />
        </React.Fragment>
    )
}

export default Layout