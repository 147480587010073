import React from 'react'
import {
    Grid,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";

import signupSideImg from "../../Assets/Images/signupSideImg.png";
import SignupForm from './Components/SignupForm';

import { useSelector, useDispatch } from 'react-redux'
import { Navigate } from "react-router-dom"
import '../../App.css';


const useStyles = makeStyles((theme) => ({
    sideImage: {
        width: '100%',
        height: 'auto',
        maxHeight: '100vh',
    }
}));


const Signup = () => {
    const classes = useStyles();

    const userAuth = useSelector((state) => state.user.userAuth)
    const user = useSelector((state) => state.user.user)
    const uid = localStorage.getItem('uid')



    if (userAuth || uid) {
        return <Navigate to='/mls' />
    }
    return (
        <>
            <Grid container sx={{ backgroundColor: '#F5F5F5',fontFamily: 'Obadiah Pro, sans-serif' }} >
                <Grid item xs={0} lg={6} sx={{ display: { xs: 'none', lg: 'block' } }}  >
                    <Box sx={{ position: 'fixed', width: '50%' }} >
                        <img src={signupSideImg} alt="Side Banner" className={classes.sideImage} />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ padding: { xs: '10px', nineS: '0px 90px 00px 90px' } ,fontFamily: 'Obadiah Pro, sans-serif'}} >
                    <SignupForm />
                </Grid>
            </Grid>
        </>
    )
}

export default Signup