import {
    SET_ALL_PLANS,
    SET_LOADING,
    SET_ERROR
} from "./PlanTypes";


const initialState = {
    loading: false,
    updating: false,
    error: null,
    allPlans: []
};


const planReducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_ALL_PLANS:
            return {
                ...state,
                allPlans: payload,
                updating: false,
                loading: false,
            };
        case SET_ERROR:
            return {
                ...state,
                error: payload,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: payload,
                error: null,
            };
        default:
            return state;
    }
};

export default planReducer;