import {
    SET_ALL_LISTINGS,
    SET_LOADING,
    SET_ERROR,
    SET_ALL_MY_LISTINGS,
    SET_LISTINGS_EXTRAS,
    SET_LISTINGS_TENDERS_TOYS,
    SET_LISTINGS_BOAT_MAKES,
    SET_MARKUP_PRICES
} from "./ListingTypes";
import Swal from "sweetalert2";
import { db } from "../../FireBase/firebase";
import { collection, doc, setDoc, getDocs, getDoc, addDoc, deleteDoc, query, where, onSnapshot, updateDoc, arrayUnion, serverTimestamp, increment } from "firebase/firestore";

// for mls Filter
import {
    SET_PRICE_RANGE,
    SET_PASSENGERS_RANGE,
    SET_BOAT_LENGTH_RANGE,
    SET_BOAT_MAKE,
    SET_COUNTRY,
    SET_COUNTRY_CODE,
    SET_BOAT_YEAR,
    SET_BOAT_PRICING_TYPE,
    SET_FILTERS_ENABLES,
    SET_DAY_CHARTER_MINIMUM_HOUR,
    SET_STATE,
    SET_STATE_CODE,
    SET_CITY
} from "./ListingTypes";

// for search filter
import {
    SET_SEARCH_COUNTRY,
    SET_SEARCH_FORMATTEDADDRESS,
    SET_SEARCH_LATLNG,
    SET_BOAT_SEARCH
} from "./ListingTypes";


// For MLS Filters

export const setmlsFilterEnables = (isEnable) => {
    return {
        type: SET_FILTERS_ENABLES,
        payload: isEnable,
    };
};

export const setPriceRange = (range) => {
    return {
        type: SET_PRICE_RANGE,
        payload: range,
    };
};

export const setPassengerRange = (range) => {
    return {
        type: SET_PASSENGERS_RANGE,
        payload: range,
    };
};

export const setBoatLengthRange = (range) => {
    return {
        type: SET_BOAT_LENGTH_RANGE,
        payload: range,
    };
};

export const setBoatMake = (make) => {
    return {
        type: SET_BOAT_MAKE,
        payload: make,
    };
};

export const setCountry = (country) => {
    return {
        type: SET_COUNTRY,
        payload: country,
    };
};

export const setCountryCode = (countryCode) => {
    return {
        type: SET_COUNTRY_CODE,
        payload: countryCode,
    };
};

export const setCountryState = (state) => {
    return {
        type: SET_STATE,
        payload: state,
    };
};

export const setCountryStateCode = (stateCode) => {
    return {
        type: SET_STATE_CODE,
        payload: stateCode,
    };
};

export const setCountryStateCity = (city) => {
    return {
        type: SET_CITY,
        payload: city,
    };
};

export const setBoatYear = (year) => {
    return {
        type: SET_BOAT_YEAR,
        payload: year,
    };
};

export const setBoatPricingType = (pricingType) => {
    return {
        type: SET_BOAT_PRICING_TYPE,
        payload: pricingType,
    };
};

export const setDayCharterMinimumHours = (hours) => {
    return {
        type: SET_DAY_CHARTER_MINIMUM_HOUR,
        payload: hours,
    };
};

// For MLS Filters End

// For Boat Address Search Start

export const setBoatSearchCountry = (country) => {
    return {
        type: SET_SEARCH_COUNTRY,
        payload: country,
    };
};

export const setBoatSearchFormattedAddress = (formattedAddress) => {
    return {
        type: SET_SEARCH_FORMATTEDADDRESS,
        payload: formattedAddress,
    };
};

export const setBoatSearchLatLng = (latLng) => {
    return {
        type: SET_SEARCH_LATLNG,
        payload: latLng,
    };
};

export const setBoatSearch = (searchs) => {
    return {
        type: SET_BOAT_SEARCH,
        payload: searchs,
    };
};



// For Boat Address Search End

export const setListingLoading = (loading) => {
    return {
        type: SET_LOADING,
        payload: loading,
    };
};


export const setError = (error) => {
    return {
        type: SET_ERROR,
        payload: error,
    };
};



export const setListingExtras = (extras) => {
    return {
        type: SET_LISTINGS_EXTRAS,
        payload: extras
    };
};


export const setListingTenderAndToys = (tendersAndToys) => {
    return {
        type: SET_LISTINGS_TENDERS_TOYS,
        payload: tendersAndToys
    };
};

export const setListingBoatMakes = (makes) => {
    return {
        type: SET_LISTINGS_BOAT_MAKES,
        payload: makes
    };
};


export const setAllListings = (listings) => {
    return {
        type: SET_ALL_LISTINGS,
        payload: listings
    };
};

export const setAllMYListings = (listings) => {
    return {
        type: SET_ALL_MY_LISTINGS,
        payload: listings
    };
};


export const setAllMarkUpPrices = (markUpPrices) => {
    return {
        type: SET_MARKUP_PRICES,
        payload: markUpPrices
    };
};


export const createUserListing = (docData) => async (dispatch) => {
    try {


        // const docRef = await addDoc(doc(db, "listings"), docData);
        const docRef = await addDoc(collection(db, "listings"), { ...docData, createdAt: serverTimestamp() });

        // console.log("Document written with ID: ", docRef.id);

        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#08A73D',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true
        })
        await Toast.fire({
            icon: 'success',
            title: `Listing Added Sucessfully`
        })
        return true
    }
    catch (err) {

        dispatch(setError(err));
        console.log(err, "createUserListing")
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#F62424',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
        })
        Toast.fire({
            icon: 'error',
            title: `${err}`
        })
        return null
    }

}


export const getAllListing = () => async (dispatch) => {
    try {
        dispatch(setListingLoading(true));
        let listing = []

        const unsub = onSnapshot(collection(db, "listings"), (snapshot) => {
            listing = []
            snapshot.forEach((doc) => {
                // console.log("Current data: ", doc.data(), doc.id);
                // listing.push({ ...doc.data(), id: doc.id })
                if (doc.data()?.active)
                    listing = [...listing, { ...doc.data(), id: doc.id }]
            });
            dispatch(setAllListings(listing))
            dispatch(setListingLoading(false))
        });

        // const querySnapshot = await getDocs(collection(db, "listings"));
        // querySnapshot.forEach((doc) => {
        //     // doc.data() is never undefined for query doc snapshots
        //     // console.log(doc.id, " => ", doc.data());
        //     listing.push({ ...doc.data(), id: doc.id })
        // });

        // dispatch(setListingLoading(false))

        // return true
    }
    catch (err) {
        dispatch(setListingLoading(false));
        dispatch(setError(err));
        console.log(err, "getAllListing")
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#F62424',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
        })
        Toast.fire({
            icon: 'error',
            title: `${err}`
        })
        return null
    }

}


export const getAllMYListing = (uid) => async (dispatch) => {
    try {
        dispatch(setListingLoading(true));
        let listing = []

        const q = query(collection(db, "listings"), where("uid", "==", uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            listing = [];
            querySnapshot.forEach((doc) => {
                listing.push({ ...doc.data(), id: doc.id });
            });
            dispatch(setAllMYListings(listing))
            dispatch(setListingLoading(false));
        });
        dispatch(setListingLoading(false));


        // const querySnapshot = await getDocs(
        //     query(collection(db, "listings"), where("uid", "==", uid))
        // );

        // querySnapshot.forEach((doc) => {
        //     listing.push({ ...doc.data(), id: doc.id })
        // });

        // await dispatch(setAllMYListings(listing))
        // await dispatch(setListingLoading(false));

        // console.log(listing, "listing")

        return true
    }
    catch (err) {
        dispatch(setListingLoading(false));
        dispatch(setError(err));
        console.log(err, "getAllListing")
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#F62424',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
        })
        Toast.fire({
            icon: 'error',
            title: `${err}`
        })
        return null
    }

}


export const deleteListing = (listingID) => async (dispatch) => {
    try {
        dispatch(setListingLoading(true));

        await deleteDoc(doc(db, "listings", listingID));
        dispatch(setListingLoading(false));

        return true
    }
    catch (err) {
        dispatch(setListingLoading(false));
        dispatch(setError(err));
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#F62424',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
        })
        Toast.fire({
            icon: 'error',
            title: `${err}`
        })
        return null
    }

}

export const toggleListingStatus = (listingID, status) => async (dispatch) => {
    try {
        // dispatch(setListingLoading(true));

        const listingRef = doc(db, "listings", listingID);
        await updateDoc(listingRef, {
            active: status
        });

        dispatch(setListingLoading(false));

        return true
    }
    catch (err) {
        dispatch(setListingLoading(false));
        dispatch(setError(err));
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#F62424',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
        })
        Toast.fire({
            icon: 'error',
            title: `${err}`
        })
        return null
    }

}

export const updateListingViews = (listingID) => async (dispatch) => {
    try {
        // dispatch(setListingLoading(true));

        const listingRef = doc(db, "listings", listingID);
        await updateDoc(listingRef, {
            views: increment(1)
        });

        dispatch(setListingLoading(false));

        return true
    }
    catch (err) {
        dispatch(setListingLoading(false));
        dispatch(setError(err));
        return null
    }

}

export const editListing = (listingID, data) => async (dispatch) => {
    try {
        // dispatch(setListingLoading(true));

        const listingRef = doc(db, "listings", listingID);
        await updateDoc(listingRef, {
            ...data,
            updatedAt: serverTimestamp()
        });

        dispatch(setListingLoading(false));

        return true
    }
    catch (err) {
        dispatch(setListingLoading(false));
        dispatch(setError(err));
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#F62424',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
        })
        Toast.fire({
            icon: 'error',
            title: `${err}`
        })
        return null
    }

}

export const getAddListingOptionsExtras = () => async (dispatch) => {
    try {
        dispatch(setListingLoading(true));

        let boatExtras = []

        const unsub = onSnapshot(doc(db, "addListingOptions", 'extras'), (doc) => {
            // console.log("Current data: ", doc.data());
            if (doc.data()) {
                boatExtras = Object.values(doc.data());
                // console.log(boatExtras, "getAddListingOptions extras")
                dispatch(setListingExtras(boatExtras))
                dispatch(setListingLoading(false))
            }
            dispatch(setListingLoading(false));
        });

    } catch (err) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#b50e0e',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            // timerProgressBar: true
        })
        await Toast.fire({
            icon: 'error',
            title: `${err || 'Something went wrong'}`
        })
        // console.log(err, "getAddListingOptionsExtras")
        dispatch(setListingLoading(false));
        dispatch(setError(err));
    }
}


export const getAddListingOptionsTendersAndToys = () => async (dispatch) => {
    try {
        dispatch(setListingLoading(true));

        let tendersAndToys = []

        const unsub = onSnapshot(doc(db, "addListingOptions", 'tendersAndToys'), (doc) => {
            // console.log("Current data: ", doc.data());
            if (doc.data()) {
                tendersAndToys = Object.values(doc.data());
                // console.log(boatExtras, "getAddListingOptions extras")
                dispatch(setListingTenderAndToys(tendersAndToys))
                dispatch(setListingLoading(false))
            }
            dispatch(setListingLoading(false));
        });

    } catch (err) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#b50e0e',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            // timerProgressBar: true
        })
        await Toast.fire({
            icon: 'error',
            title: `${err || 'Something went wrong'}`
        })
        // console.log(err, "getAddListingOptionsExtras")
        dispatch(setListingLoading(false));
        dispatch(setError(err));
    }
}

export const addListingOptionsExtras = (extras) => async (dispatch) => {
    try {
        dispatch(setListingLoading(true));

        const ListingOptionsExtrasRef = doc(db, "addListingOptions", "extras");

        await updateDoc(ListingOptionsExtrasRef, {
            extras: arrayUnion(extras)
        });

        await dispatch(setListingLoading(false));

        return true

    } catch (err) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#b50e0e',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            // timerProgressBar: true
        })
        await Toast.fire({
            icon: 'error',
            title: `${err || 'Something went wrong'}`
        })
        // console.log(err, "addListingOptionsExtras")
        dispatch(setListingLoading(false));
        dispatch(setError(err));
        return false
    }
}

export const addListingTendersAndToysExtras = (toys) => async (dispatch) => {
    try {
        dispatch(setListingLoading(true));

        const ListingOptionsExtrasRef = doc(db, "addListingOptions", "tendersAndToys");

        await updateDoc(ListingOptionsExtrasRef, {
            tendersAndToys: arrayUnion(toys)
        });

        await dispatch(setListingLoading(false));

        return true

    } catch (err) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#b50e0e',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            // timerProgressBar: true
        })
        await Toast.fire({
            icon: 'error',
            title: `${err || 'Something went wrong'}`
        })
        // console.log(err, "addListingTendersAndToysExtras")
        dispatch(setListingLoading(false));
        dispatch(setError(err));
        return false
    }
}


export const getAddListingBoatMakes = () => async (dispatch) => {
    try {
        dispatch(setListingLoading(true));

        let boatMakes = []

        const unsub = onSnapshot(doc(db, "addListingOptions", 'boatMake'), (doc) => {
            // console.log("Current data: ", doc.data());
            if (doc.data()) {
                boatMakes = Object.values(doc.data());
                dispatch(setListingBoatMakes(boatMakes))
                dispatch(setListingLoading(false))
            }
            dispatch(setListingLoading(false));
        });

    } catch (err) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#b50e0e',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            // timerProgressBar: true
        })
        await Toast.fire({
            icon: 'error',
            title: `${err || 'Something went wrong'}`
        })
        dispatch(setListingLoading(false));
        dispatch(setError(err));
    }
}

export const getAllPDFListingMarkUpPrices = (listingIdsArray) => async (dispatch) => {
    try {
        let markUpPrices = {}

        let weeklyMarkUpPrice = {
            'Monday': { '1hrs': '', '2hrs': '', '3hrs': '', '4hrs': '', '5hrs': '', '6hrs': '', '8hrs': '' },
            'Tuesday': { '1hrs': '', '2hrs': '', '3hrs': '', '4hrs': '', '5hrs': '', '6hrs': '', '8hrs': '' },
            'Wednesday': { '1hrs': '', '2hrs': '', '3hrs': '', '4hrs': '', '5hrs': '', '6hrs': '', '8hrs': '' },
            'Thursday': { '1hrs': '', '2hrs': '', '3hrs': '', '4hrs': '', '5hrs': '', '6hrs': '', '8hrs': '' },
            'Friday': { '1hrs': '', '2hrs': '', '3hrs': '', '4hrs': '', '5hrs': '', '6hrs': '', '8hrs': '' },
            'Saturday': { '1hrs': '', '2hrs': '', '3hrs': '', '4hrs': '', '5hrs': '', '6hrs': '', '8hrs': '' },
            'Sunday': { '1hrs': '', '2hrs': '', '3hrs': '', '4hrs': '', '5hrs': '', '6hrs': '', '8hrs': '' }
        }

        for (const listingId of listingIdsArray) {
            const listingRef = doc(db, "listings", listingId);
            const docSnap = await getDoc(listingRef);
            if (docSnap.exists()) {
                let { boatName, pricingType, dayCharter } = docSnap.data();

                if (pricingType == "termCharter") {
                    markUpPrices[listingId] = {
                        pricingType: pricingType
                    }
                }
                else {
                    let { pricingSameEachDay, minimumHoursBeBooked } = dayCharter;
                    markUpPrices[listingId] = {
                        pricingType: pricingType,
                        boatName: boatName,
                        pricingSameEachDay: pricingSameEachDay,
                        minimumHoursBeBooked: minimumHoursBeBooked,
                        price: "",
                        weeklyPrice: weeklyMarkUpPrice
                    }
                }

            }
        }

        dispatch(setAllMarkUpPrices(markUpPrices))

        return markUpPrices
    }
    catch (err) {
        dispatch(setListingLoading(false));
        dispatch(setError(err));
        console.log(err, "getAllPDFListingMarkUpPrices")
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: '#F62424',
            iconColor: 'white',
            color: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
        })
        Toast.fire({
            icon: 'error',
            title: `${err}`
        })
        return null
    }

}