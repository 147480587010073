import React, { useState } from 'react'
import {
    Typography,
    Grid,
    Container,
    Box,
    Paper,
    Button,
    TextField,
    MenuItem,
    IconButton,
    CircularProgress,
    OutlinedInput,
    ToggleButtonGroup,
    ToggleButton
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import { useSelector, useDispatch } from 'react-redux'

import ContainedBtn from '../../../Components/ContainedBtn';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const useStyles = makeStyles((theme) => ({
    roundGrayBG: {
        backgroundColor: '#F3F3F3',
        borderRadius: '10px'
    },
    checkBoxCntnr: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    icon: {
        color: '#0B1A9B',
        cursor: 'pointer'
    },
    displayBtwn: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    outlinedBtn: {
        border: '1px solid #0B1A9B !important',
        color: '#0B1A9B !important',
        background: '#fff !important',
        fontSize: '17px',
        fontWeight: 'bold'
    },
    extraInput: {
        border: '1px solid white',
        outline: 'none',
        color: '#fff !important',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
        },
    }
}));

const ExtraCheckBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
    borderRadius: '5px',
    border: '1px solid #0B1A9B',
    display: 'flex',
    gap: '10px',
    minWidth: '200px'
}));


const BoatAvailability = ({ onChangeListingCredentials, listingCredentials }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [availableWholeWeek, setavailableWholeWeek] = useState(Object.values(listingCredentials.availability).every(value => value === true) ? 'yes' : 'no')

    const toggelAvailabilityDay = (day) => {
        let data = listingCredentials.availability

        data[day] = !data[day]

        onChangeListingCredentials('availability', data)
    }

    const handleChange = (event, type) => {
        // setPricingType(type);
        if (type == "yes") {
            let availability = {
                'Monday': true,
                'Tuesday': true,
                'Wednesday': true,
                'Thursday': true,
                'Friday': true,
                'Saturday': true,
                'Sunday': true
            }
            onChangeListingCredentials('availability', availability)
        }
        setavailableWholeWeek(type)
    };

    return (
        <Box className={classes.roundGrayBG} p={2} >
            <Grid container spacing={3} >

                <Grid item xs={12} >
                    <Typography variant='h5' sx={{ fontFamily: 'Gilroy Regular, sans-serif ', }}  >Are You available all week ?</Typography>
                    <ToggleButtonGroup
                        color="primary"
                        value={availableWholeWeek}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        sx={{ mt: 1 }}
                    >
                        <ToggleButton value="yes">YES</ToggleButton>
                        <ToggleButton value="no">No</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                {availableWholeWeek == "no" && <Grid item xs={12}  >
                    <Box className={classes.checkBoxCntnr} >
                        {
                            Object.keys(listingCredentials.availability)?.map((day, ind) => {
                                return (
                                    <ExtraCheckBox key={ind} px={2} py={1} m={1}  >
                                        <IconButton onClick={() => toggelAvailabilityDay(day)} >
                                            {
                                                listingCredentials.availability[day] ? <CheckBoxIcon className={classes.icon} /> : <CheckBoxOutlineBlankIcon className={classes.icon} />
                                            }
                                        </IconButton>
                                        <Typography variant='h5' sx={{ color: '#0B1A9B', width: 'max-content' }} >{day}</Typography>
                                    </ExtraCheckBox>
                                )
                            })
                        }
                    </Box>
                </Grid>}
            </Grid>
        </Box>
    )
}

export default BoatAvailability