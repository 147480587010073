import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Button,
    Box,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    InputBase,
    NativeSelect,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    TablePagination,
} from "@mui/material"
import { makeStyles, withStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import chevronDownUp from "../../../Assets/Icons/chevronDownUp.png"

const useStyles = makeStyles((theme) => ({
    iconImg: {
        height: '20px',
        width: '20px',
        marginRight: '10px',
    },
    tableContainer: {
        fontFamily: "inherit",
    },
    tableRow: {
        cursor: 'pointer',
        backgroundColor: '#F3F3F3'
    },
    tableCell: {
        fontFamily: "inherit",
        backgroundColor: "#D6E2F5",
        color: "#ffff",
        textAlign: "left",
        fontWeight: "bold !important",
        fontSize: "16px !important",
        textTransform: "upperCase",
        display: 'flex'
    },
    tableCellBody: {
        textAlign: "left",
    },
    select: {
        border: '1px solid #ced4da',
        borderRadius: 4,
        outline: 'none',
        padding: '5px',
        outline: 'none',
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    ellipseShip: {
        height: '36px',
        width: '36px'
    },
    nativeSelect: {
        width: "100%",
        border: '1px solid gray',
        // color: `${theme.palette.headTypography.main} !important `,
        // backgroundColor: `${theme.palette.primary.main} !important `,
    },
    actionBtn: {
        backgroundColor: '#0B1A9B',
        color: 'white',
        "&:hover": {
            backgroundColor: '#0B1A9B !important',
            color: 'white !important',
        }
    },
    icon: {
        height: '19px',
        width: '9px',
    }
}));


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

let data = [
    {
        name: "Costermonger (6223153)",
        year: "2015",
        make: "Marlow",
        model: "Marlow 97 Pilot",
        price: "420, 000",
        type: "Standard",
        impressions: "3,265",
        ctr: "3.89%"
    },
    {
        name: "(6223153)",
        year: "2011",
        make: "Sea Fox",
        model: "200XT ro Series",
        price: "29,995",
        type: "Standard",
        impressions: "150",
        ctr: "2%"
    }
]

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#E1E1E1',
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#F3F3F3',
    },
}));

const ListingTable = () => {
    const classes = useStyles();


    return (
        <Box>
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead  >
                        <TableRow>
                            <TableCell className={classes.tableCell}>Boat Name / Boat # <img src={chevronDownUp} className={classes.icon} /> </TableCell>
                            <TableCell className={classes.tableCell}>Year <img src={chevronDownUp} className={classes.icon} /> </TableCell>
                            <TableCell className={classes.tableCell}>Make <img src={chevronDownUp} className={classes.icon} /> </TableCell>
                            <TableCell className={classes.tableCell}>Model <img src={chevronDownUp} className={classes.icon} /> </TableCell>
                            <TableCell className={classes.tableCell}>Price <img src={chevronDownUp} className={classes.icon} /> </TableCell>
                            <TableCell className={classes.tableCell}>Listing Type <img src={chevronDownUp} className={classes.icon} /> </TableCell>
                            <TableCell className={classes.tableCell}>Impressions <img src={chevronDownUp} className={classes.icon} /> </TableCell>
                            <TableCell className={classes.tableCell}>CTR <img src={chevronDownUp} className={classes.icon} /> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            [...data, ...data, ...data].map((listing, ind) => {
                                return (
                                    <StyledTableRow key={ind} className={classes.tableRow}  >
                                        <TableCell className={classes.tableCellBody} >
                                            {listing.name}
                                        </TableCell>
                                        <TableCell>
                                            {listing.year}
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}  >
                                            {listing.make}
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody} >
                                            {listing.model}
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody} >
                                            {listing.price}
                                        </TableCell>
                                        <TableCell>
                                            {listing.type}
                                        </TableCell>
                                        <TableCell>
                                            {listing.impressions}
                                        </TableCell>
                                        <TableCell>
                                            {listing.ctr}
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })
                        }
                        <TableRow sx={{ backgroundColor: "#0B1A9B" }} >
                            <TableCell sx={{color:'white'}} >Sub Total</TableCell>
                            <TableCell sx={{color:'white'}} >---</TableCell>
                            <TableCell sx={{color:'white'}} >---</TableCell>
                            <TableCell sx={{color:'white'}} >---</TableCell>
                            <TableCell sx={{color:'white'}} >---</TableCell>
                            <TableCell sx={{color:'white'}} >148</TableCell>
                            <TableCell sx={{color:'white'}} >3878</TableCell>
                            <TableCell sx={{color:'white'}} >---</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default ListingTable