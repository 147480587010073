import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Slider,
  TextField,
  MenuItem,
  IconButton,
  CircularProgress,
  FormControlLabel,
  FormControl,
  FormLabel,
  Switch,
  FormGroup,
  InputLabel,
  Select,
} from "@mui/material";
import "./CustomizePDf.css";

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import ContainedBtn from "../../../Components/ContainedBtn";
import { Toggle } from "../../../Components/Toggle";

import { setAllMarkUpPrices } from "../../../Redux/Listing/ListingActions";

const useStyles = makeStyles((theme) => ({
  textCntnr: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  headOne: {
    fontWeight: "bold",
  },
  sliderHead: {
    color: "#000 !important",
    textTransform: "capitalize",
    fontSize: "15px !important",
    fontWeight: "bold !important",
  },
  displaybtwn: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnWrapper: {
    // border: '1px solid black',
    background: "#fff",
    backgroundColor: "#fff",
    width: "inherit",
    position: "sticky",
    bottom: "0px",
    display: "flex",
    justifyContent: "flex-end",
    boxShadow: "rgba(0, 0, 0, 0.04) 0px -4px 10px",
    zIndex: "99",
  },
  bottomBtn: {
    background: "#0B1A9B !important",
    color: "white important",
  },
  checkBoxCntnr: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  icon: {
    color: "#fff",
    cursor: "pointer",
  },
  displayEnd: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "end",
  },
  markupName: {
    fontFamily: "Gilroy Regular, sans-serif ",
    fontSize: "1rem !important",
    fontWeight: "bold !important",
    color: "black",
    textTransform: "capitalize !important",
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  borderRadius: "15px",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px",
  height: "auto",
  backgroundColor: "#fff",
  padding: "8px",
  // width: '100%',
  height: "95vh",
  position: "fixed",
  overflowY: "scroll",
  [theme.breakpoints.down("md")]: {
    height: "auto",
    position: "unset",
    overflowY: "auto",
  },
}));

const SliderWrapper = styled(Box)(({ theme }) => ({
  // borderBottom: '1px solid #C0C0C0',
  maxWidth: "100%",
}));

const ExtraCheckBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#0B1A9B",
  borderRadius: "5px",
  border: "1px solid #0B1A9B",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  // width: '-webkit-fill-available'
}));

const DataText = styled(Box)(({ theme }) => ({
  fontSize: "13px",
  fontFamily: "Gilroy Regular, sans-serif !important",
  color: "#777777",
  fontWeight: "bold",
  whiteSpace: "pre-line",
  letterSpacing: "1px",
  marginTop: "10px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "13px",
    letterSpacing: "1px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "13px",
    letterSpacing: "1px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "13px",
    letterSpacing: "1px",
  },
}));

const CustomizePDf = ({
  isDescriptionDisplay,
  isDataSheetDisplay,
  isExtrastDisplay,
  isTenderToysDisplay,
  isHeaderInfoDisplay,
  isAvaliabilityDisplay,
  isPhotoAlbumDisplay,
  isCaptianInfoDisplay,
  isMarkUpPrice,

  setisDescriptionDisplay,
  setisDataSheetDisplay,
  setisExtrastDisplay,
  setisTenderToysDisplay,
  setisHeaderInfoDisplay,
  setisAvaliabilityDisplay,
  setisPhotoAlbumDisplay,
  setisCaptianInfoDisplay,
  setIsEditAllow,
  setIsMarkUpPrice,

  isEditAllow,
  printLoading,
  printPDF,
  viewOption,
  setViewOption,
  slectedListings,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [displayMarkupPrice, setDisplayMarkupPrice] = useState("");

  const markUpPrices = useSelector((state) => state.listings.markUpPrices);

  const onChangeDisplayPriceMarkUp = (key, price) => {
    let markUpObj = { ...markUpPrices };
    markUpObj[key].price = price;

    dispatch(setAllMarkUpPrices(markUpObj));
  };

  const onChangeWeekDaysPriceMarkUp = (key, hours, price) => {
    let markUpObj = { ...markUpPrices };
    markUpObj[key].weeklyPrice = {
      ...markUpObj[key].weeklyPrice,
      Monday: {
        ...markUpObj[key].weeklyPrice.Monday,
        [`${hours}hrs`]: price,
      },
    };

    dispatch(setAllMarkUpPrices(markUpObj));
  };

  const onChangeWeekEndPriceMarkUp = (key, hours, price) => {
    let markUpObj = { ...markUpPrices };
    markUpObj[key].weeklyPrice = {
      ...markUpObj[key].weeklyPrice,
      Friday: {
        ...markUpObj[key].weeklyPrice.Friday,
        [`${hours}hrs`]: price,
      },
    };

    dispatch(setAllMarkUpPrices(markUpObj));
  };

  let discountedPriceHoursRange = [2, 4, 6, 8];

  let count = 0;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Wrapper className="scrollbar">
          <Typography variant="h5" className={classes.headOne}>
            Customize Brochure
          </Typography>
          {/* Step 2 & 3: Add the Select Component with Default Value */}
          <Box p={0.5} m={0.5}>
            <FormControl fullWidth>
              <InputLabel id="view-option-label">Layout</InputLabel>
              <Select
                labelId="view-option-label"
                id="view-option-select"
                value={viewOption}
                label="View Option"
                onChange={(e) => setViewOption(e.target.value)}
              >
                <MenuItem value="Full View">Full View</MenuItem>
                <MenuItem value="Comparison">Comparison</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <>
            {viewOption === "Full View" && (
              <>
                <Typography variant="h5" className={classes.headOne}>
                  MarkUp
                </Typography>
                {Object.keys(markUpPrices).map((listingmarkUpID, ind) => {
                  if (
                    markUpPrices[listingmarkUpID]?.pricingType == "dayCharter"
                  ) {
                    count += 1;
                    return (
                      <Box key={ind}>
                        <Typography className={classes.markupName}>
                          {count}. {markUpPrices[listingmarkUpID]?.boatName}
                        </Typography>
                        <Typography>
                          {/* {markUpPrices[listingmarkUpID].pricingType ===
                      "dayCharter"
                        ? `US$ ${markUpPrices[listingmarkUpID].pricing?.price}`
                        : `US$ ${markUpPrices[listingmarkUpID].pricing?.pricing[1]}`} */}

                          {slectedListings.map((listing, ind) => {
                            if (listing?.dayCharter) {
                              return (
                                markUpPrices[listingmarkUpID]?.boatName ===
                                  listing?.boatName &&
                                `US$ ${listing?.dayCharter?.price + " +/HOUR"}`
                              );
                            } else {
                              return "";
                            }
                          })}
                          {/* {slectedListings[listingmarkUpID].pricingType ===
                      "dayCharter"
                        ? `US$ ${markUpPrices[listingmarkUpID].price}`
                        : `US$ ${"pricing?.pricing[1]"}`}
                      {markUpPrices[listingmarkUpID].pricingType ===
                      "dayCharter"
                        ? "availability[currentDay]"
                          ? " +/HOUR"
                          : ""
                        : " +/DAY"} */}
                        </Typography>
                        {/* <Box my={1}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        fullWidth
                        defaultValue="Hello World"
                        placeholder="Enter Markup Price"
                        value={markUpPrices[listingmarkUpID]?.price}
                        onChange={(e) => {
                          const numericValue = e.target.value;
                          if (/^\d*$/.test(numericValue)) {
                            onChangeDisplayPriceMarkUp(
                              listingmarkUpID,
                              numericValue
                            );
                          }
                        }}
                      />
                    </Box> */}

                        {markUpPrices[listingmarkUpID]?.pricingSameEachDay ==
                        "yes" ? (
                          <Box>
                            {/* Same Pricing */}
                            <DataText sx={{ my: 2 }}>
                              Week Pricing MarkUp (Mon - Sun){" "}
                            </DataText>
                            {!discountedPriceHoursRange.includes(
                              markUpPrices[listingmarkUpID]
                                ?.minimumHoursBeBooked
                            ) && (
                              <>
                                <TextField
                                  id="outlined-basic"
                                  label={`${markUpPrices[listingmarkUpID]?.minimumHoursBeBooked}hrs markup price`}
                                  variant="outlined"
                                  type="number"
                                  fullWidth
                                  placeholder={`${markUpPrices[listingmarkUpID]?.minimumHoursBeBooked} hrs markup price`}
                                  value={
                                    markUpPrices[listingmarkUpID].weeklyPrice
                                      .Monday[
                                      `${markUpPrices?.[listingmarkUpID]?.minimumHoursBeBooked}hrs`
                                    ]
                                  }
                                  onChange={(e) => {
                                    const numericValue = e.target.value;
                                    if (/^\d*$/.test(numericValue)) {
                                      onChangeWeekDaysPriceMarkUp(
                                        listingmarkUpID,
                                        markUpPrices[listingmarkUpID]
                                          ?.minimumHoursBeBooked,
                                        numericValue
                                      );
                                    }
                                  }}
                                />
                              </>
                            )}
                            {discountedPriceHoursRange.map((disPrice, ind) => {
                              if (
                                markUpPrices[listingmarkUpID]
                                  .minimumHoursBeBooked <= disPrice
                              )
                                return (
                                  <Box key={ind} sx={{ my: 1 }}>
                                    <TextField
                                      id="outlined-basic"
                                      label={`${disPrice}hrs markup price`}
                                      variant="outlined"
                                      type="number"
                                      // sx={{ maxWidth: '100%' }}
                                      fullWidth
                                      placeholder={`${disPrice}hrs markup price`}
                                      value={
                                        markUpPrices[listingmarkUpID]
                                          .weeklyPrice.Monday[`${disPrice}hrs`]
                                      }
                                      onChange={(e) => {
                                        const numericValue = e.target.value;
                                        if (/^\d*$/.test(numericValue)) {
                                          onChangeWeekDaysPriceMarkUp(
                                            listingmarkUpID,
                                            disPrice,
                                            numericValue
                                          );
                                        }
                                      }}
                                    />
                                  </Box>
                                );
                            })}
                          </Box>
                        ) : (
                          <Box>
                            {/* Different Pricing */}
                            <DataText sx={{ my: 2 }}>
                              Weekdays Pricing MarkUp (Mon - Thur){" "}
                            </DataText>
                            {!discountedPriceHoursRange.includes(
                              markUpPrices[listingmarkUpID]
                                ?.minimumHoursBeBooked
                            ) && (
                              <>
                                <TextField
                                  id="outlined-basic"
                                  label={`${markUpPrices[listingmarkUpID]?.minimumHoursBeBooked}hrs markup price`}
                                  variant="outlined"
                                  type="number"
                                  fullWidth
                                  placeholder={`${markUpPrices[listingmarkUpID]?.minimumHoursBeBooked}hrs markup price`}
                                  value={
                                    markUpPrices[listingmarkUpID].weeklyPrice
                                      .Monday[
                                      `${markUpPrices?.[listingmarkUpID]?.minimumHoursBeBooked}hrs`
                                    ]
                                  }
                                  onChange={(e) => {
                                    const numericValue = e.target.value;
                                    if (/^\d*$/.test(numericValue)) {
                                      onChangeWeekDaysPriceMarkUp(
                                        listingmarkUpID,
                                        markUpPrices[listingmarkUpID]
                                          ?.minimumHoursBeBooked,
                                        numericValue
                                      );
                                    }
                                  }}
                                />
                              </>
                            )}
                            {discountedPriceHoursRange.map((disPrice, ind) => {
                              if (
                                markUpPrices[listingmarkUpID]
                                  .minimumHoursBeBooked <= disPrice
                              )
                                return (
                                  <Box key={ind} sx={{ my: 1 }}>
                                    <TextField
                                      id="outlined-basic"
                                      label={`${disPrice}hrs markup price`}
                                      variant="outlined"
                                      type="number"
                                      // sx={{ maxWidth: '100%' }}
                                      fullWidth
                                      placeholder={`${disPrice}hrs markup price`}
                                      value={
                                        markUpPrices[listingmarkUpID]
                                          .weeklyPrice.Monday[`${disPrice}hrs`]
                                      }
                                      onChange={(e) => {
                                        const numericValue = e.target.value;
                                        if (/^\d*$/.test(numericValue)) {
                                          onChangeWeekDaysPriceMarkUp(
                                            listingmarkUpID,
                                            disPrice,
                                            numericValue
                                          );
                                        }
                                      }}
                                    />
                                  </Box>
                                );
                            })}
                            <DataText sx={{ my: 2 }}>
                              Weekend Pricing (Fri - Sun){" "}
                            </DataText>
                            {!discountedPriceHoursRange.includes(
                              markUpPrices[listingmarkUpID]
                                ?.minimumHoursBeBooked
                            ) && (
                              <>
                                <TextField
                                  id="outlined-basic"
                                  label={`${markUpPrices[listingmarkUpID]?.minimumHoursBeBooked}hrs markup price`}
                                  variant="outlined"
                                  type="number"
                                  fullWidth
                                  placeholder={`${markUpPrices[listingmarkUpID]?.minimumHoursBeBooked}hrs markup price`}
                                  value={
                                    markUpPrices[listingmarkUpID].weeklyPrice
                                      .Friday[
                                      `${markUpPrices?.[listingmarkUpID]?.minimumHoursBeBooked}hrs`
                                    ]
                                  }
                                  onChange={(e) => {
                                    const numericValue = e.target.value;
                                    if (/^\d*$/.test(numericValue)) {
                                      onChangeWeekEndPriceMarkUp(
                                        listingmarkUpID,
                                        markUpPrices[listingmarkUpID]
                                          ?.minimumHoursBeBooked,
                                        numericValue
                                      );
                                    }
                                  }}
                                />
                              </>
                            )}
                            {discountedPriceHoursRange.map((disPrice, ind) => {
                              if (
                                markUpPrices[listingmarkUpID]
                                  .minimumHoursBeBooked <= disPrice
                              )
                                return (
                                  <Box key={ind} sx={{ my: 1 }}>
                                    <TextField
                                      id="outlined-basic"
                                      label={`${disPrice}hrs markup price`}
                                      variant="outlined"
                                      type="number"
                                      // sx={{ maxWidth: '100%' }}
                                      fullWidth
                                      placeholder={`${disPrice}hrs markup price`}
                                      value={
                                        markUpPrices[listingmarkUpID]
                                          .weeklyPrice.Friday[`${disPrice}hrs`]
                                      }
                                      onChange={(e) => {
                                        const numericValue = e.target.value;
                                        if (/^\d*$/.test(numericValue)) {
                                          onChangeWeekEndPriceMarkUp(
                                            listingmarkUpID,
                                            disPrice,
                                            numericValue
                                          );
                                        }
                                      }}
                                    />
                                  </Box>
                                );
                            })}
                          </Box>
                        )}
                      </Box>
                    );
                  }
                })}
              </>
            )}
          </>
          {viewOption === "Full View" && (
            <ExtraCheckBox p={1} m={1}>
              <IconButton
                onClick={() => setisDescriptionDisplay(!isDescriptionDisplay)}
              >
                {isDescriptionDisplay ? (
                  <CheckBoxIcon className={classes.icon} />
                ) : (
                  <CheckBoxOutlineBlankIcon className={classes.icon} />
                )}
              </IconButton>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#fff",
                  width: "max-content",
                }}
              >
                Show Description
              </Typography>
            </ExtraCheckBox>
          )}

          {viewOption === "Full View" && (
            <ExtraCheckBox p={1} m={1}>
              <IconButton
                onClick={() => setisDataSheetDisplay(!isDataSheetDisplay)}
              >
                {isDataSheetDisplay ? (
                  <CheckBoxIcon className={classes.icon} />
                ) : (
                  <CheckBoxOutlineBlankIcon className={classes.icon} />
                )}
              </IconButton>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#fff",
                  width: "max-content",
                }}
              >
                Show Data sheet
              </Typography>
            </ExtraCheckBox>
          )}

          {viewOption === "Full View" && (
            <ExtraCheckBox p={1} m={1}>
              <IconButton
                onClick={() => setisExtrastDisplay(!isExtrastDisplay)}
              >
                {isExtrastDisplay ? (
                  <CheckBoxIcon className={classes.icon} />
                ) : (
                  <CheckBoxOutlineBlankIcon className={classes.icon} />
                )}
              </IconButton>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#fff",
                  width: "max-content",
                }}
              >
                Show Extras
              </Typography>
            </ExtraCheckBox>
          )}

          {viewOption === "Full View" && (
            <ExtraCheckBox p={1} m={1}>
              <IconButton
                onClick={() => setisTenderToysDisplay(!isTenderToysDisplay)}
              >
                {isTenderToysDisplay ? (
                  <CheckBoxIcon className={classes.icon} />
                ) : (
                  <CheckBoxOutlineBlankIcon className={classes.icon} />
                )}
              </IconButton>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#fff",
                  width: "max-content",
                }}
              >
                Show Toys and Tender{" "}
              </Typography>
            </ExtraCheckBox>
          )}

          {viewOption === "Full View" && (
            <ExtraCheckBox p={1} m={1}>
              <IconButton
                onClick={() => setisAvaliabilityDisplay(!isAvaliabilityDisplay)}
              >
                {isAvaliabilityDisplay ? (
                  <CheckBoxIcon className={classes.icon} />
                ) : (
                  <CheckBoxOutlineBlankIcon className={classes.icon} />
                )}
              </IconButton>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#fff",
                  width: "max-content",
                }}
              >
                Show Available Days{" "}
              </Typography>
            </ExtraCheckBox>
          )}

          {viewOption === "Full View" && (
            <ExtraCheckBox p={1} m={1}>
              <IconButton
                onClick={() => setisPhotoAlbumDisplay(!isPhotoAlbumDisplay)}
              >
                {isPhotoAlbumDisplay ? (
                  <CheckBoxIcon className={classes.icon} />
                ) : (
                  <CheckBoxOutlineBlankIcon className={classes.icon} />
                )}
              </IconButton>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#fff",
                  width: "max-content",
                }}
              >
                Show Photos{" "}
              </Typography>
            </ExtraCheckBox>
          )}

          {viewOption === "Full View" && (
            <ExtraCheckBox p={1} m={1}>
              <IconButton
                onClick={() => setisCaptianInfoDisplay(!isCaptianInfoDisplay)}
              >
                {isCaptianInfoDisplay ? (
                  <CheckBoxIcon className={classes.icon} />
                ) : (
                  <CheckBoxOutlineBlankIcon className={classes.icon} />
                )}
              </IconButton>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#fff",
                  width: "max-content",
                }}
              >
                Show Captians{" "}
              </Typography>
            </ExtraCheckBox>
          )}

          {viewOption === "Full View" && (
            <Box p={1} m={1}>
              <Toggle
                text="Header Info"
                checked={isHeaderInfoDisplay}
                onChange={(e) => setisHeaderInfoDisplay(e.target.checked)}
              />
            </Box>
          )}

          {/* {viewOption === 'Full View' && <>
                        <Typography variant="h5" className={classes.headOne}>MarkUp Price</Typography>
                        <Box my={2} >
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                type="text"
                                fullWidth
                                placeholder="Enter Markup Price"
                                onChange={(e) => {
                                    const numericValue = e.target.value
                                    if (/^\d*$/.test(numericValue)) {
                                        setIsMarkUpPrice(numericValue)
                                    }
                                }}
                            />
                        </Box>
                    </>} */}
          {/* {viewOption === 'Full View' && */}

          <Box className={classes.btnWrapper} pt={2} pb={1}>
            <ContainedBtn
              text={
                printLoading ? (
                  <CircularProgress
                    sx={{ color: "#fff", width: "24px", height: "25px" }}
                  />
                ) : (
                  "Export"
                )
              }
              className={classes.bottomBtn}
              onClick={() => {
                const markUpPrice =
                  Object.values(markUpPrices)[0]?.weeklyPrice?.Monday[
                    `${Object.values(markUpPrices)[0]?.minimumHoursBeBooked}hrs`
                  ];

                if (markUpPrice > 0) {
                  printPDF();
                } else {
                  if (
                    window.confirm(
                      "Are you sure you want to send PDF without commissions?"
                    )
                  ) {
                    printPDF();
                  }
                }
                // navigate(`/addPDF`)
              }}
            />
          </Box>
        </Wrapper>
      </Grid>
    </Grid>
  );
};

export default CustomizePDf;
