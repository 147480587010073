import React, { useRef, useState } from "react";
import {
    Grid,
    Box,
    Button
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@mui/styles";

import leftIcon from "../Assets/Icons/chevronLeft.png"
import rightIcon from "../Assets/Icons/chevronRight.png"
import userFeedback from "../Assets/Images/userFeedback.png"


const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "hidden !important",
        [theme.breakpoints.down("xs")]: {
            marginTop: '5px',

        },
    },
    image: {
        width: '95% !important',
        height: '300px',
        marginLeft: '2%',
        // aspectRatio: '1 / 1',
        borderRadius: '20px',
        display: 'flex',
        alignSelf: 'center',
        // padding: '0px 10px',
        [theme.breakpoints.down("sm")]: {
            width: '90% !important',
            height: '150px',
            marginLeft: '5%'
        },
    },
    acptdTextCntnr: {
        width: '100%',
        height: '20%',
        background: 'linear-gradient(0deg, rgba(0, 25, 38, 0) 0%, rgb(0, 25, 38) 70%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '100%',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
    },
    challengeInfo: {
        cursor: 'pointer',
        textShadow: 'rgb(0 0 0 / 50%) 0em 0em 0.25em',
        textTransform: 'uppercase',
        fontSize: '1.3vw',
        [theme.breakpoints.down("ms")]: {
            fontSize: '1.5vw',
        },
        color: '#FFFF',
        fontWeight: "500"
    },
    alignStart: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    alignEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    iconBtn: {
        border: '2px solid #0B1A9B',
        borderRadius: '50%',
        padding: '20px',
        width: '40px',
        height: '44px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            width: '20px',
            height: '24px',
        },
    },

}));



const SingleSlideCarousell = ({ }) => {
    const classes = useStyles();
    const customSlider = useRef();

    const [nav2, setNav2] = useState();
    const slider1 = useRef();

    let settings = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        // centerMode: true,
        responsive: [
            {
                breakpoint: 1220,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                },
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <Grid container  >
            <Grid item xs={1} className={classes.alignStart} >
                <button className={classes.iconBtn} onClick={() => customSlider.current.slickNext()} >
                    <img src={leftIcon} style={{ width: '16px', height: '14px' }} />
                </button>
            </Grid>
            <Grid item xs={10} className={classes.root} >
                <Slider
                    ref={slider => (customSlider.current = slider)}
                    {...settings} arrows={true}  >
                    {
                        [1, 2, 3, 4].map((imgSrc, i) => {
                            return (
                                <Box key={i} className={classes.image} sx={{
                                    backgroundColor: '#F0F0F0',

                                }} >
                                    <img src={userFeedback} alt='Slider Image' style={{ width: '100%', height: '100%' }} />
                                </Box>
                            )
                        })
                    }
                </Slider>
            </Grid>
            <Grid item xs={1} className={classes.alignEnd}  >
                <button className={classes.iconBtn} onClick={() => customSlider.current.slickPrev()} >
                    <img src={rightIcon} style={{ width: '16px', height: '14px' }} />
                </button>
            </Grid>
        </Grid>
    )
}

export default SingleSlideCarousell