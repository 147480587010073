import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Button,
    Box,
    FormGroup,
    FormControlLabel,
    Switch
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import Graph from "../../../Assets/Images/Graph.png"

const useStyles = makeStyles((theme) => ({
    textCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    }
}));

const VisibilityBtn = styled(Button)(({ theme, isSelected }) => ({
    backgroundColor: `${isSelected ? '#0B1A9B' : '#F3F3F3'} !important`,
    color: `${isSelected ? '#FFFFFF' : '#191717'} !important`,
    width: '100%',
    height: '80px',
    borderRadius: '0px',
    borderTopLeftRadius: "10px",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        borderTopLeftRadius: "0px",
    },
}));

const ConnectionsBtn = styled(Button)(({ theme, isSelected }) => ({
    backgroundColor: `${isSelected ? '#0B1A9B' : '#F3F3F3'} !important`,
    color: `${isSelected ? '#FFFFFF' : '#191717'} !important`,
    width: '100%',
    height: '80px',
    borderRadius: '0px',
    borderTopRightRadius: "10px",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        borderTopRightRadius: "0px",
    },
}));



const ListingGraphs = () => {
    const classes = useStyles();

    return (
        <Grid container sx={{ backgroundColor: "#F3F3F3",borderRadius:'10px' }} >
            <Grid item xs={12} sm={6} sx={{ backgroundColor: { sm: '#FFFFFF', xs: '#F3F3F3' } }} >
                <VisibilityBtn >Visibility</VisibilityBtn>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ backgroundColor: { sm: '#FFFFFF', xs: '#F3F3F3' } }} >
                <ConnectionsBtn isSelected={true} >Connections</ConnectionsBtn>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', my: 5 }}  >
                <Box>
                    <Typography variant='h5' sx={{ marginBottom: { xs: '10px' }, fontWeight: 'bold', }} >
                        Custom All Listings
                    </Typography>
                </Box>
                <Box mx={4} >
                    <FormControlLabel
                        sx={{
                            display: 'flex',
                            backgroundColor: '#0B1A9B',
                            borderRadius: '10px',
                            height: '18px',
                            width: '40px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 1
                        }}
                        control={
                            <Switch
                            // {...props}
                            // color="whiteShade"
                            defaultChecked
                            />
                        }
                    />
                </Box>
                <Box>
                    <Typography variant='h5' sx={{ marginBottom: { xs: '10px' }, fontWeight: 'bold', color: '#B6B6B6' }} >
                        Impressions
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} px={5}  >
                <img src={Graph} style={{ width: '100%', aspectRatio: '3/1' }} />
            </Grid>
        </Grid>
    )
}

export default ListingGraphs