//Service Images
import ServiceOne from "../Assets/Images/ServiceOne.png"
import ServiceTwo from "../Assets/Images/ServiceTwo.png"
import ServiceThree from "../Assets/Images/ServiceThree.png"
import ServiceFour from "../Assets/Images/ServiceFour.png"

// Industries Images
import Ship2 from "../Assets/Images/ship2.png";
import Car2 from "../Assets/Images/Car2.png";
import Web2 from "../Assets/Images/Web2.png"


//Features Images
import Frame from "../Assets/Images/Frame.png"
import Frame1 from "../Assets/Images/Frame1.png"
import Frame2 from "../Assets/Images/Frame2.png"
import Frame3 from "../Assets/Images/Frame3.png"
import Frame4 from "../Assets/Images/Frame4.png"
import Frame5 from "../Assets/Images/Frame5.png"
import Frame6 from "../Assets/Images/Frame6.png"
import Frame7 from "../Assets/Images/Frame7.png"
import Frame8 from "../Assets/Images/Frame8.png"
import Frame9 from "../Assets/Images/Frame9.png"
import Frame10 from "../Assets/Images/Frame10.png"
import Frame11 from "../Assets/Images/Frame11.png"

//Team Image
import TeamPerson from "../Assets/Images/teamPerson.png"



export let ServicesData = [
    {
        image: ServiceOne,
        title: 'Fast and easy search  Marketplace',
        text: 'Quick browse through available listings within a location area with a preview gallery and details page.'
    },
    {
        image: ServiceTwo,
        title: 'Professional Dashboard',
        text: 'Our all-in-one dashboard gives you the ability to add inventory to your fleet, track listing views, view listings, and search the marketplace.'
    },
    {
        image: ServiceThree,
        title: 'Brochures with your pricing',
        text: 'Create professional brochures with your marked up pricing to send off to clients - no more editing messages or brochures on the backend.'
    },
    {
        image: ServiceFour,
        title: 'Reporting Tool',
        text: 'Track your fleet with inventory management, pull daily, weekly, and monthly reports to see views, deals completed, and revenue generated from our platform.'
    }
]

export let IndustriesData = [
    {
        image: Ship2,
        title: 'Yacht Charter',
        text: 'Hourly charter, day charter, and term charter yachts are available on our marketplace'
    },
    {
        image: Car2,
        title: 'Luxury Car Rental',
        text: 'Hourly rental, day rental, and multi day rentals are available on our marketplace'
    },
    {
        image: Web2,
        title: 'We are working effortlessly to expand…',
        text: 'Our team is working hard with experience companies to add more industries to our marketplace to expand to other luxury assets to our platform'
    }
]


export let FeaturesData = [
    {
        image: Frame,
        title: 'Efficient marketplace',
    },
    {
        image: Frame1,
        title: 'Markup tool for your commissions',
    },
    {
        image: Frame2,
        title: 'Fleet management',
    },
    {
        image: Frame3,
        title: 'Connect with thousands of brokers',
    },
    {
        image: Frame4,
        title: 'Reporting tool',
    },
    {
        image: Frame5,
        title: 'Professional brochure creation',
    },
    {
        image: Frame6,
        title: 'Create sales on the fly',
    },
    {
        image: Frame7,
        title: 'All-in-one dashboard',
    },
    {
        image: Frame8,
        title: 'Send brochures via SMS',
    },
    {
        image: Frame9,
        title: 'API feeds directly to your website',
    },
    {
        image: Frame10,
        title: 'Access to exclusive pricing',
    },
    {
        image: Frame11,
        title: 'Track sales',
    }
]

export let TeamData = [
    {
        image: TeamPerson,
        name: 'Brett Horowitz',
        role: 'Chief Executive Officer'
    },
    {
        image: TeamPerson,
        name: 'Saad',
        role: 'Chief Technology officer'
    },
    {
        image: TeamPerson,
        name: 'Muhammad Ibad',
        role: 'Developer'
    }

]