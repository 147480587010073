import React, { useEffect, useState } from 'react'
import {
    Typography,
    Grid,
    Container,
    Button,
    Box,
    OutlinedInput,
    InputAdornment,
    IconButton,
    CircularProgress
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import sendIcon from "../../../Assets/Icons/sendIcon.png"
import PrintIcon from '@mui/icons-material/Print';

import ContainedBtn from '../../../Components/ContainedBtn';
import InventoryTable from './InventoryTable';

import { useSelector, useDispatch } from 'react-redux'
import { getAllMYListing } from '../../../Redux/Listing/ListingActions';

import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    textCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    alignCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100px'
    },
}));

const InventoryList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const uid = localStorage.getItem('uid')

    const listingLoading = useSelector((state) => state.listings.loading)

    const [printListingIDs, setPrintListingIDs] = useState([])

    // useEffect(() => {

    //     dispatch(getAllMYListing(uid))

    // }, [])

    const sendToPrint = () => {
        if (printListingIDs.length > 0) {
            let routeIDs = printListingIDs.join('-');
            navigate(`/generate-pdfs/${routeIDs}`)
        }
    }

    return (
        <Grid container mt={5} >
            <Grid item xs={12} className={classes.textCntnr} >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: 'xx-large', xs: '30px', sm: '40px ', sevenHalfS: '25px', nineS: '30px', md: '35px', lg: '40px' },
                        marginBottom: { xs: '24px', sevenHalfS: '15px', md: '24px' }
                    }}
                > My Inventory List </Typography>
                {/* <ContainedBtn
                    text='Print'
                    sx={{ '&:hover': { color: '#0B1A9B', outline: '1px solid #0B1A9B' }, height: '50px', width: '185px', marginBottom: '10px' }}
                    endIcon={<img src={sendIcon} style={{ width: '14px', height: '14px', }} alt="icon" />}
                    onClick={sendToPrint}
                /> */}
                <ContainedBtn
                    text='Print'
                    sx={{ '&:hover': { color: '#0B1A9B', outline: '1px solid #0B1A9B' }, height: '50px', width: '185px', marginBottom: '10px' }}
                    endIcon={<PrintIcon />}
                    onClick={sendToPrint}
                />
            </Grid>
            <Grid item xs={12} mb={5} >
                {listingLoading ? <Box className={classes.alignCenter} >
                    <CircularProgress sx={{ color: '#0B1A9B' }} />
                </Box>
                    : <InventoryTable
                        setPrintListingIDs={(state) => setPrintListingIDs(state)}
                        printListingIDs={printListingIDs}

                    />}
            </Grid>
        </Grid>
    )
}

export default InventoryList