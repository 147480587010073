import React, { useEffect, useState } from "react";
// import './App.css';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";

import { getAllPlans } from "./Redux/Plans/PlanActions";
import { getCurrentUser, getUserTeam } from "./Redux/User/UserActions";
import {
  getAllListing,
  getAllMYListing,
  getAddListingBoatMakes,
} from "./Redux/Listing/ListingActions";
import PasswordPrompt from "./Screens/Security/PasswordPrompt";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const theme = createTheme({
  typography: {
    body1: {
      fontFamily: ["Gilroy Regular, sans-serif"].join(","),
    },
  },
  // typography: {
  //   body1:{
  //     fontFamily: ["Motserrat", "sans-serif"].join(","),

  //   }
  // },
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       height: 40,
  //     },
  //   },

  // },
  breakpoints: {
    values: {
      xs: 0,
      ms: 479, // custom
      sm: 600,
      nineS: 900, //cuustom
      sevenHalfS: 750, //custom
      md: 1090,
      lg: 1200,
      fl: 1350,
      xl: 1556,
    },
  },
});
export function capitalizeFirstLetter(string) {
  if (typeof string === "string" && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return "";
}

function App() {
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.user.userAuth);
  let uid = localStorage.getItem("uid");

  const allListings = useSelector((state) => state.listings.allListings);
  const allMyListings = useSelector((state) => state.listings.allMyListings);
  const userTeam = useSelector((state) => state.user.userTeam);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const correctPassword = "BrokenSky";

  useEffect(() => {
    dispatch(getAllPlans());
    dispatch(getCurrentUser());
    dispatch(getAddListingBoatMakes());

    if (uid || userAuth) {
      if (userTeam.length == 0) {
        dispatch(getUserTeam());
      }

      if (allListings.length == 0) {
        dispatch(getAllListing());
      }
      if (allMyListings.length == 0) {
        dispatch(getAllMYListing(uid));
      }
    }
  }, [uid]);

  const onPasswordSubmit = (password) => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
      localStorage.setItem("global_password", password);
    } else {
      alert("Incorrect password");
    }
  };

  if (localStorage.getItem("global_password") == null) {
    if (!isAuthenticated) {
      return <PasswordPrompt onPasswordSubmit={onPasswordSubmit} />;
    }
  }
  // Load Stripe.js outside of the component to avoid recreating stripe object on every render
  const stripePromise = loadStripe(
    "pk_test_51OSLUBFGMXKGSOUsC12PSayRgj8LYiYW4HzFPUBxhxZng53Ldl4e0NyO6FSDST47dpYXZpfQEKTkTGXFGiJSAzlG00tJKitWjN"
  );
  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <Layout />
      </ThemeProvider>
    </Elements>
  );
}

export default App;
