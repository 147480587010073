import React, { useState } from 'react';
import './NavBar.css';
import {
    AppBar,
    Container,
    Toolbar,
    IconButton,
    Box,
    Button,
    List,
    Typography,
    ListItem,
    ListItemButton,
    ListItemText,
    SwipeableDrawer
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../Assets/Images/logo.png";
import Logo2 from '../Assets/Images/Logo2.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import { useNavigate, Link } from "react-router-dom";
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import ContainedBtn from './ContainedBtn';
import SendIcon from '@mui/icons-material/Send';


const useStyles = makeStyles((theme) => ({
    logo: {
        height: '60px',
        maxWidth: '100%',
        margin: '0px 10px'
    },
    navItem: {
        width: 'auto',
        color: 'white',
        textTransform: 'capitalize',
        letterSpacing: '1px !important',
        fontSize: '16px !important',
        fontFamily: 'Obadiah Pro, sans-serif !important',
        lineHeight: '13px',
        fontWeight: '400px !important',
        textAlign: 'center',
        [theme.breakpoints.down("lg")]: {
            fontSize: '14px !important',
            lineHeight: '18px',
        },
    },
    listItem: {
        width: 'auto',
        color: 'white',
        textTransform: 'inherit',
        fontFamily: 'Obadiah Pro, sans-serif !important',
        fontSize: '14px !important',
        lineHeight: '15px',
        fontWeight: 'bold !important',
        textAlign: 'right',
    },
    navItemBtn: {
        maxWidth: '940px',
        height: '100%',
        borderRadius: '0px !important',
        padding: '10px 25px 10px 15px',
        backgroundColor: 'black',
        '&:hover': {
            borderBottom: "2px solid #6DC2D3",
            cursor: 'pointer',
            "& p": {
                color: '#6DC2D3',
                transition: '0.2 s'
            },
            "& span": {
                color: '#6DC2D3',
                transition: '0.2 s'
            },
            transition: '0.2 s'
        }
    },
    navItemBtnIcon: {
        maxWidth: '940px',
        height: '100%',
        padding: '10px 25px 5px 10px',
        backgroundColor: 'black',
        '&:hover': {
            borderBottom: "2px solid #6DC2D3",
            cursor: 'pointer',
            "& p": {
                color: '#6DC2D3',
                transition: '0.2 s'
            },
            transition: '0.2 s'
        }
    },
    navItemList: {
        maxWidth: '100%',
        height: '40px',
        padding: '0px 14px',
        backgroundColor: 'black',
        '&:hover': {
            borderBottom: "2px solid #6DC2D3",
            cursor: 'pointer',
            "& p": {
                color: '#6DC2D3',
                transition: '0.2 s'
            },
            transition: '0.2 s'
        }
    },
    appBarMenu: {
        '& .MuiPaper-root': {
            padding: '0px 15px 0px 0px',
            backgroundColor: 'black',
            borderRadius: '0px',
            boxShadow: 'none',
            width: '144px',
            '& .MuiMenu-list': {
                color: 'white',

            },
        }
    },
    appBarMenuTransParent: {
        '& .MuiPaper-root': {
            padding: '0px 15px 0px 0px',
            backgroundColor: 'transparent',
            borderRadius: '0px',
            boxShadow: 'none',
            width: '144px',
            '& .MuiMenu-list': {
                color: 'white',

            },
        }
    },
    neonText: {
        color: '#160f1a',
        background: 'linear-gradient(to right,#cc00ff,#7000ff)',
        filter: 'drop-shadow(0 5px 20px rgba(204,0,255,.15))',
        WebkitTextStroke: '4px transparent',
        WebkitBackgroundClip: 'text',
        // textAlign:'center',
        marginTop: '10px !important'
    },
    dialogBtnCntnr: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px'
    }
}));





const NavBar = (props) => {
    const classes = useStyles();
    let navigate = useNavigate();

    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);

    const [indDrawerNavItem, setindDrawerNavItem] = useState(-1);
    const [MenuOpenNumber, setMenuOpenNumber] = useState(-1);
    const [openDialog, setopenDialog] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
        setindDrawerNavItem(-1)
    };



    const list = () => (
        <Box
            sx={{ width: 'auto', backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75))', }}
            role="presentation"
            onKeyDown={handleDrawerToggle}
        >
            <List>
                {navItems.map((item, index) => {
                    let { XsComp } = item
                    return (
                        <ListItem key={item.nav} disablePadding style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} >
                            <ListItemButton
                                sx={(theme) => ({
                                    maxWidth: '940px',
                                    height: '100%',
                                    borderRadius: '0px !important',
                                    padding: '10px 25px 10px 15px',
                                    fontFamily: 'Obadiah Pro, sans-serif !important',
                                    '&:hover': {
                                        borderBottom: `2px solid ${item.iconColor}`,
                                        opacity: 1,
                                        cursor: 'pointer',
                                        "& p": {
                                            color: '#FFF',
                                            transition: '0.2 s'
                                        },
                                        "& span": {
                                            color: '#FFF',
                                            transition: '0.2 s'
                                        },
                                        transition: '0.2 s'
                                    }
                                })}
                                onClick={() => {
                                    if (!item.showIcon) {
                                        navigate(`${item.path}`);
                                    }
                                    else {
                                        setindDrawerNavItem(indDrawerNavItem == index ? -1 : index)
                                    }
                                }} >
                                <ListItemText className={classes.listItem} primary={item.nav} />
                                {item?.showIcon &&
                                    <KeyboardArrowDownIcon style={{ color: '#FFF' }} />
                                }
                            </ListItemButton>
                            {(item?.showIcon && indDrawerNavItem == index) && <div>
                                {
                                    <XsComp key={index} closeAppbarNavItem={handleDrawerToggle} />
                                }
                            </div>}
                        </ListItem>
                    )
                }
                )}
                <ListItem disablePadding style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }} >
                    <ContainedBtn
                        text='Login'
                        sx={{ margin: '5px' }}
                        endIcon={<SendIcon />}
                    onClick={() => navigate('/login')}
                    />
                </ListItem>
            </List>
        </Box>
    )




    const openAppbarNavItem = (event, haveMenu, path, ind) => {
        if (haveMenu) {
            setAnchorEl(event.currentTarget);
            setMenuOpenNumber(ind)
        }
        else {
            setAnchorEl(null)
            navigate(`${path}`);
        }

    }

    const closeAppbarNavItem = () => {
        setAnchorEl(null);
        setMenuOpenNumber(-1)
    }

    const navItems = [
        { nav: 'Home', showIcon: false, path: '/', StartIcon: SportsEsportsOutlinedIcon, iconColor: '#570E82' },
        { nav: 'About Us', showIcon: false, path: '/about', StartIcon: SportsEsportsOutlinedIcon, iconColor: '#570E82' },
        { nav: 'Search', showIcon: false, path: '/', StartIcon: SportsEsportsOutlinedIcon, iconColor: 'purple' },
        { nav: 'My Fleet', showIcon: false, path: '/', StartIcon: SportsEsportsOutlinedIcon, iconColor: '#570E82' },
        // { nav: 'Broker Assets', showIcon: false, StartIcon: SportsEsportsOutlinedIcon, iconColor: '#570E82' },
        { nav: 'Membership Benefits', showIcon: false, path: '/membership-benefit', StartIcon: SportsEsportsOutlinedIcon, iconColor: '#570E82' },
        { nav: 'Pricing', showIcon: false, path: '/pricing', StartIcon: SportsEsportsOutlinedIcon, iconColor: '#570E82' },
    ];





    return (
        <div>
            <AppBar component="nav"  style={{
                backgroundColor: 'transparent', boxShadow: 'none', paddingBottom: '30px',
                backgroundImage: `-webkit-linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7))`,
                fontFamily: 'Obadiah Pro, sans-serif', 
            }} >
                <Container maxWidth="fl" style={{ padding: '0px 0px', minHeight: 'auto',fontFamily: 'Obadiah Pro, sans-serif',  }} >
                    <Toolbar variant="dense"
                    // disableGutters 
                    >
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', padding: '10px 0px', }}>
                            <Link to="/" style={{ textDecoration: 'none' }} >
                                <div style={{ margin: 'auto 0px', padding: '8px 0px', display: 'flex', minWidth: '180px' }} >
                                    <img className={classes.logo} src={Logo2} alt="logo" />
                                </div>
                            </Link>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { md: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Box sx={{ display: { xs: 'none', md: 'flex',fontFamily: 'Obadiah Pro, sans-serif', } }}>
                                {navItems.map((Item, ind) => {
                                    let { MdComp } = Item
                                    return (
                                        <Box key={ind} sx={{ margin: { lg: '0px 5px', fl: '0px 10px',fontFamily: 'Obadiah Pro, sans-serif !important' } }} >
                                            <Button sx={(theme) => ({
                                                // maxWidth: '1200px',
                                                // opacity: `${MenuOpenNumber == ind ? 1 : 0.5}`,
                                                height: '100%',
                                                borderRadius: '0px !important',
                                                padding: '10px 20px 10px 10px',
                                                fontFamily: 'Obadiah Pro, sans-serif !important',
                                                // backgroundColor: 'black',
                                                '&:hover': {
                                                    // borderBottom: `2px solid ${Item.iconColor}`,
                                                    opacity: 1,
                                                    cursor: 'pointer',
                                                    "& p": {
                                                        color: '#FFF',
                                                        transition: '0.2 s'
                                                    },
                                                    "& span": {
                                                        color: '#FFF',
                                                        transition: '0.2 s'
                                                    },
                                                    transition: '0.2 s',
                                                }
                                            })}
                                                id={Item + "button"}
                                                onClick={(e) => openAppbarNavItem(e, Item.showIcon, Item?.path, ind)}
                                            >
                                                <Typography key={Item} className={classes.navItem} >

                                                    {Item.nav}
                                                </Typography>
                                                {Item?.showIcon &&
                                                    <KeyboardArrowDownIcon style={{ color: '#FFF', }} />
                                                }
                                            </Button>
                                            {(Item?.showIcon && MenuOpenNumber == ind) && <Menu
                                                id={Item + "menu"}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={closeAppbarNavItem}
                                                disableScrollLock={true}
                                                MenuListProps={{
                                                    'aria-labelledby': (Item + "button"),
                                                }}
                                                sx={{ display: { xs: 'none', md: 'flex' } }}
                                                className={Item.nav == "Play Now" ? classes.appBarMenuTransParent : classes.appBarMenu}
                                                key={ind}
                                            >
                                                {
                                                    <MdComp key={ind} closeAppbarNavItem={closeAppbarNavItem} />
                                                }
                                            </Menu>}
                                        </Box>
                                    )
                                }
                                )}

                                <Box>
                                    <ContainedBtn
                                        text='Login'
                                        sx={{ marginTop: '15px', }}
                                        onClick={() => navigate('/login')}
                                        endIcon={<SendIcon />}
                                    />

                                </Box>
                            </Box>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box component="nav"
            // style={{ marginTop: '70px' }}
            >
                <SwipeableDrawer
                    anchor='top'
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    onOpen={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none', },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box', width: 'auto',
                            position: 'absolute',
                            // top: '50px',
                            backgroundColor: 'transparent'
                        },
                    }}
                >
                    {list()}
                </SwipeableDrawer>
            </Box>
        </div>
    )
}

export default NavBar