import React, { useState } from 'react'
import {
    Typography,
    Grid,
    Container,
    Box,
    OutlinedInput,
    Snackbar,
    CircularProgress,
    IconButton,
} from "@mui/material"
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles'
import ContainedBtn from '../../../Components/ContainedBtn';

import { useSelector, useDispatch } from 'react-redux'
import { addTeamMember, deleteTeamMember } from '../../../Redux/User/UserActions';


import hoverCard from "../../../Assets/Images/hoverCard.png"

import PrintIcon from '@mui/icons-material/Print';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import FullScreenDialog from '../../../Components/Dialog';

import defaultUser from "../../../Assets/Icons/defaultUser.svg"
import { uploadFiles } from '../../../Utils/FirebaseUtils';
import { DeleteIcon } from '../../../Assets/IconsPath/IconsPath';


const useStyles = makeStyles((theme) => ({
    grayBg: {
        backgroundColor: '#F3F3F3',
        borderRadius: '20px'
    },
    textCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    TeamMemberImg: {
        width: '100%',
        height: 'auto',
        maxWidth: '135px',
        maxHeight: '165px',
        borderRadius: '12px',
        marginRight: '20px',
    },
    inputFiledStyle: {
        border: '1px solid black',
        outline: 'none',
        color: '#000 !important',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
        },
    },
    label: {
        display: 'inline-block',
        backgroundColor: '#0074d9',
        color: '#fff',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        '& input': {
            display: 'none'
        }
    },
    backBottomBtn: {
        border: '1px solid #0B1A9B !important',
        color: '#0B1A9B !important',
        background: '#fff !important',
        fontSize: '17px',
        fontWeight: 'bold'
    },
    bottomBtn: {
        background: '#0B1A9B !important',
        color: 'white important',
    },
    deleteIconBtn: {
        border: '1px solid #E34949 !important',
        borderRadius: '5px !important',
        padding: '5px',
        cursor: 'pointer'
    },
}));

const DefaultImage = styled('img')(({ theme }) => ({
    width: '100px',
    height: '100px',
    borderRadius: '5px',
    marginRight: '10px'
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TeamMember = ({ name, role, email, phone, image, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }} mt={1} >
            <img src={image} className={classes.TeamMemberImg} />
            <Box  >
                <Typography variant='h5' sx={{ marginBottom: { xs: '5px' }, fontWeight: 'bold', }} >
                    {name}
                </Typography>
                <Typography>{role} </Typography>
                <Box mt={1} >
                    <Typography sx={{ mb: 1 }} >Email: {email}</Typography>
                    <Typography>Phone: {phone}</Typography>
                </Box>
                <IconButton className={classes.deleteIconBtn}
                    onClick={() => dispatch(deleteTeamMember(id))}
                >
                    {
                        DeleteIcon
                    }
                </IconButton>
            </Box>
        </Box>
    )
}

const TeamMembers = ({ teamData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Grid container spacing={4} mt={1} >
            {
                teamData.map((team, ind) => {
                    return (
                        <Grid item xs={12} nineS={6} key={ind} >
                            <TeamMember
                                name={team.name}
                                role={team.jobTitle}
                                email={team.email}
                                phone={team.phNo}
                                image={team.image}
                                id={team.id}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

const Team = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const userTeam = useSelector((state) => state.user.userTeam)
    const teamUpdating = useSelector((state) => state.user.teamUpdating)

    const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = React.useState(false);

    const [isAddTeamDialogOpen, setIsAddTeamDialogOpen] = useState(false)
    const [addListingLoading, setaddListingLoading] = useState(false)
    const [teamMemberCredentials, setTeamMemberCredentials] = useState({
        name: "",
        email: "",
        phNo: "",
        jobTitle: ""
    })
    const [localProilePicture, setlocalProilePicture] = useState(null)

    const onChangeTeamMemberCredentials = (key, value) => {
        setTeamMemberCredentials({
            ...teamMemberCredentials,
            [key]: value
        })
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setlocalProilePicture(file)
    }


    const hideDialog = () => {
        setIsAddTeamDialogOpen(false)
        setTeamMemberCredentials({
            name: "",
            email: "",
            phNo: "",
            image: ''
        })
        setlocalProilePicture(null)
        setaddListingLoading(false)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsErrorSnackBarOpen(false);
    };

    const createNewTeamMember = () => {



        if (teamMemberCredentials.name.trim().length == 0 ||
            teamMemberCredentials.email.trim().length == 0 ||
            teamMemberCredentials.phNo.trim().length == 0 ||
            teamMemberCredentials.jobTitle.trim().length == 0 ||
            !localProilePicture) {
            setIsErrorSnackBarOpen(true)
        }
        else {
            setaddListingLoading(true)



            uploadFiles([localProilePicture]).then((res) => {
                if (res) {
                    if (res.success) {
                        let { imagesUrls } = res
                        let imageURL = imagesUrls[0]

                        // let team = [...userTeam, { ...teamMemberCredentials, image: imageURL, deleted: false }]
                        let data = {
                            name: teamMemberCredentials.name,
                            email: teamMemberCredentials.email,
                            phNo: teamMemberCredentials.phNo,
                            jobTitle: teamMemberCredentials.jobTitle,
                            image: imageURL,
                            deleted: false
                        }

                        dispatch(addTeamMember(data)).then((res) => {
                            if (res) {
                                setaddListingLoading(false)
                                hideDialog()
                            }
                            else {
                                setaddListingLoading(false)
                            }
                        })

                    }
                }
            })



        }
    }


    return (
        <>
            <Grid container className={classes.grayBg} sx={{ padding: { xs: '20px', ms: '40px' } }}  >
                <Grid item xs={12} className={classes.textCntnr} >
                    <Typography variant='h5' sx={{ marginBottom: { xs: '10px' }, fontWeight: 'bold', }} >
                        My Team
                    </Typography>
                    <ContainedBtn
                        text='Add Team Member'
                        sx={{ '&:hover': { color: '#0B1A9B', outline: '1px solid #0B1A9B' }, height: '50px', width: '255px', marginBottom: '10px' }}
                        endIcon={<GroupAddIcon />}
                        onClick={() => setIsAddTeamDialogOpen(true)}
                    />
                </Grid>
                {!teamUpdating && <Grid item xs={12} >
                    {userTeam.length == 0 ? <Typography variant='h6' sx={{ marginBottom: { xs: '10px' }, fontWeight: 'bold', }} >You have No Member in Team</Typography>
                        : <TeamMembers teamData={userTeam} />}
                </Grid>}
            </Grid>


            <FullScreenDialog
                maxWidth='sm'
                fullWidth={true}
                open={isAddTeamDialogOpen}
                hideDialogHandler={hideDialog}
                style={{
                    background: 'rgba(255, 255, 255, 0.10)',
                    "& .MuiDialog-paper": {
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.20)',
                        borderRadius: '20px',
                        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
                    },
                }}
            >
                <Box p={2} sx={{ border: '2px solid #0B1A9B', borderRadius: '20px', color: '#000' }} >
                    <Typography variant='h4' sx={{ color: '#000' }} >Add Team Member</Typography>


                    <Box mt={2} >
                        <DefaultImage
                            src={localProilePicture ? URL.createObjectURL(localProilePicture) : defaultUser}
                        />
                        <label className={classes.label} >
                            Select Profile Picture
                            <input
                                type="file"
                                accept="image/*" // Accept only image files
                                placeholder='Select Profile Picture'
                                multiple={false}
                                onChange={handleImageChange}
                            />
                        </label>
                    </Box>
                    <Box sx={{ my: 2 }} >
                        <Typography sx={{ color: '#000' }} >Enter Name</Typography>
                        <OutlinedInput
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            required={true}
                            type="text"
                            fullWidth
                            placeholder="Name"
                            name="name"
                            className={classes.inputFiledStyle}
                            onChange={(e) => onChangeTeamMemberCredentials("name", e.target.value)}
                            value={teamMemberCredentials.name}
                        />
                    </Box>
                    <Box sx={{ my: 2 }} >
                        <Typography sx={{ color: '#000' }} >Job Title</Typography>
                        <OutlinedInput
                            id="outlined-basic"
                            label="Job Title"
                            variant="outlined"
                            required={true}
                            type="text"
                            fullWidth
                            placeholder="Job Title"
                            name="jobTitle"
                            className={classes.inputFiledStyle}
                            onChange={(e) => onChangeTeamMemberCredentials("jobTitle", e.target.value)}
                            value={teamMemberCredentials.jobTitle}
                        />
                    </Box>
                    <Box sx={{ my: 2 }} >
                        <Typography sx={{ color: '#000' }} >Enter Email</Typography>
                        <OutlinedInput
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            required={true}
                            type="text"
                            fullWidth
                            placeholder="Email"
                            name="email"
                            className={classes.inputFiledStyle}
                            onChange={(e) => onChangeTeamMemberCredentials("email", e.target.value)}
                            value={teamMemberCredentials.email}
                        />
                    </Box>
                    <Box sx={{ my: 2 }} >
                        <Typography sx={{ color: '#000' }} >Enter Phone Number</Typography>
                        <OutlinedInput
                            id="outlined-basic"
                            label="phNo"
                            variant="outlined"
                            required={true}
                            type="text"
                            fullWidth
                            placeholder="Phone Number"
                            name="phNo"
                            className={classes.inputFiledStyle}
                            onChange={(e) => {
                                const numericValue = e.target.value
                                if (numericValue.length <= 11) {
                                    if (/^\d*$/.test(numericValue)) {
                                        onChangeTeamMemberCredentials("phNo", numericValue)
                                    }
                                }

                            }}
                            value={teamMemberCredentials.phNo}
                        />
                    </Box>

                    <Box className={classes.textCntnr} >
                        <ContainedBtn
                            text='Cancel'
                            className={classes.backBottomBtn}
                            onClick={hideDialog}
                            disbled={addListingLoading}
                        />

                        <ContainedBtn
                            text={addListingLoading ? <CircularProgress size='25px' sx={{ color: '#000' }} /> : 'Add'}
                            className={classes.bottomBtn}
                            diabled={addListingLoading}
                            onClick={createNewTeamMember}
                        />
                    </Box>

                </Box>
            </FullScreenDialog >

            <Snackbar open={isErrorSnackBarOpen} autoHideDuration={1000} onClose={handleClose} >
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                    Empty Input Fields OR Incorrect Data
                </Alert>
            </Snackbar>
        </>
    )
}

export default Team