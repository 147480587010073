import { combineReducers } from 'redux';
import planReducer from './Plans/PlanReducer';
import userReducer from './User/UserReducer';
import listingReducer from './Listing/ListingReducer';



const rootReducer = combineReducers({
    plan: planReducer,
    user: userReducer,
    listings: listingReducer
})

export default rootReducer;