import React from 'react'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles';


const DataText = styled(Box)(({ theme }) => ({
    fontSize: '15px',
    fontFamily: 'Gilroy Regular, sans-serif !important',
    color: '#777777',
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
    letterSpacing: '1px',
    textAlign: 'right',
    marginTop: '10px',
    [theme.breakpoints.up("xs")]: {
        fontSize: '13px',
        letterSpacing: '1px',
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '14px',
        letterSpacing: '1px',
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '15px',
        letterSpacing: '1px',
    },
}));
const DataBox = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent:'flex-end',
    // flexWrap: 'wrap',
    gap: '10px'
}));

const TermCharterHandiPricing = ({ listingDetails, parentClasses, }) => {
    return (
        <Box>
            {
                Object.keys(listingDetails?.termCharter?.pricing)?.map((dayPrice, ind) => {
                    let currentPrice = listingDetails?.termCharter?.pricing?.[dayPrice]
                    return (
                        <Grid item xs={12} key={ind}  >
                            <DataBox mb={1} >
                                <DataText className={parentClasses?.dataTextStyle} >{dayPrice} Day Price : </DataText>
                                <DataText className={parentClasses?.dataTextStyle} >
                                    {
                                        `US$ ${currentPrice}`
                                    }
                                </DataText>
                            </DataBox>
                        </Grid>
                    )
                })

            }
        </Box>
    )
}

export default TermCharterHandiPricing