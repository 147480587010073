import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Box,
  Container,
  Switch,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import {
  getAllListing,
  getAllPDFListingMarkUpPrices,
  setAllMarkUpPrices,
} from "../../Redux/Listing/ListingActions";
import { setUserLoading, getCurrentUser } from "../../Redux/User/UserActions";

import SingleListingDetails from "./Components/SingleListingDetails";
import ComparisonLayout from "./Components/ComparisonLayout";

const useStyles = makeStyles((theme) => ({}));

const MainCntnr = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "90vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const EllipsisText = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "95%", // Optionally set a maximum width
  color: "#17233C",
  fontSize: "15px",
  fontWeight: "bold",
  cursor: "pointer",
});

const PDFViewer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  let { ids } = params;

  let slectedListingIDs = ids.split("-");
  const container = React.useRef(null);
  const pdfExportComponent = useRef(null);

  const currentUser = useSelector((state) => state.user.user);
  const markUpPrices = useSelector((state) => state.listings.markUpPrices);
  const slectedListings = useSelector((state) =>
    state.listings.allListings.filter((item) =>
      slectedListingIDs.includes(item?.id)
    )
  );

  const [markUpPrice, setmarkUpPrice] = useState(
    [...Array(slectedListingIDs.length).keys()].map(() => "")
  );

  const [isEditAllow, setIsEditAllow] = useState(true);

  const [isDescriptionDisplay, setisDescriptionDisplay] = useState(true);
  const [isDataSheetDisplay, setisDataSheetDisplay] = useState(true);
  const [isExtrastDisplay, setisExtrastDisplay] = useState(true);
  const [isTenderToysDisplay, setisTenderToysDisplay] = useState(true);
  const [isHeaderInfoDisplay, setisHeaderInfoDisplay] = useState(true);
  const [isAvaliabilityDisplay, setisAvaliabilityDisplay] = useState(true);
  const [isPhotoAlbumDisplay, setisPhotoAlbumDisplay] = useState(true);
  const [isCaptianInfoDisplay, setisCaptianInfoDisplay] = useState(true);
  const [isMarkUpPrice, setIsMarkUpPrice] = useState(true);
  const [viewOption, setViewOption] = useState(
    slectedListings?.length > 1 ? "Comparison" : "Full View"
  );
  useEffect(() => {
    // dispatch(getAllListing())

    dispatch(getAllPDFListingMarkUpPrices(slectedListingIDs));

    dispatch(setUserLoading(true));
    dispatch(getCurrentUser());

    return () => {
      dispatch(setAllMarkUpPrices({}));
    };
  }, []);

  return (
    <>
      <Container>
        <Grid
          container
          p={2}
          sx={{
            alignSelf: "center",
            // border: "1px solid black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Grid item xs={12} md={9}>
            <Document>
              <Page size="A4">
                <Grid container id="PDFExporter" p={2} ref={container}>
                  {viewOption === "Comparison" && (
                    <Box
                      sx={{
                        display: "flex",
                        mx: 2,
                        alignSelf: "center",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        width: "100%",
                      }}
                    >
                      {/* <Box>
                        {currentUser?.profilePicture && (
                          <TopLogo src={currentUser?.profilePicture} />
                        )}
                      </Box> */}
                      <Box className={classes.topInfo} alignSelf={"flex-end"}>
                        <Typography
                          className={[classes.fontBold, classes.fontFamily]}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            textAlign: "end",
                          }}
                        >
                          {currentUser?.firstName + " " + currentUser?.lastName}{" "}
                        </Typography>
                        <Typography
                          className={classes.smallFont}
                          sx={{
                            fontSize: "12px",
                            textAlign: "end",
                          }}
                        >
                          {currentUser?.email}
                        </Typography>
                        <Typography
                          className={classes.smallFont}
                          sx={{
                            fontSize: "12px",
                            textAlign: "end",
                          }}
                        >
                          {currentUser?.phoneNo}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {slectedListings.map((listing, ind) => {
                    return (
                      <>
                        {viewOption === "Full View" ? (
                          <SingleListingDetails
                            listingDetails={listing}
                            markUpPrice={markUpPrice[ind]}
                            key={ind}
                            ind={ind}
                            totalLength={slectedListings?.length}
                            isDescriptionDisplay={isDescriptionDisplay}
                            isDataSheetDisplay={isDataSheetDisplay}
                            isExtrastDisplay={isExtrastDisplay}
                            isTenderToysDisplay={isTenderToysDisplay}
                            isHeaderInfoDisplay={isHeaderInfoDisplay}
                            isAvaliabilityDisplay={isAvaliabilityDisplay}
                            isPhotoAlbumDisplay={isPhotoAlbumDisplay}
                            isCaptianInfoDisplay={isCaptianInfoDisplay}
                            isEditAllow={isEditAllow}
                            setIsEditAllow={(state) => setIsEditAllow(state)}
                            isMarkUpPrice={isMarkUpPrice}
                          />
                        ) : (
                          <ComparisonLayout
                            listingDetails={listing}
                            markUpPrice={markUpPrice[ind]}
                            key={ind}
                            ind={ind}
                            isDescriptionDisplay={isDescriptionDisplay}
                            isDataSheetDisplay={isDataSheetDisplay}
                            isExtrastDisplay={isExtrastDisplay}
                            isTenderToysDisplay={isTenderToysDisplay}
                            isHeaderInfoDisplay={isHeaderInfoDisplay}
                            isAvaliabilityDisplay={isAvaliabilityDisplay}
                            isPhotoAlbumDisplay={isPhotoAlbumDisplay}
                            isCaptianInfoDisplay={isCaptianInfoDisplay}
                            isEditAllow={isEditAllow}
                            setIsEditAllow={(state) => setIsEditAllow(state)}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
              </Page>
            </Document>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PDFViewer;
