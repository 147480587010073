import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles, withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useSelector, useDispatch } from "react-redux";
import { logOutUser } from "../Redux/User/UserActions";

import profileImg from "../Assets/Icons/defaultUser.svg";

import Home from "../Assets/Icons/Home.png";
import Message from "../Assets/Icons/Message.png";
import Paper from "../Assets/Icons/Paper.png";
import Setting from "../Assets/Icons/Setting.png";
import Wallet from "../Assets/Icons/Wallet.png";
import HomeLogo from "../Assets/Icons/Home2.png";
import msLogo from "../Assets/Icons/ms2.png";
import Chart from "../Assets/Icons/Chart.png";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ListAltIcon from "@mui/icons-material/ListAlt";

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  closeIconBtnCntnr: {
    width: "330px",
    display: "flex",
    justifyContent: "end",
    margin: "20px 0px",
  },
  sliderCntnr: {
    disply: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "330px",
    paddingLeft: "20px",
  },
  personIcon: {
    height: "60px",
    width: "60px",
    borderRadius: "40px",
    // marginRight: '10px'
  },
  iconButton: {
    border: "5px solid white !important",
    backgroundColor: "#0B1A9B !important",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#0B1A9B",
  // marginTop: "72px"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "#0B1A9B",
  // marginTop: "72px"
});

const PermanentDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#0B1A9B",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const CustomDrawer = (props) => {
  const { Component } = props;

  let accountType = localStorage.getItem("accountType");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const currentUser = useSelector((state) => state.user.user);

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // console.log(isDesktop, "isDesktop")

  const home = () => {
    navigate("/dashboard");
  };
  const mls = () => {
    navigate("/mls");
  };

  const inventory = () => {
    navigate("/inventory");
  };

  const reports = () => {
    navigate("/reports");
  };
  const subcription = () => {
    navigate("/pricing");
  };
  const account = () => {
    navigate("/account");
  };

  const logout = () => {
    dispatch(logOutUser(navigate));
  };

  let viewAndUploadDrawerRoutes = [
    {
      name: "Home",
      functn: home,
      Icon: HomeLogo,
    },
    {
      name: "Inventory",
      functn: inventory,
      Icon: Paper,
    },
    // {
    //     name: 'Reports',
    //     functn: reports,
    //     Icon: Setting
    // },
    {
      name: "MLS",
      functn: mls,
      Icon: msLogo,
    },
    {
      name: "Account",
      functn: account,
      Icon: Wallet,
    },
    {
      name: "Logout",
      functn: logout,
      Icon: Setting,
    },
  ];

  let viewOnlyDrawerRoutes = [
    {
      name: "MLS",
      functn: mls,
      Icon: Message,
    },
    {
      name: "Buy Subcription",
      functn: subcription,
      Icon: Chart,
    },
    {
      name: "Account",
      functn: account,
      Icon: Wallet,
    },
    {
      name: "Logout",
      functn: logout,
      Icon: Setting,
    },
  ];

  const getDrawerOptions = (accountStatus) => {
    let drawerRoutes = {
      viewOnly: viewOnlyDrawerRoutes,
      viewAndUpload: viewAndUploadDrawerRoutes,
    };

    return drawerRoutes[accountStatus] || viewOnlyDrawerRoutes;
  };

  const list = (anchor) => (
    <Box
      sx={{
        backgroundColor: "#0B1A9B",
        pt: 5,
        height: "-webkit-fill-available",
      }}
      role="presentation"
      onClick={() => handleDrawerToggle()}
      onKeyDown={handleDrawerToggle}
    >
      <List>
        {getDrawerOptions(accountType).map(({ name, Icon, functn }, index) => (
          <ListItem key={name} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                mt: 1,
              }}
              onClick={() => {
                functn();
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Icon}
                  alt="icon"
                  style={{ width: "25px", height: "25px" }}
                />
              </ListItemIcon>
              <ListItemText primary={name} sx={{ color: "white" }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {isDesktop && (
        <Box sx={{ position: "fixed", top: "5px", left: "10px", zIndex: 999 }}>
          <IconButton
            className={classes.iconButton}
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowRightIcon sx={{ color: "white" }} />
            ) : (
              <KeyboardArrowLeftIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </Box>
      )}
      {!isDesktop && (
        <Box sx={{ position: "fixed", top: "20px", right: "20px", zIndex: 999 }}>
          <IconButton
            className={classes.iconButton}
            onClick={() => setMobileOpen(!mobileOpen)}
          >
            <MenuOpenIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
      )}
      <Box
        // sx={{
        //     display: '-webkit-box',
        //      }}
        sx={{ flexShrink: { sm: 0 }, display: "flex", position: "sticky" }}
      >
        {isDesktop ? (
          <PermanentDrawer variant="permanent" open={!open}>
            <Box mt={10} px={1.5} style={{ display: open ? "none" : "flex" }}>
              <img
                src={currentUser?.profilePicture || profileImg}
                className={classes.personIcon}
              />
              <Box m={1}>
                <span style={{ fontSize: "12px", color: "white" }}>
                  Welcome
                </span>
                <Typography sx={{ color: "white", fontWeight: "bold" }}>
                  {currentUser?.displayName || currentUser?.firstName}
                </Typography>
                <span style={{ fontSize: "12px", color: "white" }}>
                  {currentUser?.accountType === "free"
                    ? String(currentUser?.accountType).toLocaleUpperCase()
                    : "Premium"}
                </span>
              </Box>
            </Box>

            <List>
              {getDrawerOptions(accountType).map(
                ({ name, Icon, functn }, index) => (
                  <ListItem key={name} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 3,
                        mt: 1,
                      }}
                      onClick={() => {
                        functn();
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: !open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={Icon}
                          alt="icon"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={name}
                        sx={{ opacity: !open ? 1 : 0, color: "white" }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </List>
          </PermanentDrawer>
        ) : (
          <>
            <Drawer
              // container={container}
              anchor="left"
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                // display: { xs: 'block', sm: 'none' },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  backgroundColor: "#F7F7F7",
                },
              }}
            >
              {list()}
            </Drawer>
          </>
        )}
        <Box sx={{ flexGrow: 1, p: 0 }}>
          <Component />
        </Box>
      </Box>
    </>
  );
};

export default CustomDrawer;
