import React from 'react'
import {
    Typography,
    Grid,
    Paper,
    Box,
} from "@mui/material"
import { makeStyles } from "@mui/styles";

import shipOne from "../../../Assets/Images/shipOne.png"
import shipTwo from "../../../Assets/Images/shipTwo.png"
import shipThree from "../../../Assets/Images/shipThree.png"
import shipFour from "../../../Assets/Images/shipFour.png"

import { useSelector, useDispatch } from 'react-redux'
import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    BarSeries,
} from "@devexpress/dx-react-chart-material-ui";
import { PieChart } from '@mui/x-charts/PieChart';


const useStyles = makeStyles((theme) => ({
    grayBg: {
        backgroundColor: '#F3F3F3',
        borderRadius: '20px'
    },
    alignCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    galleryImg: {
        // width: '100%',
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '500px',
        // maxWidth: '450px',
        borderRadius: '20px'
    }
}));


const GalleryView = ({ galleryImages }) => {
    const classes = useStyles();


    return (
        <>
            <Grid container spacing={4} mt={1} >
                {
                    galleryImages.map((image, ind) => {
                        return (
                            <Grid item xs={12} nineS={6} key={ind} className={classes.alignCenter} >
                                <img src={image} alt="Gallery" className={classes.galleryImg} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

const palette = ['#db3737', '#0d6efd', '#00a629', '#0dcaf0', '#ffd333'];


const Gallery = () => {
    const classes = useStyles();
    const data = [
        { years: "Jan", Dollors: 50 },
        { years: "Feb", Dollors: 400 },
        { years: "Mar", Dollors: 300 },
        { years: "apr", Dollors: 400 },
        { years: "May", Dollors: 1000 },
        { years: "Jun", Dollors: 600 },
        { years: "Jul", Dollors: 1200 },
        { years: "Aug", Dollors: 600 },
        { years: "Sep", Dollors: 900 },
        { years: "Oct", Dollors: 300 },
        { years: "Nov", Dollors: 1100 },
        { years: "Dec", Dollors: 650 },
    ];

    const listingLoading = useSelector((state) => state.listings.loading)
    const allMyListings = useSelector((state) => state.listings.allMyListings)
    return (
        <>
            <Grid container className={classes.grayBg} sx={{ padding: { xs: '20px', ms: '40px' } }}  >
                {/* <Grid item xs={12} >
                    <Typography variant='h4' sx={{ marginBottom: { xs: '10px' }, fontWeight: 'bold', }} >
                        Income
                    </Typography>
                    <Grid display={'flex'} gap={5}>
                        <Box sx={{ bgcolor: 'white', width: 300 }}>
                            <Typography sx={{ fontSize: '18px', pt: 3, ml: 3 }}>Active users</Typography>
                            <Box sx={{ bgcolor: '#d9ecff', color: '#004b9a', textAlign: 'center', padding: '30px', m: 3, fontSize: '40px', fontWeight: 600 }}>143</Box>
                            <Box display={'flex'} mx={3} justifyContent={'space-between'} borderBottom={'1px solid'} color={'#6c757d'}>
                                <Typography>Active pages</Typography>
                                <Typography>Users</Typography>
                            </Box>
                            <Box display={'flex'} mx={3} justifyContent={'space-between'} borderBottom={'1px solid'} pt={2}>
                                <Typography fontSize={'14px'}>/products/brandix-z4</Typography>
                                <Typography fontSize={'14px'}>15</Typography>
                            </Box>
                            <Box display={'flex'} mx={3} justifyContent={'space-between'} borderBottom={'1px solid'} pt={2}>
                                <Typography fontSize={'14px'}>/categories/monitors</Typography>
                                <Typography fontSize={'14px'}>11</Typography>
                            </Box>
                            <Box display={'flex'} mx={3} justifyContent={'space-between'} borderBottom={'1px solid'} pt={2}>
                                <Typography fontSize={'14px'}>/account/orders</Typography>
                                <Typography fontSize={'14px'}>7</Typography>
                            </Box>
                            <Box display={'flex'} mx={3} justifyContent={'space-between'} borderBottom={'1px solid'} pt={2}>
                                <Typography fontSize={'14px'}>/cart</Typography>
                                <Typography fontSize={'14px'}>4</Typography>
                            </Box>
                            <Box display={'flex'} mx={3} justifyContent={'space-between'} borderBottom={'1px solid'} pt={2}>
                                <Typography fontSize={'14px'}>/checkout</Typography>
                                <Typography fontSize={'14px'}>3</Typography>
                            </Box>
                            <Box display={'flex'} mx={3} justifyContent={'space-between'} borderBottom={'1px solid'} pt={2}>
                                <Typography fontSize={'14px'}>/Pages/about-us</Typography>
                                <Typography fontSize={'14px'}>1</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Paper >
                                <Chart data={data} width={830}>
                                    <ArgumentAxis />
                                    <ValueAxis />

                                    <BarSeries
                                        valueField="Dollors"
                                        argumentField="years"
                                    />
                                </Chart>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid> */}

                {allMyListings.length == 0 ? <Typography variant='h4' >You have no Listings </Typography> : <Grid item xs={12} >
                    {!listingLoading && <GalleryView galleryImages={allMyListings.map(listing => listing?.imagesUrls[0])} />}
                </Grid>}
            </Grid>
            {/* <Grid sx={{
                backgroundColor: '#F3F3F3',
                borderRadius: '20px',
                mt: 3,
                display: 'flex',
                justifyContent: 'space-between',
                padding: { xs: '20px', ms: '40px' }
            }}>
                <Box width={800}>
                    <Typography variant='h5' sx={{ marginBottom: { xs: '10px' }, fontWeight: 'bold', }}>Our Gallery</Typography>
                    {allMyListings.length == 0 ? <Typography variant='h4' >You have no Listings </Typography> : <Grid item xs={12} >
                        {!listingLoading && <GalleryView galleryImages={allMyListings.map(listing => listing?.imagesUrls[0])} />}
                    </Grid>}
                </Box>
                <Box>
                    <PieChart
                        colors={palette}
                        series={[
                            {
                                data: [
                                    { id: 0, value: 10, label: 'Red' },
                                    { id: 1, value: 15, label: 'Blue' },
                                    { id: 2, value: 20, label: 'Green' },
                                    { id: 3, value: 20, label: 'Sky blue' },
                                    { id: 4, value: 20, label: 'Yellow' },
                                ],
                            },
                        ]}
                        width={400}
                        height={200}
                    />
                </Box>
            </Grid> */}
        </>
    )
}

export default Gallery
