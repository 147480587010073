// import SingleListingDetails from "../GeneratePdfs/Components/SingleListingDetails";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { React, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Box,
  Container,
  Switch,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import SinglePDF from './Components/SinglePDF'

const AddPDF = () => {
  return (
    <>
    <SinglePDF/>
    </>
  );
};

export default AddPDF;
