import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";


import SectionHead from '../../../Components/SectionHead';
import { FeaturesData } from '../../../State/Home';


const useStyles = makeStyles((theme) => ({
    serviceImg: {
        width: 'fit-content',
        height: 'auto',
        marginBottom: '10px'
    }
}));

const Features = () => {
    const classes = useStyles();
    return (
        <div style={{ backgroundColor: '#F0F0F0', padding: '20px 0px' }}  >
            <Container maxWidth='fl'>
                <Grid container mt={10} mb={5} >
                    <Grid item xs={12} sx={{ margin: { lg: '24px 0px 60px 0px', md: '20px 0px 40px 0px', xs: '0px 0px 30px 0px' } }} >
                        <SectionHead title='Features' sx={{ textAlign: 'center' }} />
                    </Grid>
                    {
                        FeaturesData.map((feature, ind) => {
                            return (
                                <Grid key={ind} item xs={12} sm={6} lg={4} sx={{ marginBottom: { xs: '50px', sm: '10px', md: '10px', lg: '0px' } }} >
                                    <Box sx={{ margin: { lg: '32px' }, display: { xs: 'flex', md: 'block' }, flexDirection: 'column',alignItems:'center' }} >
                                        <img src={feature.image} alt="Features" className={classes.serviceImg} />
                                        <Typography variant='h5' sx={{ marginBottom: '10px', fontWeight: 'bold',textAlign:{xs:'center', md:'left'} }} > {feature.title} </Typography>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </div>
    )
}

export default Features