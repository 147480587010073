import React, { useState } from 'react'
import {
    Typography,
    Grid,
    Container,
    Button,
    Box,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    InputBase,
    NativeSelect,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    TablePagination,
    CircularProgress,
} from "@mui/material"
import { makeStyles, withStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import EllipseShip from "../../../Assets/Images/EllipseShip.png"
import ContainedBtn from '../../../Components/ContainedBtn';

import { useSelector, useDispatch } from 'react-redux'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useNavigate } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIcon } from '../../../Assets/IconsPath/IconsPath';

import { deleteListing, toggleListingStatus } from '../../../Redux/Listing/ListingActions';



const useStyles = makeStyles((theme) => ({
    iconImg: {
        height: '20px',
        width: '20px',
        marginRight: '10px',
    },
    tableContainer: {
        fontFamily: "Gilroy Regular, sans-serif !important",
    },
    tableRow: {
        cursor: 'pointer',
        backgroundColor: '#F3F3F3'
    },
    tableCell: {
        fontFamily: "Gilroy Regular, sans-serif !important",
        // backgroundColor: "#D6E2F5",
        background: 'rgba(51, 128, 255, 0.15)',
        color: "#000",
        textAlign: "left",
        fontWeight: "bold !important",
        fontSize: "16px !important",
        textTransform: "upperCase",
    },
    tableCellBody: {
        textAlign: "left",
    },
    select: {
        border: '1px solid #ced4da',
        borderRadius: 4,
        outline: 'none',
        padding: '5px',
        outline: 'none',
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
        },
    },
    ellipseShip: {
        height: '40px',
        width: '40px',
        borderRadius: '17px'
    },
    nativeSelect: {
        width: "100%",
        border: '1px solid gray',
    },
    actionBtn: {
        backgroundColor: '#0B1A9B',
        color: 'white',
        "&:hover": {
            backgroundColor: '#0B1A9B !important',
            color: 'white !important',
        }
    },
    actionCntnr: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
    },
    deleteIconBtn: {
        // width: '28px',
        // height: '28px',
        border: '1px solid #E34949 !important',
        borderRadius: '5px !important',
        padding: '5px',
        cursor: 'pointer'
    },
    editIconBtn: {
        // width: '28px',
        // height: '28px',
        border: '1px solid #0B1A9B !important',
        borderRadius: '5px !important',
        padding: '5px',
        cursor: 'pointer'
    },
}));


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));



const InventoryTable = ({ setPrintListingIDs, printListingIDs }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [deleteLoading, setDeleteLoading] = useState(false)
    const [statusToggleLoading, setStatusToggleLoading] = useState(false)
    const [listingID, setListingID] = useState(false)


    const allMyListings = useSelector((state) => state.listings.allMyListings)

    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const currentDay = daysOfWeek[dayOfWeek];

    let listingPricingType = {
        dayCharter: 'Day Charter',
        termCharter: 'Term Charter'
    }

    const addListingToGeneratePDF = (listingId) => {
        let updatePrintListingIDs = [...printListingIDs]
        updatePrintListingIDs.push(listingId)
        setPrintListingIDs([...updatePrintListingIDs])
    }

    const removeListingFromGeneratePDF = (listingId) => {
        let updatePrintListingIDs = printListingIDs.filter((itemID) => itemID != listingId)
        setPrintListingIDs([...updatePrintListingIDs])
    }

    return (
        <>
            {allMyListings.length == 0 ? <Typography variant='h5' >You have no Listings</Typography> : <Box sx={{ backgroundColor: "#F3F3F3", borderRadius: '10px' }} pb={4} >
                {/* <Box py={2} px={3} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} >
                    <ContainedBtn text="Group Actions" className={classes.actionBtn} />
                </Box> */}
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableHead  >
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    {/* <Checkbox /> */}
                                </TableCell>
                                <TableCell className={classes.tableCell}>Image</TableCell>
                                <TableCell className={classes.tableCell}>Title</TableCell>
                                <TableCell className={classes.tableCell}>Price</TableCell>
                                <TableCell className={classes.tableCell}>Type</TableCell>
                                <TableCell className={classes.tableCell}>Status</TableCell>
                                <TableCell className={classes.tableCell}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                allMyListings.map((listing, ind) => {
                                    return (
                                        <TableRow key={ind} className={classes.tableRow}  >
                                            <TableCell className={classes.tableCellBody} >
                                                <IconButton p={0}
                                                    disabled={!listing?.active}
                                                    onClick={() => {
                                                        printListingIDs.includes(listing?.id) ? removeListingFromGeneratePDF(listing?.id) : addListingToGeneratePDF(listing?.id)
                                                    }} >
                                                    {printListingIDs.includes(listing?.id) ? <CheckBoxIcon sx={{ color: '#0B1A9B' }} /> :
                                                        <CheckBoxOutlineBlankIcon />}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <img src={listing.imagesUrls[0]} alr="Listing Image" className={classes.ellipseShip} />
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody}  >
                                                {listing?.title}
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody} >
                                                {/* {listing?.hourlyPrice}+/Hour */}
                                                {
                                                    listing?.pricingType === "dayCharter" ?
                                                        `US$ ${listing[listing?.pricingType]?.price}`
                                                        : `US$ ${listing[listing?.pricingType]?.pricing[1]}`
                                                }
                                                {/* {listing?.pricingType === "dayCharter" ? listing?.availability[currentDay] ? ' +/HOUR' : '' : ' +/DAY'} */}
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody}  >
                                                {listingPricingType[listing?.pricingType]}
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody} sx={{ color: listing?.active ? "#219653" : "#E34949" }} >
                                                {listing?.active ? 'Active' : "In-Active"}
                                            </TableCell>
                                            <TableCell>
                                                <Box className={classes.actionCntnr}  >
                                                    <IconButton sx={{ mt: -1 }}
                                                        className={classes.deleteIconBtn}
                                                        disabled={deleteLoading}
                                                        onClick={() => {
                                                            setListingID(listing?.id)
                                                            setDeleteLoading(true)
                                                            dispatch(deleteListing(listing?.id)).then((res) => {
                                                                if (res) {
                                                                    setDeleteLoading(false)
                                                                    setListingID('')
                                                                }
                                                                setDeleteLoading(false)

                                                            })
                                                        }}
                                                    >
                                                        {
                                                            (deleteLoading && listingID == listing?.id) ? <CircularProgress sx={{ color: '#F84C4C' }} size={24} /> : DeleteIcon
                                                        }

                                                    </IconButton>
                                                    <IconButton sx={{ mt: -1 }}
                                                        className={classes.editIconBtn}
                                                        // disabled={deleteLoading}
                                                        onClick={() => {
                                                            navigate(`/editlisting/${listing?.id}`)
                                                        }}
                                                    >
                                                        {
                                                            <EditIcon sx={{ color: '#0B1A9B' }} />
                                                        }

                                                    </IconButton>
                                                    <IconButton sx={{ mt: -1 }}
                                                        className={classes.editIconBtn}
                                                        disabled={statusToggleLoading}
                                                        onClick={() => {
                                                            setStatusToggleLoading(true)
                                                            dispatch(toggleListingStatus(listing?.id, !listing?.active)).then((res) => {
                                                                if (res) {
                                                                    setStatusToggleLoading(false)
                                                                }
                                                                setStatusToggleLoading(false)

                                                            })
                                                        }}
                                                    >
                                                        {
                                                            listing?.active ? <VisibilityIcon sx={{ color: '#219653' }} /> : <VisibilityOffIcon sx={{ color: '#E34949' }} />
                                                        }

                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
        </>
    )
}

export default InventoryTable