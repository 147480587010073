import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";


import SectionHead from '../../../Components/SectionHead';
import ContainedBtn from '../../../Components/ContainedBtn';

import pexels from "../../../Assets/Images/pexels.png"
import videoFrame from "../../../Assets/Images/videoFrame.png"
import sendIcon from "../../../Assets/Icons/sendIcon.png"


const useStyles = makeStyles((theme) => ({
    virtualBg: {
        width: '100% !important',
        height: 'auto',
        backgroundImage: `url(${pexels})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    videFrame: {
        // width: 'fit-content',
        // height: 'auto',
        width: '100%',
        height: '100%',
        marginBottom: '10px'
    }
}));

const Virtual = () => {
    const classes = useStyles();
    return (
        <div className={classes.virtualBg} >
            <Container maxWidth='fl'  >
                <Grid container py={8}  >
                    <Grid item xs={12} mb={5} >
                        <SectionHead title='Take a  Virtual Tour' sx={{ textAlign: 'left' }} />
                    </Grid>
                    {
                        [1, 2, 3].map((videoBg, ind) => {
                            return (
                                <Grid item xs={12} sm={6} lg={4} >
                                    <Box m={2} key={ind} >
                                        <img src={videoFrame} className={classes.videFrame} />
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                    <Grid item xs={12} mt={10} py={7} sx={{ backgroundColor: '#0B1A9B', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                        <Typography
                            sx={{ fontWeight: 'bold', fontSize: { xs: 'xx-large', sm: '40px ' }, color: 'white', paddingBottom: '20px',textAlign:{xs:'center', md:'left'}, fontFamily: 'Obadiah Pro, sans-serif'  }}
                        > Call us for more information </Typography>
                        <Typography sx={{ maxWidth: '500px', textAlign: 'center', color: 'white', paddingBottom: '30px', fontFamily: 'Gilroy Regular, sans-serif !important' }} >
                            There are many variations of passages of Lorem Ipsum available,
                            have suffered alteration.
                        </Typography>
                        <ContainedBtn
                            text='Become a Member'
                            sx={{ '&:hover': { color: '#0B1A9B',backgroundColor:'white'} ,outline: '1px solid white !important'  }}
                            endIcon={<img src={sendIcon} style={{ width: '14px', height: '14px', }} alt="icon" />}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Virtual