import React from 'react'
import {
    Typography,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    textCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    }
}));

const MainCntnr = styled(Box)(({ theme }) => ({
    backgroundColor: "#0B1A9B",
    padding: theme.spacing(3),
    height: 'auto',
}));


const LatestUpdate = () => {
    const classes = useStyles();


    return (
        <>
            <MainCntnr>
                <Typography sx={{ textAlign: 'center',color:'#FFFFFF' }} >
                    See what’s new! Click here for the latest updated to the Rent by Broker reporting suite
                </Typography>
            </MainCntnr>
        </>
    )
}

export default LatestUpdate