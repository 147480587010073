import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";


import { TeamData } from '../../../State/Home';
import SectionHead from '../../../Components/SectionHead';
import SingleSlideCarousell from '../../../Components/SingleSlideCarousell';

import pexels from "../../../Assets/Images/pexels.png"


const useStyles = makeStyles((theme) => ({
    teamImg: {
        // width: 'fit-content',
        // height: 'auto',
        width: '100%',
        height: '100%',
        marginBottom: '10px'
    },
    virtualBg: {
        width: '100% !important',
        height: 'auto',
        // backgroundImage: `url(${virtualBg})`,
        backgroundImage: `url(${pexels})`,
        backgroundSize:'cover',
        backgroundPosition:'center',
        backgroundRepeat:'no-repeat'
        // backgroundSize: 'auto, cover,cover',
        // backgroundPosition: '0px 0px, 50% 0%,50% 0%',
        // backgroundRepeat: 'no-repeat,no-repeat,repeat-y',
        // [theme.breakpoints.down("md")]: {
        //     backgroundSize: 'auto, cover,cover',
        //     backgroundPosition: '0px 0px, 82% 0%,50% 0%',
        //     backgroundRepeat: 'repeat,no-repeat,repeat-y'
        // },
    }
}));


const Testimonal = () => {
    const classes = useStyles();
    return (
        <>
            <Container maxWidth='fl'>
                <Grid container mt={10} mb={5} >
                    <Grid item xs={12} sx={{ margin: { lg: '24px 0px 30px 0px', md: '20px 0px 30px 0px', xs: '0px 0px 20px 0px' } }} >
                        <SectionHead title='Testimonal' sx={{ textAlign: 'center' }} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: { xs: 'flex', }, flexDirection: 'column', alignItems: 'center' }} >
                        <Typography sx={{ maxWidth: '612px', textAlign: 'center' ,fontFamily: 'Gilroy Regular, sans-serif !important'}} >
                            Sign up for Benchmark today to stay focused on the reason you're
                            using email marketing in the first place: bringing your vision to life.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={8} >
                        <SingleSlideCarousell />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Testimonal