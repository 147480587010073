import React from "react";
import { Box, Typography, Snackbar } from "@mui/material";
import BoatAvailability from "./BoatAvailability";
import Pricing from "../Pricing/Pricing";
const BoatAvailabilityAndPricing = ({
  onChangeListingCredentials,
  listingCredentials,
}) => {
  return (
    <>
      <Box>
        <Box>
          <BoatAvailability
            onChangeListingCredentials={onChangeListingCredentials}
            listingCredentials={listingCredentials}
          />
          <Box mt={5}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: {
                  xs: "xx-large",
                //   xs: "30px",
                  sm: "40px ",
                  sevenHalfS: "25px",
                  nineS: "30px",
                  md: "35px",
                  lg: "40px",
                },
                marginBottom: { xs: "24px", sevenHalfS: "15px", md: "30px" },
              }}
            >
              Now, add your Prices
            </Typography>
            <Pricing
              onChangeListingCredentials={onChangeListingCredentials}
              listingCredentials={listingCredentials}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BoatAvailabilityAndPricing;
