import React from 'react'
import {
    Grid
} from "@mui/material";
import { makeStyles, } from "@mui/styles";
import memberBanner from "../../../Assets/Images/memberBanner.png"
import PricingBanner from '../../../Assets/Images/pricingBanner.png'
import MainHeading from '../../../Components/MainHeading';


const useStyles = makeStyles((theme) => ({
    homeCover: {
        width: '100%',
        height: '80vh',
        backgroundImage: `-webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)),url(${PricingBanner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover, cover,cover',
        // backgroundPosition: '0px 0px, 50% 0%,50% 0%',
        // backgroundRepeat: 'no-repeat,no-repeat,repeat-y',
        [theme.breakpoints.down("md")]: {
            backgroundSize: 'auto, cover,cover',
            backgroundPosition: '0px 0px, 82% 0%,50% 0%',
            backgroundRepeat: 'repeat,no-repeat,repeat-y'
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));




const BannerOne = () => {

    const classes = useStyles();
    return (
        <Grid container >
            <Grid item xs={12} className={classes.homeCover} >
                <MainHeading sx={{
                    color: 'white',
                    textTransform: 'capitalize',
                    fontWeight: '400',
                    textAlign:'center'
                }} text={<> Pricing</>} />
            </Grid>
        </Grid>
    )
}

export default BannerOne