import { collection, doc, setDoc, getDocs } from "firebase/firestore";
import { db, auth, storage } from "../FireBase/firebase";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';



export const uploadFiles = async (files) => {
    if (!files || files.length === 0) {
        return {
            success: false,
            message: 'No files selected',
        };
    }

    try {
        const fileUrls = [];

        for (const file of files) {
            // Create a unique file name based on timestamp
            const timestamp = Date.now();
            const fileName = `${timestamp}_${file.name}`;

            // Create a storage reference for the file
            const fileRef = ref(storage, `images/${fileName}`);

            // Upload the file to Firebase Storage
            await uploadBytes(fileRef, file);

            // Get the download URL for the uploaded file
            const downloadURL = await getDownloadURL(fileRef);

            // Push the download URL to the array
            fileUrls.push(downloadURL);
        }

        return {
            success: true,
            message: 'Images uploaded successfully',
            imagesUrls: fileUrls,
        };
    } catch (error) {
        console.error('Error uploading Images:', error);
        return {
            success: false,
            message: 'Error uploading Images',
        };
    }
};


export const savePdfToFirebaseStorage = async (dataUri) => {
    try {
        // Generate the PDF
        // const dataUri = await savePDF(pdfExportComponent, { paperSize: 'A4' });

        // Generate a unique filename based on timestamp
        const timestamp = Date.now();
        const fileName = `pdf_${timestamp}.pdf`;

        // Create a storage reference for the PDF file
        const fileRef = ref(storage, `pdfs/${fileName}`);

        // Upload the PDF Data URL to Firebase Storage
        await uploadBytes(fileRef, new Blob([dataUri], { type: 'application/pdf' }));

        // Get the download URL for the uploaded file
        const downloadURL = await getDownloadURL(fileRef);

        console.log('PDF uploaded to Firebase Storage:', downloadURL);

        return downloadURL;
    } catch (error) {
        console.error('Error saving PDF to Firebase Storage:', error);
        return null;
    }
};