import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { updateListingViews } from "../../../Redux/Listing/ListingActions";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ContainedBtn from "../../../Components/ContainedBtn";

const useStyles = makeStyles((theme) => ({
  listingImg: {
    height: "100%",
    width: "100%",
    borderRadius: "15px 0px 0px 15px",
    objectFit: "cover",
    overflow: "hidden",
    // borderRadius:'15px',
  },
  address: {
    color: "rgb(143, 150, 151) !important",
    textTransform: "uppercase",
    fontSize: "13px !important",
    fontWeight: "600 !important",
  },
  displayBetween: {
    display: "flex",
    gap: "10px",
  },
  title: {
    color: "#17233C !important",
    textTransform: "uppercase",
    fontSize: "18px !important",
    fontWeight: "bold !important",
  },
  price: {
    color: "#000 !important",
    textTransform: "uppercase",
    fontSize: "15px !important",
    fontWeight: "bold !important",
  },
}));

const ListingCntnr = styled(Box)(({ theme }) => ({
  borderRadius: "15px",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px",
  transition: "300ms",
  "&:hover": {
    boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 10px",
  },
  width: "100%",
  // minHeight: '350px',
  height: "260px",
  backgroundColor: "#fff",
  cursor: "pointer",
}));
const ImageBox = styled(Box)(({ theme }) => ({
  height: "260px",
  width: "100%",
  position: "relative",
  overflow: "hidden",
  // borderRadius: '15px 15px 15px 15px'
}));
const PriceBox = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "5px",
  position: "absolute",
  bottom: "5px",
  right: "5px",
}));
const CheckBoxComp = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "5px",
  position: "absolute",
  top: "5px",
  left: "5px",
  zIndex: "99",
}));

const EllipsisText = styled(Typography)({
  // overflow: "hidden",
  // textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
  // maxWidth: "100%", // Optionally set a maximum width
  // color: "#17233C",
  // textTransform: "uppercase",
  // fontSize: "18px",
  fontWeight: "bold",
});

const DataText = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap", // Prevent text from wrapping to next line
  width: "inherit",
  fontSize: "18px",
  color: "#777777",
  fontFamily: "Gilroy Regular, sans-serif !important",
  fontWeight: "bold",
  letterSpacing: "1px",
  marginTop: "8px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
    letterSpacing: "1px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    letterSpacing: "1px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
    letterSpacing: "1px",
  },
}));

const ListingCard = ({
  image,
  year,
  formattedAddress,
  title,
  pricing,
  make,
  model,
  boatLength,
  noOfPassengers,
  id,
  isAlreadyExists,
  pricingType,
  availability,
  currentDay,
  setPrintListingIDs,
  printListingIDs,
  ListingUser,
  brokerName,
  companyName,
  phoneNo,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const uid = localStorage.getItem("uid");

  const addListingToGeneratePDF = (listingId) => {
    let updatePrintListingIDs = [...printListingIDs];
    updatePrintListingIDs.push(listingId);
    setPrintListingIDs(updatePrintListingIDs);
  };

  const removeListingFromGeneratePDF = (listingId) => {
    let updatePrintListingIDs = printListingIDs.filter(
      (itemID) => itemID != listingId
    );
    setPrintListingIDs(updatePrintListingIDs);
  };

  const [isRevel, setIsRevel] = useState(false);
  const handleNavigation = () => {
    if (uid !== ListingUser) {
      dispatch(updateListingViews(id));
    }
    navigate(`/listing-details/${id}/${ListingUser}`);
    window.scrollTo(0, 0); // Scroll to the top of the page upon navigation
  };

  return (
    <Grid
      // maxWidth={"70%"}
      container
      sx={{ border: "2px solid #0B1A9B", borderRadius: "15px" }}
    >
      <Grid item xs={12} md={4}>
        <Box sx={{ position: "relative" }}>
          <CheckBoxComp>
            <IconButton
              p={0}
              onClick={() =>
                isAlreadyExists
                  ? removeListingFromGeneratePDF(id)
                  : addListingToGeneratePDF(id)
              }
            >
              {isAlreadyExists ? (
                <CheckBoxIcon sx={{ color: "#0B1A9B" }} />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
            </IconButton>
          </CheckBoxComp>
          <ListingCntnr>
            <ImageBox onClick={handleNavigation}>
              <img src={image} className={classes.listingImg} />
              <PriceBox p={1}>
                <Typography className={classes.price}>
                  {pricingType === "dayCharter"
                    ? `US$ ${pricing?.price}`
                    : `US$ ${pricing?.pricing[1]}`}
                  {pricingType === "dayCharter"
                    ? availability[currentDay]
                      ? " +/HOUR"
                      : ""
                    : " +/DAY"}
                </Typography>
              </PriceBox>
            </ImageBox>
            {/* <Box p={2} >
                        <EllipsisText className={classes.address} >
                            {formattedAddress}
                        </EllipsisText>
                        <Box className={classes.displayBetween} sx={{ my: 1 }} >
                            <Typography className={classes.address}> {boatLength}' feet Length</Typography>
                            <Typography className={classes.address}> Up to  {noOfPassengers} Passengers </Typography>
                        </Box>
                        <Box className={classes.displayBetween} sx={{ mb: 1 }} >
                            <Typography className={classes.address}> {make} </Typography>
                        </Box>
                        <EllipsisText>
                            {title}
                        </EllipsisText>
                    </Box> */}
          </ListingCntnr>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={3.7}
        p={1}
        sx={{
          borderLeft: "1px solid #0B1A9B",
          borderRight: "1px solid #0B1A9B",
        }}
        onClick={handleNavigation}
      >
        <Box className={classes.displayBetween} sx={{ my: 1 }}>
          <Box sx={{ width: "120px" }}>
            <DataText> Year</DataText>
          </Box>
          <DataText sx={{ color: "#000" }}> {year} </DataText>
        </Box>
        <Box className={classes.displayBetween} sx={{ my: 1 }}>
          <Box sx={{ width: "120px" }}>
            <DataText> Make</DataText>
          </Box>
          <DataText sx={{ color: "#000" }}> {make} </DataText>
        </Box>
        <Box className={classes.displayBetween} sx={{ my: 1 }}>
          <Box sx={{ width: "120px" }}>
            <DataText> Model</DataText>
          </Box>
          <DataText sx={{ color: "#000" }}> {model} </DataText>
        </Box>
        <Box className={classes.displayBetween} sx={{ my: 1 }}>
          <Box sx={{ width: "120px" }}>
            <DataText> Length</DataText>
          </Box>
          <DataText sx={{ color: "#000" }}> {boatLength} ft </DataText>
        </Box>
        <Box className={classes.displayBetween} sx={{ my: 1 }}>
          <Box sx={{ width: "120px" }}>
            <DataText> Boat Locations</DataText>
          </Box>
          <DataText sx={{ color: "#000" }}> {formattedAddress} </DataText>
        </Box>
        <Box className={classes.displayBetween} sx={{ my: 1 }}>
          <Box sx={{ width: "120px" }}>
            <DataText> Durations Offered</DataText>
          </Box>
          <DataText sx={{ color: "#000" }}>
            {pricingType == "dayCharter" ? (
              <>
                {Object.keys(pricing?.weeklyPrice?.Monday)
                  .sort((a, b) => {
                    // Remove the 'hrs' suffix and convert to numbers for comparison
                    const numA = parseInt(a.replace(/hrs$/, ""), 10);
                    const numB = parseInt(b.replace(/hrs$/, ""), 10);
                    return numA - numB;
                  })
                  .map((day, ind, arr) => {
                    day = String(day).replace(/hrs$/, "");
                    const isLast = ind === arr.length - 1;
                    return (
                      <span key={ind}>
                        {Object.values(pricing?.weeklyPrice?.Monday)[ind] !=
                          "" && (
                          <>
                            {day}
                            {!isLast && ", "}
                          </>
                        )}
                      </span>
                    );
                  })}
              </>
            ) : (
              <>
                {Object.keys(pricing?.pricing)
                  .sort((a, b) => {
                    // Remove the 'hrs' suffix and convert to numbers for comparison
                    const numA = parseInt(a.replace(/hrs$/, ""), 10);
                    const numB = parseInt(b.replace(/hrs$/, ""), 10);
                    return numA - numB;
                  })
                  .map((day, ind, arr) => {
                    day = String(day).replace(/hrs$/, "");
                    const isLast = ind === arr.length - 1;
                    return (
                      <span key={ind}>
                        {Object.values(pricing?.pricing)[ind] != "" && (
                          <>
                            {day}
                            {!isLast && ", "}
                          </>
                        )}
                      </span>
                    );
                  })}
              </>
            )}
            {pricingType == "dayCharter"
              ? " hrs"
              : Object.keys(pricing?.pricing)?.length > 1
              ? " Days"
              : " Day"}{" "}
          </DataText>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4.3}
        p={1}
        // onClick={handleNavigation}
      >
        <Box className={classes.displayBetween} sx={{ my: 1 }}>
          <Box sx={{ width: "130px" }}>
            <DataText> Brokerage</DataText>
          </Box>
          <DataText sx={{ color: "#000" }}> {companyName} </DataText>
        </Box>
        <Box className={classes.displayBetween} sx={{ my: 1 }}>
          <Box sx={{ width: "130px" }}>
            <DataText> Contact</DataText>
          </Box>
          <DataText sx={{ color: "#000" }}> {brokerName} </DataText>
        </Box>
        <Box className={classes.displayBetween} sx={{ my: 1 }}>
          <Box sx={{ width: "130px" }}>
            <DataText> Phone Number</DataText>
          </Box>
          <DataText
            sx={{
              color: isRevel ? "#000" : "#0B1A9B",
              textDecoration: isRevel ? "none" : "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              if (isRevel === true) {
                // Navigate to the phone dialer if the number is revealed
                window.location.href = `tel:${phoneNo}`;
                setIsRevel(false);
              } else {
                // Reveal the phone number
                setIsRevel(true);
              }
            }}
          >
            {isRevel ? phoneNo : "Click to reveal"}
          </DataText>
        </Box>
        <Box className={classes.displayBetween} sx={{ my: 1 }}>
          <Box sx={{ width: "130px" }}>
            <DataText>
              {pricingType == "dayCharter"
                ? pricing?.weeklyPrice?.Monday?.["4hrs"] !== ""
                  ? "4 Hours Price"
                  : pricing?.weeklyPrice?.Monday?.["6hrs"] !== ""
                  ? "6 Hours Price"
                  : "8 Hours Price"
                : "1 Day Price"}{" "}
            </DataText>
          </Box>
          <DataText sx={{ color: "#000" }}>
            <>
              {"$"}
              {pricingType == "dayCharter"
                ? pricing?.weeklyPrice?.Monday?.["4hrs"] !== ""
                  ? pricing?.weeklyPrice?.Monday?.["4hrs"]
                  : pricing?.weeklyPrice?.Monday?.["6hrs"] !== ""
                  ? pricing?.weeklyPrice?.Monday?.["6hrs"]
                  : pricing?.weeklyPrice?.Monday?.["8hrs"]
                : pricing?.pricing?.["1"]}
            </>

            {/* {pricingType == "dayCharter" && "hrs"}{" "} */}
          </DataText>
          <EllipsisText
            sx={{
              color: "#0B1A9B",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "10px",
              // textAlign: 'left',
              alignSelf: "end",
            }}
            onClick={handleNavigation}
          >
            {"Click to see more price"}
          </EllipsisText>
        </Box>
        <Box sx={{ alignSelf: "flex-end", mt: 5 }}>
          <ContainedBtn
            text="MarkUp and Export"
            sx={{
              background: "#0B1A9B !important",
              color: "white !important",
              // position: "absolute",
            }}
            // onClick={() => {
            //     if (uid !== ListingUser) {
            //         dispatch(updateListingViews(id))
            //     }
            //     navigate(`/listing-details/${id}/${ListingUser}`)
            // }}
            onClick={handleNavigation}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ListingCard;
