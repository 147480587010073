import React, { useState } from 'react'
import {
    Typography,
    Grid,
    Container,
    Box,
    Paper,
    Button,
    TextField,
    MenuItem
} from "@mui/material"
import { makeStyles } from "@mui/styles";



const useStyles = makeStyles((theme) => ({
    roundGrayBG: {
        backgroundColor: '#F3F3F3',
        borderRadius: '10px'
    }
}));

const BoatSpecificDetails = ({ onChangeListingCredentials, listingCredentials }) => {
    const classes = useStyles();

    let fuelTypeOptions = ['gas', 'diesel', 'hybrid', 'electric']
    let engineTypeOptions = ['inboard', 'outboard', 'inboard/outboard']

    const noOfPassengersLimit = () => {
        let arr = []
        for (let index = 1; index <= 50; index++) {
            arr.push(index)
        }
        return arr;
    }
    const noOfBathroomsLimit = () => {
        let arr = []
        for (let index = 0; index <= 10; index++) {
            arr.push(index)
        }
        return arr;
    }

    return (
        <Box className={classes.roundGrayBG} p={2} >
            <Grid container spacing={3} >
                <Grid item xs={12} mt={2} >
                    <Typography variant='h5' sx={{ fontFamily: 'Gilroy Regular, sans-serif ', }} >Engine Details</Typography>
                </Grid>

                <Grid item xs={12} md={6}  >
                    <TextField
                        id="outlined-basic"
                        label="Number of engines"
                        variant="outlined"
                        type="number"
                        fullWidth
                        required={true}
                        placeholder="Number of engines"
                        name="Number of engines"
                        onChange={(e) => {
                            // const inputValue = e.target.value;
                            const inputValue = e.target.value.replace(/^0+/, '');
                            if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                // let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, sleepsTotal: inputValue };
                                // onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                                onChangeListingCredentials("noOfEngines", inputValue)
                            } else {
                                let noOfEngines = 1;
                                onChangeListingCredentials("noOfEngines", noOfEngines);
                            }
                        }}
                        value={listingCredentials.noOfEngines}
                    />
                </Grid>

                <Grid item xs={12} md={6}  >
                    <TextField
                        id="outlined-basic"
                        label="Make"
                        variant="outlined"
                        type="text"
                        fullWidth
                        placeholder="Make"
                        name="Make"
                        required={true}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            onChangeListingCredentials("engineMake", inputValue);
                        }}
                        value={listingCredentials.engineMake}
                    />
                </Grid>

                <Grid item xs={12} md={6}  >
                    <TextField
                        id="outlined-basic"
                        label="Model"
                        variant="outlined"
                        type="text"
                        fullWidth
                        placeholder="Model"
                        name="Model"
                        required={true}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            onChangeListingCredentials("engineModel", inputValue);
                        }}
                        value={listingCredentials.engineModel}
                    />
                </Grid>

                <Grid item xs={12} md={6}  >
                    <TextField
                        id="outlined-basic"
                        label="Horse Power"
                        variant="outlined"
                        type="number"
                        fullWidth
                        placeholder="Horse Power"
                        name="Horse Power"
                        required={true}
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/^0+/, '');
                            if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                onChangeListingCredentials("engineHoursePower", inputValue)
                            } else {
                                let engineHoursePower = 0;
                                onChangeListingCredentials("engineHoursePower", engineHoursePower);
                            }
                        }}
                        value={listingCredentials.engineHoursePower}
                    />
                </Grid>


                <Grid item xs={12} md={6} >
                    <TextField
                        id="outlined-select-currency"
                        select
                        required={true}
                        label="Fuel Type"
                        fullWidth
                        onChange={(e) => onChangeListingCredentials("fuelType", e.target.value)}
                        value={listingCredentials.fuelType}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: 200 }, // Set the maximum height here
                                },
                            },
                        }}
                        sx={{
                            textTransform: 'capitalize'
                        }}
                    >
                        {fuelTypeOptions.map((fuel) => (
                            <MenuItem key={fuel} value={fuel} sx={{ textTransform: 'capitalize' }} >
                                {fuel}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>


                <Grid item xs={12} md={6} >
                    <TextField
                        id="outlined-select-currency"
                        select
                        required={true}
                        label="Engine Type"
                        fullWidth
                        onChange={(e) => onChangeListingCredentials("engineType", e.target.value)}
                        value={listingCredentials.engineType}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: 200 }, // Set the maximum height here
                                },
                            },
                        }}
                        sx={{
                            textTransform: 'capitalize'
                        }}
                    >
                        {engineTypeOptions.map((engineType) => (
                            <MenuItem key={engineType} value={engineType} sx={{ textTransform: 'capitalize' }} >
                                {engineType}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>




            </Grid>


            <Grid container spacing={3} >
                <Grid item xs={12} mt={2} >
                    <Typography variant='h5' sx={{ fontFamily: 'Gilroy Regular, sans-serif ', }} >Accommodations</Typography>
                </Grid>

                <Grid item xs={12} md={6} >
                    <TextField
                        id="outlined-select-currency"
                        select
                        required={true}
                        label="Maximum Passengers allowed onboard"
                        fullWidth
                        onChange={(e) => onChangeListingCredentials("noOfPassengers", e.target.value)}
                        value={listingCredentials.noOfPassengers}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: 200 }, // Set the maximum height here
                                },
                            },
                        }}
                    >
                        {noOfPassengersLimit().map((elem) => (
                            <MenuItem key={elem} value={elem} sx={{ textTransform: 'capitalize' }} >
                                {elem}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12} md={6} >
                    <TextField
                        id="outlined-select-currency"
                        select
                        required={true}
                        label="Number of Bathrooms"
                        fullWidth
                        onChange={(e) => onChangeListingCredentials("noOfBathrooms", e.target.value)}
                        value={listingCredentials.noOfBathrooms}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: 200 }, // Set the maximum height here
                                },
                            },
                        }}
                    >
                        {noOfBathroomsLimit().map((elem) => (
                            <MenuItem key={elem} value={elem} sx={{ textTransform: 'capitalize' }} >
                                {elem}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12} md={6}  >
                    <TextField
                        id="outlined-basic"
                        label="Sleeps Total"
                        variant="outlined"
                        type="number"
                        fullWidth
                        // placeholder="Sleeps Total"
                        name="sleepsTotal"
                        onChange={(e) => {
                            // const inputValue = e.target.value;
                            const inputValue = e.target.value.replace(/^0+/, '');
                            if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, sleepsTotal: inputValue };
                                onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                            } else {
                                let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, sleepsTotal: 0 };
                                onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                            }
                        }}
                        value={listingCredentials.sleepingAccmmodations?.sleepsTotal}
                    />
                </Grid>


                <Grid item xs={12} md={6}  >
                    <TextField
                        id="outlined-basic"
                        label="Number Of Cabins"
                        variant="outlined"
                        type="number"
                        fullWidth
                        placeholder="Number Of Cabins"
                        name="noOfCabins"
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/^0+/, '');
                            if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, noOfCabins: inputValue };
                                onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                            } else {
                                let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, noOfCabins: 0 };
                                onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                            }
                        }}
                        value={listingCredentials.sleepingAccmmodations.noOfCabins}
                    />
                </Grid>



                <Grid item xs={12} md={6}  >
                    <TextField
                        id="outlined-basic"
                        label="Kings Beds"
                        variant="outlined"
                        type="number"
                        fullWidth
                        // placeholder="Kings Beds"
                        name="kingsBeds"
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/^0+/, '');
                            if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, kingsBeds: inputValue };
                                onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                            } else {
                                let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, kingsBeds: 0 };
                                onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                            }
                        }}
                        value={listingCredentials.sleepingAccmmodations.kingsBeds}
                    />
                </Grid>

                <Grid item xs={12} md={6}  >
                    <TextField
                        id="outlined-basic"
                        label="Queens Beds"
                        variant="outlined"
                        type="number"
                        fullWidth
                        // placeholder="Queens Beds"
                        name="queenBeds"
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/^0+/, '');
                            if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, queenBeds: inputValue };
                                onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                            } else {
                                let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, queenBeds: 0 };
                                onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                            }
                        }}
                        value={listingCredentials.sleepingAccmmodations.queenBeds}
                    />
                </Grid>

                <Grid item xs={12} md={6}  >
                    <TextField
                        id="outlined-basic"
                        label="Twin Beds"
                        variant="outlined"
                        type="number"
                        fullWidth
                        // placeholder="Twin Beds"
                        name="twinBeds"
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/^0+/, '');
                            if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, twinBeds: inputValue };
                                onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                            } else {
                                let sleepingAccmmodations = { ...listingCredentials.sleepingAccmmodations, twinBeds: 0 };
                                onChangeListingCredentials("sleepingAccmmodations", sleepingAccmmodations);
                            }
                        }}
                        value={listingCredentials.sleepingAccmmodations.twinBeds}
                    />
                </Grid>


            </Grid>

        </Box>
    )
}

export default BoatSpecificDetails