import React from 'react'
import Virtual from '../Home/Components/Virtual'
import BannerOne from './Components/BannerOne'
import BannerTwo from './Components/BannerTwo'

const MemberShip = () => {
    return (
        <>
            <BannerOne />
            <BannerTwo/>
            <Virtual/>
        </>
    )
}

export default MemberShip