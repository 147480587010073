import React, { useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Box,
  Paper,
  Button,
  TextField,
  MenuItem,
  IconButton,
  CircularProgress,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";

import ContainedBtn from "../../../Components/ContainedBtn";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { addListingTendersAndToysExtras } from "../../../Redux/Listing/ListingActions";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import FullScreenDialog from "../../../Components/Dialog";

const useStyles = makeStyles((theme) => ({
  roundGrayBG: {
    backgroundColor: "#F3F3F3",
    borderRadius: "10px",
  },
  checkBoxCntnr: {
    display: "flex",
    // justifyContent: 'space-between',
    flexWrap: "wrap",
  },
  icon: {
    color: "#0B1A9B",
    cursor: "pointer",
  },
  displayBtwn: {
    display: "flex",
    justifyContent: "space-between",
  },
  outlinedBtn: {
    border: "1px solid #0B1A9B !important",
    color: "#0B1A9B !important",
    background: "#fff !important",
    fontSize: "17px",
    fontWeight: "bold",
  },
  extraInput: {
    border: "1px solid black",
    outline: "none",
    color: "#000 !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  displayBtwn: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  displayflex: {
    display: "flex",
    gap: "10px",
  },
  bottomBtn: {
    background: "#0B1A9B !important",
    color: "black important",
  },
  tenderWrapper: {
    display: "flex !important",
    flexWrap: "wrap !important",
    gap: "10px",
    flexDirection: "row !important",
  },
}));

const ExtraCheckBox = styled(Box)(({ theme }) => ({
  // backgroundColor: '#0B1A9B',
  backgroundColor: "#fff",
  borderRadius: "5px",
  border: "1px solid #0B1A9B",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  minWidth: "200px",
}));

const TenderssCntnr = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: `${theme.spacing(1)} 0px`,
  maxWidth: "fit-content",
  minWidth: "300px",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: "50px",
  alignItems: "center",
}));

const DataText = styled(Box)(({ theme }) => ({
  fontSize: "20px",
  color: "#777777",
  fontFamily: "Gilroy Regular, sans-serif !important",
  fontWeight: "bold",
  whiteSpace: "pre-line",
  letterSpacing: "1px",
}));

const TenderAndToys = ({
  onChangeListingCredentials,
  listingCredentials,
  setIsErrorSnackBarOpen,
  isErrorSnackBarOpen,
  handleClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let startYear = 1950;
  let endYear = new Date().getFullYear();

  const calculateYear = (startYear, endYear) => {
    let arr = [];

    for (let index = startYear; index <= endYear; index++) {
      arr.push(index);
    }
    return arr;
  };

  let years = calculateYear(startYear, endYear).reverse();

  const listingTendersAndToys = useSelector(
    (state) => state.listings.listingTendersAndToys[0]
  );

  const [addMoreTendersAndToysDialogOpen, setAddMoreTendersAndToysDialogOpen] =
    useState(false);

  const [addMoreJetskiDialogOpen, setAddMoreJetskiDialogOpen] = useState(false);
  const [addInflatableRibDialogOpen, setAddInflatableRibDialogOpen] =
    useState(false);
  const [addMoreTendersDialogOpen, setAddMoreTendersDialogOpen] =
    useState(false);
  const [addSailBoatDialogOpen, setAddSailBoatDialogOpen] = useState(false);
  const [addHydroFoilDialogOpen, setAddHydroFoilDialogOpen] = useState(false);
  const [addSeaBobsDialogOpen, setAddSeaBobsDialogOpen] = useState(false);
  const [addPaddleBoardsDialogOpen, setAddPaddleBoardsDialogOpen] =
    useState(false);

  const [addTendersAndToysLoading, setAddTendersAndToysLoading] =
    useState(false);

  const [newTenderCredentials, setNewTenderCredentials] = useState({
    year: `${endYear}`,
    make: "",
    model: "",
    type: "",
    engineMake: "",
    horsePower: 0,
  });
  const [inflatableRIBCredentials, setInflatableRIBCredentials] = useState({
    year: `${endYear}`,
    make: "",
    model: "",
    type: "",
    engineMake: "",
    horsePower: 0,
  });
  const [sailboatCredentials, setSailboatCredentials] = useState({
    make: "",
    length: 0,
  });
  const [hydroFoilCredentials, setHydroFoilCredentials] = useState({
    make: "",
    numberOfHydrofoil: 0,
  });
  const [paddleboardsCredentials, setPaddleboardsCredentials] = useState({
    make: "",
    numberOfPaddleboards: 0,
  });
  const [seaBobCredentials, setSeaBobCredentials] = useState({
    numberOfSeaBobs: 0,
  });
  const [addTendersLoading, setAddTendersLoading] = useState(false);

  const [newJetskiCredentials, setNewJetskiCredentials] = useState({
    quantity: 1,
    make: "",
    model: "",
    year: `${endYear}`,
  });
  const [addJetskiLoading, setAddJetskiLoading] = useState(false);

  const [tendersAndToysName, setTendersAndToysName] = useState("");

  let engineTypeOptions = ["Inboard", "Outboard"];

  let TendersToysCredentialsDialog = {
    Jetski: (state) => setAddMoreJetskiDialogOpen(state),
    "Inflatable RIB": (state) => setAddInflatableRibDialogOpen(state),
    Tender: (state) => setAddMoreTendersDialogOpen(state),
    Sailboat: (state) => setAddSailBoatDialogOpen(state),
    Hydrofoil: (state) => setAddHydroFoilDialogOpen(state),
    Seabobs: (state) => setAddSeaBobsDialogOpen(state),
    Paddleboards: (state) => setAddPaddleBoardsDialogOpen(state),
  };

  const includesTendersAndToys = (toyName) => {
    let tenderAndToys = listingCredentials.tenderAndToys;

    if (listingCredentials.tenderAndToys.includes(toyName)) {
      tenderAndToys = tenderAndToys.filter((val) => val !== toyName);
    } else {
      // tenderAndToys.push(toyName)
      console.log(toyName, "toyname");
      TendersToysCredentialsDialog[toyName](true);
    }
    onChangeListingCredentials("tenderAndToys", tenderAndToys);
  };

  const addTendersAndToys = (toyName) => {
    let tenderAndToys = listingCredentials.tenderAndToys;
    tenderAndToys.push(toyName);
    onChangeListingCredentials("tenderAndToys", tenderAndToys);
  };

  const addMoreTendersAndToys = () => {
    if (tendersAndToysName.trim().length > 0) {
      setAddTendersAndToysLoading(true);

      dispatch(addListingTendersAndToysExtras(tendersAndToysName)).then(
        (res) => {
          if (res) {
            setAddTendersAndToysLoading(false);
            setTendersAndToysName("");
            hideDialog();
          }
          setAddTendersAndToysLoading(false);
        }
      );
    }
  };

  const hideDialog = () => {
    setAddMoreTendersAndToysDialogOpen(false);
    setTendersAndToysName("");
  };

  const hideTenderDialog = () => {
    setAddMoreTendersDialogOpen(false);
  };

  const onChangeTenderCredentials = (key, value) => {
    setNewTenderCredentials({
      ...newTenderCredentials,
      [key]: value,
    });
  };
  const onChangeInflatableRIBCredentials = (key, value) => {
    setInflatableRIBCredentials({
      ...inflatableRIBCredentials,
      [key]: value,
    });
  };

  const onChangeJetskiCredentials = (key, value) => {
    setNewJetskiCredentials({
      ...newJetskiCredentials,
      [key]: value,
    });
  };

  const onChangeSailboatCredentials = (key, value) => {
    setSailboatCredentials({
      ...sailboatCredentials,
      [key]: value,
    });
  };

  const onChangeHydroFoilCredentials = (key, value) => {
    setHydroFoilCredentials({
      ...hydroFoilCredentials,
      [key]: value,
    });
  };

  const onChangePaddleboardsCredentials = (key, value) => {
    setPaddleboardsCredentials({
      ...paddleboardsCredentials,
      [key]: value,
    });
  };

  const onChangeSeaBobsCredentials = (key, value) => {
    setSeaBobCredentials({
      ...seaBobCredentials,
      [key]: value,
    });
  };

  const hideJetskiDialog = () => {
    setAddMoreJetskiDialogOpen(false);
  };

  const addTenders = () => {
    if (
      String(newTenderCredentials.year).trim().length == 0 ||
      newTenderCredentials.make.trim().length == 0 ||
      newTenderCredentials.model.trim().length == 0 ||
      newTenderCredentials.type.trim().length == 0 ||
      newTenderCredentials.engineMake.trim().length == 0 ||
      String(newTenderCredentials.horsePower).trim().length == 0 ||
      newTenderCredentials.horsePower <= 0
    ) {
      setIsErrorSnackBarOpen(true);
    } else {
      addTendersAndToys("Tender");
      onChangeListingCredentials("tenders", newTenderCredentials);
      hideTenderDialog();
    }
  };

  const addInflatableRIB = () => {
    if (
      String(inflatableRIBCredentials.year).trim().length == 0 ||
      inflatableRIBCredentials.make.trim().length == 0 ||
      inflatableRIBCredentials.model.trim().length == 0 ||
      inflatableRIBCredentials.type.trim().length == 0 ||
      inflatableRIBCredentials.engineMake.trim().length == 0 ||
      String(inflatableRIBCredentials.horsePower).trim().length == 0 ||
      inflatableRIBCredentials.horsePower <= 0
    ) {
      setIsErrorSnackBarOpen(true);
    } else {
      addTendersAndToys("Inflatable RIB");
      onChangeListingCredentials("inflatableRIB", inflatableRIBCredentials);
      setAddInflatableRibDialogOpen(false);
    }
  };

  const addJetskis = () => {
    if (
      newJetskiCredentials.make.trim().length == 0 ||
      newJetskiCredentials.model.trim().length == 0
    ) {
      setIsErrorSnackBarOpen(true);
    } else {
      addTendersAndToys("Jetski");
      onChangeListingCredentials("jetskis", newJetskiCredentials);
      hideJetskiDialog();
    }
  };

  const addSailBoat = () => {
    if (
      sailboatCredentials.make.trim().length == 0 ||
      String(sailboatCredentials.length).trim().length == 0 ||
      sailboatCredentials.length <= 0
    ) {
      setIsErrorSnackBarOpen(true);
    } else {
      addTendersAndToys("Sailboat");
      onChangeListingCredentials("sailBoat", sailboatCredentials);
      setAddSailBoatDialogOpen(false);
    }
  };

  const addHydrofoil = () => {
    if (
      hydroFoilCredentials.make.trim().length == 0 ||
      String(hydroFoilCredentials.numberOfHydrofoil).trim().length == 0 ||
      hydroFoilCredentials.numberOfHydrofoil <= 0
    ) {
      setIsErrorSnackBarOpen(true);
    } else {
      addTendersAndToys("Hydrofoil");
      onChangeListingCredentials("hydroFoil", hydroFoilCredentials);
      setAddHydroFoilDialogOpen(false);
    }
  };

  const addPaddleboards = () => {
    if (
      paddleboardsCredentials.make.trim().length == 0 ||
      String(paddleboardsCredentials.numberOfPaddleboards).trim().length == 0 ||
      paddleboardsCredentials.numberOfPaddleboards <= 0
    ) {
      setIsErrorSnackBarOpen(true);
    } else {
      addTendersAndToys("Paddleboards");
      onChangeListingCredentials("paddleBoards", paddleboardsCredentials);
      setAddPaddleBoardsDialogOpen(false);
    }
  };

  const addSeaBob = () => {
    if (
      String(seaBobCredentials.numberOfSeaBobs).trim().length == 0 ||
      seaBobCredentials.numberOfSeaBobs <= 0
    ) {
      setIsErrorSnackBarOpen(true);
    } else {
      addTendersAndToys("Seabobs");
      onChangeListingCredentials("seaBob", seaBobCredentials);
      setAddPaddleBoardsDialogOpen(false);

      setAddSeaBobsDialogOpen(false);
    }
  };

  const removeTender = (index) => {
    let tenders = [...listingCredentials.tenders];
    tenders = tenders.filter((val, ind) => ind !== index);
    onChangeListingCredentials("tenders", [...tenders]);
  };

  const removeJetskis = (index) => {
    let jetskis = [...listingCredentials.jetskis];
    jetskis = jetskis.filter((val, ind) => ind !== index);
    onChangeListingCredentials("jetskis", [...jetskis]);
  };

  return (
    <>
      <Box className={classes.roundGrayBG} p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.displayBtwn}>
            <ContainedBtn
              text="Add more  Toys"
              sx={{
                "&:hover": { color: "#0B1A9B", outline: "1px solid #0B1A9B" },
                height: "50px",
                width: "300px",
                marginBottom: "10px",
              }}
              endIcon={<AddIcon sx={{ "&:hover": { color: "#0B1A9B" } }} />}
              onClick={() => setAddMoreTendersAndToysDialogOpen(true)}
            />
            <Box className={classes.displayflex}>
              {listingCredentials.tenderAndToys.includes("Tender") && (
                <ContainedBtn
                  text="Add Tenders"
                  sx={{
                    "&:hover": {
                      color: "#0B1A9B",
                      outline: "1px solid #0B1A9B",
                    },
                    height: "50px",
                    width: "180px",
                    marginBottom: "10px",
                  }}
                  endIcon={<AddIcon sx={{ "&:hover": { color: "#0B1A9B" } }} />}
                  onClick={() => setAddMoreTendersDialogOpen(true)}
                />
              )}
              {listingCredentials.tenderAndToys.includes("Jetski") && (
                <ContainedBtn
                  text="Add Jetskis"
                  sx={{
                    "&:hover": {
                      color: "#0B1A9B",
                      outline: "1px solid #0B1A9B",
                    },
                    height: "50px",
                    width: "180px",
                    marginBottom: "10px",
                  }}
                  endIcon={<AddIcon sx={{ "&:hover": { color: "#0B1A9B" } }} />}
                  onClick={() => setAddMoreJetskiDialogOpen(true)}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.checkBoxCntnr}>
              {listingTendersAndToys?.map((toy, ind) => {
                return (
                  <ExtraCheckBox key={ind} px={2} py={1} m={1}>
                    <IconButton onClick={() => includesTendersAndToys(toy)}>
                      {listingCredentials.tenderAndToys.includes(toy) ? (
                        <CheckBoxIcon className={classes.icon} />
                      ) : (
                        <CheckBoxOutlineBlankIcon className={classes.icon} />
                      )}
                    </IconButton>
                    <Typography
                      variant="h6"
                      sx={{ color: "#0B1A9B", width: "max-content" }}
                    >
                      {toy}
                    </Typography>
                  </ExtraCheckBox>
                );
              })}
            </Box>
          </Grid>
          {/* {
                        (listingCredentials.tenderAndToys.includes('Tender') && listingCredentials?.tenders?.length > 0) && <Grid item xs={12}  >
                            <Typography variant='h5' sx={{ fontFamily: 'Gilroy Regular, sans-serif ', }} >Tenders</Typography>
                            <Box className={classes.tenderWrapper} >
                                {
                                    listingCredentials?.tenders?.map((tender, ind) => {
                                        return (
                                            <TenderssCntnr key={ind}  >
                                                <DataText>{tender?.make} -- {tender?.model} </DataText>
                                                <Box  >
                                                    <IconButton sx={{ backgroundColor: '#F5F5F5' }}
                                                        onClick={() => removeTender(ind)}  >
                                                        <CloseIcon sx={{ color: 'red' }} />
                                                    </IconButton>
                                                </Box>
                                            </TenderssCntnr>
                                        )
                                    })
                                }
                            </Box>
                        </Grid>
                    } */}
          {/* {
                        (listingCredentials.tenderAndToys.includes('Jetski') && listingCredentials?.jetskis?.length > 0) && <Grid item xs={12}  >
                            <Typography variant='h5' sx={{ fontFamily: 'Gilroy Regular, sans-serif ', }} >Jetskis</Typography>
                            <Box className={classes.tenderWrapper} >
                                {
                                    listingCredentials?.jetskis?.map((jetski, ind) => {
                                        return (
                                            <TenderssCntnr key={ind}  >
                                                <DataText>{jetski?.make} -- {jetski?.model} </DataText>
                                                <Box  >
                                                    <IconButton sx={{ backgroundColor: '#F5F5F5' }}
                                                        onClick={() => removeJetskis(ind)}  >
                                                        <CloseIcon sx={{ color: 'red' }} />
                                                    </IconButton>
                                                </Box>
                                            </TenderssCntnr>
                                        )
                                    })
                                }
                            </Box>
                        </Grid>
                    } */}
          {/* {listingCredentials.tenderAndToys.includes('Tender') && <Grid item xs={12} md={6}  >
                        <TextField
                        variant="outlined" 
                            id="outlined-basic"
                            label="Tenders Type"
                            variant="outlined"
                            required={true}
                            type="text"
                            fullWidth
                            placeholder="Tenders Type"
                            name="tenderType"
                            sx={{ my: 4 }}
                            onChange={(e) => onChangeListingCredentials("tenderType", e.target.value)}
                            value={listingCredentials.tenderType}
                        />
                    </Grid>} */}
        </Grid>
      </Box>

      <FullScreenDialog
        maxWidth="xs"
        fullWidth={true}
        open={addMoreTendersAndToysDialogOpen}
        hideDialogHandler={hideDialog}
        style={{
          background: "rgba(255, 255, 255, 0.10)",
          "& .MuiDialog-paper": {
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "20px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box p={2} sx={{ border: "2px solid #0B1A9B", borderRadius: "20px" }}>
          <Typography variant="h6" sx={{ color: "#000" }}>
            Enter the name of Tenders and Toys you want to Add
          </Typography>
          <OutlinedInput
            id="outlined-basic"
            label="Tenders and Toys"
            variant="outlined"
            required={true}
            type="text"
            fullWidth
            placeholder="Tenders and Toys"
            // name="boatName"
            sx={{ my: 4 }}
            className={classes.extraInput}
            onChange={(e) => setTendersAndToysName(e.target.value)}
            value={tendersAndToysName}
          />
          <Box className={classes.displayBtwn}>
            <ContainedBtn
              text="Cancel"
              className={classes.outlinedBtn}
              disabled={addTendersAndToysLoading}
              onClick={hideDialog}
            />

            <ContainedBtn
              text={addTendersAndToysLoading ? <CircularProgress /> : "Add"}
              disabled={addTendersAndToysLoading}
              onClick={addMoreTendersAndToys}
            />
          </Box>
        </Box>
      </FullScreenDialog>

      {/* Add Jetskis Dialog */}
      <FullScreenDialog
        maxWidth="sevenHalfS"
        fullWidth={true}
        open={addMoreJetskiDialogOpen}
        hideDialogHandler={hideJetskiDialog}
        style={{
          background: "rgba(255, 255, 255, 0.10)",
          "& .MuiDialog-paper": {
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "20px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box p={2} sx={{ border: "2px solid #0B1A9B", borderRadius: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: "#000" }}>
                Enter the Jetskis Details
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#000", mb: 1 }}>
                Number of Jetskis
              </Typography>
              <TextField
                variant="outlined"
                id="outlined-select-currency"
                select
                required={true}
                label="Number of Jetskis"
                fullWidth
                onChange={(e) =>
                  onChangeJetskiCredentials("quantity", e.target.value)
                }
                value={newJetskiCredentials.quantity}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover": {
                      "& fieldset": {
                        border: "1px solid #000", // On hover border color
                      },
                    },
                    "&.Mui-focused": {
                      "& fieldset": {
                        border: "1px solid #000", // On active/focus border color
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000", // On active/focus label color
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #000",
                    },
                    "& .MuiInputBase-input": {
                      color: "#000", // Text color
                    },
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: { maxHeight: 200 }, // Set the maximum height here
                    },
                  },
                }}
              >
                {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map(
                  (quantity) => (
                    <MenuItem key={quantity} value={quantity}>
                      {quantity}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff", mb: 1 }}>Year</Typography>
              <TextField
                variant="outlined"
                id="outlined-select-currency"
                select
                required={true}
                label="Please Select Year"
                fullWidth
                onChange={(e) =>
                  onChangeJetskiCredentials("year", e.target.value)
                }
                value={newJetskiCredentials.year}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover": {
                      "& fieldset": {
                        border: "1px solid #000", // On hover border color
                      },
                    },
                    "&.Mui-focused": {
                      "& fieldset": {
                        border: "1px solid #000", // On active/focus border color
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000", // On active/focus label color
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #000",
                    },
                    "& .MuiInputBase-input": {
                      color: "#000", // Text color
                    },
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: { maxHeight: 200 }, // Set the maximum height here
                    },
                  },
                }}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Make</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Jetski Make"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Jetski Make"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangeJetskiCredentials("make", e.target.value)
                }
                value={newJetskiCredentials.make}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Model</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Jetski Model"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Jetski Model"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangeJetskiCredentials("model", e.target.value)
                }
                value={newJetskiCredentials.model}
              />
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.displayBtwn}>
                <ContainedBtn
                  text="Cancel"
                  className={classes.outlinedBtn}
                  onClick={hideJetskiDialog}
                />

                <ContainedBtn
                  text="Add Jetski"
                  className={classes.bottomBtn}
                  onClick={addJetskis}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FullScreenDialog>

      {/* Add Inflatable RIB Dialog */}
      <FullScreenDialog
        maxWidth="sevenHalfS"
        fullWidth={true}
        open={addInflatableRibDialogOpen}
        hideDialogHandler={() => setAddInflatableRibDialogOpen(false)}
        style={{
          background: "rgba(255, 255, 255, 0.10)",
          "& .MuiDialog-paper": {
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "20px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box p={2} sx={{ border: "2px solid #0B1A9B", borderRadius: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: "#000" }}>
                Enter Inflatable RIB Details you want to Add
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#000", mb: 1 }}>Year</Typography>
              <TextField
                variant="outlined"
                id="outlined-select-currency"
                select
                required={true}
                label="Please Select Year"
                fullWidth
                onChange={(e) =>
                  onChangeInflatableRIBCredentials("year", e.target.value)
                }
                value={inflatableRIBCredentials.year}
                sx={{
                  // border: '1px solid black',
                  "& .MuiOutlinedInput-root": {
                    "&:hover": {
                      "& fieldset": {
                        border: "1px solid #000", // On hover border color
                      },
                    },
                    "&.Mui-focused": {
                      "& fieldset": {
                        border: "1px solid #000", // On active/focus border color
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000", // On active/focus label color
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #000",
                    },
                    "& .MuiInputBase-input": {
                      color: "#000", // Text color
                    },
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: { maxHeight: 200 }, // Set the maximum height here
                    },
                  },
                }}
              >
                {calculateYear(startYear, endYear)
                  .reverse()
                  .map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Make</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Make"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Make"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangeInflatableRIBCredentials("make", e.target.value)
                }
                value={inflatableRIBCredentials.make}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Model</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Model"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Model"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangeInflatableRIBCredentials("model", e.target.value)
                }
                value={inflatableRIBCredentials.model}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff", mb: 1 }}>Type</Typography>
              <TextField
                variant="outlined"
                id="outlined-select-currency"
                select
                required={true}
                label="Type"
                fullWidth
                onChange={(e) =>
                  onChangeInflatableRIBCredentials("type", e.target.value)
                }
                value={inflatableRIBCredentials.type}
                sx={{
                  textTransform: "capitalize",
                  "& .MuiOutlinedInput-root": {
                    "&:hover": {
                      "& fieldset": {
                        border: "1px solid #000", // On hover border color
                      },
                    },
                    "&.Mui-focused": {
                      "& fieldset": {
                        border: "1px solid #000", // On active/focus border color
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000", // On active/focus label color
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #000",
                    },
                    "& .MuiInputBase-input": {
                      color: "#000",
                    },
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: { maxHeight: 200 }, // Set the maximum height here
                    },
                  },
                }}
              >
                {engineTypeOptions.map((engineType) => (
                  <MenuItem
                    key={engineType}
                    value={engineType}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {engineType}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Engine Make</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Engine Make"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Engine Make"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangeInflatableRIBCredentials("engineMake", e.target.value)
                }
                value={inflatableRIBCredentials.engineMake}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Engine Horse Power</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Engine Horse Power"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Engine Horse Power"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/^0+/, "");
                  if (inputValue === "" || /^\d+$/.test(inputValue)) {
                    onChangeInflatableRIBCredentials("horsePower", inputValue);
                  } else {
                    let noOfEngines = 0;
                    onChangeInflatableRIBCredentials("horsePower", noOfEngines);
                  }
                }}
                value={inflatableRIBCredentials.horsePower}
              />
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.displayBtwn}>
                <ContainedBtn
                  text="Cancel"
                  className={classes.outlinedBtn}
                  // disabled={addTendersAndToysLoading}
                  onClick={() => setAddInflatableRibDialogOpen(false)}
                />

                <ContainedBtn
                  text="Add Inflatable RIB"
                  // disabled={addTendersAndToysLoading}
                  className={classes.bottomBtn}
                  onClick={addInflatableRIB}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FullScreenDialog>

      {/* Add Tenders Dialog */}
      <FullScreenDialog
        maxWidth="sevenHalfS"
        fullWidth={true}
        open={addMoreTendersDialogOpen}
        hideDialogHandler={hideTenderDialog}
        style={{
          background: "rgba(255, 255, 255, 0.10)",
          "& .MuiDialog-paper": {
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "20px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box p={2} sx={{ border: "2px solid #0B1A9B", borderRadius: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: "#000" }}>
                Enter the New Tenders Details you want to Add
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#000", mb: 1 }}>Year</Typography>
              <TextField
                variant="outlined"
                id="outlined-select-currency"
                select
                required={true}
                label="Please Select Year"
                fullWidth
                onChange={(e) =>
                  onChangeTenderCredentials("year", e.target.value)
                }
                value={newTenderCredentials.year}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover": {
                      "& fieldset": {
                        border: "1px solid #000", // On hover border color
                      },
                    },
                    "&.Mui-focused": {
                      "& fieldset": {
                        border: "1px solid #000", // On active/focus border color
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000", // On active/focus label color
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #000",
                    },
                    "& .MuiInputBase-input": {
                      color: "#000", // Text color
                    },
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: { maxHeight: 200 }, // Set the maximum height here
                    },
                  },
                }}
              >
                {calculateYear(startYear, endYear)
                  .reverse()
                  .map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Make</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Make"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Make"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangeTenderCredentials("make", e.target.value)
                }
                value={newTenderCredentials.make}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Model</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Model"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Model"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangeTenderCredentials("model", e.target.value)
                }
                value={newTenderCredentials.model}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff", mb: 1 }}>Type</Typography>
              <TextField
                variant="outlined"
                id="outlined-select-currency"
                select
                required={true}
                label="Type"
                fullWidth
                onChange={(e) =>
                  onChangeTenderCredentials("type", e.target.value)
                }
                value={newTenderCredentials.type}
                sx={{
                  textTransform: "capitalize",
                  "& .MuiOutlinedInput-root": {
                    "&:hover": {
                      "& fieldset": {
                        border: "1px solid #000", // On hover border color
                      },
                    },
                    "&.Mui-focused": {
                      "& fieldset": {
                        border: "1px solid #000", // On active/focus border color
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000", // On active/focus label color
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #000",
                    },
                    "& .MuiInputBase-input": {
                      color: "#000",
                    },
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: { maxHeight: 200 }, // Set the maximum height here
                    },
                  },
                }}
              >
                {engineTypeOptions.map((engineType) => (
                  <MenuItem
                    key={engineType}
                    value={engineType}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {engineType}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Engine Make</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Engine Make"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Engine Make"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangeTenderCredentials("engineMake", e.target.value)
                }
                value={newTenderCredentials.engineMake}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Engine Horse Power</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Engine Horse Power"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Engine Horse Power"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/^0+/, "");
                  if (inputValue === "" || /^\d+$/.test(inputValue)) {
                    onChangeTenderCredentials("horsePower", inputValue);
                  } else {
                    let noOfEngines = 0;
                    onChangeTenderCredentials("horsePower", noOfEngines);
                  }
                }}
                value={newTenderCredentials.horsePower}
              />
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.displayBtwn}>
                <ContainedBtn
                  text="Cancel"
                  className={classes.outlinedBtn}
                  // disabled={addTendersAndToysLoading}
                  onClick={hideTenderDialog}
                />

                <ContainedBtn
                  text="Add Tender"
                  // disabled={addTendersAndToysLoading}
                  className={classes.bottomBtn}
                  onClick={addTenders}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FullScreenDialog>

      {/* Add Sailboat Dialog */}
      <FullScreenDialog
        maxWidth="sevenHalfS"
        fullWidth={true}
        open={addSailBoatDialogOpen}
        hideDialogHandler={() => setAddSailBoatDialogOpen(false)}
        style={{
          background: "rgba(255, 255, 255, 0.10)",
          "& .MuiDialog-paper": {
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "20px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box p={2} sx={{ border: "2px solid #0B1A9B", borderRadius: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: "#000" }}>
                Enter Sailboat Details you want to Add
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#000" }}>Make</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Make"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Make"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangeSailboatCredentials("make", e.target.value)
                }
                value={sailboatCredentials.make}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Length</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Length"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Length"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/^0+/, "");
                  if (inputValue === "" || /^\d+$/.test(inputValue)) {
                    onChangeSailboatCredentials("length", inputValue);
                  } else {
                    let length = 0;
                    onChangeSailboatCredentials("length", length);
                  }
                }}
                value={sailboatCredentials.length}
              />
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.displayBtwn}>
                <ContainedBtn
                  text="Cancel"
                  className={classes.outlinedBtn}
                  // disabled={addTendersAndToysLoading}
                  onClick={() => setAddSailBoatDialogOpen(false)}
                />

                <ContainedBtn
                  text="Add Sailboat"
                  // disabled={addTendersAndToysLoading}
                  className={classes.bottomBtn}
                  onClick={addSailBoat}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FullScreenDialog>

      {/* Add Hydrofoil Dialog */}
      <FullScreenDialog
        maxWidth="sevenHalfS"
        fullWidth={true}
        open={addHydroFoilDialogOpen}
        hideDialogHandler={() => setAddHydroFoilDialogOpen(false)}
        style={{
          background: "rgba(255, 255, 255, 0.10)",
          "& .MuiDialog-paper": {
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "20px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box p={2} sx={{ border: "2px solid #0B1A9B", borderRadius: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: "#000" }}>
                Enter Hydrofoil Details you want to Add
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#000" }}>
                Number of Hydrofoils
              </Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Number of Hydrofoils"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Number of Hydrofoils"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/^0+/, "");
                  if (inputValue === "" || /^\d+$/.test(inputValue)) {
                    onChangeHydroFoilCredentials(
                      "numberOfHydrofoil",
                      inputValue
                    );
                  } else {
                    let numberOfHydrofoil = 0;
                    onChangeHydroFoilCredentials(
                      "numberOfHydrofoil",
                      numberOfHydrofoil
                    );
                  }
                }}
                value={hydroFoilCredentials.numberOfHydrofoil}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Make</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Make"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Make"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangeHydroFoilCredentials("make", e.target.value)
                }
                value={hydroFoilCredentials.make}
              />
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.displayBtwn}>
                <ContainedBtn
                  text="Cancel"
                  className={classes.outlinedBtn}
                  // disabled={addTendersAndToysLoading}
                  onClick={() => setAddHydroFoilDialogOpen(false)}
                />

                <ContainedBtn
                  text="Add Hydrofoil"
                  // disabled={addTendersAndToysLoading}
                  className={classes.bottomBtn}
                  onClick={addHydrofoil}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FullScreenDialog>

      {/* Add SeaBob Dialog */}
      <FullScreenDialog
        maxWidth="xs"
        fullWidth={true}
        open={addSeaBobsDialogOpen}
        hideDialogHandler={() => setAddSeaBobsDialogOpen(false)}
        style={{
          background: "rgba(255, 255, 255, 0.10)",
          "& .MuiDialog-paper": {
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "20px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box p={2} sx={{ border: "2px solid #0B1A9B", borderRadius: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: "#000" }}>
                Enter SeaBob details that you want to Add
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ color: "#000" }}>Number of SeaBob</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Number of SeaBob"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Number of SeaBob"
                // name="boatName"
                sx={{ my: 1, width: "100%" }}
                className={classes.extraInput}
                value={seaBobCredentials.numberOfSeaBobs}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/^0+/, "");
                  //   seaBobCredentials({
                  //     numberOfSeaBobs: e.target.value.replace(/^0+/, ""),
                  //   });
                  if (inputValue === "" || /^\d+$/.test(inputValue)) {
                    onChangeSeaBobsCredentials("numberOfSeaBobs", inputValue);
                  } else {
                    let numberOfPaddleboards = 0;
                    onChangeSeaBobsCredentials(
                      "numberOfSeaBobs",
                      numberOfPaddleboards
                    );
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.displayBtwn}>
                <ContainedBtn
                  text="Cancel"
                  className={classes.outlinedBtn}
                  // disabled={addTendersAndToysLoading}
                  onClick={() => setAddSeaBobsDialogOpen(false)}
                />

                <ContainedBtn
                  text="Add SeaBob"
                  // disabled={addTendersAndToysLoading}
                  className={classes.bottomBtn}
                  onClick={addSeaBob}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FullScreenDialog>

      {/* Add Paddleboards Dialog */}
      <FullScreenDialog
        maxWidth="sevenHalfS"
        fullWidth={true}
        open={addPaddleBoardsDialogOpen}
        hideDialogHandler={() => setAddPaddleBoardsDialogOpen(false)}
        style={{
          background: "rgba(255, 255, 255, 0.10)",
          "& .MuiDialog-paper": {
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "20px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box p={2} sx={{ border: "2px solid #0B1A9B", borderRadius: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: "#000" }}>
                Enter Paddleboards Details you want to Add
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#000" }}>
                Number of Paddleboards
              </Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Number of Paddleboards"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Number of Paddleboards"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/^0+/, "");
                  if (inputValue === "" || /^\d+$/.test(inputValue)) {
                    onChangePaddleboardsCredentials(
                      "numberOfPaddleboards",
                      inputValue
                    );
                  } else {
                    let numberOfPaddleboards = 0;
                    onChangePaddleboardsCredentials(
                      "numberOfPaddleboards",
                      numberOfPaddleboards
                    );
                  }
                }}
                value={paddleboardsCredentials.numberOfPaddleboards}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography sx={{ color: "#fff" }}>Make</Typography>
              <OutlinedInput
                id="outlined-basic"
                label="Make"
                variant="outlined"
                required={true}
                type="text"
                fullWidth
                placeholder="Make"
                // name="boatName"
                sx={{ my: 1 }}
                className={classes.extraInput}
                onChange={(e) =>
                  onChangePaddleboardsCredentials("make", e.target.value)
                }
                value={paddleboardsCredentials.make}
              />
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.displayBtwn}>
                <ContainedBtn
                  text="Cancel"
                  className={classes.outlinedBtn}
                  // disabled={addTendersAndToysLoading}
                  onClick={() => setAddPaddleBoardsDialogOpen(false)}
                />

                <ContainedBtn
                  text="Add Paddleboards"
                  // disabled={addTendersAndToysLoading}
                  className={classes.bottomBtn}
                  onClick={addPaddleboards}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FullScreenDialog>
    </>
  );
};

export default TenderAndToys;
