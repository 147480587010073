import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Container,
  Box,
  CircularProgress,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
// import SearchIcon from "../../../Assets/Icons/Search.png"
import SearchIcon from "@mui/icons-material/Search";
import ContainedBtn from "../../../Components/ContainedBtn";

import PlaceIcon from "@mui/icons-material/Place";

import { useNavigate } from "react-router-dom";

import {
  setBoatSearchCountry,
  setBoatSearchFormattedAddress,
  setBoatSearchLatLng,
  setBoatSearch,
  setmlsFilterEnables,
} from "../../../Redux/Listing/ListingActions";

import { useSelector, useDispatch } from "react-redux";

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    background: "#fff",
    border: "none !important",
    borderRadius: "10px !important",
    color: "#000 !important",
    fontSize: "18px !important",
    outline: "none !important",
    width: "700px !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    [theme.breakpoints.down("nineS")]: {
      width: "100% !important",
    },
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    color: "gray !important",
  },
  bottomBtn: {
    background: "#0B1A9B !important",
    color: "white important",
    borderRadius: "5px !important",
    "&:hover": {
      background: "transparent !important",
      color: "#0B1A9B !important",
      border: "1px solid #0B1A9B !important",
    },
  },
  printBtn: {
    background: "#0B1A9B !important",
    color: "white important",
    height: "50px !important",
    // padding: '10px 15px !important',
    width: "190px !important",
    borderRadius: "5px !important",
    fontSize: "13px !important",
    alignSelf: 'center',
    "&:hover": {
      background: "transparent !important",
      color: "#0B1A9B !important",
      border: "1px solid #0B1A9B !important",
    },
  },
  suggestionCntnr: {
    boxShadow: "0 2px 4px rgba(0,0,0,.2) !important",
    borderRadius: "0px 0px 16px 16px",
    outline: "0px 0px 16px 16px",
  },
  activeSuggestionRow: {
    cursor: "pointer",
    padding: "4px 16px",
    display: "flex",
    gap: "10px",
  },
  suggestionRow: {
    cursor: "pointer",
    padding: "4px 16px",
    display: "flex",
    gap: "10px",
  },
  smallTypo: {
    color: "#000 !important",
    fontSize: "15px !important",
    fontWeight: "bold !important",
  },
}));

const SearchBarPaper = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const ListingSearch = ({
  mlsListings,
  setPrintListingIDs,
  printListingIDs,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMlsFiltersEnables = useSelector(
    (state) => state.listings.isMlsFiltersEnables
  );

  const [address, setaddress] = useState("");

  const handleChange = (address1) => {
    dispatch(setmlsFilterEnables(true));
    setaddress(address1);
  };

  const handleSelect = (address1) => {
    let country = "";
    let region = "";
    geocodeByAddress(address1)
      .then((results) => {
        results[0]?.address_components.forEach((component) => {
          if (component.types.includes("country")) {
            country = component.long_name;
          }
          // if (component.types.includes('administrative_area_level_1')) {
          //     region = component.long_name;
          // }
        });
        const formattedAddress = results[0]?.formatted_address;
        return getLatLng(results[0]).then((latLng) => {
          // Create an object with all the updated values
          setaddress(formattedAddress);

          const updatedState = {
            formattedAddress: formattedAddress,
            latLng: latLng,
            searchCountry: country,
          };
          // Update the state with all the values at once
          dispatch(setBoatSearch(updatedState));
        });
      })
      .catch((error) => {
        setaddress(address1);
        console.error("Error", error);
      });
  };

  const sendToPrint = () => {
    if (printListingIDs.length > 0) {
      let routeIDs = printListingIDs.join("-");
      navigate(`/generate-pdfs/${routeIDs}`);
    }
  };

  const handelSearch = () => {
    if (address.length == 0) {
      dispatch(
        setBoatSearch({
          latLng: {
            lng: "",
            lat: "",
          },
          formattedAddress: "",
          searchCountry: "",
        })
      );
    } else {
      handleSelect(address);
    }
  };

  return (
    <Grid
      container
      sx={{
        position: "sticky",
        top: "16px",
        zIndex: "199",
        backgroundColor: "#F3F3F3",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          gap: "5px",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <SearchBarPaper>
                  <OutlinedInput
                    className={classes.searchBar}
                    startAdornment={
                      <SearchIcon className={classes.searchIcon} />
                    }
                    endAdornment={
                      <ContainedBtn
                        text="Search"
                        className={classes.bottomBtn}
                        onClick={handelSearch}
                      />
                    }
                    {...getInputProps({
                      placeholder: "Search by city",
                      className: classes.searchBar,
                    })}
                  />
                </SearchBarPaper>
                <div
                  className={classes.suggestionCntnr}
                  style={{
                    backgroundColor: suggestions.length > 0 ? "#ffff " : "",
                    border: suggestions.length > 0 ? "#6c707d80" : "",
                    boxShadow: suggestions.length > 0 ? "1px 1px 5px grey" : "",
                  }}
                >
                  {/* {loading && <div>Searching...</div>} */}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? classes.suggestionRow
                      : classes.activeSuggestionRow;
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <PlaceIcon className="place-mui-icon1" />
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </Box>
        <ContainedBtn
          text="Compare and Export"
          className={classes.printBtn}
          onClick={sendToPrint}
        />
      </Grid>
      {mlsListings?.length > 0 && (
        <Grid item xs={12} mt={2}>
          <Typography className={classes.smallTypo}>
            Check the boxes to compile a Market Overview Comparison
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ListingSearch;
