import React, { useEffect } from 'react'
import {
  Typography,
  Grid,
  Container,
  CircularProgress,
  InputBase,
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { alpha, styled } from '@mui/material/styles';

import ContainedBtn from '../../Components/ContainedBtn';
import sendIcon from "../../Assets/Icons/sendIcon.png"
import ListingStats from './Components/ListingStats';
import Gallery from './Components/Gallery';
import Team from './Components/Team';
import Tips from './Components/Tips';

import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'

import { getAllMYListing } from '../../Redux/Listing/ListingActions';
import { getUserTeam } from '../../Redux/User/UserActions';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  marginLeft: 0,
  width: '70%',
  background: '#F3F3F3',
  borderRadius: '5px'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
    },
  },
}));


const useStyles = makeStyles((theme) => ({
  textCntnr: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  }
}));


const Dashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const uid = localStorage.getItem('uid')


  const currentUser = useSelector((state) => state.user.user)
  const userTeam = useSelector((state) => state.user.userTeam)
  const listingLoading = useSelector((state) => state.listings.loading)

  useEffect(() => {

    // dispatch(getAllMYListing(uid))
    if (userTeam.length == 0) {
      dispatch(getUserTeam())
    }

  }, [])



  return (
    <>
      <Container maxWidth='fl' >
        <Grid container mt={3}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          <Grid item xs={12} className={classes.textCntnr} mt={3}>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: 'xx-large', xs: '30px', sm: '40px ', sevenHalfS: '25px', nineS: '30px', md: '35px', lg: '40px' },
                marginBottom: { xs: '24px', sevenHalfS: '15px', md: '24px' }
              }}
            >
              Welcome {currentUser?.displayName || currentUser?.firstName} {currentUser?.lastName}!
            </Typography>
            {currentUser?.accountType === "viewAndUpload" && <ContainedBtn
              text='Add a Listing'
              sx={{ '&:hover': { color: '#0B1A9B', outline: '1px solid #0B1A9B' }, height: '50px', width: '185px', marginBottom: '10px' }}
              endIcon={<img src={sendIcon} style={{ width: '14px', height: '14px', }} alt="icon" />}
              onClick={() => {
                navigate('/addlisting')
              }}
            />}
          </Grid>
          <Grid item xs={12} >
            <ListingStats />
          </Grid>
          <Grid item xs={12} mt={5} >
            <Gallery />
          </Grid>
          <Grid item xs={12} mt={5} >
            <Team />
          </Grid>
          <Grid item xs={12} mt={5} >
            <Tips />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Dashboard