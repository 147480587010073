import React from 'react'
import {
    Typography,
    Grid,
    Container,
    Box,
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';

import threeUser from "../../../Assets/Icons/threeUser.png"
import show from "../../../Assets/Icons/Show.png"
import Chart from "../../../Assets/Icons/Chart.png"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ListIcon from '@mui/icons-material/List';

import { useSelector, useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    grayBg: {
        backgroundColor: '#F3F3F3',
        borderRadius: '20px'
    },
    textCntnr: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    }

}));


const StatsCardStyle = styled(Box)(({ theme, isBlue }) => ({
    backgroundColor: `${isBlue ? '#0B1A9B' : '#191717'}`,
    borderRadius: '20px',
    // maxWidth: '371px',
    margin: 'auto'
}));

const StatsCard = ({ isBlue, head, number, iconImg, Icon }) => {
    const classes = useStyles();

    return (
        <StatsCardStyle isBlue={isBlue} p={5} >
            <Box className={classes.textCntnr}  >
                <Typography variant='h5' sx={{ marginBottom: { xs: '10px', md: '20px' }, color: 'white' }} >
                    {head}
                </Typography>
                {iconImg && <img src={iconImg} alt="Icon" style={{ width: '28px', height: '28px' }} />}
                {Icon ? Icon : ''}
            </Box>
            <Typography
                sx={{
                    color: 'white',
                    fontSize: { xs: '2rem', ms: '3.75rem' }
                }}
            > {number} </Typography>
        </StatsCardStyle>
    )
}

const StatsView = () => {
    const allMyListings = useSelector((state) => state.listings.allMyListings)

    const calViews = () => {
        let views = 0;
        allMyListings.forEach(element => {
            views += Number(element?.views)
        })
        return views
    }

    return (
        <Grid container spacing={4} mt={1} >
            <Grid item xs={12} nineS={4}   >
                <StatsCard isBlue={true} Icon={<ListIcon sx={{ fontSize: '28px', color: '#fff' }} />} number={allMyListings.length} head='Total Listings' />
            </Grid>
            <Grid item xs={12} nineS={4}   >
                <StatsCard isBlue={false} iconImg={show} number={[...allMyListings.filter(listing => listing?.active == true)].length} head='Active Listings' />
            </Grid>
            <Grid item xs={12} nineS={4}   >
                <StatsCard isBlue={false} Icon={<VisibilityOffIcon sx={{ fontSize: '28px', color: '#fff' }} />} number={[...allMyListings.filter(listing => listing?.active == false)].length} head='In Active Listings' />
            </Grid>
            <Grid item xs={12} nineS={4}   >
                <StatsCard isBlue={false} iconImg={show} number={calViews()} head='Total Views' />
            </Grid>
        </Grid>
    )
}

const ListingStats = () => {
    const classes = useStyles();

    const allMyListings = useSelector((state) => state.listings.allMyListings)
    const listingLoading = useSelector((state) => state.listings.loading)

    return (
        <Grid container className={classes.grayBg} sx={{ padding: { xs: '20px', ms: '40px' } }}  >
            <Grid item xs={12} >
                <Typography variant='h5' sx={{ marginBottom: { xs: '10px' }, fontWeight: 'bold', }} >
                    Listing Stats
                </Typography>
            </Grid>
            <Grid item xs={12} >
                {!listingLoading && <StatsView />}
            </Grid>
        </Grid>
    )
}

export default ListingStats