import React from 'react'
import {
    Typography,
} from "@mui/material"

const SectionHead = ({ title, sx, ...props }) => {
    return (
        <Typography
            variant='h2'
            sx={{ fontFamily: 'Obadiah Pro, sans-serif', fontSize: { xs: 'xx-large', sm: '60px ' }, ...sx }}
            {...props}
        > {title} </Typography>
    )
}

export default SectionHead