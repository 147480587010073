import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Container,
  Box,
  Paper,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Autocomplete,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Country, State } from "country-state-city";

import { useSelector, useDispatch } from "react-redux";

import { getAddListingBoatMakes } from "../../../Redux/Listing/ListingActions";
import { capitalizeFirstLetter } from "../../../App";

const useStyles = makeStyles((theme) => ({
  roundGrayBG: {
    backgroundColor: "#F3F3F3",
    borderRadius: "10px",
  },
}));

const BoatDetails = ({ onChangeListingCredentials, listingCredentials }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [countryCode, setCountryCode] = useState("");
  const [selectCountrytState, setselectCountrytState] = useState([]);
  const [selectCountry, setselectCountry] = useState("");
  const [selectStateCity, setselectStateCity] = useState([]);
  const [selectState, setselectState] = useState("");

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const allCountries = Country.getAllCountries();

  // const boatMake = useSelector((state) => state.listings?.boatMake?.[0])
  const boatMakes = useSelector((state) => state.listings.boatMakes[0]);
  let boatMake = [
    "Bayliner",
    "beneteau",
    "sea ray",
    "yamaha",
    "boston whaler",
    "catalina",
    "formula",
    "azimut",
    "wellcraft",
    "others",
  ];

  let startYear = 1950;
  let endYear = new Date().getFullYear();

  const calculateYear = (startYear, endYear) => {
    let arr = [];

    for (let index = startYear; index <= endYear; index++) {
      arr.push(index);
    }
    return arr;
  };

  const handleSelectCountry = (e) => {
    onChangeListingCredentials("country", e.target.value.split("?")[1]);
    let code = e.target.value.split("?")[0];
    let country = e.target.value.split("?")[1];
    setselectCountry(e.target.value);
    setCountryCode(code);
    setselectCountrytState(State.getStatesOfCountry(code));
    setselectState("");
  };

  const handleSelectState = (e) => {
    let state = e.target.value;
    setselectState(state);
    onChangeListingCredentials("state", state);
  };

  useEffect(() => {
    dispatch(getAddListingBoatMakes());
  }, []);

  return (
    <Box className={classes.roundGrayBG} p={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Boat Name"
            variant="outlined"
            required={true}
            type="text"
            fullWidth
            placeholder="Boat Name"
            name="boatName"
            onChange={(e) =>
              onChangeListingCredentials("boatName", e.target.value)
            }
            value={listingCredentials.boatName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-select-currency"
            select
            required={true}
            label="Please Select Year"
            fullWidth
            onChange={(e) => onChangeListingCredentials("year", e.target.value)}
            value={listingCredentials.year}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: { maxHeight: 200 }, // Set the maximum height here
                },
              },
            }}
          >
            {calculateYear(startYear, endYear)
              .reverse()
              .map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
          </TextField>

          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={calculateYear(startYear, endYear)
              .reverse()
              .map((year) => ({ value: listingCredentials.year, label: year }))}
            // value={listingCredentials.year}
            sx={{ width: "auto" }}
            onChange={(e) => onChangeListingCredentials("year", e.target.value)}
            renderInput={(params) => (
              <TextField {...params} label="Please Select Year" />
            )}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: { maxHeight: 200 }, // Set the maximum height here
                },
              },
            }}
          /> */}
        </Grid>
        {/* <Grid item xs={12} md={6} >
                    <TextField
                        id="outlined-basic"
                        label="Major City the boat/Yacht opperates in"
                        variant="outlined"
                        required={true}
                        type="text"
                        fullWidth
                        placeholder="Major City the boat/Yacht opperates in"
                        onChange={(e) => onChangeListingCredentials("majorCity", e.target.value)}
                        value={listingCredentials.majorCity}
                    />
                </Grid> */}

        <Grid item xs={12} md={6}>
          {/* <TextField
                        id="outlined-select-currency"
                        select
                        required={true}
                        label="Please Select Make"
                        fullWidth
                        onChange={(e) => onChangeListingCredentials("make", e.target.value)}
                        value={listingCredentials.make}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { maxHeight: 200 }, // Set the maximum height here
                                },
                            },
                        }}
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {boatMakes?.map((make) => (
                            <MenuItem key={make} value={make} sx={{ textTransform: 'capitalize' }} >
                                {make}
                            </MenuItem>
                        ))}
                    </TextField> */}

          <Autocomplete
            freeSolo
            id="combo-box-demo"
            options={boatMakes || []} // Ensure boatMakes is not null or undefined
            value={listingCredentials.make || ""} // Set the value to the selected make
            sx={{ width: "auto" }}
            onChange={(event, newValue) => {
              // console.log("newValue", newValue);
              onChangeListingCredentials("make", newValue); // Update the state with the selected or entered make
            }}
            onInputChange={(event, newInputValue) => {
              // console.log("newInputValue", newInputValue);
              onChangeListingCredentials("make", newInputValue); // Update the state with the typed make
            }}
            renderInput={(params) => (
              <TextField {...params} label="Please Select Make" />
            )}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: { maxHeight: 200 }, // Set the maximum height here
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Model"
            variant="outlined"
            required={true}
            type="text"
            fullWidth
            placeholder="Model"
            name="model"
            onChange={(e) =>
              onChangeListingCredentials("model", e.target.value)
            }
            value={listingCredentials.model}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Boat Length in ft"
            variant="outlined"
            required={true}
            type="number"
            fullWidth
            placeholder="Boat Length in ft"
            name="boatLength"
            onChange={(e) => {
              // const inputValue = e.target.value.replace(/^0+/, '');
              // if (/^\d+$/.test(inputValue)) {
              //     onChangeListingCredentials("boatLength", e.target.value)
              // }
              const inputValue = e.target.value.replace(/^0+/, "");

              if (inputValue === "" || /^\d+$/.test(inputValue)) {
                onChangeListingCredentials("boatLength", inputValue);
              }
            }}
            value={listingCredentials.boatLength}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Boat Beam in ft"
            variant="outlined"
            required={true}
            type="number"
            fullWidth
            placeholder="Boat Beam in ft"
            name="beamLength"
            onChange={(e) => {
              const inputValue = e.target.value.replace(/^0+/, "");

              if (inputValue === "" || /^\d+$/.test(inputValue)) {
                onChangeListingCredentials("beamLength", inputValue);
              }
            }}
            value={listingCredentials.beamLength}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Draft"
            variant="outlined"
            // required={true}
            type="text"
            fullWidth
            placeholder="Draft"
            name="draft"
            onChange={(e) =>
              onChangeListingCredentials("draft", e.target.value)
            }
            value={listingCredentials.draft}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="HIN"
            variant="outlined"
            required={true}
            type="text"
            fullWidth
            placeholder="HIN"
            name="hin"
            onChange={(e) => onChangeListingCredentials("hin", e.target.value)}
            value={listingCredentials.hin}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BoatDetails;
