import React, { useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Box,
  Paper,
  Button,
  TextField,
  MenuItem,
  IconButton,
  OutlinedInput,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import DayCharter from "./Components/DayCharter";
import TermCharter from "./Components/TermCharter";

const Pricing = ({ onChangeListingCredentials, listingCredentials }) => {
  // const [pricingType, setPricingType] = React.useState('');

  const handleChange = (event, type) => {
    // setPricingType(type);
    onChangeListingCredentials("pricingType", type);
  };

  let renderPricing = {
    dayCharter: (
      <DayCharter
        onChangeListingCredentials={onChangeListingCredentials}
        listingCredentials={listingCredentials}
      />
    ),
    termCharter: (
      <TermCharter
        onChangeListingCredentials={onChangeListingCredentials}
        listingCredentials={listingCredentials}
      />
    ),
  };

  return (
    <>
      {/* {listingCredentials.pricingType.length === 0 && ( */}
      <ToggleButtonGroup
        color="primary"
        value={listingCredentials.pricingType}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton value="dayCharter">Day Charter</ToggleButton>
        <ToggleButton value="termCharter">Term Charter</ToggleButton>
      </ToggleButtonGroup>
      {/* )} */}
      {renderPricing[listingCredentials.pricingType]}
    </>
  );
};

export default Pricing;
